import React from 'react';
import styled from 'styled-components';
import { PlannedWorkAffectedRoute } from '../../../types';

import pxToViewport from '../../../utils/px-to-viewport-units';

const Bullet = styled.div`
  margin: 0 ${pxToViewport(24)}rem;
  padding: ${pxToViewport(24)}rem;
  font-size: ${pxToViewport(56)}rem;
  line-height: ${pxToViewport(72)}rem;
  font-weight: bold;
  letter-spacing: -${pxToViewport(1.4)}rem;
`;

const LandscapeRouteList: React.FC<{
  affectedRoutes: PlannedWorkAffectedRoute[];
}> = ({ affectedRoutes }) => {
  if (!affectedRoutes?.length) {
    return null;
  }

  const bulletEls = affectedRoutes
    .map(route => {
      const { gtfsRouteId, color, textColor, shortName, longName } = route;
      const name = shortName || longName;

      if (!name) {
        return null;
      }

      return (
        <Bullet
          key={gtfsRouteId}
          style={{
            backgroundColor: color ? `#${color}` : '',
            color: textColor ? `#${textColor}` : '',
          }}
        >
          {name}
        </Bullet>
      );
    })
    .filter(Boolean)
    .slice(0, 4);

  return <>{bulletEls}</>;
};

export default LandscapeRouteList;
