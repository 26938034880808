import React from 'react';
import styled from 'styled-components';

import {
  MESSAGE_TYPE_DESCRIPTIONS,
  databaseToGQLEnum,
  ScreenContentAffectedRoute,
} from '@mta-live-media-manager/shared';
import Alert from './Body/Alert';
import Footer from './Body/Footer';
import PortraitHeader from './Body/PortraitHeader';
import PortraitRouteList from './Body/PortraitRouteList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

interface PortraitProps {
  affectedRoutes: ScreenContentAffectedRoute[];
  content: string;
  messageType: string;
  additionalContent?: string;
  allRoutesAffected?: boolean;
  hasRouteList?: boolean;
  isUpdate?: boolean;
  publishAt?: string;
  title?: string;
}

const Portrait: React.FC<PortraitProps> = ({
  affectedRoutes,
  allRoutesAffected,
  content,
  additionalContent,
  hasRouteList,
  isUpdate,
  messageType,
  publishAt,
  title,
}) => {
  const selectedTitle = (() => {
    try {
      if (title) {
        return title;
      }

      if (MESSAGE_TYPE_DESCRIPTIONS[messageType]) {
        return MESSAGE_TYPE_DESCRIPTIONS[messageType];
      }

      if (MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)]) {
        return MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)];
      }
    } catch (e) {
      return 'Status';
    }

    return 'Status';
  })();

  return (
    <Container>
      <PortraitHeader title={selectedTitle} />
      {hasRouteList && (
        <PortraitRouteList
          affectedRoutes={affectedRoutes}
          allRoutesAffected={allRoutesAffected}
        />
      )}
      <Alert
        content={content}
        additionalContent={additionalContent}
        publishedAt={publishAt}
        isUpdate={isUpdate}
        orientation="portrait"
      />
      <Footer footerText="Visit mta.info for more information" />
    </Container>
  );
};

export default Portrait;
