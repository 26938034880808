"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPWTemplateHeader = void 0;
// Get next day of the week from a given date
// `dayNumber` starting 0 from Sunday and ending 6 on Saturday
const getNextDayOfWeek = (dayNumber, date = new Date()) => {
    let delta = dayNumber - date.getDay();
    if (delta <= 0) {
        delta += 7;
    }
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + delta);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};
const formatAMPM = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const timeConvention = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutesStr} ${timeConvention}`;
    return strTime;
};
const formatAMPMWithDayname = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayName = days[date.getDay()];
    return `${dayName} ${formatAMPM(date)}`;
};
const dayWithSuffix = (date) => {
    const day = date.getDate();
    const suffix = ['st', 'nd', 'rd'][((((day + 90) % 100) - 10) % 10) - 1] || 'th';
    return `${day}${suffix}`;
};
const formatMonthWithDay = (date) => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const monthName = months[date.getMonth()];
    return `${monthName} ${dayWithSuffix(date)}`;
};
const getWeekNumber = (date) => {
    const dayNum = date.getUTCDay() || 7;
    date.setUTCDate(date.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};
const isSameISOWeek = (startDate, endDate) => {
    return getWeekNumber(startDate) === getWeekNumber(endDate);
};
const ACTIVE_WORK_PERIOD_PREFIX = 'Now Until';
var Days;
(function (Days) {
    Days["FRIDAY"] = "FRIDAY";
    Days["MONDAY"] = "MONDAY";
    Days["SATURDAY"] = "SATURDAY";
    Days["SUNDAY"] = "SUNDAY";
    Days["THURSDAY"] = "THURSDAY";
    Days["TUESDAY"] = "TUESDAY";
    Days["WEDNESDAY"] = "WEDNESDAY";
})(Days || (Days = {}));
var MessageDaysOfWeek;
(function (MessageDaysOfWeek) {
    MessageDaysOfWeek["INDIVIDUAL_DAYS"] = "INDIVIDUAL_DAYS";
    MessageDaysOfWeek["WEEKDAYS"] = "WEEKDAYS";
    MessageDaysOfWeek["WEEKEND"] = "WEEKEND";
})(MessageDaysOfWeek || (MessageDaysOfWeek = {}));
var MessageTimeOfDay;
(function (MessageTimeOfDay) {
    MessageTimeOfDay["DAYS"] = "DAYS";
    MessageTimeOfDay["NIGHTS"] = "NIGHTS";
})(MessageTimeOfDay || (MessageTimeOfDay = {}));
const NEXT_WEEKDAY_FUNCTIONS = {
    nextMonday: date => getNextDayOfWeek(1, date),
    nextTuesday: date => getNextDayOfWeek(2, date),
    nextWednesday: date => getNextDayOfWeek(3, date),
    nextThursday: date => getNextDayOfWeek(4, date),
    nextFriday: date => getNextDayOfWeek(5, date),
    nextSaturday: date => getNextDayOfWeek(6, date),
    nextSunday: date => getNextDayOfWeek(0, date),
};
const differenceInDays = (end, start) => {
    const msPerDay = 1000 * 60 * 60 * 24;
    const startDate = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
    return Math.floor((endDate - startDate) / msPerDay);
};
const isSameDay = (first, second) => first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
const addHours = (date, hours) => {
    const copiedDate = new Date(date.getTime());
    copiedDate.setHours(copiedDate.getHours() + hours);
    return copiedDate;
};
const subDays = (date, days) => {
    const copiedDate = new Date(date.getTime());
    copiedDate.setDate(date.getDate() - days);
    return copiedDate;
};
const getNextWeekdayFunction = (day) => {
    return NEXT_WEEKDAY_FUNCTIONS[`next${day.slice(0, 1)}${day.slice(1).toLowerCase()}`];
};
/**
 *
 * @param activeWorkPeriods list of all the active work period of a planned work
 * @returns the nearest active work period, wether it's active or not
 */
const getNearestActiveWorkPeriod = (activeWorkPeriods) => {
    const now = new Date().getTime();
    return activeWorkPeriods.reduce((previousActiveWorkPeriod, currentActiveWorkPeriod) => {
        if (currentActiveWorkPeriod?.end &&
            now < currentActiveWorkPeriod.end.getTime() &&
            currentActiveWorkPeriod.start < previousActiveWorkPeriod.start) {
            return currentActiveWorkPeriod;
        }
        return previousActiveWorkPeriod;
    }, activeWorkPeriods[0]);
};
const getPWTemplateHeader = ({ override, activeWorkPeriods, daysOfWeek, timeOfDay, daysBeforeWorkPeriod, }) => {
    if (override) {
        return override;
    }
    const now = new Date();
    // Filter periods that are in the past in case non active periods were passed
    const filteredActiveWorkPeriods = activeWorkPeriods.filter(a => a.end && a.end.getTime() > now.getTime());
    if (!filteredActiveWorkPeriods.length) {
        return 'Not Active';
    }
    const nearestActiveWorkPeriod = getNearestActiveWorkPeriod(filteredActiveWorkPeriods);
    // The work period is already active
    if (nearestActiveWorkPeriod.start.getTime() < now.getTime()) {
        if (nearestActiveWorkPeriod.end &&
            differenceInDays(nearestActiveWorkPeriod.end, now) > 7) {
            return `${ACTIVE_WORK_PERIOD_PREFIX} ${formatMonthWithDay(nearestActiveWorkPeriod?.end)}`;
        }
        if (nearestActiveWorkPeriod.end &&
            isSameDay(now, nearestActiveWorkPeriod.end)) {
            return `${ACTIVE_WORK_PERIOD_PREFIX} ${formatAMPM(nearestActiveWorkPeriod.end)}`;
        }
        return nearestActiveWorkPeriod.end
            ? `${ACTIVE_WORK_PERIOD_PREFIX} ${formatAMPMWithDayname(nearestActiveWorkPeriod.end)}`
            : '';
    }
    // Work period is in the future
    const screenExpectedDisplayStart = subDays(nearestActiveWorkPeriod.start, daysBeforeWorkPeriod ?? 0);
    // The date when the template is exptected to be shown could already be in the past
    // In that case, we use the current date instead
    const screenDisplayStart = screenExpectedDisplayStart.getTime() > now.getTime()
        ? screenExpectedDisplayStart
        : now;
    if (daysOfWeek.days?.length && daysOfWeek.days.length <= 2) {
        const nightSuffix = timeOfDay === MessageTimeOfDay.NIGHTS ? 'Night' : '';
        if (daysOfWeek.days.length === 1) {
            const nextInstanceOfDay = getNextWeekdayFunction(daysOfWeek.days[0])(screenDisplayStart);
            return `${isSameISOWeek(now, screenDisplayStart) ||
                isSameISOWeek(screenDisplayStart, nextInstanceOfDay)
                ? 'This'
                : 'Next'} ${daysOfWeek.days[0]} ${nightSuffix}`;
        }
        if (daysOfWeek.days.length === 2) {
            const nextInstanceOfFirstDay = getNextWeekdayFunction(daysOfWeek.days[0])(screenDisplayStart);
            const firstDayPrefix = isSameISOWeek(now, screenDisplayStart) ||
                isSameISOWeek(screenDisplayStart, nextInstanceOfFirstDay)
                ? 'This'
                : 'Next';
            const nextInstanceOfSecondDay = getNextWeekdayFunction(daysOfWeek.days[1])(screenDisplayStart);
            const secondDayPrefix = (() => {
                const secondDayPrefix = isSameISOWeek(now, screenDisplayStart) ||
                    isSameISOWeek(screenDisplayStart, nextInstanceOfSecondDay)
                    ? 'This'
                    : 'Next';
                if (secondDayPrefix !== firstDayPrefix) {
                    return `${secondDayPrefix} `;
                }
                return '';
            })();
            return `${firstDayPrefix} ${daysOfWeek.days[0].slice(0, 3)} & ${secondDayPrefix}${daysOfWeek.days[1].slice(0, 3)} ${nightSuffix}`;
        }
    }
    // If the display start is not on the same week as the start of the next active work then it means it's on the next
    // The week starts on Monday 5:00 AM for the MTA, and the `daysBeforeWorkPeriod` has a limit of 7 days
    // What should we name this ;-;? Maybe we can use an enum instead?
    const isDisplayStartOnSameWeekAsActiveWorkPeriod = nearestActiveWorkPeriod.start.getTime() <
        addHours(getNextDayOfWeek(1, screenDisplayStart), 5).getTime();
    if (daysOfWeek?.group === MessageDaysOfWeek.WEEKDAYS ||
        (daysOfWeek?.days?.length ?? 0) > 2) {
        const weekSuffix = isDisplayStartOnSameWeekAsActiveWorkPeriod
            ? 'This Week'
            : 'Next Week';
        if (timeOfDay === MessageTimeOfDay.DAYS) {
            return `Days ${weekSuffix}`;
        }
        if (timeOfDay === MessageTimeOfDay.NIGHTS) {
            return `Nights ${weekSuffix}`;
        }
        return weekSuffix;
    }
    if (daysOfWeek.group === MessageDaysOfWeek.WEEKEND) {
        const weekendSuffix = isDisplayStartOnSameWeekAsActiveWorkPeriod
            ? 'This Weekend'
            : 'Next Weekend';
        if (timeOfDay === MessageTimeOfDay.NIGHTS) {
            return `Nights ${weekendSuffix}`;
        }
        return weekendSuffix;
    }
    return '';
};
exports.getPWTemplateHeader = getPWTemplateHeader;
