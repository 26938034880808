import React from 'react';

const ADAIcon: React.FC<{
  size: number;
}> = ({ size }) => {
  return (
    <svg
      style={{
        display: 'flex',
        flex: `0 0 ${size}rem`,
      }}
      height={`${size}rem`}
      width={`${size}rem`}
      viewBox="0 0 895.5 895.5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#007AC2"
        d="M895.5 111c0-60.9-50.1-111-111-111H111.1C49.7 0 0 49.7 0 111v673.4c0 61.3 49.7 111 111 111h673.4c60.9 0 111-50.1 111-111l.1-673.4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M386 205.1c-35.3-.2-63.8-29.1-63.6-64.4 0-34 30.4-64.5 63.6-64.5 37.6 0 64.5 30.4 64.5 64.5 0 37.5-26.9 64.4-64.5 64.4zm386.8 508.6c-7.2 3.6-13.4 5.4-17.9 5.4-13.4 0-25.1-5.4-34-17l-90.4-159.4h-232c-13.4 0-26.9-8.1-34.9-18.8-10.5-8.8-17.2-21.3-18.8-34.9L326 288.4c-1.8-16.1 3.6-31.3 15.2-44.8 10.7-12.5 25.1-19.7 42.1-19.7h24.2c29.6 0 53.7 25.1 56.4 52.8l4.5 44.8H626c15.2 0 26.9 13.4 26.9 26.9 0 17.9-11.6 28.7-26.9 28.7H472.8l9 74.3H643c11.6 0 24.2 6.3 29.6 18.8l113.7 196.1c9.8 17.9 4.4 39.4-13.5 47.4zm-328.6 21.5c97.6 0 178.2-76.1 181.8-173.7l49.3 88.7c-34.9 89.5-126.3 154.9-231 154.9-138.8 0-249-111-249-249.8 0-90.4 47.5-170.1 120.9-215.8l9.9 77c-39.4 35.8-62.7 84.2-62.7 138.8-.1 99.3 80.5 179.9 180.8 179.9z"
      />
    </svg>
  );
};

export default ADAIcon;
