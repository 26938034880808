"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGtfsTextReplacement = exports.SHUTTLE_TRAINS = exports.GTFS_RT_TEXT_REPLACEMENTS = exports.LONG_GTFS_TEXT_REPLACEMENTS = exports.SHORT_GTFS_TEXT_REPLACEMENTS = exports.getFlattenedGtfsKey = void 0;
const getFlattenedGtfsKey = (id) => {
    return id.toLowerCase().replace(/:+/g, '-');
};
exports.getFlattenedGtfsKey = getFlattenedGtfsKey;
exports.SHORT_GTFS_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': '42 St S',
    'mtasbwy-h': 'Rockaway Park S',
    'mtasbwy-fs': 'Franklin Av S', // SF
};
exports.LONG_GTFS_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': '42 St Shuttle',
    'mtasbwy-h': 'Rockaway Park Shuttle',
    'mtasbwy-fs': 'Franklin Av Shuttle', // SF
};
exports.GTFS_RT_TEXT_REPLACEMENTS = {
    'mtasbwy-gs': 'S',
    'mtasbwy-h': 'H',
    'mtasbwy-fs': 'FS', // SF
};
exports.SHUTTLE_TRAINS = ['S', 'H', 'FS'];
const getGtfsTextReplacement = ({ id, isShort, }) => {
    const flattenedKey = (0, exports.getFlattenedGtfsKey)(id);
    if (isShort) {
        return exports.SHORT_GTFS_TEXT_REPLACEMENTS[flattenedKey];
    }
    return exports.LONG_GTFS_TEXT_REPLACEMENTS[flattenedKey];
};
exports.getGtfsTextReplacement = getGtfsTextReplacement;
