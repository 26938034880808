"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.constructMultiEvents = exports.constructEvents = exports.generateSingleDuration = void 0;
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const human_readable_durations_1 = require("./human-readable-durations");
const event_helpers_1 = require("./event-helpers");
const constructLongTermDayTimeString = (event, notSameDays, firstDuration, isDirectOngoing = false) => {
    const { weekdays: allWeekdays, dates } = (0, event_helpers_1.generateDates)(event, notSameDays);
    const areAllDurationsTheSame = (() => {
        for (let i = 1; i < event.length; i++) {
            if (i > 0) {
                const prevDuration = event[i - 1];
                const currentDuration = event[i];
                if (prevDuration.startWeekday !== currentDuration.startWeekday ||
                    prevDuration.endWeekday !== currentDuration.endWeekday ||
                    prevDuration.startTime !== currentDuration.startTime ||
                    prevDuration.endTime !== currentDuration.endTime) {
                    return false;
                }
            }
        }
        return true;
    })();
    if (areAllDurationsTheSame &&
        firstDuration.end &&
        (0, date_fns_1.differenceInDays)(firstDuration.end, firstDuration.start) > 0) {
        return `${firstDuration.startWeekday} ${firstDuration.startTime} to ${firstDuration.endWeekday} ${firstDuration.endTime}${isDirectOngoing ? `, ${dates}` : ''}`;
    }
    else {
        return `${isDirectOngoing ? `${dates}, ` : ''}${allWeekdays}, ${firstDuration.startTime} to ${firstDuration.endTime}${(0, event_helpers_1.generateSameDayDurationsString)(firstDuration.sameDayDurations)}`;
    }
};
const generateEventOngoing = (allWeeks, event, notSameDays, ongoingText, type) => {
    const firstWeek = allWeeks[0];
    const firstDuration = firstWeek[0];
    const showStartTime = type !== human_readable_durations_1.DurationsType.CADENCE;
    let longTermString = constructLongTermDayTimeString(event, notSameDays, firstDuration, type === human_readable_durations_1.DurationsType.DIRECT);
    return [
        longTermString,
        `${firstDuration.startMonth} ${firstDuration.startDate}${showStartTime ? ` ${firstDuration.startTime}` : ''} - ${ongoingText}`,
    ];
};
const generateEventMultiWeek = (allWeeks, singleDurations, event, notSameDays) => {
    const firstWeek = allWeeks[0];
    const firstDuration = firstWeek[0];
    const lastWeek = allWeeks[allWeeks.length - 1];
    const lastDuration = lastWeek[lastWeek.length - 1];
    const allDatesByWeek = allWeeks.map(x => (0, event_helpers_1.generateDates)(x, notSameDays).dates);
    const isLessThanSevenDays = (0, date_fns_1.differenceInCalendarDays)(lastDuration?.end ?? lastDuration.start, firstDuration.start) < 7;
    const { dates: allDates, weekdays: allWeekdays, multipleRangesCoupledByWeek, } = (0, event_helpers_1.generateDates)(event, notSameDays, !isLessThanSevenDays);
    const dates = isLessThanSevenDays
        ? allDates
        : (0, event_helpers_1.formatToSentence)(allDatesByWeek);
    const daysTimes = singleDurations && !firstDuration.sameDay && !multipleRangesCoupledByWeek
        ? `${firstDuration.startWeekday} ${firstDuration.startTime} to ${lastDuration.endWeekday} ${lastDuration.endTime}`
        : `${allWeekdays}, ${firstDuration.startTime} to ${firstDuration.endTime}${(0, event_helpers_1.generateSameDayDurationsString)(firstDuration.sameDayDurations)}`;
    return `${dates}, ${daysTimes}`;
};
const generateEventLongTerm = (allWeeks, event, notSameDays) => {
    const firstWeek = allWeeks[0];
    const firstDuration = firstWeek[0];
    const lastWeek = allWeeks[allWeeks.length - 1];
    const lastDuration = lastWeek[lastWeek.length - 1];
    const dates = `${firstDuration.startMonth} ${firstDuration.startDate} - ${lastDuration.endMonth} ${lastDuration.endDate}`;
    return `${constructLongTermDayTimeString(event, notSameDays, firstDuration)}, ${dates}`;
};
/**
 * Builds a human-readable description of durations for a single event or multiple events on different dates
 */
const generateSingleDuration = (duration, isOngoing, ongoingText, mappedExceptions = []) => {
    const startWeekDay = `${duration.sameDay
        ? (0, event_helpers_1.formatDate)(duration.start, 'cccc')
        : duration.startWeekday}`;
    const exceptions = mappedExceptions.length
        ? ` (${(0, event_helpers_1.buildExceptionsStringFromInput)(mappedExceptions)})`
        : '';
    if (duration.sameDay) {
        return `${duration.startMonth} ${duration.startDate}, ${startWeekDay}, ${duration.startTime} to ${duration.endTime}${exceptions}`;
    }
    const dates = duration.startMonth === duration.endMonth
        ? `${duration.startMonth} ${duration.startDate} - ${duration.endDate}`
        : `${duration.startMonth} ${duration.startDate} - ${duration.endMonth} ${duration.endDate}`;
    if (isOngoing) {
        return `${duration.startMonth} ${duration.startDate} ${duration.startTime} - ${ongoingText}${exceptions}`;
    }
    return `${dates}, ${startWeekDay} ${duration.startTime} to ${duration.endWeekday} ${duration.endTime}${exceptions}`;
};
exports.generateSingleDuration = generateSingleDuration;
const constructEvents = (events, isOngoing, ongoingText, type, mappedExceptions = []) => {
    // If we don't have any input exceptions, we generate the exceptions from the input events
    const shouldGenerateExceptions = !mappedExceptions?.length;
    const allEvents = [];
    let eventAddendum = '';
    events.forEach((event, index) => {
        if (event[0].skipListingDurations) {
            return;
        }
        let eventStringToAdd = '';
        const groupByWeek = (0, lodash_1.groupBy)(event, result => (0, date_fns_1.getWeek)(result.start, {
            weekStartsOn: 1,
        }));
        const allWeeks = Object.values(groupByWeek).sort((weekA, weekB) => {
            return weekA[0].start < weekB[0].start ? -1 : 1;
        });
        const singleDurations = allWeeks.every(x => x.length === 1);
        const notSameDays = allWeeks.every(x => x.every(d => !d.sameDay));
        const isLastEvent = index === events.length - 1;
        if (isOngoing) {
            const [ongoingEventString, ongoingAddendum] = generateEventOngoing(allWeeks, event, notSameDays, ongoingText, type);
            eventStringToAdd = ongoingEventString;
            if (!eventAddendum || type === human_readable_durations_1.DurationsType.DIRECT) {
                eventAddendum = ongoingAddendum;
            }
        }
        else if (allWeeks.length < 4) {
            eventStringToAdd = generateEventMultiWeek(allWeeks, singleDurations, event, notSameDays);
        }
        else {
            eventStringToAdd = generateEventLongTerm(allWeeks, event, notSameDays);
        }
        const exceptions = !isOngoing && shouldGenerateExceptions ? (0, event_helpers_1.generateExceptions)(event) : '';
        eventStringToAdd = exceptions.length
            ? `${eventStringToAdd} (${exceptions})`
            : eventStringToAdd;
        if (isLastEvent && isOngoing && type === human_readable_durations_1.DurationsType.DIRECT) {
            eventStringToAdd = eventAddendum;
        }
        else {
            eventStringToAdd = `${eventStringToAdd}${isLastEvent && eventAddendum ? `, ${eventAddendum}` : ''}`;
        }
        const sameEventStringIndex = allEvents.indexOf(eventStringToAdd);
        if (sameEventStringIndex !== -1) {
            allEvents.splice(sameEventStringIndex, 1);
        }
        allEvents.push(eventStringToAdd);
    });
    return `${(0, event_helpers_1.formatToSentence)(allEvents)}${shouldGenerateExceptions
        ? ''
        : ` (${(0, event_helpers_1.buildExceptionsStringFromInput)(mappedExceptions, events.reduce((a, b) => [...a, ...b], []))})`}`;
};
exports.constructEvents = constructEvents;
// multi events
const generateMultiEventMultiWeek = (allWeeks, allWeekdays) => {
    const times = [];
    const dates = allWeeks.map(x => (0, event_helpers_1.generateDates)((0, lodash_1.uniqBy)(x, date => `${date.startMonth} ${date.startDate}`))
        .dates);
    allWeeks.forEach(week => {
        week.forEach(date => {
            if (!date.timeException &&
                !times.includes(`${date.startTime} - ${date.endTime}`)) {
                times.push(`${date.startTime} - ${date.endTime}`);
            }
        });
    });
    return `${(0, event_helpers_1.formatToSentence)(dates)}, ${allWeekdays}, ${(0, event_helpers_1.formatToSentence)(times)}`;
};
const generateMultiEventLongTerm = (allWeeks, allWeekdays) => {
    const firstWeek = (0, lodash_1.uniqBy)(allWeeks[0], date => `${date.startMonth} ${date.startDate}`);
    const lastWeek = (0, lodash_1.uniqBy)(allWeeks[allWeeks.length - 1], date => `${date.startMonth} ${date.startDate}`);
    const firstDuration = firstWeek[0];
    const lastDuration = lastWeek[lastWeek.length - 1];
    const times = [];
    allWeeks.forEach(week => {
        week.forEach(date => {
            if (!date.timeException &&
                !times.includes(`${date.startTime} - ${date.endTime}`)) {
                times.push(`${date.startTime} - ${date.endTime}`);
            }
        });
    });
    return `${allWeekdays}, ${(0, event_helpers_1.formatToSentence)(times)}, ${firstDuration.startMonth} ${firstDuration.startDate} - ${lastDuration.endMonth} ${lastDuration.endDate}`;
};
/**
 * Builds a human-readable description of durations for multiple events on the same dates
 */
const constructMultiEvents = (events, mappedExceptions = []) => {
    let multiEventString = '';
    const allDurations = events.reduce((a, b) => [...a, ...b], []);
    const groupByWeek = (0, lodash_1.groupBy)(allDurations, result => (0, date_fns_1.getWeek)(result.start, {
        weekStartsOn: 1,
    }));
    const allWeeks = Object.values(groupByWeek);
    const allWeekdays = (0, event_helpers_1.generateDates)(allDurations).weekdays;
    if (allWeeks.length < 4) {
        multiEventString = generateMultiEventMultiWeek(allWeeks, allWeekdays);
    }
    else {
        multiEventString = generateMultiEventLongTerm(allWeeks, allWeekdays);
    }
    const exceptions = mappedExceptions.length
        ? (0, event_helpers_1.buildExceptionsStringFromInput)(mappedExceptions)
        : (0, event_helpers_1.generateExceptions)(allDurations);
    if (exceptions.length) {
        multiEventString += ` (${exceptions})`;
    }
    return multiEventString;
};
exports.constructMultiEvents = constructMultiEvents;
