import React from 'react';
import styled from 'styled-components';

import pxToViewport from '../../../utils/px-to-viewport-units';

const Title = styled.h1`
  line-height: ${pxToViewport(72)}rem;
  font-size: ${pxToViewport(54)}rem;
  margin: 0;
`;

const PageHeader = styled.header`
  background: #000;
  color: #fff;
  padding: ${pxToViewport(24)}rem ${pxToViewport(24)}rem;
`;

const LandscapeHeader: React.FC<{
  status?: string;
}> = ({ status }) => {
  return (
    <PageHeader>
      <Title>{status}</Title>
    </PageHeader>
  );
};

export default LandscapeHeader;
