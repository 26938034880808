import React from 'react';

import { ScreenContentAffectedRoute } from '@mta-live-media-manager/shared';
import Portrait from './Portrait';
import Landscape from './Landscape';
import Dup from './Dup';

import { FeedId } from '../../../../../types/feed';

const Message: React.FC<{
  affectedRoutes: ScreenContentAffectedRoute[];
  content: string;
  feedId: FeedId;
  messageType: string;
  additionalContent?: string;
  allRoutesAffected?: boolean;
  isLandscape?: boolean;
  isDup?: boolean;
  isUpdate?: boolean;
  publishAt?: string;
  title?: string;
}> = ({
  feedId,
  title,
  content,
  additionalContent,
  isLandscape,
  isDup,
  isUpdate,
  messageType,
  affectedRoutes,
  allRoutesAffected,
  publishAt,
}) => {
  const isTrain = feedId === FeedId.MNR || feedId === FeedId.LIRR;

  if (isDup) {
    return <Dup content={content} />;
  }

  if (isLandscape) {
    return (
      <Landscape
        hasRouteList={isTrain}
        affectedRoutes={affectedRoutes}
        allRoutesAffected={isTrain && allRoutesAffected}
        content={content}
        additionalContent={additionalContent}
        messageType={messageType}
        isUpdate={isUpdate}
        publishAt={publishAt}
        title={title}
      />
    );
  }

  return (
    <Portrait
      hasRouteList={isTrain}
      affectedRoutes={affectedRoutes}
      allRoutesAffected={isTrain && allRoutesAffected}
      content={content}
      additionalContent={additionalContent}
      messageType={messageType}
      isUpdate={isUpdate}
      publishAt={publishAt}
      title={title}
    />
  );
};

export default Message;
