import React from 'react';
import styled from 'styled-components';
import MTALogo from '../../Icons/MTALogo';

const PageFooter = styled.footer<{ isDup: boolean }>`
  ${({ isDup }) => `
    background: ${isDup ? '#FFF' : '#000'};
    color: ${isDup ? '#000' : '#FFF'};
    display: flex;
    padding: 0 ${isDup ? '1vw' : '3.704vw'};
    justify-content: space-between;
    font-size: ${isDup ? '2vw' : '2.037vw;'};
    line-height: ${isDup ? '3.556vw' : '5.556vw'};
    font-weight: bold;

    svg {
      display: block;
      vertical-align: baseline;
      margin-top: ${isDup ? '0.2vw' : '1.204vw'};
    }
  `}
`;

const Footer = ({
  footerText,
  isDup = false,
}: {
  footerText?: string;
  isDup?: boolean;
}): JSX.Element => (
  <PageFooter isDup={isDup}>
    {footerText}
    <MTALogo fill={isDup ? '#000' : '#FFF'} size={3.148} />
  </PageFooter>
);

export default Footer;
