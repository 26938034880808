"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClearedMessageTypesByFeedAndCategory = exports.getMessageTypesByFeedAndCategory = exports.ARCHIVED_MESSAGE_TYPE_ASSIGNMENTS = exports.MESSAGE_TYPE_ASSIGNMENTS = exports.MessageTypeCategory = exports.MESSAGE_TYPE_DESCRIPTIONS = exports.databaseToGQLEnum = exports.FeedId = void 0;
const message_type_1 = require("./message-type");
var FeedId;
(function (FeedId) {
    FeedId["NYCTBus"] = "nyct-bus";
    FeedId["NYCTSubway"] = "nyct-subway";
    FeedId["MNR"] = "mnr";
    FeedId["LIRR"] = "lirr";
    FeedId["BT"] = "bridges-tunnels";
    FeedId["OutfrontQA"] = "outfront-qa";
    FeedId["OutfrontDEV"] = "outfront-dev";
})(FeedId = exports.FeedId || (exports.FeedId = {}));
// MessageType values in the database are in kebab-case, while MessageType
// values from GraphQL (and the MessageType enum, above) are in
// SCREAMING_SNAKE_CASE. This function converts the former to the latter.
const databaseToGQLEnum = (input) => {
    const output = input.toUpperCase().replace(/-/g, '_');
    if (!Object(message_type_1.default)[output]) {
        throw new Error(`Invalid message type "${input}"`);
    }
    return output;
};
exports.databaseToGQLEnum = databaseToGQLEnum;
exports.MESSAGE_TYPE_DESCRIPTIONS = {
    [message_type_1.default.ESSENTIAL_SERVICE]: 'Essential Service',
    [message_type_1.default.NO_SCHEDULED_SERVICE]: 'No Scheduled Service',
    [message_type_1.default.WEEKEND_SERVICE]: 'Weekend Service',
    [message_type_1.default.WEEKDAY_SERVICE]: 'Weekday Service',
    [message_type_1.default.SUNDAY_SCHEDULE]: 'Sunday Schedule',
    [message_type_1.default.SATURDAY_SCHEDULE]: 'Saturday Schedule',
    [message_type_1.default.HOLIDAY_SERVICE]: 'Holiday Service',
    [message_type_1.default.BUSING]: 'Busing',
    [message_type_1.default.PLANNED_DETOUR]: 'Planned - Detour',
    [message_type_1.default.EXTRA_SERVICE]: 'Extra Service',
    [message_type_1.default.SPECIAL_SCHEDULE]: 'Special Schedule',
    [message_type_1.default.PLANNED_WORK]: 'Planned Work',
    [message_type_1.default.ON_OR_CLOSE]: 'On or Close',
    [message_type_1.default.SLOW_SPEEDS]: 'Slow Speeds',
    [message_type_1.default.SOME_DELAYS]: 'Some Delays',
    [message_type_1.default.SPECIAL_EVENT]: 'Special Event',
    [message_type_1.default.CROWDING]: 'Crowding',
    [message_type_1.default.STATIONS_SKIPPED]: 'Stations Skipped',
    [message_type_1.default.DELAYS]: 'Delays',
    [message_type_1.default.EXPRESS_TO_LOCAL]: 'Express to Local',
    [message_type_1.default.SOME_REROUTES]: 'Some Reroutes',
    [message_type_1.default.LOCAL_TO_EXPRESS]: 'Local to Express',
    [message_type_1.default.DETOURS]: 'Detours',
    [message_type_1.default.SERVICE_CHANGE]: 'Service Change',
    [message_type_1.default.TRAINS_REROUTED]: 'Trains Rerouted',
    [message_type_1.default.PART_SUSPENDED]: 'Part Suspended',
    [message_type_1.default.MULTIPLE_IMPACTS]: 'Multiple Impacts',
    [message_type_1.default.SUSPENDED]: 'Suspended',
    [message_type_1.default.BOARDING_CHANGE]: 'Boarding Change',
    [message_type_1.default.EXPECT_DELAYS]: 'Expect Delays',
    [message_type_1.default.BUSES_DETOURED]: 'Buses Detoured',
    [message_type_1.default.PLANNED_BOARDING_CHANGE]: 'Planned - Boarding Change',
    [message_type_1.default.PLANNED_STATIONS_SKIPPED]: 'Planned - Stations Skipped',
    [message_type_1.default.PLANNED_EXPRESS_TO_LOCAL]: 'Planned - Express to Local',
    [message_type_1.default.PLANNED_LOCAL_TO_EXPRESS]: 'Planned - Local to Express',
    [message_type_1.default.PLANNED_BUSES_DETOURED]: 'Planned - Buses Detoured',
    [message_type_1.default.PLANNED_TRAINS_REROUTED]: 'Planned - Trains Rerouted',
    [message_type_1.default.PLANNED_PART_SUSPENDED]: 'Planned - Part Suspended',
    [message_type_1.default.PLANNED_MULTIPLE_IMPACTS]: 'Planned - Multiple Impacts',
    [message_type_1.default.NO_MIDDAY_SERVICE]: 'No Midday Service',
    [message_type_1.default.NO_OVERNIGHT_SERVICE]: 'No Overnight Service',
    [message_type_1.default.NO_WEEKEND_SERVICE]: 'No Weekend Service',
    [message_type_1.default.STATION_NOTICE]: 'Station Notice',
    [message_type_1.default.PLANNED_SUSPENDED]: 'Planned - Suspended',
    [message_type_1.default.WEEKDAY_SCHEDULE]: 'Weekday Schedule',
    [message_type_1.default.WEEKEND_SCHEDULE]: 'Weekend Schedule',
    [message_type_1.default.REDUCED_SERVICE]: 'Reduced Service',
    [message_type_1.default.SUBSTITUTE_BUSES]: 'Substitute Buses',
    [message_type_1.default.PLANNED_SUBSTITUTE_BUSES]: 'Planned - Substitute Buses',
    [message_type_1.default.MULTIPLE_CHANGES]: 'Multiple Changes',
    [message_type_1.default.PLANNED_MULTIPLE_CHANGES]: 'Planned - Multiple Changes',
    [message_type_1.default.ARRIVAL_INFORMATION_OUTAGE]: 'Arrival Information Outage',
    [message_type_1.default.SHUTTLE_BUSES_DETOURED]: 'Shuttle Buses Detoured',
    [message_type_1.default.INFORMATION_OUTAGE]: 'Information Outage',
    [message_type_1.default.SPECIAL_NOTICE]: 'Special Notice',
    [message_type_1.default.PLANNED_EXTRA_TRANSFER]: 'Planned - Extra Transfer',
    [message_type_1.default.PLANNED_STOPS_SKIPPED]: 'Planned - Stops Skipped',
    [message_type_1.default.PLANNED_REROUTE]: 'Planned - Reroute',
    [message_type_1.default.CANCELLATIONS]: 'Cancellations',
    [message_type_1.default.DELAYS_AND_CANCELLATIONS]: 'Delays and Cancellations ',
    [message_type_1.default.STOPS_SKIPPED]: 'Stops Skipped',
    [message_type_1.default.SEVERE_DELAYS]: 'Severe Delays',
    [message_type_1.default.DETOUR]: 'Detour',
    [message_type_1.default.REROUTE]: 'Reroute',
};
var MessageTypeCategory;
(function (MessageTypeCategory) {
    MessageTypeCategory["SERVICE_ALERT"] = "SERVICE_ALERT";
    MessageTypeCategory["PLANNED_WORK"] = "PLANNED_WORK";
})(MessageTypeCategory = exports.MessageTypeCategory || (exports.MessageTypeCategory = {}));
// Currently active statuses only that new messages are supposed to use
// The order of keys in this object must match the priority of these statuses in mercury-gtfs-realtime.proto
// Ignoring the compiler error here about missing message types because we don't always want to include all message types
// @ts-ignore
exports.MESSAGE_TYPE_ASSIGNMENTS = {
    [message_type_1.default.NO_SCHEDULED_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.INFORMATION_OUTAGE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [message_type_1.default.STATION_NOTICE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [message_type_1.default.SPECIAL_NOTICE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [message_type_1.default.WEEKDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.WEEKEND_SCHEDULE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.SATURDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.SUNDAY_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.EXTRA_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.BOARDING_CHANGE]: {
        [FeedId.NYCTSubway]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontDEV]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
    },
    [message_type_1.default.SPECIAL_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.EXPECT_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.REDUCED_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_EXPRESS_TO_LOCAL]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_EXTRA_TRANSFER]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_STOPS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_DETOUR]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.PLANNED_REROUTE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_SUBSTITUTE_BUSES]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.PLANNED_PART_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.SERVICE_CHANGE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.PLANNED_WORK]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.SOME_DELAYS]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.EXPRESS_TO_LOCAL]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.CANCELLATIONS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.DELAYS_AND_CANCELLATIONS]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.STOPS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SEVERE_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.DETOUR]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.REROUTE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SUBSTITUTE_BUSES]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.PART_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
};
// Archived statuses used for searching cleared messages in UI
// These overwrite the active statuses, so must include historical uses as well
// Only includes statuses that have been deleted or have a status that has been deleted
// @ts-ignore
exports.ARCHIVED_MESSAGE_TYPE_ASSIGNMENTS = {
    [message_type_1.default.NO_MIDDAY_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.NO_OVERNIGHT_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.NO_WEEKEND_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.WEEKEND_SCHEDULE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.EXTRA_SERVICE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.LIRR]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.SPECIAL_EVENT]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_BOARDING_CHANGE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_WORK]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [
            MessageTypeCategory.SERVICE_ALERT,
            MessageTypeCategory.PLANNED_WORK,
        ],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_STATIONS_SKIPPED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.SLOW_SPEEDS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.PLANNED_LOCAL_TO_EXPRESS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.PLANNED_TRAINS_REROUTED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.PLANNED_MULTIPLE_CHANGES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
    [message_type_1.default.ARRIVAL_INFORMATION_OUTAGE]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SHUTTLE_BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SOME_DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.DELAYS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.SOME_REROUTES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.LOCAL_TO_EXPRESS]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.BUSES_DETOURED]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.SERVICE_CHANGE]: {
        [FeedId.NYCTSubway]: [],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.LIRR]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.BT]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontQA]: [],
        [FeedId.OutfrontDEV]: [],
    },
    [message_type_1.default.TRAINS_REROUTED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.MULTIPLE_CHANGES]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.NYCTBus]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.MNR]: [],
        [FeedId.LIRR]: [],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.SERVICE_ALERT],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.SERVICE_ALERT],
    },
    [message_type_1.default.PLANNED_SUSPENDED]: {
        [FeedId.NYCTSubway]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.NYCTBus]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.MNR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.LIRR]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.BT]: [],
        [FeedId.OutfrontQA]: [MessageTypeCategory.PLANNED_WORK],
        [FeedId.OutfrontDEV]: [MessageTypeCategory.PLANNED_WORK],
    },
};
const getMessageTypesByFeedAndCategory = (feedId, category) => Object.keys(exports.MESSAGE_TYPE_ASSIGNMENTS).filter(k => exports.MESSAGE_TYPE_ASSIGNMENTS[k][feedId].includes(category));
exports.getMessageTypesByFeedAndCategory = getMessageTypesByFeedAndCategory;
const getClearedMessageTypesByFeedAndCategory = (feedId, category) => {
    const combinedMessageTypes = {
        ...exports.MESSAGE_TYPE_ASSIGNMENTS,
        ...exports.ARCHIVED_MESSAGE_TYPE_ASSIGNMENTS,
    };
    return Object.keys(combinedMessageTypes).filter(k => combinedMessageTypes[k][feedId].includes(category));
};
exports.getClearedMessageTypesByFeedAndCategory = getClearedMessageTypesByFeedAndCategory;
