import React from 'react';
import styled from 'styled-components';
import { PlannedWorkAffectedRoute } from '../../../types';
import pxToViewport from '../../../utils/px-to-viewport-units';

const AffectedRoutesContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const AffectedRoutesList = styled.div`
  display: flex;
  font-size: ${pxToViewport(52)}rem;
  flex-basis: ${pxToViewport(320)}rem;
  flex-direction: column;
`;

const AffectedRoute = styled.div<{
  backgroundColor: string | null;
  color: string | null;
}>`
  display: flex;
  flex-basis: ${pxToViewport(320)}rem;
  line-height: ${pxToViewport(64)}rem;
  letter-spacing: ${pxToViewport(-2.24)}rem;
  font-weight: bold;
  padding: 0 ${pxToViewport(16)}rem;
  flex: 1 0 ${pxToViewport(160)}rem;
  align-items: center;

  ${props =>
    props.backgroundColor && `background-color: #${props.backgroundColor};`}
  ${props => props.color && `color: #${props.color};`};
`;

const PortraitRouteList: React.FC<{
  affectedRoutes: PlannedWorkAffectedRoute[];
}> = ({ affectedRoutes }) => {
  return (
    <AffectedRoutesContainer>
      <AffectedRoutesList>
        {affectedRoutes
          .slice(0, 2)
          .map(({ shortName, longName, color, textColor, gtfsRouteId }) => {
            const name = shortName || longName;

            return (
              <AffectedRoute
                key={gtfsRouteId}
                backgroundColor={color}
                color={textColor}
              >
                {name}
              </AffectedRoute>
            );
          })}
      </AffectedRoutesList>
    </AffectedRoutesContainer>
  );
};

export default PortraitRouteList;
