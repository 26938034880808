import React from 'react';
import styled from 'styled-components';

import pxToViewport from '../../utils/px-to-viewport-units';

import Alert from './Body/Alert';
import Footer from './Body/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20%;
  max-height: 216px;
  width: 100%;
`;

const ADContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 80%;
  max-height: calc(100% - 216px);
  font-size: ${pxToViewport(50)}rem;
  font-weight: bold;
  color: #eaeaea;
`;

const Dup: React.FC<{
  content: string;
}> = ({ content }) => {
  return (
    <Container>
      <ADContainer>ADVERTISEMENT</ADContainer>
      <InfoContainer>
        <Alert content={content} orientation="landscape" isDup />
        <Footer isDup footerText="Visit mta.info for more information" />
      </InfoContainer>
    </Container>
  );
};

export default Dup;
