import React from 'react';
import styled from 'styled-components';

import { ScreenContentAffectedRoute } from '@mta-live-media-manager/shared';
import pxToViewport from '../../../utils/px-to-viewport-units';

const Bullet = styled.div`
  margin: ${pxToViewport(10)}rem;
  padding: ${pxToViewport(25)}rem ${pxToViewport(75)}rem;
  border-radius: ${pxToViewport(10)}rem;
  font-size: ${pxToViewport(60)}rem;
  font-weight: bold;
  letter-spacing: -${pxToViewport(1.11)}rem;
`;

export interface IRouteList {
  affectedRoutes?: ScreenContentAffectedRoute[];
  allRoutesAffected?: boolean;
}

const PortraitRouteList: React.FC<IRouteList> = ({
  affectedRoutes,
  allRoutesAffected,
}) => {
  if (!affectedRoutes) {
    return null;
  }

  if (allRoutesAffected) {
    return (
      <Bullet style={{ backgroundColor: '#000', color: '#fff' }}>
        All Branches
      </Bullet>
    );
  }

  const bulletEls = affectedRoutes
    .map(route => {
      const { gtfsId, color, textColor, shortName, longName } = route;
      const name = shortName || longName;

      if (!name) {
        return null;
      }

      return (
        <Bullet
          key={gtfsId}
          style={{
            backgroundColor: color ? `#${color}` : '',
            color: textColor ? `#${textColor}` : '',
          }}
        >
          {name}
        </Bullet>
      );
    })
    .filter(Boolean)
    .slice(0, 5);

  return <>{bulletEls}</>;
};

export default PortraitRouteList;
