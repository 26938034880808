import React from 'react';
import styled from 'styled-components';

import {
  MESSAGE_TYPE_DESCRIPTIONS,
  databaseToGQLEnum,
  ScreenContentAffectedRoute,
  MessageType,
} from '@mta-live-media-manager/shared';
import pxToViewport from '../../utils/px-to-viewport-units';
import Alert from './Body/Alert';
import LandscapeHeader from './Body/LandscapeHeader';
import LandscapeRouteList from './Body/LandscapeRouteList';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

interface LandscapeProps {
  affectedRoutes: ScreenContentAffectedRoute[];
  content: string;
  messageType: MessageType;
  additionalContent?: string;
  allRoutesAffected?: boolean;
  hasRouteList?: boolean;
  isUpdate?: boolean;
  publishAt?: string;
  title?: string;
}

const LeftBar = styled.div`
  background-color: black;
  flex: 0 0 ${pxToViewport(650)}rem;
`;

const Landscape: React.FC<LandscapeProps> = ({
  affectedRoutes,
  allRoutesAffected,
  content,
  additionalContent,
  hasRouteList,
  isUpdate,
  messageType,
  publishAt,
  title,
}) => {
  const selectedTitle = (() => {
    try {
      if (title) {
        return title;
      }

      if (MESSAGE_TYPE_DESCRIPTIONS[messageType]) {
        return MESSAGE_TYPE_DESCRIPTIONS[messageType];
      }

      if (MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)]) {
        return MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)];
      }
    } catch (e) {
      return 'Status';
    }

    return 'Status';
  })();

  return (
    <Container>
      <LeftBar>
        <LandscapeHeader title={selectedTitle} />
        {hasRouteList && (
          <LandscapeRouteList
            affectedRoutes={affectedRoutes}
            allRoutesAffected={allRoutesAffected}
          />
        )}
      </LeftBar>
      <Alert
        content={content}
        contentBgColor="#f6f6f6"
        additionalContent={additionalContent}
        additionalContentBgColor="#fff"
        publishedAt={publishAt}
        isUpdate={isUpdate}
        orientation="landscape"
      />
    </Container>
  );
};

export default Landscape;
