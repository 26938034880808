import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const fallbackPortraitSrc = require('../../images/fallback-portrait.png');
const fallbackLandscapeSrc = require('../../images/fallback-landscape.png');

const DELAY_FALLBACK_MS = 200;

const Error = styled.div`
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const FallbackImage = styled.img`
  width: 100%;
  height: 100%;
`;

const NoContent: React.FC<{ isLandscape?: boolean }> = ({ isLandscape }) => {
  const [shouldShow, setShouldShow] = useState(false);

  // Delay showing a flash of the fallback image if content is just about to load.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldShow(true);
    }, DELAY_FALLBACK_MS);

    return () => window.clearTimeout(timeoutId);
  }, []);

  if (!shouldShow) {
    return <div />;
  }

  return (
    <Error>
      <FallbackImage
        src={isLandscape ? fallbackLandscapeSrc : fallbackPortraitSrc}
        alt="MTA"
      />
    </Error>
  );
};

export default NoContent;
