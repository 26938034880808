import React from 'react';

/**
 * Template sections
 */
import Template from './components/Template';
import ErrorBoundary from './components/ErrorBoundary';
import ScreenRenderer from './components/ScreenRenderer';

/**
 * Global Styles
 */
import GlobalStyles from './GlobalStyles';

const App: React.FC<{ children?: React.ReactNode }> = () => {
  return (
    <Template>
      <GlobalStyles />
      <ErrorBoundary>
        <ScreenRenderer />
      </ErrorBoundary>
    </Template>
  );
};

export default App;
