export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigFloat: { input: any; output: any };
  BigInt: { input: any; output: any };
  Cursor: { input: any; output: any };
  Date: { input: any; output: any };
  Datetime: { input: any; output: any };
  JSON: { input: any; output: any };
  Time: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type ActiveRealtimeMessage = {
  __typename?: 'ActiveRealtimeMessage';
  additionalInfo?: Maybe<Scalars['JSON']['output']>;
  affectedStations?: Maybe<Scalars['JSON']['output']>;
  body?: Maybe<Scalars['JSON']['output']>;
  directionality?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['Datetime']['output']>;
  entitySelectors?: Maybe<Scalars['JSON']['output']>;
  eventCreatedAt?: Maybe<Scalars['Datetime']['output']>;
  eventUpdatedAt?: Maybe<Scalars['Datetime']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  messageImpactId?: Maybe<Scalars['Int']['output']>;
  messageImpacts?: Maybe<Scalars['JSON']['output']>;
  noAffectedStations?: Maybe<Scalars['Boolean']['output']>;
  startAt?: Maybe<Scalars['Datetime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

/** All input for the `addUpdateToEvent` mutation. */
export type AddUpdateToEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  isDraft: Scalars['Boolean']['input'];
  patch: EventPatchRecordInput;
  updateMessage: MessagePatchRecordInput;
};

/** The output of our `addUpdateToEvent` mutation. */
export type AddUpdateToEventPayload = {
  __typename?: 'AddUpdateToEventPayload';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `addUpdateToEvent` mutation. */
export type AddUpdateToEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

export type AffectedStation = {
  __typename?: 'AffectedStation';
  direction?: Maybe<CardinalDirection>;
  entitySelector?: Maybe<GtfsEntitySelector>;
};

/** An input for mutations affecting `AffectedStation` */
export type AffectedStationInput = {
  direction?: InputMaybe<CardinalDirection>;
  entitySelector?: InputMaybe<GtfsEntitySelectorInput>;
};

/** A connection to a list of `Agency` values. */
export type AgenciesConnection = {
  __typename?: 'AgenciesConnection';
  /** A list of edges which contains the `Agency` and cursor to aid in pagination. */
  edges: Array<AgenciesEdge>;
  /** A list of `Agency` objects. */
  nodes: Array<Agency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Agency` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Agency` edge in the connection. */
export type AgenciesEdge = {
  __typename?: 'AgenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Agency` at the end of the edge. */
  node: Agency;
};

/** Methods to use when ordering `Agency`. */
export enum AgenciesOrderBy {
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PHONE_ASC = 'PHONE_ASC',
  PHONE_DESC = 'PHONE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TIMEZONE_ASC = 'TIMEZONE_ASC',
  TIMEZONE_DESC = 'TIMEZONE_DESC',
  URL_ASC = 'URL_ASC',
  URL_DESC = 'URL_DESC',
}

export type Agency = Node & {
  __typename?: 'Agency';
  /** Reads a single `Feed` that is related to this `Agency`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Route`. */
  routes: RoutesConnection;
  timezone: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AgencyRoutesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RouteCondition>;
  filter?: InputMaybe<RouteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/** A condition to be used against `Agency` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AgencyCondition = {
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `language` field. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Agency` object types. All fields are combined with a logical ‘and.’ */
export type AgencyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AgencyFilter>>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `language` field. */
  language?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AgencyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AgencyFilter>>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Agency` */
export type AgencyInput = {
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Represents an update to a `Agency`. Fields that are set will be updated. */
export type AgencyPatch = {
  feedId?: InputMaybe<Scalars['String']['input']>;
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export enum Borough {
  BRONX = 'BRONX',
  BROOKLYN = 'BROOKLYN',
  MANHATTAN = 'MANHATTAN',
  QUEENS = 'QUEENS',
  STATEN_ISLAND = 'STATEN_ISLAND',
}

/** A filter to be used against Borough fields. All fields are combined with a logical ‘and.’ */
export type BoroughFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Borough>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Borough>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Borough>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Borough>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Borough>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Borough>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Borough>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Borough>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Borough>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Borough>>;
};

/** A filter to be used against Borough List fields. All fields are combined with a logical ‘and.’ */
export type BoroughListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Borough>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Borough>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Borough>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Borough>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Borough>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Borough>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Borough>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Borough>>>;
};

/**
 * Represents one schedule to be repeated weekly within a cadence_range, i.e. 11pm Monday to 5am Tuesday.
 *
 * start_dow and end_dow are days of the week, where 0 is Sunday. This corresponds to the `dow` part in `EXTRACT()`
 */
export type Cadence = {
  __typename?: 'Cadence';
  endDow: Scalars['Int']['output'];
  endTime: Scalars['Time']['output'];
  startDow: Scalars['Int']['output'];
  startTime: Scalars['Time']['output'];
};

/** An input for mutations affecting `Cadence` */
export type CadenceInput = {
  endDow: Scalars['Int']['input'];
  endTime: Scalars['Time']['input'];
  startDow: Scalars['Int']['input'];
  startTime: Scalars['Time']['input'];
};

export type Campaign = Node & {
  __typename?: 'Campaign';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  campaignScreenContents: CampaignScreenContentsConnection;
  canReclearScreens: Scalars['Boolean']['output'];
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  createdAt: Scalars['Datetime']['output'];
  durations: Array<DatetimeRange>;
  durationsEnd?: Maybe<Scalars['Datetime']['output']>;
  durationsSource: DurationsSource;
  end?: Maybe<Scalars['Datetime']['output']>;
  fastRouteIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fastStatus: CampaignStatus;
  feedId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['Datetime']['output']>;
  routeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  screensAreClearing: Scalars['Boolean']['output'];
  selectors: Array<ContextualSelector>;
  start?: Maybe<Scalars['Datetime']['output']>;
  status?: Maybe<CampaignStatus>;
  stopIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  targetTypes: Array<TargetType>;
  targetedScreensCount: Scalars['Int']['output'];
  targetedSyntheticScreensCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<CampaignType>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
  updaterId?: Maybe<Scalars['UUID']['output']>;
};

export type CampaignCampaignScreenContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentCondition>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/**
 * A condition to be used against `Campaign` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CampaignCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `durationsSource` field. */
  durationsSource?: InputMaybe<DurationsSourceInput>;
  /** Checks for equality with the object’s `fastStatus` field. */
  fastStatus?: InputMaybe<CampaignStatus>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updaterId` field. */
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `Campaign` object types. All fields are combined with a logical ‘and.’ */
export type CampaignFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `canReclearScreens` field. */
  canReclearScreens?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `durations` field. */
  durations?: InputMaybe<DatetimeRangeListFilter>;
  /** Filter by the object’s `durationsEnd` field. */
  durationsEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `end` field. */
  end?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fastRouteIds` field. */
  fastRouteIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `fastStatus` field. */
  fastStatus?: InputMaybe<CampaignStatusFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `routeIds` field. */
  routeIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `screensAreClearing` field. */
  screensAreClearing?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `start` field. */
  start?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<CampaignStatusFilter>;
  /** Filter by the object’s `stopIds` field. */
  stopIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<TargetTypeListFilter>;
  /** Filter by the object’s `targetedScreensCount` field. */
  targetedScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `targetedSyntheticScreensCount` field. */
  targetedSyntheticScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<CampaignTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updaterId` field. */
  updaterId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `CampaignInputRecord` */
export type CampaignInputRecordInput = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  durationsSource?: InputMaybe<DurationsSourceInput>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  targeting?: InputMaybe<Array<InputMaybe<CampaignTargetingInputRecordInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Stores related screen content for campaigns. */
export type CampaignScreenContent = {
  __typename?: 'CampaignScreenContent';
  actualScreenName?: Maybe<Scalars['String']['output']>;
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `Campaign` that is related to this `CampaignScreenContent`. */
  campaign: Campaign;
  campaignId?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `CampaignScreenContentUpload`. */
  campaignScreenContentUploads: CampaignScreenContentUploadsConnection;
  createdAt: Scalars['Datetime']['output'];
  /** Timestamp of when this message was deleted from a campaign. */
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  id: Scalars['Int']['output'];
  isClearing: Scalars['Boolean']['output'];
  priority: SchedulePriority;
  screenName?: Maybe<Scalars['String']['output']>;
  screenSelectors: Array<CampaignScreenSelector>;
  targetTypes: Array<TargetType>;
  targetedScreensCount: Scalars['Int']['output'];
  targetedScreensLockedCount: Scalars['Int']['output'];
  targetedSyntheticScreensCount: Scalars['Int']['output'];
  trainTargeting?: Maybe<TrainTargeting>;
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  updater?: Maybe<User>;
  updaterId?: Maybe<Scalars['UUID']['output']>;
  weight: Scalars['Int']['output'];
};

/** Stores related screen content for campaigns. */
export type CampaignScreenContentCampaignScreenContentUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentUploadCondition>;
  filter?: InputMaybe<CampaignScreenContentUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
};

/**
 * A condition to be used against `CampaignScreenContent` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CampaignScreenContentCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriority>;
  /** Checks for equality with the object’s `screenName` field. */
  screenName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `screenSelectors` field. */
  screenSelectors?: InputMaybe<Array<InputMaybe<CampaignScreenSelectorInput>>>;
  /** Checks for equality with the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Checks for equality with the object’s `trainTargeting` field. */
  trainTargeting?: InputMaybe<TrainTargetingInput>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updaterId` field. */
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `CampaignScreenContent` object types. All fields are combined with a logical ‘and.’ */
export type CampaignScreenContentFilter = {
  /** Filter by the object’s `actualScreenName` field. */
  actualScreenName?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignScreenContentFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isClearing` field. */
  isClearing?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignScreenContentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignScreenContentFilter>>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriorityFilter>;
  /** Filter by the object’s `screenName` field. */
  screenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<TargetTypeListFilter>;
  /** Filter by the object’s `targetedScreensCount` field. */
  targetedScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `targetedScreensLockedCount` field. */
  targetedScreensLockedCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `targetedSyntheticScreensCount` field. */
  targetedSyntheticScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updaterId` field. */
  updaterId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `CampaignScreenContent` */
export type CampaignScreenContentInput = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Timestamp of when this message was deleted from a campaign. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  priority?: InputMaybe<SchedulePriority>;
  screenName?: InputMaybe<Scalars['String']['input']>;
  screenSelectors: Array<InputMaybe<CampaignScreenSelectorInput>>;
  targetTypes: Array<InputMaybe<TargetType>>;
  trainTargeting?: InputMaybe<TrainTargetingInput>;
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents an update to a `CampaignScreenContent`. Fields that are set will be updated. */
export type CampaignScreenContentPatch = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Timestamp of when this message was deleted from a campaign. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  priority?: InputMaybe<SchedulePriority>;
  screenName?: InputMaybe<Scalars['String']['input']>;
  screenSelectors?: InputMaybe<Array<InputMaybe<CampaignScreenSelectorInput>>>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  trainTargeting?: InputMaybe<TrainTargetingInput>;
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignScreenContentUpload = {
  __typename?: 'CampaignScreenContentUpload';
  /** Reads a single `CampaignScreenContent` that is related to this `CampaignScreenContentUpload`. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  campaignScreenContentId: Scalars['Int']['output'];
  setIndex: Scalars['Int']['output'];
  /** Reads a single `Upload` that is related to this `CampaignScreenContentUpload`. */
  upload: Upload;
  uploadId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `CampaignScreenContentUpload` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CampaignScreenContentUploadCondition = {
  /** Checks for equality with the object’s `campaignScreenContentId` field. */
  campaignScreenContentId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `setIndex` field. */
  setIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `CampaignScreenContentUpload` object types. All fields are combined with a logical ‘and.’ */
export type CampaignScreenContentUploadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignScreenContentUploadFilter>>;
  /** Filter by the object’s `campaignScreenContentId` field. */
  campaignScreenContentId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignScreenContentUploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignScreenContentUploadFilter>>;
  /** Filter by the object’s `setIndex` field. */
  setIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `CampaignScreenContentUpload` */
export type CampaignScreenContentUploadInput = {
  campaignScreenContentId: Scalars['Int']['input'];
  setIndex: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/** Represents an update to a `CampaignScreenContentUpload`. Fields that are set will be updated. */
export type CampaignScreenContentUploadPatch = {
  campaignScreenContentId?: InputMaybe<Scalars['Int']['input']>;
  setIndex?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `CampaignScreenContentUpload` values. */
export type CampaignScreenContentUploadsConnection = {
  __typename?: 'CampaignScreenContentUploadsConnection';
  /** A list of edges which contains the `CampaignScreenContentUpload` and cursor to aid in pagination. */
  edges: Array<CampaignScreenContentUploadsEdge>;
  /** A list of `CampaignScreenContentUpload` objects. */
  nodes: Array<CampaignScreenContentUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignScreenContentUpload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CampaignScreenContentUpload` edge in the connection. */
export type CampaignScreenContentUploadsEdge = {
  __typename?: 'CampaignScreenContentUploadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CampaignScreenContentUpload` at the end of the edge. */
  node: CampaignScreenContentUpload;
};

/** Methods to use when ordering `CampaignScreenContentUpload`. */
export enum CampaignScreenContentUploadsOrderBy {
  CAMPAIGN_SCREEN_CONTENT_ID_ASC = 'CAMPAIGN_SCREEN_CONTENT_ID_ASC',
  CAMPAIGN_SCREEN_CONTENT_ID_DESC = 'CAMPAIGN_SCREEN_CONTENT_ID_DESC',
  NATURAL = 'NATURAL',
  SET_INDEX_ASC = 'SET_INDEX_ASC',
  SET_INDEX_DESC = 'SET_INDEX_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

/** A connection to a list of `CampaignScreenContent` values. */
export type CampaignScreenContentsConnection = {
  __typename?: 'CampaignScreenContentsConnection';
  /** A list of edges which contains the `CampaignScreenContent` and cursor to aid in pagination. */
  edges: Array<CampaignScreenContentsEdge>;
  /** A list of `CampaignScreenContent` objects. */
  nodes: Array<CampaignScreenContent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignScreenContent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CampaignScreenContent` edge in the connection. */
export type CampaignScreenContentsEdge = {
  __typename?: 'CampaignScreenContentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CampaignScreenContent` at the end of the edge. */
  node: CampaignScreenContent;
};

/** Methods to use when ordering `CampaignScreenContent`. */
export enum CampaignScreenContentsOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  CAMPAIGN_ID_ASC = 'CAMPAIGN_ID_ASC',
  CAMPAIGN_ID_DESC = 'CAMPAIGN_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  SCREEN_NAME_ASC = 'SCREEN_NAME_ASC',
  SCREEN_NAME_DESC = 'SCREEN_NAME_DESC',
  SCREEN_SELECTORS_ASC = 'SCREEN_SELECTORS_ASC',
  SCREEN_SELECTORS_DESC = 'SCREEN_SELECTORS_DESC',
  TARGET_TYPES_ASC = 'TARGET_TYPES_ASC',
  TARGET_TYPES_DESC = 'TARGET_TYPES_DESC',
  TRAIN_TARGETING_ASC = 'TRAIN_TARGETING_ASC',
  TRAIN_TARGETING_DESC = 'TRAIN_TARGETING_DESC',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPDATER_ID_ASC = 'UPDATER_ID_ASC',
  UPDATER_ID_DESC = 'UPDATER_ID_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export type CampaignScreenSelector = {
  __typename?: 'CampaignScreenSelector';
  selectors?: Maybe<Array<Maybe<ContextualSelector>>>;
  tags?: Maybe<Scalars['JSON']['output']>;
};

/** An input for mutations affecting `CampaignScreenSelector` */
export type CampaignScreenSelectorInput = {
  selectors?: InputMaybe<Array<InputMaybe<ContextualSelectorInput>>>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
};

/**
 *   The publish status of a campaign.
 *
 *     LIVE:
 *       Deployed to at least <limited threshold percentage> of targeted screens
 *       and currently showing.
 *
 *     SCHEDULED:
 *       Deployed to at least <limited threshold percentage> of targeted screens
 *       and scheduled to be shown at some point in the future.
 *
 *     EXPIRED: Unused
 *
 *     DRAFT:
 *       Not yet sent to screens. Campaigns in this state are the only that can
 *       be hard-deleted.
 *
 *     CLEARED:
 *       No longer shown on screens. Can happen manually or after the last
 *       scheduled duration.
 *
 *     DEPLOYING:
 *       Targeted screens have not been synchronized since the last update to
 *       the campaign.
 *
 *     ERROR:
 *       Could not synchronize to any targeted screens.
 *
 *     LIMITED:
 *       Deployed to fewer than <limited threshold percentage> of targeted screens.
 *
 *     <limited threshold percentage>: lmm_private.get_campaign_limited_threshold()
 *
 */
export enum CampaignStatus {
  CLEARED = 'CLEARED',
  CLEARING = 'CLEARING',
  DEPLOYING = 'DEPLOYING',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  LIMITED = 'LIMITED',
  LIVE = 'LIVE',
  SCHEDULED = 'SCHEDULED',
  UNKNOWN = 'UNKNOWN',
}

/** A filter to be used against CampaignStatus fields. All fields are combined with a logical ‘and.’ */
export type CampaignStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CampaignStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CampaignStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CampaignStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CampaignStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CampaignStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CampaignStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CampaignStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CampaignStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CampaignStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CampaignStatus>>;
};

/** An input for mutations affecting `CampaignTargetingInputRecord` */
export type CampaignTargetingInputRecordInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<SchedulePriority>;
  screenName?: InputMaybe<Scalars['String']['input']>;
  screenSelectors?: InputMaybe<Array<InputMaybe<CampaignScreenSelectorInput>>>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  trainTargeting?: InputMaybe<TrainTargetingInput>;
  uploads?: InputMaybe<
    Array<InputMaybe<CreateCampaignUploadsInputRecordInput>>
  >;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/**
 *   The type of a campaign.
 *
 *   REGULAR:
 *     A campaign that targets multiple screens through selecting line(s) and/or stop(s).
 *
 *   SINGLE_SCREEN:
 *     A campaign that targets a single screen through selecting a screen name.
 *
 */
export enum CampaignType {
  REGULAR = 'REGULAR',
  SINGLE_SCREEN = 'SINGLE_SCREEN',
}

/** A filter to be used against CampaignType fields. All fields are combined with a logical ‘and.’ */
export type CampaignTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CampaignType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CampaignType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CampaignType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CampaignType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CampaignType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CampaignType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CampaignType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CampaignType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CampaignType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CampaignType>>;
};

/** A connection to a list of `Campaign` values. */
export type CampaignsConnection = {
  __typename?: 'CampaignsConnection';
  /** A list of edges which contains the `Campaign` and cursor to aid in pagination. */
  edges: Array<CampaignsEdge>;
  /** A list of `Campaign` objects. */
  nodes: Array<Campaign>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Campaign` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Campaign` edge in the connection. */
export type CampaignsEdge = {
  __typename?: 'CampaignsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Campaign` at the end of the edge. */
  node: Campaign;
};

/** Methods to use when ordering `Campaign`. */
export enum CampaignsOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DURATIONS_SOURCE_ASC = 'DURATIONS_SOURCE_ASC',
  DURATIONS_SOURCE_DESC = 'DURATIONS_SOURCE_DESC',
  FAST_STATUS_ASC = 'FAST_STATUS_ASC',
  FAST_STATUS_DESC = 'FAST_STATUS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PUBLISHED_AT_ASC = 'PUBLISHED_AT_ASC',
  PUBLISHED_AT_DESC = 'PUBLISHED_AT_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPDATER_ID_ASC = 'UPDATER_ID_ASC',
  UPDATER_ID_DESC = 'UPDATER_ID_DESC',
}

export enum CardinalDirection {
  EAST = 'EAST',
  NORTH = 'NORTH',
  SOUTH = 'SOUTH',
  WEST = 'WEST',
}

/** All input for the `clearCampaign` mutation. */
export type ClearCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `clearCampaign` mutation. */
export type ClearCampaignPayload = {
  __typename?: 'ClearCampaignPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our `clearCampaign` mutation. */
export type ClearCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `clearMessage` mutation. */
export type ClearMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['Int']['input'];
};

/** The output of our `clearMessage` mutation. */
export type ClearMessagePayload = {
  __typename?: 'ClearMessagePayload';
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
};

/** The output of our `clearMessage` mutation. */
export type ClearMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `clearPlannedWork` mutation. */
export type ClearPlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWorkId: Scalars['Int']['input'];
};

/** The output of our `clearPlannedWork` mutation. */
export type ClearPlannedWorkPayload = {
  __typename?: 'ClearPlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `clearPlannedWork` mutation. */
export type ClearPlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `clearScreenLock` mutation. */
export type ClearScreenLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  screenName: Scalars['String']['input'];
};

/** The output of our `clearScreenLock` mutation. */
export type ClearScreenLockPayload = {
  __typename?: 'ClearScreenLockPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screenLockTarget?: Maybe<ScreenLockTarget>;
};

export type ClearedReport = Node & {
  __typename?: 'ClearedReport';
  createdAt: Scalars['Datetime']['output'];
  feedId: Scalars['String']['output'];
  fromDate?: Maybe<Scalars['Datetime']['output']>;
  generatedLogsCount?: Maybe<Scalars['Int']['output']>;
  generatedLogsDurations?: Maybe<DatetimeRange>;
  id: Scalars['UUID']['output'];
  isBeingGenerated: Scalars['Boolean']['output'];
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  s3Url?: Maybe<Scalars['String']['output']>;
  signedS3Url?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `ClearedReport` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClearedReportCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `generatedLogsCount` field. */
  generatedLogsCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `generatedLogsDurations` field. */
  generatedLogsDurations?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isBeingGenerated` field. */
  isBeingGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `s3Url` field. */
  s3Url?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `ClearedReport` object types. All fields are combined with a logical ‘and.’ */
export type ClearedReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClearedReportFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fromDate` field. */
  fromDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `generatedLogsCount` field. */
  generatedLogsCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `generatedLogsDurations` field. */
  generatedLogsDurations?: InputMaybe<DatetimeRangeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isBeingGenerated` field. */
  isBeingGenerated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCurrent` field. */
  isCurrent?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClearedReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClearedReportFilter>>;
  /** Filter by the object’s `s3Url` field. */
  s3Url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `signedS3Url` field. */
  signedS3Url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `toDate` field. */
  toDate?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ClearedReport` */
export type ClearedReportInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  feedId: Scalars['String']['input'];
  generatedLogsCount?: InputMaybe<Scalars['Int']['input']>;
  generatedLogsDurations?: InputMaybe<DatetimeRangeInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isBeingGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  s3Url?: InputMaybe<Scalars['String']['input']>;
};

/** Represents an update to a `ClearedReport`. Fields that are set will be updated. */
export type ClearedReportPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  generatedLogsCount?: InputMaybe<Scalars['Int']['input']>;
  generatedLogsDurations?: InputMaybe<DatetimeRangeInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isBeingGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  s3Url?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `ClearedReport` values. */
export type ClearedReportsConnection = {
  __typename?: 'ClearedReportsConnection';
  /** A list of edges which contains the `ClearedReport` and cursor to aid in pagination. */
  edges: Array<ClearedReportsEdge>;
  /** A list of `ClearedReport` objects. */
  nodes: Array<ClearedReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClearedReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ClearedReport` edge in the connection. */
export type ClearedReportsEdge = {
  __typename?: 'ClearedReportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ClearedReport` at the end of the edge. */
  node: ClearedReport;
};

/** Methods to use when ordering `ClearedReport`. */
export enum ClearedReportsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GENERATED_LOGS_COUNT_ASC = 'GENERATED_LOGS_COUNT_ASC',
  GENERATED_LOGS_COUNT_DESC = 'GENERATED_LOGS_COUNT_DESC',
  GENERATED_LOGS_DURATIONS_ASC = 'GENERATED_LOGS_DURATIONS_ASC',
  GENERATED_LOGS_DURATIONS_DESC = 'GENERATED_LOGS_DURATIONS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_BEING_GENERATED_ASC = 'IS_BEING_GENERATED_ASC',
  IS_BEING_GENERATED_DESC = 'IS_BEING_GENERATED_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  S3_URL_ASC = 'S3_URL_ASC',
  S3_URL_DESC = 'S3_URL_DESC',
}

export type ContextualSelector = {
  __typename?: 'ContextualSelector';
  context: Scalars['JSON']['output'];
  entitySelector: GtfsEntitySelector;
};

/** An input for mutations affecting `ContextualSelector` */
export type ContextualSelectorInput = {
  context: Scalars['JSON']['input'];
  entitySelector: GtfsEntitySelectorInput;
};

/** All input for the create `Agency` mutation. */
export type CreateAgencyInput = {
  /** The `Agency` to be created by this mutation. */
  agency: AgencyInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `Agency` mutation. */
export type CreateAgencyPayload = {
  __typename?: 'CreateAgencyPayload';
  /** The `Agency` that was created by this mutation. */
  agency?: Maybe<Agency>;
  /** An edge for our `Agency`. May be used by Relay 1. */
  agencyEdge?: Maybe<AgenciesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Agency`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Agency` mutation. */
export type CreateAgencyPayloadAgencyEdgeArgs = {
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

/** All input for the `createCampaign` mutation. */
export type CreateCampaignInput = {
  campaignInput: CampaignInputRecordInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `createCampaign` mutation. */
export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our `createCampaign` mutation. */
export type CreateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the create `CampaignScreenContent` mutation. */
export type CreateCampaignScreenContentInput = {
  /** The `CampaignScreenContent` to be created by this mutation. */
  campaignScreenContent: CampaignScreenContentInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `CampaignScreenContent` mutation. */
export type CreateCampaignScreenContentPayload = {
  __typename?: 'CreateCampaignScreenContentPayload';
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  author?: Maybe<User>;
  /** Reads a single `Campaign` that is related to this `CampaignScreenContent`. */
  campaign?: Maybe<Campaign>;
  /** The `CampaignScreenContent` that was created by this mutation. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** An edge for our `CampaignScreenContent`. May be used by Relay 1. */
  campaignScreenContentEdge?: Maybe<CampaignScreenContentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  updater?: Maybe<User>;
};

/** The output of our create `CampaignScreenContent` mutation. */
export type CreateCampaignScreenContentPayloadCampaignScreenContentEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/** All input for the create `CampaignScreenContentUpload` mutation. */
export type CreateCampaignScreenContentUploadInput = {
  /** The `CampaignScreenContentUpload` to be created by this mutation. */
  campaignScreenContentUpload: CampaignScreenContentUploadInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `CampaignScreenContentUpload` mutation. */
export type CreateCampaignScreenContentUploadPayload = {
  __typename?: 'CreateCampaignScreenContentUploadPayload';
  /** Reads a single `CampaignScreenContent` that is related to this `CampaignScreenContentUpload`. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** The `CampaignScreenContentUpload` that was created by this mutation. */
  campaignScreenContentUpload?: Maybe<CampaignScreenContentUpload>;
  /** An edge for our `CampaignScreenContentUpload`. May be used by Relay 1. */
  campaignScreenContentUploadEdge?: Maybe<CampaignScreenContentUploadsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `CampaignScreenContentUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our create `CampaignScreenContentUpload` mutation. */
export type CreateCampaignScreenContentUploadPayloadCampaignScreenContentUploadEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
  };

/** An input for mutations affecting `CreateCampaignUploadsInputRecord` */
export type CreateCampaignUploadsInputRecordInput = {
  setIndex?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** All input for the create `ClearedReport` mutation. */
export type CreateClearedReportInput = {
  /** The `ClearedReport` to be created by this mutation. */
  clearedReport: ClearedReportInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `ClearedReport` mutation. */
export type CreateClearedReportPayload = {
  __typename?: 'CreateClearedReportPayload';
  /** The `ClearedReport` that was created by this mutation. */
  clearedReport?: Maybe<ClearedReport>;
  /** An edge for our `ClearedReport`. May be used by Relay 1. */
  clearedReportEdge?: Maybe<ClearedReportsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ClearedReport` mutation. */
export type CreateClearedReportPayloadClearedReportEdgeArgs = {
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/** All input for the `createEvent` mutation. */
export type CreateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
  initialMessage: MessagePatchRecordInput;
  isDraft: Scalars['Boolean']['input'];
  patch: EventPatchRecordInput;
};

/** The output of our `createEvent` mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `createEvent` mutation. */
export type CreateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `createFallbackContent` mutation. */
export type CreateFallbackContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fallbackScreens: Array<InputMaybe<CreateFallbackScreenInput>>;
  feedId: Scalars['String']['input'];
};

/** The output of our `createFallbackContent` mutation. */
export type CreateFallbackContentPayload = {
  __typename?: 'CreateFallbackContentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fallbackScreensContents?: Maybe<Array<FallbackScreensContent>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** An input for mutations affecting `CreateFallbackScreen` */
export type CreateFallbackScreenInput = {
  direction?: InputMaybe<Direction>;
  type?: InputMaybe<TargetType>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** All input for the create `FallbackScreensContent` mutation. */
export type CreateFallbackScreensContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `FallbackScreensContent` to be created by this mutation. */
  fallbackScreensContent: FallbackScreensContentInput;
};

/** The output of our create `FallbackScreensContent` mutation. */
export type CreateFallbackScreensContentPayload = {
  __typename?: 'CreateFallbackScreensContentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FallbackScreensContent` that was created by this mutation. */
  fallbackScreensContent?: Maybe<FallbackScreensContent>;
  /** An edge for our `FallbackScreensContent`. May be used by Relay 1. */
  fallbackScreensContentEdge?: Maybe<FallbackScreensContentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `FallbackScreensContent`. */
  upload?: Maybe<Upload>;
};

/** The output of our create `FallbackScreensContent` mutation. */
export type CreateFallbackScreensContentPayloadFallbackScreensContentEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FallbackScreensContentsOrderBy>>;
  };

/** All input for the create `FeatureFlag` mutation. */
export type CreateFeatureFlagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `FeatureFlag` to be created by this mutation. */
  featureFlag: FeatureFlagInput;
};

/** The output of our create `FeatureFlag` mutation. */
export type CreateFeatureFlagPayload = {
  __typename?: 'CreateFeatureFlagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeatureFlag` that was created by this mutation. */
  featureFlag?: Maybe<FeatureFlag>;
  /** An edge for our `FeatureFlag`. May be used by Relay 1. */
  featureFlagEdge?: Maybe<FeatureFlagsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FeatureFlag` mutation. */
export type CreateFeatureFlagPayloadFeatureFlagEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** All input for the create `FeedGroup` mutation. */
export type CreateFeedGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `FeedGroup` to be created by this mutation. */
  feedGroup: FeedGroupInput;
};

/** The output of our create `FeedGroup` mutation. */
export type CreateFeedGroupPayload = {
  __typename?: 'CreateFeedGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeedGroup` that was created by this mutation. */
  feedGroup?: Maybe<FeedGroup>;
  /** An edge for our `FeedGroup`. May be used by Relay 1. */
  feedGroupEdge?: Maybe<FeedGroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FeedGroup` mutation. */
export type CreateFeedGroupPayloadFeedGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedGroupsOrderBy>>;
};

/** All input for the create `Feed` mutation. */
export type CreateFeedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Feed` to be created by this mutation. */
  feed: FeedInput;
};

/** All input for the create `FeedMessageTextAlias` mutation. */
export type CreateFeedMessageTextAliasInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `FeedMessageTextAlias` to be created by this mutation. */
  feedMessageTextAlias: FeedMessageTextAliasInput;
};

/** The output of our create `FeedMessageTextAlias` mutation. */
export type CreateFeedMessageTextAliasPayload = {
  __typename?: 'CreateFeedMessageTextAliasPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeedMessageTextAlias` that was created by this mutation. */
  feedMessageTextAlias?: Maybe<FeedMessageTextAlias>;
  /** An edge for our `FeedMessageTextAlias`. May be used by Relay 1. */
  feedMessageTextAliasEdge?: Maybe<FeedMessageTextAliasesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FeedMessageTextAlias` mutation. */
export type CreateFeedMessageTextAliasPayloadFeedMessageTextAliasEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedMessageTextAliasesOrderBy>>;
};

/** The output of our create `Feed` mutation. */
export type CreateFeedPayload = {
  __typename?: 'CreateFeedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Feed` that was created by this mutation. */
  feed?: Maybe<Feed>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  feedEdge?: Maybe<FeedsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Feed` mutation. */
export type CreateFeedPayloadFeedEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** All input for the create `FeedSetting` mutation. */
export type CreateFeedSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `FeedSetting` to be created by this mutation. */
  feedSetting: FeedSettingInput;
};

/** The output of our create `FeedSetting` mutation. */
export type CreateFeedSettingPayload = {
  __typename?: 'CreateFeedSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `FeedSetting`. */
  feed?: Maybe<Feed>;
  /** The `FeedSetting` that was created by this mutation. */
  feedSetting?: Maybe<FeedSetting>;
  /** An edge for our `FeedSetting`. May be used by Relay 1. */
  feedSettingEdge?: Maybe<FeedSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FeedSetting` mutation. */
export type CreateFeedSettingPayloadFeedSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/** All input for the create `LockableUpload` mutation. */
export type CreateLockableUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LockableUpload` to be created by this mutation. */
  lockableUpload: LockableUploadInput;
};

/** The output of our create `LockableUpload` mutation. */
export type CreateLockableUploadPayload = {
  __typename?: 'CreateLockableUploadPayload';
  /** Reads a single `User` that is related to this `LockableUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LockableUpload` that was created by this mutation. */
  lockableUpload?: Maybe<LockableUpload>;
  /** An edge for our `LockableUpload`. May be used by Relay 1. */
  lockableUploadEdge?: Maybe<LockableUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  thumbnail?: Maybe<Upload>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our create `LockableUpload` mutation. */
export type CreateLockableUploadPayloadLockableUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/** All input for the create `MessageImpact` mutation. */
export type CreateMessageImpactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `MessageImpact` to be created by this mutation. */
  messageImpact: MessageImpactInput;
};

/** The output of our create `MessageImpact` mutation. */
export type CreateMessageImpactPayload = {
  __typename?: 'CreateMessageImpactPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Message` that is related to this `MessageImpact`. */
  message?: Maybe<Message>;
  /** The `MessageImpact` that was created by this mutation. */
  messageImpact?: Maybe<MessageImpact>;
  /** An edge for our `MessageImpact`. May be used by Relay 1. */
  messageImpactEdge?: Maybe<MessageImpactsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MessageImpact` mutation. */
export type CreateMessageImpactPayloadMessageImpactEdgeArgs = {
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/** An input for mutations affecting `CreatePlannedWorkImpactInputRecord` */
export type CreatePlannedWorkImpactInputRecordInput = {
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  isAgencyWide: Scalars['Boolean']['input'];
  messageType?: InputMaybe<MessageType>;
};

/** All input for the `createPlannedWork` mutation. */
export type CreatePlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWork: PlannedWorkInputRecordInput;
};

/** The output of our `createPlannedWork` mutation. */
export type CreatePlannedWorkPayload = {
  __typename?: 'CreatePlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `createPlannedWork` mutation. */
export type CreatePlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** An input for mutations affecting `CreatePlannedWorkScreenMessageInputRecord` */
export type CreatePlannedWorkScreenMessageInputRecordInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  beforeActivePriority?: InputMaybe<SchedulePriority>;
  beforeActiveWeight?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['JSON']['input']>;
  durationsSettings?: InputMaybe<PlannedWorkMessageDurationSettingInput>;
  isActiveDuringPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  isAda?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<SchedulePriority>;
  screenPublishOffset?: InputMaybe<IntervalInput>;
  screenTargeting?: InputMaybe<
    Array<InputMaybe<PlannedWorkScreenSelectorInput>>
  >;
  selectors?: InputMaybe<Array<InputMaybe<ContextualSelectorInput>>>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** All input for the create `Route` mutation. */
export type CreateRouteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Route` to be created by this mutation. */
  route: RouteInput;
};

/** The output of our create `Route` mutation. */
export type CreateRoutePayload = {
  __typename?: 'CreateRoutePayload';
  /** Reads a single `Agency` that is related to this `Route`. */
  agency?: Maybe<Agency>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Route`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Route` that was created by this mutation. */
  route?: Maybe<Route>;
  /** An edge for our `Route`. May be used by Relay 1. */
  routeEdge?: Maybe<RoutesEdge>;
};

/** The output of our create `Route` mutation. */
export type CreateRoutePayloadRouteEdgeArgs = {
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/** All input for the `createScreenLock` mutation. */
export type CreateScreenLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  screenLock: ScreenLockInputRecordInput;
};

/** The output of our `createScreenLock` mutation. */
export type CreateScreenLockPayload = {
  __typename?: 'CreateScreenLockPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screenLockTarget?: Maybe<ScreenLockTarget>;
};

/** All input for the create `ScreenNote` mutation. */
export type CreateScreenNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `ScreenNote` to be created by this mutation. */
  screenNote: ScreenNoteInput;
};

/** The output of our create `ScreenNote` mutation. */
export type CreateScreenNotePayload = {
  __typename?: 'CreateScreenNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ScreenNote` that was created by this mutation. */
  screenNote?: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge?: Maybe<ScreenNotesEdge>;
};

/** The output of our create `ScreenNote` mutation. */
export type CreateScreenNotePayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** All input for the create `Screens2` mutation. */
export type CreateScreens2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Screens2` to be created by this mutation. */
  screens2: Screens2Input;
};

/** The output of our create `Screens2` mutation. */
export type CreateScreens2Payload = {
  __typename?: 'CreateScreens2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Screens2` that was created by this mutation. */
  screens2?: Maybe<Screens2>;
  /** An edge for our `Screens2`. May be used by Relay 1. */
  screens2Edge?: Maybe<Screens2sEdge>;
};

/** The output of our create `Screens2` mutation. */
export type CreateScreens2PayloadScreens2EdgeArgs = {
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/** All input for the create `SocialMediaFolder` mutation. */
export type CreateSocialMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `SocialMediaFolder` to be created by this mutation. */
  socialMediaFolder: SocialMediaFolderInput;
};

/** The output of our create `SocialMediaFolder` mutation. */
export type CreateSocialMediaFolderPayload = {
  __typename?: 'CreateSocialMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaFolder` that was created by this mutation. */
  socialMediaFolder?: Maybe<SocialMediaFolder>;
  /** An edge for our `SocialMediaFolder`. May be used by Relay 1. */
  socialMediaFolderEdge?: Maybe<SocialMediaFoldersEdge>;
  /** Reads a single `User` that is related to this `SocialMediaFolder`. */
  updater?: Maybe<User>;
};

/** The output of our create `SocialMediaFolder` mutation. */
export type CreateSocialMediaFolderPayloadSocialMediaFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

/** All input for the create `SocialMediaUpload` mutation. */
export type CreateSocialMediaUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `SocialMediaUpload` to be created by this mutation. */
  socialMediaUpload: SocialMediaUploadInput;
};

/** The output of our create `SocialMediaUpload` mutation. */
export type CreateSocialMediaUploadPayload = {
  __typename?: 'CreateSocialMediaUploadPayload';
  /** Reads a single `User` that is related to this `SocialMediaUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `SocialMediaFolder` that is related to this `SocialMediaUpload`. */
  folder?: Maybe<SocialMediaFolder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaUpload` that was created by this mutation. */
  socialMediaUpload?: Maybe<SocialMediaUpload>;
  /** An edge for our `SocialMediaUpload`. May be used by Relay 1. */
  socialMediaUploadEdge?: Maybe<SocialMediaUploadsEdge>;
  /** Reads a single `Upload` that is related to this `SocialMediaUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our create `SocialMediaUpload` mutation. */
export type CreateSocialMediaUploadPayloadSocialMediaUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/** All input for the create `Stop` mutation. */
export type CreateStopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Stop` to be created by this mutation. */
  stop: StopInput;
};

/** The output of our create `Stop` mutation. */
export type CreateStopPayload = {
  __typename?: 'CreateStopPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Stop`. */
  feed?: Maybe<Feed>;
  /** Reads a single `Stop` that is related to this `Stop`. */
  parentStation?: Maybe<Stop>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stop` that was created by this mutation. */
  stop?: Maybe<Stop>;
  /** An edge for our `Stop`. May be used by Relay 1. */
  stopEdge?: Maybe<StopsEdge>;
};

/** The output of our create `Stop` mutation. */
export type CreateStopPayloadStopEdgeArgs = {
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

/** All input for the create `StopTime` mutation. */
export type CreateStopTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `StopTime` to be created by this mutation. */
  stopTime: StopTimeInput;
};

/** The output of our create `StopTime` mutation. */
export type CreateStopTimePayload = {
  __typename?: 'CreateStopTimePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `StopTime`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Stop` that is related to this `StopTime`. */
  stop?: Maybe<Stop>;
  /** The `StopTime` that was created by this mutation. */
  stopTime?: Maybe<StopTime>;
  /** An edge for our `StopTime`. May be used by Relay 1. */
  stopTimeEdge?: Maybe<StopTimesEdge>;
  /** Reads a single `Trip` that is related to this `StopTime`. */
  trip?: Maybe<Trip>;
};

/** The output of our create `StopTime` mutation. */
export type CreateStopTimePayloadStopTimeEdgeArgs = {
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

/** All input for the create `Trip` mutation. */
export type CreateTripInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Trip` to be created by this mutation. */
  trip: TripInput;
};

/** The output of our create `Trip` mutation. */
export type CreateTripPayload = {
  __typename?: 'CreateTripPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Trip`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Route` that is related to this `Trip`. */
  route?: Maybe<Route>;
  /** The `Trip` that was created by this mutation. */
  trip?: Maybe<Trip>;
  /** An edge for our `Trip`. May be used by Relay 1. */
  tripEdge?: Maybe<TripsEdge>;
};

/** The output of our create `Trip` mutation. */
export type CreateTripPayloadTripEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/** All input for the create `TripsLirr` mutation. */
export type CreateTripsLirrInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `TripsLirr` to be created by this mutation. */
  tripsLirr: TripsLirrInput;
};

/** The output of our create `TripsLirr` mutation. */
export type CreateTripsLirrPayload = {
  __typename?: 'CreateTripsLirrPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Route` that is related to this `TripsLirr`. */
  gtfsRoute?: Maybe<Route>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TripsLirr` that was created by this mutation. */
  tripsLirr?: Maybe<TripsLirr>;
  /** An edge for our `TripsLirr`. May be used by Relay 1. */
  tripsLirrEdge?: Maybe<TripsLirrsEdge>;
};

/** The output of our create `TripsLirr` mutation. */
export type CreateTripsLirrPayloadTripsLirrEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/** All input for the create `TweetsUpload` mutation. */
export type CreateTweetsUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `TweetsUpload` to be created by this mutation. */
  tweetsUpload: TweetsUploadInput;
};

/** The output of our create `TweetsUpload` mutation. */
export type CreateTweetsUploadPayload = {
  __typename?: 'CreateTweetsUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tweet` that is related to this `TweetsUpload`. */
  tweet?: Maybe<Tweet>;
  /** The `TweetsUpload` that was created by this mutation. */
  tweetsUpload?: Maybe<TweetsUpload>;
  /** An edge for our `TweetsUpload`. May be used by Relay 1. */
  tweetsUploadEdge?: Maybe<TweetsUploadsEdge>;
  /** Reads a single `Upload` that is related to this `TweetsUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our create `TweetsUpload` mutation. */
export type CreateTweetsUploadPayloadTweetsUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** All input for the create `Upload` mutation. */
export type CreateUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Upload` to be created by this mutation. */
  upload: UploadInput;
};

/** The output of our create `Upload` mutation. */
export type CreateUploadPayload = {
  __typename?: 'CreateUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Upload` that was created by this mutation. */
  upload?: Maybe<Upload>;
  /** An edge for our `Upload`. May be used by Relay 1. */
  uploadEdge?: Maybe<UploadsEdge>;
};

/** The output of our create `Upload` mutation. */
export type CreateUploadPayloadUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<UploadsOrderBy>>;
};

/** All input for the create `UserFeedGroupGrant` mutation. */
export type CreateUserFeedGroupGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `UserFeedGroupGrant` to be created by this mutation. */
  userFeedGroupGrant: UserFeedGroupGrantInput;
};

/** The output of our create `UserFeedGroupGrant` mutation. */
export type CreateUserFeedGroupGrantPayload = {
  __typename?: 'CreateUserFeedGroupGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `FeedGroup` that is related to this `UserFeedGroupGrant`. */
  feedGroupByFeedGroupName?: Maybe<FeedGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFeedGroupGrant`. */
  user?: Maybe<User>;
  /** The `UserFeedGroupGrant` that was created by this mutation. */
  userFeedGroupGrant?: Maybe<UserFeedGroupGrant>;
  /** An edge for our `UserFeedGroupGrant`. May be used by Relay 1. */
  userFeedGroupGrantEdge?: Maybe<UserFeedGroupGrantsEdge>;
};

/** The output of our create `UserFeedGroupGrant` mutation. */
export type CreateUserFeedGroupGrantPayloadUserFeedGroupGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/** All input for the create `UsersAccess` mutation. */
export type CreateUsersAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `UsersAccess` to be created by this mutation. */
  usersAccess: UsersAccessInput;
};

/** The output of our create `UsersAccess` mutation. */
export type CreateUsersAccessPayload = {
  __typename?: 'CreateUsersAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersAccess`. */
  user?: Maybe<User>;
  /** The `UsersAccess` that was created by this mutation. */
  usersAccess?: Maybe<UsersAccess>;
  /** An edge for our `UsersAccess`. May be used by Relay 1. */
  usersAccessEdge?: Maybe<UsersAccessesEdge>;
};

/** The output of our create `UsersAccess` mutation. */
export type CreateUsersAccessPayloadUsersAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

/** An input for mutations affecting `DashboardMessagesInputRecord` */
export type DashboardMessagesInputRecordInput = {
  allRoutes?: InputMaybe<Scalars['Boolean']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  isSetToExpire?: InputMaybe<Scalars['Boolean']['input']>;
  messageType?: InputMaybe<MessageType>;
  pollingAt?: InputMaybe<Scalars['Datetime']['input']>;
  routes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DashboardPlannedWork = {
  __typename?: 'DashboardPlannedWork';
  end?: Maybe<Scalars['Datetime']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  feedId?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastModifiedAt?: Maybe<Scalars['Datetime']['output']>;
  liveOnHomepage?: Maybe<Scalars['Boolean']['output']>;
  liveOnScreens?: Maybe<Scalars['Boolean']['output']>;
  messageType?: Maybe<Array<Maybe<MessageType>>>;
  /** Reads a single `PlannedWork` that is related to this `DashboardPlannedWork`. */
  plannedWorkById?: Maybe<PlannedWork>;
  start?: Maybe<Scalars['Datetime']['output']>;
  status?: Maybe<PlannedWorkStatus>;
};

/**
 * A condition to be used against `DashboardPlannedWork` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DashboardPlannedWorkCondition = {
  /** Checks for equality with the object’s `end` field. */
  end?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `header` field. */
  header?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastModifiedAt` field. */
  lastModifiedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `liveOnHomepage` field. */
  liveOnHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `liveOnScreens` field. */
  liveOnScreens?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `messageType` field. */
  messageType?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Checks for equality with the object’s `start` field. */
  start?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<PlannedWorkStatus>;
};

/** A filter to be used against `DashboardPlannedWork` object types. All fields are combined with a logical ‘and.’ */
export type DashboardPlannedWorkFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DashboardPlannedWorkFilter>>;
  /** Filter by the object’s `end` field. */
  end?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `header` field. */
  header?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastModifiedAt` field. */
  lastModifiedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `liveOnHomepage` field. */
  liveOnHomepage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `liveOnScreens` field. */
  liveOnScreens?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageType` field. */
  messageType?: InputMaybe<MessageTypeListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DashboardPlannedWorkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DashboardPlannedWorkFilter>>;
  /** Filter by the object’s `start` field. */
  start?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<PlannedWorkStatusFilter>;
};

/** A connection to a list of `DashboardPlannedWork` values. */
export type DashboardPlannedWorksConnection = {
  __typename?: 'DashboardPlannedWorksConnection';
  /** A list of edges which contains the `DashboardPlannedWork` and cursor to aid in pagination. */
  edges: Array<DashboardPlannedWorksEdge>;
  /** A list of `DashboardPlannedWork` objects. */
  nodes: Array<DashboardPlannedWork>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DashboardPlannedWork` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DashboardPlannedWork` edge in the connection. */
export type DashboardPlannedWorksEdge = {
  __typename?: 'DashboardPlannedWorksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DashboardPlannedWork` at the end of the edge. */
  node: DashboardPlannedWork;
};

/** Methods to use when ordering `DashboardPlannedWork`. */
export enum DashboardPlannedWorksOrderBy {
  END_ASC = 'END_ASC',
  END_DESC = 'END_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  HEADER_ASC = 'HEADER_ASC',
  HEADER_DESC = 'HEADER_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  LAST_MODIFIED_AT_ASC = 'LAST_MODIFIED_AT_ASC',
  LAST_MODIFIED_AT_DESC = 'LAST_MODIFIED_AT_DESC',
  LIVE_ON_HOMEPAGE_ASC = 'LIVE_ON_HOMEPAGE_ASC',
  LIVE_ON_HOMEPAGE_DESC = 'LIVE_ON_HOMEPAGE_DESC',
  LIVE_ON_SCREENS_ASC = 'LIVE_ON_SCREENS_ASC',
  LIVE_ON_SCREENS_DESC = 'LIVE_ON_SCREENS_DESC',
  MESSAGE_TYPE_ASC = 'MESSAGE_TYPE_ASC',
  MESSAGE_TYPE_DESC = 'MESSAGE_TYPE_DESC',
  NATURAL = 'NATURAL',
  START_ASC = 'START_ASC',
  START_DESC = 'START_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']['input']>>;
};

/** A range of `Date`. */
export type DateRange = {
  __typename?: 'DateRange';
  /** The ending bound of our range. */
  end?: Maybe<DateRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<DateRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBound = {
  __typename?: 'DateRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean']['output'];
  /** The value at one end of our range. */
  value: Scalars['Date']['output'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean']['input'];
  /** The value at one end of our range. */
  value: Scalars['Date']['input'];
};

/** A range of `Date`. */
export type DateRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<DateRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<DateRangeBoundInput>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

/** A range of `Datetime`. */
export type DatetimeRange = {
  __typename?: 'DatetimeRange';
  /** The ending bound of our range. */
  end?: Maybe<DatetimeRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<DatetimeRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DatetimeRangeBound = {
  __typename?: 'DatetimeRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean']['output'];
  /** The value at one end of our range. */
  value: Scalars['Datetime']['output'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DatetimeRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean']['input'];
  /** The value at one end of our range. */
  value: Scalars['Datetime']['input'];
};

/** A filter to be used against DatetimeRange fields. All fields are combined with a logical ‘and.’ */
export type DatetimeRangeFilter = {
  /** Adjacent to the specified range. */
  adjacentTo?: InputMaybe<DatetimeRangeInput>;
  /** Contained by the specified range. */
  containedBy?: InputMaybe<DatetimeRangeInput>;
  /** Contains the specified range. */
  contains?: InputMaybe<DatetimeRangeInput>;
  /** Contains the specified value. */
  containsElement?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DatetimeRangeInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DatetimeRangeInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DatetimeRangeInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DatetimeRangeInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DatetimeRangeInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DatetimeRangeInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Does not extend left of the specified range. */
  notExtendsLeftOf?: InputMaybe<DatetimeRangeInput>;
  /** Does not extend right of the specified range. */
  notExtendsRightOf?: InputMaybe<DatetimeRangeInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DatetimeRangeInput>>;
  /** Overlaps the specified range. */
  overlaps?: InputMaybe<DatetimeRangeInput>;
  /** Strictly left of the specified range. */
  strictlyLeftOf?: InputMaybe<DatetimeRangeInput>;
  /** Strictly right of the specified range. */
  strictlyRightOf?: InputMaybe<DatetimeRangeInput>;
};

/** A range of `Datetime`. */
export type DatetimeRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<DatetimeRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<DatetimeRangeBoundInput>;
};

/** A filter to be used against DatetimeRange List fields. All fields are combined with a logical ‘and.’ */
export type DatetimeRangeListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<DatetimeRangeInput>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<DatetimeRangeInput>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
};

export enum Days {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

/** All input for the `deactivateUser` mutation. */
export type DeactivateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** The output of our `deactivateUser` mutation. */
export type DeactivateUserPayload = {
  __typename?: 'DeactivateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our `deactivateUser` mutation. */
export type DeactivateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteAgencyByNodeId` mutation. */
export type DeleteAgencyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Agency` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteAgency` mutation. */
export type DeleteAgencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
};

/** The output of our delete `Agency` mutation. */
export type DeleteAgencyPayload = {
  __typename?: 'DeleteAgencyPayload';
  /** The `Agency` that was deleted by this mutation. */
  agency?: Maybe<Agency>;
  /** An edge for our `Agency`. May be used by Relay 1. */
  agencyEdge?: Maybe<AgenciesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedAgencyNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Feed` that is related to this `Agency`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Agency` mutation. */
export type DeleteAgencyPayloadAgencyEdgeArgs = {
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

/** All input for the `deleteCampaignByNodeId` mutation. */
export type DeleteCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Campaign` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteCampaign` mutation. */
export type DeleteCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  /** The `Campaign` that was deleted by this mutation. */
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedCampaignNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `deleteCampaignScreenContentById` mutation. */
export type DeleteCampaignScreenContentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `CampaignScreenContent` mutation. */
export type DeleteCampaignScreenContentPayload = {
  __typename?: 'DeleteCampaignScreenContentPayload';
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  author?: Maybe<User>;
  /** Reads a single `Campaign` that is related to this `CampaignScreenContent`. */
  campaign?: Maybe<Campaign>;
  /** The `CampaignScreenContent` that was deleted by this mutation. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** An edge for our `CampaignScreenContent`. May be used by Relay 1. */
  campaignScreenContentEdge?: Maybe<CampaignScreenContentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedCampaignScreenContentNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  updater?: Maybe<User>;
};

/** The output of our delete `CampaignScreenContent` mutation. */
export type DeleteCampaignScreenContentPayloadCampaignScreenContentEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/** All input for the `deleteCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndex` mutation. */
export type DeleteCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexInput =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']['input']>;
    setIndex: Scalars['Int']['input'];
  };

/** All input for the `deleteCampaignScreenContentUploadByCampaignScreenContentIdAndUploadId` mutation. */
export type DeleteCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdInput =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']['input']>;
    uploadId: Scalars['UUID']['input'];
  };

/** The output of our delete `CampaignScreenContentUpload` mutation. */
export type DeleteCampaignScreenContentUploadPayload = {
  __typename?: 'DeleteCampaignScreenContentUploadPayload';
  /** Reads a single `CampaignScreenContent` that is related to this `CampaignScreenContentUpload`. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** The `CampaignScreenContentUpload` that was deleted by this mutation. */
  campaignScreenContentUpload?: Maybe<CampaignScreenContentUpload>;
  /** An edge for our `CampaignScreenContentUpload`. May be used by Relay 1. */
  campaignScreenContentUploadEdge?: Maybe<CampaignScreenContentUploadsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedCampaignScreenContentUploadNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `CampaignScreenContentUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our delete `CampaignScreenContentUpload` mutation. */
export type DeleteCampaignScreenContentUploadPayloadCampaignScreenContentUploadEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
  };

/** All input for the `deleteClearedReportByNodeId` mutation. */
export type DeleteClearedReportByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `ClearedReport` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteClearedReport` mutation. */
export type DeleteClearedReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `ClearedReport` mutation. */
export type DeleteClearedReportPayload = {
  __typename?: 'DeleteClearedReportPayload';
  /** The `ClearedReport` that was deleted by this mutation. */
  clearedReport?: Maybe<ClearedReport>;
  /** An edge for our `ClearedReport`. May be used by Relay 1. */
  clearedReportEdge?: Maybe<ClearedReportsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedClearedReportNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ClearedReport` mutation. */
export type DeleteClearedReportPayloadClearedReportEdgeArgs = {
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/** All input for the `deleteDraftMessage` mutation. */
export type DeleteDraftMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['Int']['input'];
};

/** The output of our `deleteDraftMessage` mutation. */
export type DeleteDraftMessagePayload = {
  __typename?: 'DeleteDraftMessagePayload';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `deleteDraftMessage` mutation. */
export type DeleteDraftMessagePayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteFeatureFlagByNodeId` mutation. */
export type DeleteFeatureFlagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeatureFlag` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFeatureFlag` mutation. */
export type DeleteFeatureFlagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: FeatureFlagName;
};

/** The output of our delete `FeatureFlag` mutation. */
export type DeleteFeatureFlagPayload = {
  __typename?: 'DeleteFeatureFlagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedFeatureFlagNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `FeatureFlag` that was deleted by this mutation. */
  featureFlag?: Maybe<FeatureFlag>;
  /** An edge for our `FeatureFlag`. May be used by Relay 1. */
  featureFlagEdge?: Maybe<FeatureFlagsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FeatureFlag` mutation. */
export type DeleteFeatureFlagPayloadFeatureFlagEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** All input for the `deleteFeedByName` mutation. */
export type DeleteFeedByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** All input for the `deleteFeedByNodeId` mutation. */
export type DeleteFeedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Feed` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFeedByOtpId` mutation. */
export type DeleteFeedByOtpIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpId: Scalars['String']['input'];
};

/** All input for the `deleteFeedGroupByNodeId` mutation. */
export type DeleteFeedGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedGroup` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFeedGroup` mutation. */
export type DeleteFeedGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** The output of our delete `FeedGroup` mutation. */
export type DeleteFeedGroupPayload = {
  __typename?: 'DeleteFeedGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedFeedGroupNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `FeedGroup` that was deleted by this mutation. */
  feedGroup?: Maybe<FeedGroup>;
  /** An edge for our `FeedGroup`. May be used by Relay 1. */
  feedGroupEdge?: Maybe<FeedGroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FeedGroup` mutation. */
export type DeleteFeedGroupPayloadFeedGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedGroupsOrderBy>>;
};

/** All input for the `deleteFeed` mutation. */
export type DeleteFeedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

/** All input for the `deleteFeedMessageTextAliasByGtfsIdAndAliasAndFeedId` mutation. */
export type DeleteFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdInput = {
  alias: Scalars['String']['input'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
};

/** All input for the `deleteFeedMessageTextAliasByNodeId` mutation. */
export type DeleteFeedMessageTextAliasByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedMessageTextAlias` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFeedMessageTextAlias` mutation. */
export type DeleteFeedMessageTextAliasInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `FeedMessageTextAlias` mutation. */
export type DeleteFeedMessageTextAliasPayload = {
  __typename?: 'DeleteFeedMessageTextAliasPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedFeedMessageTextAliasNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `FeedMessageTextAlias` that was deleted by this mutation. */
  feedMessageTextAlias?: Maybe<FeedMessageTextAlias>;
  /** An edge for our `FeedMessageTextAlias`. May be used by Relay 1. */
  feedMessageTextAliasEdge?: Maybe<FeedMessageTextAliasesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FeedMessageTextAlias` mutation. */
export type DeleteFeedMessageTextAliasPayloadFeedMessageTextAliasEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedMessageTextAliasesOrderBy>>;
};

/** The output of our delete `Feed` mutation. */
export type DeleteFeedPayload = {
  __typename?: 'DeleteFeedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedFeedNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `Feed` that was deleted by this mutation. */
  feed?: Maybe<Feed>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  feedEdge?: Maybe<FeedsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Feed` mutation. */
export type DeleteFeedPayloadFeedEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** All input for the `deleteFeedSettingByNodeId` mutation. */
export type DeleteFeedSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedSetting` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFeedSetting` mutation. */
export type DeleteFeedSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
};

/** The output of our delete `FeedSetting` mutation. */
export type DeleteFeedSettingPayload = {
  __typename?: 'DeleteFeedSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedFeedSettingNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Feed` that is related to this `FeedSetting`. */
  feed?: Maybe<Feed>;
  /** The `FeedSetting` that was deleted by this mutation. */
  feedSetting?: Maybe<FeedSetting>;
  /** An edge for our `FeedSetting`. May be used by Relay 1. */
  feedSettingEdge?: Maybe<FeedSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FeedSetting` mutation. */
export type DeleteFeedSettingPayloadFeedSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/** All input for the `deleteLockableUploadByNodeId` mutation. */
export type DeleteLockableUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LockableUpload` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLockableUpload` mutation. */
export type DeleteLockableUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uploadId: Scalars['UUID']['input'];
};

/** The output of our delete `LockableUpload` mutation. */
export type DeleteLockableUploadPayload = {
  __typename?: 'DeleteLockableUploadPayload';
  /** Reads a single `User` that is related to this `LockableUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLockableUploadNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `LockableUpload` that was deleted by this mutation. */
  lockableUpload?: Maybe<LockableUpload>;
  /** An edge for our `LockableUpload`. May be used by Relay 1. */
  lockableUploadEdge?: Maybe<LockableUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  thumbnail?: Maybe<Upload>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our delete `LockableUpload` mutation. */
export type DeleteLockableUploadPayloadLockableUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/** All input for the `deleteMessageImpactByNodeId` mutation. */
export type DeleteMessageImpactByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `MessageImpact` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteMessageImpact` mutation. */
export type DeleteMessageImpactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `MessageImpact` mutation. */
export type DeleteMessageImpactPayload = {
  __typename?: 'DeleteMessageImpactPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedMessageImpactNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Message` that is related to this `MessageImpact`. */
  message?: Maybe<Message>;
  /** The `MessageImpact` that was deleted by this mutation. */
  messageImpact?: Maybe<MessageImpact>;
  /** An edge for our `MessageImpact`. May be used by Relay 1. */
  messageImpactEdge?: Maybe<MessageImpactsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `MessageImpact` mutation. */
export type DeleteMessageImpactPayloadMessageImpactEdgeArgs = {
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/** All input for the `deletePlannedWorkByNodeId` mutation. */
export type DeletePlannedWorkByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `PlannedWork` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deletePlannedWork` mutation. */
export type DeletePlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `PlannedWork` mutation. */
export type DeletePlannedWorkPayload = {
  __typename?: 'DeletePlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedPlannedWorkNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `PlannedWork` that was deleted by this mutation. */
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our delete `PlannedWork` mutation. */
export type DeletePlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `deleteRouteByNodeId` mutation. */
export type DeleteRouteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Route` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteRoute` mutation. */
export type DeleteRouteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
};

/** The output of our delete `Route` mutation. */
export type DeleteRoutePayload = {
  __typename?: 'DeleteRoutePayload';
  /** Reads a single `Agency` that is related to this `Route`. */
  agency?: Maybe<Agency>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedRouteNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Feed` that is related to this `Route`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Route` that was deleted by this mutation. */
  route?: Maybe<Route>;
  /** An edge for our `Route`. May be used by Relay 1. */
  routeEdge?: Maybe<RoutesEdge>;
};

/** The output of our delete `Route` mutation. */
export type DeleteRoutePayloadRouteEdgeArgs = {
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/** All input for the `deleteScreenNoteByName` mutation. */
export type DeleteScreenNoteByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** The output of our delete `ScreenNote` mutation. */
export type DeleteScreenNotePayload = {
  __typename?: 'DeleteScreenNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedScreenNoteNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ScreenNote` that was deleted by this mutation. */
  screenNote?: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge?: Maybe<ScreenNotesEdge>;
};

/** The output of our delete `ScreenNote` mutation. */
export type DeleteScreenNotePayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** All input for the `deleteScreens2ByNodeId` mutation. */
export type DeleteScreens2ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Screens2` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteScreens2` mutation. */
export type DeleteScreens2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

/** The output of our delete `Screens2` mutation. */
export type DeleteScreens2Payload = {
  __typename?: 'DeleteScreens2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedScreens2NodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Screens2` that was deleted by this mutation. */
  screens2?: Maybe<Screens2>;
  /** An edge for our `Screens2`. May be used by Relay 1. */
  screens2Edge?: Maybe<Screens2sEdge>;
};

/** The output of our delete `Screens2` mutation. */
export type DeleteScreens2PayloadScreens2EdgeArgs = {
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/** All input for the `deleteSocialMediaFolderByNodeId` mutation. */
export type DeleteSocialMediaFolderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SocialMediaFolder` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteSocialMediaFolder` mutation. */
export type DeleteSocialMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `SocialMediaFolder` mutation. */
export type DeleteSocialMediaFolderPayload = {
  __typename?: 'DeleteSocialMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedSocialMediaFolderNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaFolder` that was deleted by this mutation. */
  socialMediaFolder?: Maybe<SocialMediaFolder>;
  /** An edge for our `SocialMediaFolder`. May be used by Relay 1. */
  socialMediaFolderEdge?: Maybe<SocialMediaFoldersEdge>;
  /** Reads a single `User` that is related to this `SocialMediaFolder`. */
  updater?: Maybe<User>;
};

/** The output of our delete `SocialMediaFolder` mutation. */
export type DeleteSocialMediaFolderPayloadSocialMediaFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

/** All input for the `deleteSocialMediaUploadByNodeId` mutation. */
export type DeleteSocialMediaUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SocialMediaUpload` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteSocialMediaUpload` mutation. */
export type DeleteSocialMediaUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uploadId: Scalars['UUID']['input'];
};

/** The output of our delete `SocialMediaUpload` mutation. */
export type DeleteSocialMediaUploadPayload = {
  __typename?: 'DeleteSocialMediaUploadPayload';
  /** Reads a single `User` that is related to this `SocialMediaUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedSocialMediaUploadNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `SocialMediaFolder` that is related to this `SocialMediaUpload`. */
  folder?: Maybe<SocialMediaFolder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaUpload` that was deleted by this mutation. */
  socialMediaUpload?: Maybe<SocialMediaUpload>;
  /** An edge for our `SocialMediaUpload`. May be used by Relay 1. */
  socialMediaUploadEdge?: Maybe<SocialMediaUploadsEdge>;
  /** Reads a single `Upload` that is related to this `SocialMediaUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our delete `SocialMediaUpload` mutation. */
export type DeleteSocialMediaUploadPayloadSocialMediaUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/** All input for the `deleteStopByNodeId` mutation. */
export type DeleteStopByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Stop` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteStop` mutation. */
export type DeleteStopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
};

/** The output of our delete `Stop` mutation. */
export type DeleteStopPayload = {
  __typename?: 'DeleteStopPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedStopNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Feed` that is related to this `Stop`. */
  feed?: Maybe<Feed>;
  /** Reads a single `Stop` that is related to this `Stop`. */
  parentStation?: Maybe<Stop>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stop` that was deleted by this mutation. */
  stop?: Maybe<Stop>;
  /** An edge for our `Stop`. May be used by Relay 1. */
  stopEdge?: Maybe<StopsEdge>;
};

/** The output of our delete `Stop` mutation. */
export type DeleteStopPayloadStopEdgeArgs = {
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

/** All input for the `deleteTripByNodeId` mutation. */
export type DeleteTripByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Trip` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteTrip` mutation. */
export type DeleteTripInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
};

/** The output of our delete `Trip` mutation. */
export type DeleteTripPayload = {
  __typename?: 'DeleteTripPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedTripNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Feed` that is related to this `Trip`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Route` that is related to this `Trip`. */
  route?: Maybe<Route>;
  /** The `Trip` that was deleted by this mutation. */
  trip?: Maybe<Trip>;
  /** An edge for our `Trip`. May be used by Relay 1. */
  tripEdge?: Maybe<TripsEdge>;
};

/** The output of our delete `Trip` mutation. */
export type DeleteTripPayloadTripEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/** All input for the `deleteTripsLirrByNodeId` mutation. */
export type DeleteTripsLirrByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `TripsLirr` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteTripsLirrByTrainNum` mutation. */
export type DeleteTripsLirrByTrainNumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  trainNum: Scalars['String']['input'];
};

/** All input for the `deleteTripsLirr` mutation. */
export type DeleteTripsLirrInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsTripId: Scalars['String']['input'];
};

/** The output of our delete `TripsLirr` mutation. */
export type DeleteTripsLirrPayload = {
  __typename?: 'DeleteTripsLirrPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedTripsLirrNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `Route` that is related to this `TripsLirr`. */
  gtfsRoute?: Maybe<Route>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TripsLirr` that was deleted by this mutation. */
  tripsLirr?: Maybe<TripsLirr>;
  /** An edge for our `TripsLirr`. May be used by Relay 1. */
  tripsLirrEdge?: Maybe<TripsLirrsEdge>;
};

/** The output of our delete `TripsLirr` mutation. */
export type DeleteTripsLirrPayloadTripsLirrEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/** All input for the `deleteTweetsUploadByNodeId` mutation. */
export type DeleteTweetsUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `TweetsUpload` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteTweetsUpload` mutation. */
export type DeleteTweetsUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tweetId: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/** The output of our delete `TweetsUpload` mutation. */
export type DeleteTweetsUploadPayload = {
  __typename?: 'DeleteTweetsUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedTweetsUploadNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tweet` that is related to this `TweetsUpload`. */
  tweet?: Maybe<Tweet>;
  /** The `TweetsUpload` that was deleted by this mutation. */
  tweetsUpload?: Maybe<TweetsUpload>;
  /** An edge for our `TweetsUpload`. May be used by Relay 1. */
  tweetsUploadEdge?: Maybe<TweetsUploadsEdge>;
  /** Reads a single `Upload` that is related to this `TweetsUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our delete `TweetsUpload` mutation. */
export type DeleteTweetsUploadPayloadTweetsUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** All input for the `deleteUploadByNodeId` mutation. */
export type DeleteUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Upload` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteUpload` mutation. */
export type DeleteUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `Upload` mutation. */
export type DeleteUploadPayload = {
  __typename?: 'DeleteUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUploadNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Upload` that was deleted by this mutation. */
  upload?: Maybe<Upload>;
  /** An edge for our `Upload`. May be used by Relay 1. */
  uploadEdge?: Maybe<UploadsEdge>;
};

/** The output of our delete `Upload` mutation. */
export type DeleteUploadPayloadUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<UploadsOrderBy>>;
};

/** All input for the `deleteUserFeedGroupGrantByNodeId` mutation. */
export type DeleteUserFeedGroupGrantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `UserFeedGroupGrant` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteUserFeedGroupGrant` mutation. */
export type DeleteUserFeedGroupGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedGroupName: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
};

/** The output of our delete `UserFeedGroupGrant` mutation. */
export type DeleteUserFeedGroupGrantPayload = {
  __typename?: 'DeleteUserFeedGroupGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUserFeedGroupGrantNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `FeedGroup` that is related to this `UserFeedGroupGrant`. */
  feedGroupByFeedGroupName?: Maybe<FeedGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFeedGroupGrant`. */
  user?: Maybe<User>;
  /** The `UserFeedGroupGrant` that was deleted by this mutation. */
  userFeedGroupGrant?: Maybe<UserFeedGroupGrant>;
  /** An edge for our `UserFeedGroupGrant`. May be used by Relay 1. */
  userFeedGroupGrantEdge?: Maybe<UserFeedGroupGrantsEdge>;
};

/** The output of our delete `UserFeedGroupGrant` mutation. */
export type DeleteUserFeedGroupGrantPayloadUserFeedGroupGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/** All input for the `deleteUsersAccessByNodeId` mutation. */
export type DeleteUsersAccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `UsersAccess` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteUsersAccess` mutation. */
export type DeleteUsersAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['UUID']['input'];
};

/** The output of our delete `UsersAccess` mutation. */
export type DeleteUsersAccessPayload = {
  __typename?: 'DeleteUsersAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUsersAccessNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersAccess`. */
  user?: Maybe<User>;
  /** The `UsersAccess` that was deleted by this mutation. */
  usersAccess?: Maybe<UsersAccess>;
  /** An edge for our `UsersAccess`. May be used by Relay 1. */
  usersAccessEdge?: Maybe<UsersAccessesEdge>;
};

/** The output of our delete `UsersAccess` mutation. */
export type DeleteUsersAccessPayloadUsersAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

export enum Direction {
  BOTH_DIRECTIONS = 'BOTH_DIRECTIONS',
  EASTBOUND = 'EASTBOUND',
  NORTHBOUND = 'NORTHBOUND',
  SOUTHBOUND = 'SOUTHBOUND',
  WESTBOUND = 'WESTBOUND',
}

/** A filter to be used against Direction fields. All fields are combined with a logical ‘and.’ */
export type DirectionFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Direction>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Direction>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Direction>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Direction>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Direction>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Direction>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Direction>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Direction>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Direction>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Direction>>;
};

export type DraftMessage = {
  __typename?: 'DraftMessage';
  additionalInfoText?: Maybe<Scalars['String']['output']>;
  /** Reads a single `User` that is related to this `DraftMessage`. */
  author?: Maybe<User>;
  authorId: Scalars['UUID']['output'];
  bodyHtml: Scalars['String']['output'];
  bodyText: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  entitySelectors: Array<GtfsEntitySelector>;
  eventId: Scalars['Int']['output'];
  feedId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impacts: Array<MessageImpact>;
  includeApps: Scalars['Boolean']['output'];
  includeEmailSms: Scalars['Boolean']['output'];
  includeScreens: Scalars['Boolean']['output'];
  includeTwitter: Scalars['Boolean']['output'];
  messageTypes?: Maybe<Array<Maybe<MessageType>>>;
  notes?: Maybe<Scalars['String']['output']>;
  routeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taggedTrips: Array<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `DraftMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DraftMessageCondition = {
  /** Checks for equality with the object’s `additionalInfoText` field. */
  additionalInfoText?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bodyText` field. */
  bodyText?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `impacts` field. */
  impacts?: InputMaybe<Array<InputMaybe<MessageImpactInput>>>;
  /** Checks for equality with the object’s `includeApps` field. */
  includeApps?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `includeEmailSms` field. */
  includeEmailSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `includeScreens` field. */
  includeScreens?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `includeTwitter` field. */
  includeTwitter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `DraftMessage` object types. All fields are combined with a logical ‘and.’ */
export type DraftMessageFilter = {
  /** Filter by the object’s `additionalInfoText` field. */
  additionalInfoText?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DraftMessageFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bodyText` field. */
  bodyText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eventId` field. */
  eventId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `includeApps` field. */
  includeApps?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `includeEmailSms` field. */
  includeEmailSms?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `includeScreens` field. */
  includeScreens?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `includeTwitter` field. */
  includeTwitter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageTypes` field. */
  messageTypes?: InputMaybe<MessageTypeListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DraftMessageFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DraftMessageFilter>>;
  /** Filter by the object’s `routeIds` field. */
  routeIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `taggedTrips` field. */
  taggedTrips?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `DraftMessage` values. */
export type DraftMessagesConnection = {
  __typename?: 'DraftMessagesConnection';
  /** A list of edges which contains the `DraftMessage` and cursor to aid in pagination. */
  edges: Array<DraftMessagesEdge>;
  /** A list of `DraftMessage` objects. */
  nodes: Array<DraftMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DraftMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DraftMessage` edge in the connection. */
export type DraftMessagesEdge = {
  __typename?: 'DraftMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DraftMessage` at the end of the edge. */
  node: DraftMessage;
};

/** Methods to use when ordering `DraftMessage`. */
export enum DraftMessagesOrderBy {
  ADDITIONAL_INFO_TEXT_ASC = 'ADDITIONAL_INFO_TEXT_ASC',
  ADDITIONAL_INFO_TEXT_DESC = 'ADDITIONAL_INFO_TEXT_DESC',
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  BODY_HTML_ASC = 'BODY_HTML_ASC',
  BODY_HTML_DESC = 'BODY_HTML_DESC',
  BODY_TEXT_ASC = 'BODY_TEXT_ASC',
  BODY_TEXT_DESC = 'BODY_TEXT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  EVENT_ID_ASC = 'EVENT_ID_ASC',
  EVENT_ID_DESC = 'EVENT_ID_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IMPACTS_ASC = 'IMPACTS_ASC',
  IMPACTS_DESC = 'IMPACTS_DESC',
  INCLUDE_APPS_ASC = 'INCLUDE_APPS_ASC',
  INCLUDE_APPS_DESC = 'INCLUDE_APPS_DESC',
  INCLUDE_EMAIL_SMS_ASC = 'INCLUDE_EMAIL_SMS_ASC',
  INCLUDE_EMAIL_SMS_DESC = 'INCLUDE_EMAIL_SMS_DESC',
  INCLUDE_SCREENS_ASC = 'INCLUDE_SCREENS_ASC',
  INCLUDE_SCREENS_DESC = 'INCLUDE_SCREENS_DESC',
  INCLUDE_TWITTER_ASC = 'INCLUDE_TWITTER_ASC',
  INCLUDE_TWITTER_DESC = 'INCLUDE_TWITTER_DESC',
  NATURAL = 'NATURAL',
  NOTES_ASC = 'NOTES_ASC',
  NOTES_DESC = 'NOTES_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type DurationsSource = {
  __typename?: 'DurationsSource';
  cadenceRange?: Maybe<DateRange>;
  cadences?: Maybe<Array<Cadence>>;
  directDurations?: Maybe<Array<DatetimeRange>>;
  exceptions?: Maybe<Array<DatetimeRange>>;
  humanReadableDurationsOverrideMessage?: Maybe<Scalars['String']['output']>;
  inputMethod?: Maybe<DurationsSourceInputMethod>;
  untilFurtherNoticeMessage?: Maybe<Scalars['String']['output']>;
};

/** An input for mutations affecting `DurationsSource` */
export type DurationsSourceInput = {
  cadenceRange?: InputMaybe<DateRangeInput>;
  cadences?: InputMaybe<Array<CadenceInput>>;
  directDurations?: InputMaybe<Array<DatetimeRangeInput>>;
  exceptions?: InputMaybe<Array<DatetimeRangeInput>>;
  humanReadableDurationsOverrideMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  inputMethod: DurationsSourceInputMethod;
  untilFurtherNoticeMessage?: InputMaybe<Scalars['String']['input']>;
};

export enum DurationsSourceInputMethod {
  CADENCE = 'CADENCE',
  DIRECT = 'DIRECT',
}

export enum EmailMessageTypeCodes {
  CONST = 'CONST',
  LIELE = 'LIELE',
  NEWRS = 'NEWRS',
  OTHER = 'OTHER',
  PLSVC = 'PLSVC',
  RTSCH = 'RTSCH',
}

/** A filter to be used against EmailMessageTypeCodes List fields. All fields are combined with a logical ‘and.’ */
export type EmailMessageTypeCodesListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<EmailMessageTypeCodes>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<EmailMessageTypeCodes>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<EmailMessageTypeCodes>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<EmailMessageTypeCodes>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<EmailMessageTypeCodes>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<EmailMessageTypeCodes>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
};

/** A logical grouping of an initial message and its updates, not exposed to end-users. */
export type Event = Node & {
  __typename?: 'Event';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  clearedBy?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Datetime']['output'];
  feedId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  incidentEndAt?: Maybe<Scalars['Datetime']['output']>;
  incidentStartAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  /** LIRR/MNR field for keeping track of response times for incidents. */
  neededBy?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  notifiedAt?: Maybe<Scalars['Datetime']['output']>;
  status: EventStatus;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** A logical grouping of an initial message and its updates, not exposed to end-users. */
export type EventMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `clearedBy` field. */
  clearedBy?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `incidentEndAt` field. */
  incidentEndAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `incidentStartAt` field. */
  incidentStartAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `neededBy` field. */
  neededBy?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notifiedAt` field. */
  notifiedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `clearedBy` field. */
  clearedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `incidentEndAt` field. */
  incidentEndAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `incidentStartAt` field. */
  incidentStartAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `neededBy` field. */
  neededBy?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `notifiedAt` field. */
  notifiedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<EventStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `EventPatchRecord` */
export type EventPatchRecordInput = {
  incidentEndAt?: InputMaybe<Scalars['Datetime']['input']>;
  incidentStartAt?: InputMaybe<Scalars['Datetime']['input']>;
  neededBy?: InputMaybe<Scalars['Datetime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export enum EventStatus {
  CLEARED = 'CLEARED',
  CLEARING = 'CLEARING',
  CURRENT = 'CURRENT',
  DRAFT = 'DRAFT',
}

/** A filter to be used against EventStatus fields. All fields are combined with a logical ‘and.’ */
export type EventStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EventStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EventStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EventStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EventStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EventStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EventStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EventStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EventStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EventStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EventStatus>>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Event` at the end of the edge. */
  node: Event;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  CLEARED_BY_ASC = 'CLEARED_BY_ASC',
  CLEARED_BY_DESC = 'CLEARED_BY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INCIDENT_END_AT_ASC = 'INCIDENT_END_AT_ASC',
  INCIDENT_END_AT_DESC = 'INCIDENT_END_AT_DESC',
  INCIDENT_START_AT_ASC = 'INCIDENT_START_AT_ASC',
  INCIDENT_START_AT_DESC = 'INCIDENT_START_AT_DESC',
  NATURAL = 'NATURAL',
  NEEDED_BY_ASC = 'NEEDED_BY_ASC',
  NEEDED_BY_DESC = 'NEEDED_BY_DESC',
  NOTES_ASC = 'NOTES_ASC',
  NOTES_DESC = 'NOTES_DESC',
  NOTIFIED_AT_ASC = 'NOTIFIED_AT_ASC',
  NOTIFIED_AT_DESC = 'NOTIFIED_AT_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type FallbackScreensContent = {
  __typename?: 'FallbackScreensContent';
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  createdAt: Scalars['Datetime']['output'];
  direction?: Maybe<Direction>;
  feedId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: TargetType;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `Upload` that is related to this `FallbackScreensContent`. */
  upload?: Maybe<Upload>;
  uploadId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `FallbackScreensContent` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FallbackScreensContentCondition = {
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `direction` field. */
  direction?: InputMaybe<Direction>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<TargetType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `FallbackScreensContent` object types. All fields are combined with a logical ‘and.’ */
export type FallbackScreensContentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FallbackScreensContentFilter>>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `direction` field. */
  direction?: InputMaybe<DirectionFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FallbackScreensContentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FallbackScreensContentFilter>>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<TargetTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `FallbackScreensContent` */
export type FallbackScreensContentInput = {
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  direction?: InputMaybe<Direction>;
  feedId: Scalars['String']['input'];
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  type: TargetType;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  uploadId: Scalars['UUID']['input'];
};

/** A connection to a list of `FallbackScreensContent` values. */
export type FallbackScreensContentsConnection = {
  __typename?: 'FallbackScreensContentsConnection';
  /** A list of edges which contains the `FallbackScreensContent` and cursor to aid in pagination. */
  edges: Array<FallbackScreensContentsEdge>;
  /** A list of `FallbackScreensContent` objects. */
  nodes: Array<FallbackScreensContent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FallbackScreensContent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FallbackScreensContent` edge in the connection. */
export type FallbackScreensContentsEdge = {
  __typename?: 'FallbackScreensContentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FallbackScreensContent` at the end of the edge. */
  node: FallbackScreensContent;
};

/** Methods to use when ordering `FallbackScreensContent`. */
export enum FallbackScreensContentsOrderBy {
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

export enum FeatureEnabled {
  OFF = 'OFF',
  ON = 'ON',
  PARTIAL = 'PARTIAL',
}

/** A filter to be used against FeatureEnabled fields. All fields are combined with a logical ‘and.’ */
export type FeatureEnabledFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<FeatureEnabled>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<FeatureEnabled>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<FeatureEnabled>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<FeatureEnabled>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<FeatureEnabled>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<FeatureEnabled>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<FeatureEnabled>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<FeatureEnabled>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<FeatureEnabled>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<FeatureEnabled>>;
};

/**
 * Feature flags in Mercury are a simple system to indicate what features should and should not
 * be available. A feature flag can be in one of three states:
 *
 * - `off`, which indicates a feature is turned off for all users regardless of any user- or feed-
 * level exceptions as described below;
 * - `partial`, which indicates a feature is only available to users and/or feeds that have explicitly
 * been granted access; and
 * - `on`, which indicates a feature is fully available to all users.
 *
 * The default feature flag `enabled` state is `partial`. A `partial` state with no associated entries
 * in lmm_private.feed_feature_flags or lmm_private.user_feature_flags is effectively equivalent to `off`.
 * Otherwise, a feature is determined to be enabled for a given user if:
 *
 * 1. It is `on`.
 * 2. It is `partial` and the user has an associated entry in `lmm_private.user_feature_flags`.
 * 3. It is `partial` and active feed_id has an associated entry in `lmm_private.feed_feature_flags`.
 *
 * This means that a feature can be enabled for a specific user (e.g. to test it), or only or specific feeds,
 * but a feature cannot be _disabled_ for specific users or feeds. Flag exceptions mark availability.
 *
 */
export type FeatureFlag = Node & {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: FeatureEnabled;
  feeds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: FeatureFlagName;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

/**
 * A condition to be used against `FeatureFlag` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeatureFlagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<FeatureEnabled>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<FeatureFlagName>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeatureFlag` object types. All fields are combined with a logical ‘and.’ */
export type FeatureFlagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureFlagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<FeatureEnabledFilter>;
  /** Filter by the object’s `feeds` field. */
  feeds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<FeatureFlagNameFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureFlagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureFlagFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `FeatureFlag` */
export type FeatureFlagInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<FeatureEnabled>;
  name: FeatureFlagName;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export enum FeatureFlagName {
  AFFECTED_STATIONS = 'AFFECTED_STATIONS',
  ASSETS = 'ASSETS',
  ASSETS_CRUD = 'ASSETS_CRUD',
  CAMPAIGNS = 'CAMPAIGNS',
  CAMPAIGN_ROLLING_STOCK = 'CAMPAIGN_ROLLING_STOCK',
  COMPLEX_DURATIONS = 'COMPLEX_DURATIONS',
  CUSTOM_TAGGING = 'CUSTOM_TAGGING',
  CUSTOM_TAGGING_TARGETING = 'CUSTOM_TAGGING_TARGETING',
  CUSTOM_TARGETING = 'CUSTOM_TARGETING',
  DISABLE_ELEVATOR_DISTRIBUTION_GROUP = 'DISABLE_ELEVATOR_DISTRIBUTION_GROUP',
  DISABLE_TRANSIT_V1 = 'DISABLE_TRANSIT_V1',
  EDITABLE_SMS = 'EDITABLE_SMS',
  ENABLE_SINGLE_SCREEN_CAMPAIGNS = 'ENABLE_SINGLE_SCREEN_CAMPAIGNS',
  E_ALERTS_USE_MIS_API = 'E_ALERTS_USE_MIS_API',
  E_ALERTS_USE_SALESFORCE_API = 'E_ALERTS_USE_SALESFORCE_API',
  HUMAN_READABLE_DURATIONS_OVERRIDE = 'HUMAN_READABLE_DURATIONS_OVERRIDE',
  LIVE_CONTENT = 'LIVE_CONTENT',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  MESSAGED_NEEDED_IN_CLEARED_LOGS = 'MESSAGED_NEEDED_IN_CLEARED_LOGS',
  PLANNED_WORK = 'PLANNED_WORK',
  PLANNED_WORK_DISABLE_PUBLISH_BUTTON = 'PLANNED_WORK_DISABLE_PUBLISH_BUTTON',
  PLANNED_WORK_GO_SP = 'PLANNED_WORK_GO_SP',
  PLANNED_WORK_SCREEN_TARGETS = 'PLANNED_WORK_SCREEN_TARGETS',
  PLANNED_WORK_TEMPLATE_DISABLE_PORTRAIT_SCREEN_PREVIEW = 'PLANNED_WORK_TEMPLATE_DISABLE_PORTRAIT_SCREEN_PREVIEW',
  RICH_EDITING = 'RICH_EDITING',
  SCREENS_UI = 'SCREENS_UI',
  SCREEN_LOCKING_UI = 'SCREEN_LOCKING_UI',
  SERVICE_ALERTS = 'SERVICE_ALERTS',
  SERVICE_ALERTS_ADDITIONAL_INFORMATION = 'SERVICE_ALERTS_ADDITIONAL_INFORMATION',
  SERVICE_ALERTS_SCREEN_TITLE = 'SERVICE_ALERTS_SCREEN_TITLE',
  SERVICE_ALERT_DISABLE_PORTRAIT_SCREEN_PREVIEW = 'SERVICE_ALERT_DISABLE_PORTRAIT_SCREEN_PREVIEW',
  SERVICE_ALERT_SCREEN_TARGETS = 'SERVICE_ALERT_SCREEN_TARGETS',
  SIDEWALK_SCREEN_TARGETING = 'SIDEWALK_SCREEN_TARGETING',
  TARGET_AGENCY_IN_GTFS = 'TARGET_AGENCY_IN_GTFS',
  WEIGHTING_FREQUENCY = 'WEIGHTING_FREQUENCY',
}

/** A filter to be used against FeatureFlagName fields. All fields are combined with a logical ‘and.’ */
export type FeatureFlagNameFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<FeatureFlagName>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<FeatureFlagName>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<FeatureFlagName>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<FeatureFlagName>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<FeatureFlagName>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<FeatureFlagName>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<FeatureFlagName>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<FeatureFlagName>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<FeatureFlagName>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<FeatureFlagName>>;
};

/** Represents an update to a `FeatureFlag`. Fields that are set will be updated. */
export type FeatureFlagPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<FeatureEnabled>;
  name?: InputMaybe<FeatureFlagName>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `FeatureFlag` values. */
export type FeatureFlagsConnection = {
  __typename?: 'FeatureFlagsConnection';
  /** A list of edges which contains the `FeatureFlag` and cursor to aid in pagination. */
  edges: Array<FeatureFlagsEdge>;
  /** A list of `FeatureFlag` objects. */
  nodes: Array<FeatureFlag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeatureFlag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeatureFlag` edge in the connection. */
export type FeatureFlagsEdge = {
  __typename?: 'FeatureFlagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeatureFlag` at the end of the edge. */
  node: FeatureFlag;
};

/** Methods to use when ordering `FeatureFlag`. */
export enum FeatureFlagsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  ENABLED_ASC = 'ENABLED_ASC',
  ENABLED_DESC = 'ENABLED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type Feed = Node & {
  __typename?: 'Feed';
  /** Reads and enables pagination through a set of `Agency`. */
  agencies: AgenciesConnection;
  createdAt: Scalars['Datetime']['output'];
  /** Reads a single `FeedSetting` that is related to this `Feed`. */
  feedSettingByFeedId?: Maybe<FeedSetting>;
  /**
   * Reads and enables pagination through a set of `FeedSetting`.
   * @deprecated Please use feedSettingByFeedId instead
   */
  feedSettings: FeedSettingsConnection;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  otpId: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `Route`. */
  routes: RoutesConnection;
  /** Reads and enables pagination through a set of `StopTime`. */
  stopTimes: StopTimesConnection;
  /** Reads and enables pagination through a set of `Stop`. */
  stops: StopsConnection;
  /** Reads and enables pagination through a set of `SyntheticScreen`. */
  syntheticScreens: SyntheticScreensConnection;
  /** Reads and enables pagination through a set of `Trip`. */
  trips: TripsConnection;
  versionTimestamp: Scalars['Datetime']['output'];
};

export type FeedAgenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AgencyCondition>;
  filter?: InputMaybe<AgencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

export type FeedFeedSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedSettingCondition>;
  filter?: InputMaybe<FeedSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

export type FeedRoutesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RouteCondition>;
  filter?: InputMaybe<RouteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

export type FeedStopTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopTimeCondition>;
  filter?: InputMaybe<StopTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

export type FeedStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopCondition>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

export type FeedSyntheticScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SyntheticScreenCondition>;
  filter?: InputMaybe<SyntheticScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SyntheticScreensOrderBy>>;
};

export type FeedTripsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripCondition>;
  filter?: InputMaybe<TripFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/** A condition to be used against `Feed` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeedCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `otpId` field. */
  otpId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `versionTimestamp` field. */
  versionTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FeedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedFilter>>;
  /** Filter by the object’s `otpId` field. */
  otpId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `versionTimestamp` field. */
  versionTimestamp?: InputMaybe<DatetimeFilter>;
};

export type FeedGroup = Node & {
  __typename?: 'FeedGroup';
  feedIds: Array<Maybe<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `UserFeedGroupGrant`. */
  userFeedGroupGrantsByFeedGroupName: UserFeedGroupGrantsConnection;
};

export type FeedGroupUserFeedGroupGrantsByFeedGroupNameArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserFeedGroupGrantCondition>;
  filter?: InputMaybe<UserFeedGroupGrantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/**
 * A condition to be used against `FeedGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedGroupCondition = {
  /** Checks for equality with the object’s `feedIds` field. */
  feedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedGroup` object types. All fields are combined with a logical ‘and.’ */
export type FeedGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedGroupFilter>>;
  /** Filter by the object’s `feedIds` field. */
  feedIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedGroupFilter>>;
};

/** An input for mutations affecting `FeedGroup` */
export type FeedGroupInput = {
  feedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
};

/** Represents an update to a `FeedGroup`. Fields that are set will be updated. */
export type FeedGroupPatch = {
  feedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `FeedGroup` values. */
export type FeedGroupsConnection = {
  __typename?: 'FeedGroupsConnection';
  /** A list of edges which contains the `FeedGroup` and cursor to aid in pagination. */
  edges: Array<FeedGroupsEdge>;
  /** A list of `FeedGroup` objects. */
  nodes: Array<FeedGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedGroup` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedGroup` edge in the connection. */
export type FeedGroupsEdge = {
  __typename?: 'FeedGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedGroup` at the end of the edge. */
  node: FeedGroup;
};

/** Methods to use when ordering `FeedGroup`. */
export enum FeedGroupsOrderBy {
  FEED_IDS_ASC = 'FEED_IDS_ASC',
  FEED_IDS_DESC = 'FEED_IDS_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** An input for mutations affecting `Feed` */
export type FeedInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  otpId: Scalars['String']['input'];
  versionTimestamp: Scalars['Datetime']['input'];
};

export type FeedMessageTextAlias = Node & {
  __typename?: 'FeedMessageTextAlias';
  alias: Scalars['String']['output'];
  feedId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/**
 * A condition to be used against `FeedMessageTextAlias` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedMessageTextAliasCondition = {
  /** Checks for equality with the object’s `alias` field. */
  alias?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `FeedMessageTextAlias` object types. All fields are combined with a logical ‘and.’ */
export type FeedMessageTextAliasFilter = {
  /** Filter by the object’s `alias` field. */
  alias?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedMessageTextAliasFilter>>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedMessageTextAliasFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedMessageTextAliasFilter>>;
};

/** An input for mutations affecting `FeedMessageTextAlias` */
export type FeedMessageTextAliasInput = {
  alias: Scalars['String']['input'];
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
};

/** Represents an update to a `FeedMessageTextAlias`. Fields that are set will be updated. */
export type FeedMessageTextAliasPatch = {
  alias?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  gtfsId?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `FeedMessageTextAlias` values. */
export type FeedMessageTextAliasesConnection = {
  __typename?: 'FeedMessageTextAliasesConnection';
  /** A list of edges which contains the `FeedMessageTextAlias` and cursor to aid in pagination. */
  edges: Array<FeedMessageTextAliasesEdge>;
  /** A list of `FeedMessageTextAlias` objects. */
  nodes: Array<FeedMessageTextAlias>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedMessageTextAlias` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedMessageTextAlias` edge in the connection. */
export type FeedMessageTextAliasesEdge = {
  __typename?: 'FeedMessageTextAliasesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedMessageTextAlias` at the end of the edge. */
  node: FeedMessageTextAlias;
};

/** Methods to use when ordering `FeedMessageTextAlias`. */
export enum FeedMessageTextAliasesOrderBy {
  ALIAS_ASC = 'ALIAS_ASC',
  ALIAS_DESC = 'ALIAS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** Represents an update to a `Feed`. Fields that are set will be updated. */
export type FeedPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otpId?: InputMaybe<Scalars['String']['input']>;
  versionTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

export type FeedSetting = Node & {
  __typename?: 'FeedSetting';
  /** Reads a single `Feed` that is related to this `FeedSetting`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  messageTypes: Array<Maybe<MessageType>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/**
 * A condition to be used against `FeedSetting` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedSettingCondition = {
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageTypes` field. */
  messageTypes?: InputMaybe<Array<InputMaybe<MessageType>>>;
};

/** A filter to be used against `FeedSetting` object types. All fields are combined with a logical ‘and.’ */
export type FeedSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedSettingFilter>>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageTypes` field. */
  messageTypes?: InputMaybe<MessageTypeListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedSettingFilter>>;
};

/** An input for mutations affecting `FeedSetting` */
export type FeedSettingInput = {
  feedId: Scalars['String']['input'];
  messageTypes?: InputMaybe<Array<InputMaybe<MessageType>>>;
};

/** Represents an update to a `FeedSetting`. Fields that are set will be updated. */
export type FeedSettingPatch = {
  feedId?: InputMaybe<Scalars['String']['input']>;
  messageTypes?: InputMaybe<Array<InputMaybe<MessageType>>>;
};

/** A connection to a list of `FeedSetting` values. */
export type FeedSettingsConnection = {
  __typename?: 'FeedSettingsConnection';
  /** A list of edges which contains the `FeedSetting` and cursor to aid in pagination. */
  edges: Array<FeedSettingsEdge>;
  /** A list of `FeedSetting` objects. */
  nodes: Array<FeedSetting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedSetting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedSetting` edge in the connection. */
export type FeedSettingsEdge = {
  __typename?: 'FeedSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedSetting` at the end of the edge. */
  node: FeedSetting;
};

/** Methods to use when ordering `FeedSetting`. */
export enum FeedSettingsOrderBy {
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  MESSAGE_TYPES_ASC = 'MESSAGE_TYPES_ASC',
  MESSAGE_TYPES_DESC = 'MESSAGE_TYPES_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** A connection to a list of `Feed` values. */
export type FeedsConnection = {
  __typename?: 'FeedsConnection';
  /** A list of edges which contains the `Feed` and cursor to aid in pagination. */
  edges: Array<FeedsEdge>;
  /** A list of `Feed` objects. */
  nodes: Array<Feed>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Feed` edge in the connection. */
export type FeedsEdge = {
  __typename?: 'FeedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Feed;
};

/** Methods to use when ordering `Feed`. */
export enum FeedsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  OTP_ID_ASC = 'OTP_ID_ASC',
  OTP_ID_DESC = 'OTP_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  VERSION_TIMESTAMP_ASC = 'VERSION_TIMESTAMP_ASC',
  VERSION_TIMESTAMP_DESC = 'VERSION_TIMESTAMP_DESC',
}

/** An input for mutations affecting `FlagFeed` */
export type FlagFeedInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
};

export type GeneralOrderNumber = {
  __typename?: 'GeneralOrderNumber';
  generalOrderNumber: Scalars['String']['output'];
};

/**
 * A condition to be used against `GeneralOrderNumber` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GeneralOrderNumberCondition = {
  /** Checks for equality with the object’s `generalOrderNumber` field. */
  generalOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `GeneralOrderNumber` object types. All fields are combined with a logical ‘and.’ */
export type GeneralOrderNumberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GeneralOrderNumberFilter>>;
  /** Filter by the object’s `generalOrderNumber` field. */
  generalOrderNumber?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GeneralOrderNumberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GeneralOrderNumberFilter>>;
};

/** A connection to a list of `GeneralOrderNumber` values. */
export type GeneralOrderNumbersConnection = {
  __typename?: 'GeneralOrderNumbersConnection';
  /** A list of edges which contains the `GeneralOrderNumber` and cursor to aid in pagination. */
  edges: Array<GeneralOrderNumbersEdge>;
  /** A list of `GeneralOrderNumber` objects. */
  nodes: Array<GeneralOrderNumber>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeneralOrderNumber` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GeneralOrderNumber` edge in the connection. */
export type GeneralOrderNumbersEdge = {
  __typename?: 'GeneralOrderNumbersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GeneralOrderNumber` at the end of the edge. */
  node: GeneralOrderNumber;
};

/** Methods to use when ordering `GeneralOrderNumber`. */
export enum GeneralOrderNumbersOrderBy {
  GENERAL_ORDER_NUMBER_ASC = 'GENERAL_ORDER_NUMBER_ASC',
  GENERAL_ORDER_NUMBER_DESC = 'GENERAL_ORDER_NUMBER_DESC',
  NATURAL = 'NATURAL',
}

/** All input for the `generateCurrentClearedReports` mutation. */
export type GenerateCurrentClearedReportsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
};

/** The output of our `generateCurrentClearedReports` mutation. */
export type GenerateCurrentClearedReportsPayload = {
  __typename?: 'GenerateCurrentClearedReportsPayload';
  clearedReport?: Maybe<ClearedReport>;
  /** An edge for our `ClearedReport`. May be used by Relay 1. */
  clearedReportEdge?: Maybe<ClearedReportsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `generateCurrentClearedReports` mutation. */
export type GenerateCurrentClearedReportsPayloadClearedReportEdgeArgs = {
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/** All input for the `getActiveRealtimeMessages` mutation. */
export type GetActiveRealtimeMessagesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feeds: Array<InputMaybe<Scalars['String']['input']>>;
};

/** The output of our `getActiveRealtimeMessages` mutation. */
export type GetActiveRealtimeMessagesPayload = {
  __typename?: 'GetActiveRealtimeMessagesPayload';
  activeRealtimeMessages?: Maybe<Array<ActiveRealtimeMessage>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `grantUserFeatureFlag` mutation. */
export type GrantUserFeatureFlagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flagName: FeatureFlagName;
  userId: Scalars['UUID']['input'];
};

/** The output of our `grantUserFeatureFlag` mutation. */
export type GrantUserFeatureFlagPayload = {
  __typename?: 'GrantUserFeatureFlagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our `grantUserFeatureFlag` mutation. */
export type GrantUserFeatureFlagPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type GroupedStop = {
  __typename?: 'GroupedStop';
  borough?: Maybe<Scalars['String']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  feedId?: Maybe<Scalars['String']['output']>;
  gtfsRouteIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  gtfsStopId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `GroupedStop` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GroupedStopCondition = {
  /** Checks for equality with the object’s `borough` field. */
  borough?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsRouteIds` field. */
  gtfsRouteIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `gtfsStopId` field. */
  gtfsStopId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `GroupedStop` object types. All fields are combined with a logical ‘and.’ */
export type GroupedStopFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupedStopFilter>>;
  /** Filter by the object’s `borough` field. */
  borough?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsRouteIds` field. */
  gtfsRouteIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `gtfsStopId` field. */
  gtfsStopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupedStopFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupedStopFilter>>;
};

/** A connection to a list of `GroupedStop` values. */
export type GroupedStopsConnection = {
  __typename?: 'GroupedStopsConnection';
  /** A list of edges which contains the `GroupedStop` and cursor to aid in pagination. */
  edges: Array<GroupedStopsEdge>;
  /** A list of `GroupedStop` objects. */
  nodes: Array<GroupedStop>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupedStop` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GroupedStop` edge in the connection. */
export type GroupedStopsEdge = {
  __typename?: 'GroupedStopsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GroupedStop` at the end of the edge. */
  node: GroupedStop;
};

/** Methods to use when ordering `GroupedStop`. */
export enum GroupedStopsOrderBy {
  BOROUGH_ASC = 'BOROUGH_ASC',
  BOROUGH_DESC = 'BOROUGH_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ROUTE_IDS_ASC = 'GTFS_ROUTE_IDS_ASC',
  GTFS_ROUTE_IDS_DESC = 'GTFS_ROUTE_IDS_DESC',
  GTFS_STOP_ID_ASC = 'GTFS_STOP_ID_ASC',
  GTFS_STOP_ID_DESC = 'GTFS_STOP_ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
}

export type GtfsEntitySelector = {
  __typename?: 'GtfsEntitySelector';
  agencyId?: Maybe<Scalars['String']['output']>;
  routeId?: Maybe<Scalars['String']['output']>;
  routeType?: Maybe<Scalars['Int']['output']>;
  stopId?: Maybe<Scalars['String']['output']>;
  trip?: Maybe<GtfsTripDescriptor>;
};

/** An input for mutations affecting `GtfsEntitySelector` */
export type GtfsEntitySelectorInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  routeType?: InputMaybe<Scalars['Int']['input']>;
  stopId?: InputMaybe<Scalars['String']['input']>;
  trip?: InputMaybe<GtfsTripDescriptorInput>;
};

export type GtfsTripDescriptor = {
  __typename?: 'GtfsTripDescriptor';
  directionId?: Maybe<Scalars['Int']['output']>;
  routeId?: Maybe<Scalars['String']['output']>;
  scheduleRelationship?: Maybe<ScheduleRelationship>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  tripId?: Maybe<Scalars['String']['output']>;
};

/** An input for mutations affecting `GtfsTripDescriptor` */
export type GtfsTripDescriptorInput = {
  directionId?: InputMaybe<Scalars['Int']['input']>;
  routeId?: InputMaybe<Scalars['String']['input']>;
  scheduleRelationship?: InputMaybe<ScheduleRelationship>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']['output']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']['output']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']['output']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']['output']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']['output']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']['input']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']['input']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

/** A filter to be used against JSON List fields. All fields are combined with a logical ‘and.’ */
export type JsonListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars['JSON']['input']>>
  >;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Job = {
  __typename?: 'Job';
  completedon?: Maybe<Scalars['Datetime']['output']>;
  createdon: Scalars['Datetime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  expirein: Interval;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  resultCompletedon?: Maybe<Scalars['Datetime']['output']>;
  resultCreatedon?: Maybe<Scalars['Datetime']['output']>;
  resultData?: Maybe<Scalars['JSON']['output']>;
  resultExpirein?: Maybe<Interval>;
  resultId?: Maybe<Scalars['UUID']['output']>;
  resultName?: Maybe<Scalars['String']['output']>;
  resultPriority?: Maybe<Scalars['Int']['output']>;
  resultRetrybackoff?: Maybe<Scalars['Boolean']['output']>;
  resultRetrycount?: Maybe<Scalars['Int']['output']>;
  resultRetrydelay?: Maybe<Scalars['Int']['output']>;
  resultRetrylimit?: Maybe<Scalars['Int']['output']>;
  resultSingletonkey?: Maybe<Scalars['String']['output']>;
  resultSingletonon?: Maybe<Scalars['Datetime']['output']>;
  resultStartafter?: Maybe<Scalars['Datetime']['output']>;
  resultStartedon?: Maybe<Scalars['Datetime']['output']>;
  resultState?: Maybe<JobState>;
  retrybackoff: Scalars['Boolean']['output'];
  retrycount: Scalars['Int']['output'];
  retrydelay: Scalars['Int']['output'];
  retrylimit: Scalars['Int']['output'];
  singletonkey?: Maybe<Scalars['String']['output']>;
  singletonon?: Maybe<Scalars['Datetime']['output']>;
  startafter: Scalars['Datetime']['output'];
  startedon?: Maybe<Scalars['Datetime']['output']>;
  state: JobState;
};

/** A condition to be used against `Job` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type JobCondition = {
  /** Checks for equality with the object’s `completedon` field. */
  completedon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdon` field. */
  createdon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `expirein` field. */
  expirein?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultCompletedon` field. */
  resultCompletedon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resultCreatedon` field. */
  resultCreatedon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resultData` field. */
  resultData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `resultExpirein` field. */
  resultExpirein?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `resultId` field. */
  resultId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `resultName` field. */
  resultName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `resultPriority` field. */
  resultPriority?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultRetrybackoff` field. */
  resultRetrybackoff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `resultRetrycount` field. */
  resultRetrycount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultRetrydelay` field. */
  resultRetrydelay?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultRetrylimit` field. */
  resultRetrylimit?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultSingletonkey` field. */
  resultSingletonkey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `resultSingletonon` field. */
  resultSingletonon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resultStartafter` field. */
  resultStartafter?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resultStartedon` field. */
  resultStartedon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resultState` field. */
  resultState?: InputMaybe<JobState>;
  /** Checks for equality with the object’s `retrybackoff` field. */
  retrybackoff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `retrycount` field. */
  retrycount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retrydelay` field. */
  retrydelay?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retrylimit` field. */
  retrylimit?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `singletonkey` field. */
  singletonkey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `singletonon` field. */
  singletonon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startafter` field. */
  startafter?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startedon` field. */
  startedon?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<JobState>;
};

/** A filter to be used against `Job` object types. All fields are combined with a logical ‘and.’ */
export type JobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `completedon` field. */
  completedon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdon` field. */
  createdon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `expirein` field. */
  expirein?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<JobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultCompletedon` field. */
  resultCompletedon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resultCreatedon` field. */
  resultCreatedon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resultData` field. */
  resultData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `resultExpirein` field. */
  resultExpirein?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `resultId` field. */
  resultId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `resultName` field. */
  resultName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `resultPriority` field. */
  resultPriority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultRetrybackoff` field. */
  resultRetrybackoff?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `resultRetrycount` field. */
  resultRetrycount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultRetrydelay` field. */
  resultRetrydelay?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultRetrylimit` field. */
  resultRetrylimit?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultSingletonkey` field. */
  resultSingletonkey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `resultSingletonon` field. */
  resultSingletonon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resultStartafter` field. */
  resultStartafter?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resultStartedon` field. */
  resultStartedon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resultState` field. */
  resultState?: InputMaybe<JobStateFilter>;
  /** Filter by the object’s `retrybackoff` field. */
  retrybackoff?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `retrycount` field. */
  retrycount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `retrydelay` field. */
  retrydelay?: InputMaybe<IntFilter>;
  /** Filter by the object’s `retrylimit` field. */
  retrylimit?: InputMaybe<IntFilter>;
  /** Filter by the object’s `singletonkey` field. */
  singletonkey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `singletonon` field. */
  singletonon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startafter` field. */
  startafter?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedon` field. */
  startedon?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<JobStateFilter>;
};

export enum JobState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  RETRY = 'RETRY',
}

/** A filter to be used against JobState fields. All fields are combined with a logical ‘and.’ */
export type JobStateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<JobState>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<JobState>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<JobState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<JobState>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<JobState>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<JobState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<JobState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<JobState>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<JobState>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<JobState>>;
};

/** A connection to a list of `Job` values. */
export type JobsConnection = {
  __typename?: 'JobsConnection';
  /** A list of edges which contains the `Job` and cursor to aid in pagination. */
  edges: Array<JobsEdge>;
  /** A list of `Job` objects. */
  nodes: Array<Job>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Job` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Job` edge in the connection. */
export type JobsEdge = {
  __typename?: 'JobsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Job` at the end of the edge. */
  node: Job;
};

/** Methods to use when ordering `Job`. */
export enum JobsOrderBy {
  COMPLETEDON_ASC = 'COMPLETEDON_ASC',
  COMPLETEDON_DESC = 'COMPLETEDON_DESC',
  CREATEDON_ASC = 'CREATEDON_ASC',
  CREATEDON_DESC = 'CREATEDON_DESC',
  DATA_ASC = 'DATA_ASC',
  DATA_DESC = 'DATA_DESC',
  EXPIREIN_ASC = 'EXPIREIN_ASC',
  EXPIREIN_DESC = 'EXPIREIN_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  RESULT_COMPLETEDON_ASC = 'RESULT_COMPLETEDON_ASC',
  RESULT_COMPLETEDON_DESC = 'RESULT_COMPLETEDON_DESC',
  RESULT_CREATEDON_ASC = 'RESULT_CREATEDON_ASC',
  RESULT_CREATEDON_DESC = 'RESULT_CREATEDON_DESC',
  RESULT_DATA_ASC = 'RESULT_DATA_ASC',
  RESULT_DATA_DESC = 'RESULT_DATA_DESC',
  RESULT_EXPIREIN_ASC = 'RESULT_EXPIREIN_ASC',
  RESULT_EXPIREIN_DESC = 'RESULT_EXPIREIN_DESC',
  RESULT_ID_ASC = 'RESULT_ID_ASC',
  RESULT_ID_DESC = 'RESULT_ID_DESC',
  RESULT_NAME_ASC = 'RESULT_NAME_ASC',
  RESULT_NAME_DESC = 'RESULT_NAME_DESC',
  RESULT_PRIORITY_ASC = 'RESULT_PRIORITY_ASC',
  RESULT_PRIORITY_DESC = 'RESULT_PRIORITY_DESC',
  RESULT_RETRYBACKOFF_ASC = 'RESULT_RETRYBACKOFF_ASC',
  RESULT_RETRYBACKOFF_DESC = 'RESULT_RETRYBACKOFF_DESC',
  RESULT_RETRYCOUNT_ASC = 'RESULT_RETRYCOUNT_ASC',
  RESULT_RETRYCOUNT_DESC = 'RESULT_RETRYCOUNT_DESC',
  RESULT_RETRYDELAY_ASC = 'RESULT_RETRYDELAY_ASC',
  RESULT_RETRYDELAY_DESC = 'RESULT_RETRYDELAY_DESC',
  RESULT_RETRYLIMIT_ASC = 'RESULT_RETRYLIMIT_ASC',
  RESULT_RETRYLIMIT_DESC = 'RESULT_RETRYLIMIT_DESC',
  RESULT_SINGLETONKEY_ASC = 'RESULT_SINGLETONKEY_ASC',
  RESULT_SINGLETONKEY_DESC = 'RESULT_SINGLETONKEY_DESC',
  RESULT_SINGLETONON_ASC = 'RESULT_SINGLETONON_ASC',
  RESULT_SINGLETONON_DESC = 'RESULT_SINGLETONON_DESC',
  RESULT_STARTAFTER_ASC = 'RESULT_STARTAFTER_ASC',
  RESULT_STARTAFTER_DESC = 'RESULT_STARTAFTER_DESC',
  RESULT_STARTEDON_ASC = 'RESULT_STARTEDON_ASC',
  RESULT_STARTEDON_DESC = 'RESULT_STARTEDON_DESC',
  RESULT_STATE_ASC = 'RESULT_STATE_ASC',
  RESULT_STATE_DESC = 'RESULT_STATE_DESC',
  RETRYBACKOFF_ASC = 'RETRYBACKOFF_ASC',
  RETRYBACKOFF_DESC = 'RETRYBACKOFF_DESC',
  RETRYCOUNT_ASC = 'RETRYCOUNT_ASC',
  RETRYCOUNT_DESC = 'RETRYCOUNT_DESC',
  RETRYDELAY_ASC = 'RETRYDELAY_ASC',
  RETRYDELAY_DESC = 'RETRYDELAY_DESC',
  RETRYLIMIT_ASC = 'RETRYLIMIT_ASC',
  RETRYLIMIT_DESC = 'RETRYLIMIT_DESC',
  SINGLETONKEY_ASC = 'SINGLETONKEY_ASC',
  SINGLETONKEY_DESC = 'SINGLETONKEY_DESC',
  SINGLETONON_ASC = 'SINGLETONON_ASC',
  SINGLETONON_DESC = 'SINGLETONON_DESC',
  STARTAFTER_ASC = 'STARTAFTER_ASC',
  STARTAFTER_DESC = 'STARTAFTER_DESC',
  STARTEDON_ASC = 'STARTEDON_ASC',
  STARTEDON_DESC = 'STARTEDON_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
}

export type LiveContent = {
  __typename?: 'LiveContent';
  compId?: Maybe<Scalars['String']['output']>;
  durationEnd?: Maybe<Scalars['Datetime']['output']>;
  durationStart?: Maybe<Scalars['Datetime']['output']>;
  entryId?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  liveContentType?: Maybe<LiveContentType>;
  routeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  screenName?: Maybe<Scalars['String']['output']>;
  targetTypes?: Maybe<Array<Maybe<TargetType>>>;
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `LiveContent` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LiveContentCondition = {
  /** Checks for equality with the object’s `compId` field. */
  compId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `durationEnd` field. */
  durationEnd?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `durationStart` field. */
  durationStart?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `liveContentType` field. */
  liveContentType?: InputMaybe<LiveContentType>;
  /** Checks for equality with the object’s `routeIds` field. */
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `LiveContent` object types. All fields are combined with a logical ‘and.’ */
export type LiveContentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LiveContentFilter>>;
  /** Filter by the object’s `compId` field. */
  compId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `durationEnd` field. */
  durationEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `durationStart` field. */
  durationStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liveContentType` field. */
  liveContentType?: InputMaybe<LiveContentTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LiveContentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LiveContentFilter>>;
  /** Filter by the object’s `routeIds` field. */
  routeIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `screenName` field. */
  screenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<TargetTypeListFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

export enum LiveContentType {
  ALERT = 'ALERT',
  CAMPAIGN = 'CAMPAIGN',
  PLANNED = 'PLANNED',
}

/** A filter to be used against LiveContentType fields. All fields are combined with a logical ‘and.’ */
export type LiveContentTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LiveContentType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LiveContentType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LiveContentType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LiveContentType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LiveContentType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LiveContentType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LiveContentType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LiveContentType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LiveContentType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LiveContentType>>;
};

/** A connection to a list of `LiveContent` values. */
export type LiveContentsConnection = {
  __typename?: 'LiveContentsConnection';
  /** A list of edges which contains the `LiveContent` and cursor to aid in pagination. */
  edges: Array<LiveContentsEdge>;
  /** A list of `LiveContent` objects. */
  nodes: Array<LiveContent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiveContent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LiveContent` edge in the connection. */
export type LiveContentsEdge = {
  __typename?: 'LiveContentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LiveContent` at the end of the edge. */
  node: LiveContent;
};

/** Methods to use when ordering `LiveContent`. */
export enum LiveContentsOrderBy {
  COMP_ID_ASC = 'COMP_ID_ASC',
  COMP_ID_DESC = 'COMP_ID_DESC',
  DURATION_END_ASC = 'DURATION_END_ASC',
  DURATION_END_DESC = 'DURATION_END_DESC',
  DURATION_START_ASC = 'DURATION_START_ASC',
  DURATION_START_DESC = 'DURATION_START_DESC',
  ENTRY_ID_ASC = 'ENTRY_ID_ASC',
  ENTRY_ID_DESC = 'ENTRY_ID_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  LIVE_CONTENT_TYPE_ASC = 'LIVE_CONTENT_TYPE_ASC',
  LIVE_CONTENT_TYPE_DESC = 'LIVE_CONTENT_TYPE_DESC',
  NATURAL = 'NATURAL',
  ROUTE_IDS_ASC = 'ROUTE_IDS_ASC',
  ROUTE_IDS_DESC = 'ROUTE_IDS_DESC',
  TARGET_TYPES_ASC = 'TARGET_TYPES_ASC',
  TARGET_TYPES_DESC = 'TARGET_TYPES_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
}

export enum LocationType {
  STATION = 'STATION',
  STOP = 'STOP',
}

/** A filter to be used against LocationType fields. All fields are combined with a logical ‘and.’ */
export type LocationTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LocationType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LocationType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LocationType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LocationType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LocationType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LocationType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LocationType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LocationType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LocationType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LocationType>>;
};

export type LockableUpload = Node & {
  __typename?: 'LockableUpload';
  activeScreensCount?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `LockableUpload`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  screenType?: Maybe<ScreenType>;
  /** Reads and enables pagination through a set of `LockableUploadsScreen`. */
  screens: LockableUploadsScreensConnection;
  /** Reads and enables pagination through a set of `LockableUploadsScreen`. */
  screensSyntheticScreens: LockableUploadsScreensConnection;
  /** Reads and enables pagination through a set of `LockableUploadsScreen`. */
  screensV2: LockableUploadsScreensConnection;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  thumbnail?: Maybe<Upload>;
  thumbnailId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  upload?: Maybe<Upload>;
  uploadId: Scalars['UUID']['output'];
};

export type LockableUploadScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<LockableUploadsScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LockableUploadScreensSyntheticScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<LockableUploadsScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LockableUploadScreensV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<LockableUploadsScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A condition to be used against `LockableUpload` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockableUploadCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `screenType` field. */
  screenType?: InputMaybe<ScreenType>;
  /** Checks for equality with the object’s `thumbnailId` field. */
  thumbnailId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `LockableUpload` object types. All fields are combined with a logical ‘and.’ */
export type LockableUploadFilter = {
  /** Filter by the object’s `activeScreensCount` field. */
  activeScreensCount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockableUploadFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockableUploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockableUploadFilter>>;
  /** Filter by the object’s `screenType` field. */
  screenType?: InputMaybe<ScreenTypeFilter>;
  /** Filter by the object’s `thumbnailId` field. */
  thumbnailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `LockableUpload` */
export type LockableUploadInput = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  screenType?: InputMaybe<ScreenType>;
  thumbnailId?: InputMaybe<Scalars['UUID']['input']>;
  uploadId: Scalars['UUID']['input'];
};

/** Represents an update to a `LockableUpload`. Fields that are set will be updated. */
export type LockableUploadPatch = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  screenType?: InputMaybe<ScreenType>;
  thumbnailId?: InputMaybe<Scalars['UUID']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `LockableUpload` values. */
export type LockableUploadsConnection = {
  __typename?: 'LockableUploadsConnection';
  /** A list of edges which contains the `LockableUpload` and cursor to aid in pagination. */
  edges: Array<LockableUploadsEdge>;
  /** A list of `LockableUpload` objects. */
  nodes: Array<LockableUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockableUpload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LockableUpload` edge in the connection. */
export type LockableUploadsEdge = {
  __typename?: 'LockableUploadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LockableUpload` at the end of the edge. */
  node: LockableUpload;
};

/** Methods to use when ordering `LockableUpload`. */
export enum LockableUploadsOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SCREEN_TYPE_ASC = 'SCREEN_TYPE_ASC',
  SCREEN_TYPE_DESC = 'SCREEN_TYPE_DESC',
  THUMBNAIL_ID_ASC = 'THUMBNAIL_ID_ASC',
  THUMBNAIL_ID_DESC = 'THUMBNAIL_ID_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

export type LockableUploadsScreen = {
  __typename?: 'LockableUploadsScreen';
  totalCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ScreenType>;
};

/** A filter to be used against `LockableUploadsScreen` object types. All fields are combined with a logical ‘and.’ */
export type LockableUploadsScreenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockableUploadsScreenFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<LockableUploadsScreenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockableUploadsScreenFilter>>;
  /** Filter by the object’s `totalCount` field. */
  totalCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<ScreenTypeFilter>;
};

/** A connection to a list of `LockableUploadsScreen` values. */
export type LockableUploadsScreensConnection = {
  __typename?: 'LockableUploadsScreensConnection';
  /** A list of edges which contains the `LockableUploadsScreen` and cursor to aid in pagination. */
  edges: Array<LockableUploadsScreensEdge>;
  /** A list of `LockableUploadsScreen` objects. */
  nodes: Array<LockableUploadsScreen>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockableUploadsScreen` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LockableUploadsScreen` edge in the connection. */
export type LockableUploadsScreensEdge = {
  __typename?: 'LockableUploadsScreensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LockableUploadsScreen` at the end of the edge. */
  node: LockableUploadsScreen;
};

export type MatchedMentionAlias = {
  __typename?: 'MatchedMentionAlias';
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MentionType>;
};

/** A filter to be used against `MatchedMentionAlias` object types. All fields are combined with a logical ‘and.’ */
export type MatchedMentionAliasFilter = {
  /** Filter by the object’s `alias` field. */
  alias?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MatchedMentionAliasFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MatchedMentionAliasFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MatchedMentionAliasFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<MentionTypeFilter>;
};

/** A connection to a list of `MatchedMentionAlias` values. */
export type MatchedMentionAliasesConnection = {
  __typename?: 'MatchedMentionAliasesConnection';
  /** A list of edges which contains the `MatchedMentionAlias` and cursor to aid in pagination. */
  edges: Array<MatchedMentionAliasesEdge>;
  /** A list of `MatchedMentionAlias` objects. */
  nodes: Array<MatchedMentionAlias>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MatchedMentionAlias` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MatchedMentionAlias` edge in the connection. */
export type MatchedMentionAliasesEdge = {
  __typename?: 'MatchedMentionAliasesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MatchedMentionAlias` at the end of the edge. */
  node: MatchedMentionAlias;
};

export type Mention = {
  __typename?: 'Mention';
  boroughs?: Maybe<Array<Borough>>;
  color?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /**
   *   An optional dictionary of useful display data for this mention.
   *   Currently only non-null for trip mentions, in which it contains:
   *   {
   *     shortName: lmm_public.trip.short_name,
   *     headsign: lmm_public.trip.headsign,
   *     direction: lmm_public.trip.direction, // optional
   *     firstStop: {
   *       id: // gtfs_id of first chronological stop
   *       time: // time trip departs that stop
   *       name: // name of that stop
   *     },
   *     lastStop: {
   *       id: // gtfs_id of last chronological stop
   *       time: // time trip departs that stop
   *       name: // name of that stop
   *     }
   *   }
   *
   */
  meta?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  relatedRouteIds?: Maybe<Array<Scalars['String']['output']>>;
  textColor?: Maybe<Scalars['String']['output']>;
  type: MentionType;
};

/** A filter to be used against `Mention` object types. All fields are combined with a logical ‘and.’ */
export type MentionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MentionFilter>>;
  /** Filter by the object’s `boroughs` field. */
  boroughs?: InputMaybe<BoroughListFilter>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MentionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MentionFilter>>;
  /** Filter by the object’s `relatedRouteIds` field. */
  relatedRouteIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `textColor` field. */
  textColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<MentionTypeFilter>;
};

export enum MentionType {
  ADA = 'ADA',
  AIRPLANE = 'AIRPLANE',
  ROUTE = 'ROUTE',
  SHUTTLE_BUS = 'SHUTTLE_BUS',
  STOP = 'STOP',
  TRIP = 'TRIP',
}

/** A filter to be used against MentionType fields. All fields are combined with a logical ‘and.’ */
export type MentionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MentionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MentionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MentionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MentionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MentionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MentionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MentionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MentionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MentionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MentionType>>;
};

/** A connection to a list of `Mention` values. */
export type MentionsConnection = {
  __typename?: 'MentionsConnection';
  /** A list of edges which contains the `Mention` and cursor to aid in pagination. */
  edges: Array<MentionsEdge>;
  /** A list of `Mention` objects. */
  nodes: Array<Mention>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Mention` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Mention` edge in the connection. */
export type MentionsEdge = {
  __typename?: 'MentionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Mention` at the end of the edge. */
  node: Mention;
};

/** Single message sent to users via GTFS-RT, Twitter, Outfront screens, and other outlets. */
export type Message = Node & {
  __typename?: 'Message';
  additionalInfo?: Maybe<Scalars['JSON']['output']>;
  affectedStations?: Maybe<Array<AffectedStation>>;
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  body: Scalars['JSON']['output'];
  bodyHtml: Scalars['String']['output'];
  canReclearScreens: Scalars['Boolean']['output'];
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  clearingScreenMessages: ScreenMessagesConnection;
  createdAt: Scalars['Datetime']['output'];
  /** Used during drafting to calculate end_at. Cannot store sub-second precision. */
  duration?: Maybe<Interval>;
  emailBody?: Maybe<Scalars['String']['output']>;
  emailBodyMeta?: Maybe<Scalars['JSON']['output']>;
  emailBodyPlain?: Maybe<Scalars['String']['output']>;
  emailSmsError?: Maybe<Scalars['JSON']['output']>;
  emailSmsPostedAt?: Maybe<Scalars['Datetime']['output']>;
  emailSmsStatus: PublishStatus;
  emailTypes: Array<Maybe<EmailMessageTypeCodes>>;
  endAt?: Maybe<Scalars['Datetime']['output']>;
  /** A JSON array of GTFS-RT EntitySelectors */
  entitySelectors: Array<GtfsEntitySelector>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['Int']['output']>;
  /** Must match parent event feed_id. Denormalized for easier querying. */
  feedId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  includeApps: Scalars['Boolean']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDraft: Scalars['Boolean']['output'];
  isSetToExpire?: Maybe<Scalars['Boolean']['output']>;
  isUpdate?: Maybe<Scalars['Boolean']['output']>;
  latestModifiedDate?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `MessageImpact`. */
  messageImpacts: MessageImpactsConnection;
  messageTypes?: Maybe<Array<Maybe<MessageType>>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  numEmailSmsSent?: Maybe<Scalars['Int']['output']>;
  routeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  screenMessages: ScreenMessagesConnection;
  screensAreClearing: Scalars['Boolean']['output'];
  screensFastStatus: PublishStatus;
  screensStatus: PublishStatus;
  smsBody?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['Datetime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  taggedTripIds: Array<Maybe<Scalars['String']['output']>>;
  taggedTripNames: Array<Scalars['String']['output']>;
  targetedScreensCount?: Maybe<Scalars['Int']['output']>;
  targetedSyntheticScreensCount?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Tweet`. */
  tweets: TweetsConnection;
  tweetsStatus: PublishStatus;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
  updaterId?: Maybe<Scalars['UUID']['output']>;
  webStatus: PublishStatus;
};

/** Single message sent to users via GTFS-RT, Twitter, Outfront screens, and other outlets. */
export type MessageClearingScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Single message sent to users via GTFS-RT, Twitter, Outfront screens, and other outlets. */
export type MessageMessageImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageImpactCondition>;
  filter?: InputMaybe<MessageImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/** Single message sent to users via GTFS-RT, Twitter, Outfront screens, and other outlets. */
export type MessageScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenMessageCondition>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenMessagesOrderBy>>;
};

/** Single message sent to users via GTFS-RT, Twitter, Outfront screens, and other outlets. */
export type MessageTweetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetCondition>;
  filter?: InputMaybe<TweetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsOrderBy>>;
};

/** A condition to be used against `Message` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MessageCondition = {
  /** Checks for equality with the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `affectedStations` field. */
  affectedStations?: InputMaybe<Array<InputMaybe<AffectedStationInput>>>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `emailBody` field. */
  emailBody?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailBodyMeta` field. */
  emailBodyMeta?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `emailBodyPlain` field. */
  emailBodyPlain?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailSmsError` field. */
  emailSmsError?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `emailSmsPostedAt` field. */
  emailSmsPostedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailTypes` field. */
  emailTypes?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `includeApps` field. */
  includeApps?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `numEmailSmsSent` field. */
  numEmailSmsSent?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `screensFastStatus` field. */
  screensFastStatus?: InputMaybe<PublishStatus>;
  /** Checks for equality with the object’s `smsBody` field. */
  smsBody?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updaterId` field. */
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum MessageDaysOfWeek {
  INDIVIDUAL_DAYS = 'INDIVIDUAL_DAYS',
  WEEKDAYS = 'WEEKDAYS',
  WEEKEND = 'WEEKEND',
}

/** A filter to be used against `Message` object types. All fields are combined with a logical ‘and.’ */
export type MessageFilter = {
  /** Filter by the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `canReclearScreens` field. */
  canReclearScreens?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `emailBody` field. */
  emailBody?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailBodyMeta` field. */
  emailBodyMeta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `emailBodyPlain` field. */
  emailBodyPlain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailSmsError` field. */
  emailSmsError?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `emailSmsPostedAt` field. */
  emailSmsPostedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailSmsStatus` field. */
  emailSmsStatus?: InputMaybe<PublishStatusFilter>;
  /** Filter by the object’s `emailTypes` field. */
  emailTypes?: InputMaybe<EmailMessageTypeCodesListFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eventId` field. */
  eventId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `includeApps` field. */
  includeApps?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDraft` field. */
  isDraft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSetToExpire` field. */
  isSetToExpire?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isUpdate` field. */
  isUpdate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `latestModifiedDate` field. */
  latestModifiedDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `messageTypes` field. */
  messageTypes?: InputMaybe<MessageTypeListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageFilter>;
  /** Filter by the object’s `numEmailSmsSent` field. */
  numEmailSmsSent?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `routeIds` field. */
  routeIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `screensAreClearing` field. */
  screensAreClearing?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `screensFastStatus` field. */
  screensFastStatus?: InputMaybe<PublishStatusFilter>;
  /** Filter by the object’s `screensStatus` field. */
  screensStatus?: InputMaybe<PublishStatusFilter>;
  /** Filter by the object’s `smsBody` field. */
  smsBody?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startAt` field. */
  startAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taggedTripIds` field. */
  taggedTripIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `taggedTripNames` field. */
  taggedTripNames?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `targetedScreensCount` field. */
  targetedScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `targetedSyntheticScreensCount` field. */
  targetedSyntheticScreensCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tweetsStatus` field. */
  tweetsStatus?: InputMaybe<PublishStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updaterId` field. */
  updaterId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `webStatus` field. */
  webStatus?: InputMaybe<PublishStatusFilter>;
};

export type MessageImpact = Node & {
  __typename?: 'MessageImpact';
  allRoutesAffected?: Maybe<Scalars['Boolean']['output']>;
  /** A JSON array of GTFS-RT EntitySelectors, used for targeting non-screen messages. */
  entitySelectors: Array<Maybe<GtfsEntitySelector>>;
  id: Scalars['Int']['output'];
  isAgencyWide: Scalars['Boolean']['output'];
  /** Reads a single `Message` that is related to this `MessageImpact`. */
  message?: Maybe<Message>;
  messageId: Scalars['Int']['output'];
  messageType: MessageType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/**
 * A condition to be used against `MessageImpact` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MessageImpactCondition = {
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isAgencyWide` field. */
  isAgencyWide?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `messageType` field. */
  messageType?: InputMaybe<MessageType>;
};

/** A filter to be used against `MessageImpact` object types. All fields are combined with a logical ‘and.’ */
export type MessageImpactFilter = {
  /** Filter by the object’s `allRoutesAffected` field. */
  allRoutesAffected?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageImpactFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isAgencyWide` field. */
  isAgencyWide?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `messageType` field. */
  messageType?: InputMaybe<MessageTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageImpactFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageImpactFilter>>;
};

/** An input for mutations affecting `MessageImpact` */
export type MessageImpactInput = {
  /** A JSON array of GTFS-RT EntitySelectors, used for targeting non-screen messages. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAgencyWide?: InputMaybe<Scalars['Boolean']['input']>;
  messageId: Scalars['Int']['input'];
  messageType: MessageType;
};

/** Represents an update to a `MessageImpact`. Fields that are set will be updated. */
export type MessageImpactPatch = {
  /** A JSON array of GTFS-RT EntitySelectors, used for targeting non-screen messages. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAgencyWide?: InputMaybe<Scalars['Boolean']['input']>;
  messageId?: InputMaybe<Scalars['Int']['input']>;
  messageType?: InputMaybe<MessageType>;
};

/** A connection to a list of `MessageImpact` values. */
export type MessageImpactsConnection = {
  __typename?: 'MessageImpactsConnection';
  /** A list of edges which contains the `MessageImpact` and cursor to aid in pagination. */
  edges: Array<MessageImpactsEdge>;
  /** A list of `MessageImpact` objects. */
  nodes: Array<MessageImpact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageImpact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageImpact` edge in the connection. */
export type MessageImpactsEdge = {
  __typename?: 'MessageImpactsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageImpact` at the end of the edge. */
  node: MessageImpact;
};

/** Methods to use when ordering `MessageImpact`. */
export enum MessageImpactsOrderBy {
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_AGENCY_WIDE_ASC = 'IS_AGENCY_WIDE_ASC',
  IS_AGENCY_WIDE_DESC = 'IS_AGENCY_WIDE_DESC',
  MESSAGE_ID_ASC = 'MESSAGE_ID_ASC',
  MESSAGE_ID_DESC = 'MESSAGE_ID_DESC',
  MESSAGE_TYPE_ASC = 'MESSAGE_TYPE_ASC',
  MESSAGE_TYPE_DESC = 'MESSAGE_TYPE_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** Represents an update to a `Message`. Fields that are set will be updated. */
export type MessagePatch = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  affectedStations?: InputMaybe<Array<InputMaybe<AffectedStationInput>>>;
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  body?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Used during drafting to calculate end_at. Cannot store sub-second precision. */
  duration?: InputMaybe<IntervalInput>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailBodyMeta?: InputMaybe<Scalars['JSON']['input']>;
  emailBodyPlain?: InputMaybe<Scalars['String']['input']>;
  emailSmsError?: InputMaybe<Scalars['JSON']['input']>;
  emailSmsPostedAt?: InputMaybe<Scalars['Datetime']['input']>;
  emailTypes?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  endAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** A JSON array of GTFS-RT EntitySelectors */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  eventId?: InputMaybe<Scalars['Int']['input']>;
  /** Must match parent event feed_id. Denormalized for easier querying. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  includeApps?: InputMaybe<Scalars['Boolean']['input']>;
  numEmailSmsSent?: InputMaybe<Scalars['Int']['input']>;
  screensFastStatus?: InputMaybe<PublishStatus>;
  smsBody?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['Datetime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** An input for mutations affecting `MessagePatchImpact` */
export type MessagePatchImpactInput = {
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  isAgencyWide: Scalars['Boolean']['input'];
  messageType?: InputMaybe<MessageType>;
};

/** An input for mutations affecting `MessagePatchRecord` */
export type MessagePatchRecordInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  affectedStations?: InputMaybe<Array<InputMaybe<AffectedStationInput>>>;
  body?: InputMaybe<Scalars['JSON']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailBodyMeta?: InputMaybe<Scalars['JSON']['input']>;
  emailBodyPlain?: InputMaybe<Scalars['String']['input']>;
  emailTypes?: InputMaybe<Array<InputMaybe<EmailMessageTypeCodes>>>;
  endAt?: InputMaybe<Scalars['Datetime']['input']>;
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  impacts?: InputMaybe<Array<InputMaybe<MessagePatchImpactInput>>>;
  includeApps?: InputMaybe<Scalars['Boolean']['input']>;
  screens?: InputMaybe<Array<InputMaybe<ScreenMessagePatchRecordInput>>>;
  smsBody?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tweets?: InputMaybe<Array<InputMaybe<TweetPatchRecordInput>>>;
};

export enum MessageTimeOfDay {
  DAYS = 'DAYS',
  NIGHTS = 'NIGHTS',
}

/** A general-purpose category assigned to messages. */
export enum MessageType {
  ARRIVAL_INFORMATION_OUTAGE = 'ARRIVAL_INFORMATION_OUTAGE',
  BOARDING_CHANGE = 'BOARDING_CHANGE',
  BUSES_DETOURED = 'BUSES_DETOURED',
  BUSING = 'BUSING',
  CANCELLATIONS = 'CANCELLATIONS',
  CROWDING = 'CROWDING',
  DELAYS = 'DELAYS',
  DELAYS_AND_CANCELLATIONS = 'DELAYS_AND_CANCELLATIONS',
  DETOUR = 'DETOUR',
  DETOURS = 'DETOURS',
  ESSENTIAL_SERVICE = 'ESSENTIAL_SERVICE',
  EXPECT_DELAYS = 'EXPECT_DELAYS',
  EXPRESS_TO_LOCAL = 'EXPRESS_TO_LOCAL',
  EXTRA_SERVICE = 'EXTRA_SERVICE',
  HOLIDAY_SERVICE = 'HOLIDAY_SERVICE',
  INFORMATION_OUTAGE = 'INFORMATION_OUTAGE',
  LOCAL_TO_EXPRESS = 'LOCAL_TO_EXPRESS',
  MULTIPLE_CHANGES = 'MULTIPLE_CHANGES',
  MULTIPLE_IMPACTS = 'MULTIPLE_IMPACTS',
  NO_MIDDAY_SERVICE = 'NO_MIDDAY_SERVICE',
  NO_OVERNIGHT_SERVICE = 'NO_OVERNIGHT_SERVICE',
  NO_SCHEDULED_SERVICE = 'NO_SCHEDULED_SERVICE',
  NO_WEEKEND_SERVICE = 'NO_WEEKEND_SERVICE',
  ON_OR_CLOSE = 'ON_OR_CLOSE',
  PART_SUSPENDED = 'PART_SUSPENDED',
  PLANNED_BOARDING_CHANGE = 'PLANNED_BOARDING_CHANGE',
  PLANNED_BUSES_DETOURED = 'PLANNED_BUSES_DETOURED',
  PLANNED_DETOUR = 'PLANNED_DETOUR',
  PLANNED_EXPRESS_TO_LOCAL = 'PLANNED_EXPRESS_TO_LOCAL',
  PLANNED_EXTRA_TRANSFER = 'PLANNED_EXTRA_TRANSFER',
  PLANNED_LOCAL_TO_EXPRESS = 'PLANNED_LOCAL_TO_EXPRESS',
  PLANNED_MULTIPLE_CHANGES = 'PLANNED_MULTIPLE_CHANGES',
  PLANNED_MULTIPLE_IMPACTS = 'PLANNED_MULTIPLE_IMPACTS',
  PLANNED_PART_SUSPENDED = 'PLANNED_PART_SUSPENDED',
  PLANNED_REROUTE = 'PLANNED_REROUTE',
  PLANNED_STATIONS_SKIPPED = 'PLANNED_STATIONS_SKIPPED',
  PLANNED_STOPS_SKIPPED = 'PLANNED_STOPS_SKIPPED',
  PLANNED_SUBSTITUTE_BUSES = 'PLANNED_SUBSTITUTE_BUSES',
  PLANNED_SUSPENDED = 'PLANNED_SUSPENDED',
  PLANNED_TRAINS_REROUTED = 'PLANNED_TRAINS_REROUTED',
  PLANNED_WORK = 'PLANNED_WORK',
  REDUCED_SERVICE = 'REDUCED_SERVICE',
  REROUTE = 'REROUTE',
  SATURDAY_SCHEDULE = 'SATURDAY_SCHEDULE',
  SERVICE_CHANGE = 'SERVICE_CHANGE',
  SEVERE_DELAYS = 'SEVERE_DELAYS',
  SHUTTLE_BUSES_DETOURED = 'SHUTTLE_BUSES_DETOURED',
  SLOW_SPEEDS = 'SLOW_SPEEDS',
  SOME_DELAYS = 'SOME_DELAYS',
  SOME_REROUTES = 'SOME_REROUTES',
  SPECIAL_EVENT = 'SPECIAL_EVENT',
  SPECIAL_NOTICE = 'SPECIAL_NOTICE',
  SPECIAL_SCHEDULE = 'SPECIAL_SCHEDULE',
  STATIONS_SKIPPED = 'STATIONS_SKIPPED',
  STATION_NOTICE = 'STATION_NOTICE',
  STOPS_SKIPPED = 'STOPS_SKIPPED',
  SUBSTITUTE_BUSES = 'SUBSTITUTE_BUSES',
  SUNDAY_SCHEDULE = 'SUNDAY_SCHEDULE',
  SUSPENDED = 'SUSPENDED',
  TRAINS_REROUTED = 'TRAINS_REROUTED',
  WEEKDAY_SCHEDULE = 'WEEKDAY_SCHEDULE',
  WEEKDAY_SERVICE = 'WEEKDAY_SERVICE',
  WEEKEND_SCHEDULE = 'WEEKEND_SCHEDULE',
  WEEKEND_SERVICE = 'WEEKEND_SERVICE',
}

/** A filter to be used against MessageType fields. All fields are combined with a logical ‘and.’ */
export type MessageTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MessageType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MessageType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MessageType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MessageType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MessageType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MessageType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MessageType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MessageType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MessageType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MessageType>>;
};

/** A filter to be used against MessageType List fields. All fields are combined with a logical ‘and.’ */
export type MessageTypeListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<MessageType>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<MessageType>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<MessageType>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<MessageType>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<MessageType>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<MessageType>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<MessageType>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<MessageType>>>;
};

/** A connection to a list of `Message` values. */
export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  /** A list of edges which contains the `Message` and cursor to aid in pagination. */
  edges: Array<MessagesEdge>;
  /** A list of `Message` objects. */
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Message` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Message` edge in the connection. */
export type MessagesEdge = {
  __typename?: 'MessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Message` at the end of the edge. */
  node: Message;
};

/** Methods to use when ordering `Message`. */
export enum MessagesOrderBy {
  ADDITIONAL_INFO_ASC = 'ADDITIONAL_INFO_ASC',
  ADDITIONAL_INFO_DESC = 'ADDITIONAL_INFO_DESC',
  AFFECTED_STATIONS_ASC = 'AFFECTED_STATIONS_ASC',
  AFFECTED_STATIONS_DESC = 'AFFECTED_STATIONS_DESC',
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DURATION_ASC = 'DURATION_ASC',
  DURATION_DESC = 'DURATION_DESC',
  EMAIL_BODY_ASC = 'EMAIL_BODY_ASC',
  EMAIL_BODY_DESC = 'EMAIL_BODY_DESC',
  EMAIL_BODY_META_ASC = 'EMAIL_BODY_META_ASC',
  EMAIL_BODY_META_DESC = 'EMAIL_BODY_META_DESC',
  EMAIL_BODY_PLAIN_ASC = 'EMAIL_BODY_PLAIN_ASC',
  EMAIL_BODY_PLAIN_DESC = 'EMAIL_BODY_PLAIN_DESC',
  EMAIL_SMS_ERROR_ASC = 'EMAIL_SMS_ERROR_ASC',
  EMAIL_SMS_ERROR_DESC = 'EMAIL_SMS_ERROR_DESC',
  EMAIL_SMS_POSTED_AT_ASC = 'EMAIL_SMS_POSTED_AT_ASC',
  EMAIL_SMS_POSTED_AT_DESC = 'EMAIL_SMS_POSTED_AT_DESC',
  EMAIL_TYPES_ASC = 'EMAIL_TYPES_ASC',
  EMAIL_TYPES_DESC = 'EMAIL_TYPES_DESC',
  END_AT_ASC = 'END_AT_ASC',
  END_AT_DESC = 'END_AT_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  EVENT_ID_ASC = 'EVENT_ID_ASC',
  EVENT_ID_DESC = 'EVENT_ID_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INCLUDE_APPS_ASC = 'INCLUDE_APPS_ASC',
  INCLUDE_APPS_DESC = 'INCLUDE_APPS_DESC',
  LATEST_MODIFIED_DATE_ASC = 'LATEST_MODIFIED_DATE_ASC',
  LATEST_MODIFIED_DATE_DESC = 'LATEST_MODIFIED_DATE_DESC',
  NATURAL = 'NATURAL',
  NUM_EMAIL_SMS_SENT_ASC = 'NUM_EMAIL_SMS_SENT_ASC',
  NUM_EMAIL_SMS_SENT_DESC = 'NUM_EMAIL_SMS_SENT_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SCREENS_FAST_STATUS_ASC = 'SCREENS_FAST_STATUS_ASC',
  SCREENS_FAST_STATUS_DESC = 'SCREENS_FAST_STATUS_DESC',
  SMS_BODY_ASC = 'SMS_BODY_ASC',
  SMS_BODY_DESC = 'SMS_BODY_DESC',
  START_AT_ASC = 'START_AT_ASC',
  START_AT_DESC = 'START_AT_DESC',
  SUBJECT_ASC = 'SUBJECT_ASC',
  SUBJECT_DESC = 'SUBJECT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPDATER_ID_ASC = 'UPDATER_ID_ASC',
  UPDATER_ID_DESC = 'UPDATER_ID_DESC',
}

/** Is a list of targetable lines/line groups for MNR that have rolling stock screens. */
export enum MnrRollingStockLine {
  HARLEM_HUDSON = 'HARLEM_HUDSON',
  NEW_HAVEN = 'NEW_HAVEN',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   *   - If the event is live and was updated and saved as a draft, we need to create a new draft message and clear out the live message.
   *   - If the event is draft and was updated this will just update the corresponding draft message, and no new messages will be added.
   *   - If the event is draft and was updated and published this will update the draft message and publish it.
   *   - If message is published and no draft exists this will publish a new message, this will also close any ongoing older messages.
   *
   */
  addUpdateToEvent?: Maybe<AddUpdateToEventPayload>;
  clearCampaign?: Maybe<ClearCampaignPayload>;
  clearMessage?: Maybe<ClearMessagePayload>;
  clearPlannedWork?: Maybe<ClearPlannedWorkPayload>;
  clearScreenLock?: Maybe<ClearScreenLockPayload>;
  /** Creates a single `Agency`. */
  createAgency?: Maybe<CreateAgencyPayload>;
  createCampaign?: Maybe<CreateCampaignPayload>;
  /** Creates a single `CampaignScreenContent`. */
  createCampaignScreenContent?: Maybe<CreateCampaignScreenContentPayload>;
  /** Creates a single `CampaignScreenContentUpload`. */
  createCampaignScreenContentUpload?: Maybe<CreateCampaignScreenContentUploadPayload>;
  /** Creates a single `ClearedReport`. */
  createClearedReport?: Maybe<CreateClearedReportPayload>;
  /** Create a new event with an initial message. */
  createEvent?: Maybe<CreateEventPayload>;
  createFallbackContent?: Maybe<CreateFallbackContentPayload>;
  /** Creates a single `FallbackScreensContent`. */
  createFallbackScreensContent?: Maybe<CreateFallbackScreensContentPayload>;
  /** Creates a single `FeatureFlag`. */
  createFeatureFlag?: Maybe<CreateFeatureFlagPayload>;
  /** Creates a single `Feed`. */
  createFeed?: Maybe<CreateFeedPayload>;
  /** Creates a single `FeedGroup`. */
  createFeedGroup?: Maybe<CreateFeedGroupPayload>;
  /** Creates a single `FeedMessageTextAlias`. */
  createFeedMessageTextAlias?: Maybe<CreateFeedMessageTextAliasPayload>;
  /** Creates a single `FeedSetting`. */
  createFeedSetting?: Maybe<CreateFeedSettingPayload>;
  /** Creates a single `LockableUpload`. */
  createLockableUpload?: Maybe<CreateLockableUploadPayload>;
  /** Creates a single `MessageImpact`. */
  createMessageImpact?: Maybe<CreateMessageImpactPayload>;
  createPlannedWork?: Maybe<CreatePlannedWorkPayload>;
  /** Creates a single `Route`. */
  createRoute?: Maybe<CreateRoutePayload>;
  createScreenLock?: Maybe<CreateScreenLockPayload>;
  /** Creates a single `ScreenNote`. */
  createScreenNote?: Maybe<CreateScreenNotePayload>;
  /** Creates a single `Screens2`. */
  createScreens2?: Maybe<CreateScreens2Payload>;
  /** Creates a single `SocialMediaFolder`. */
  createSocialMediaFolder?: Maybe<CreateSocialMediaFolderPayload>;
  /** Creates a single `SocialMediaUpload`. */
  createSocialMediaUpload?: Maybe<CreateSocialMediaUploadPayload>;
  /** Creates a single `Stop`. */
  createStop?: Maybe<CreateStopPayload>;
  /** Creates a single `StopTime`. */
  createStopTime?: Maybe<CreateStopTimePayload>;
  /** Creates a single `Trip`. */
  createTrip?: Maybe<CreateTripPayload>;
  /** Creates a single `TripsLirr`. */
  createTripsLirr?: Maybe<CreateTripsLirrPayload>;
  /** Creates a single `TweetsUpload`. */
  createTweetsUpload?: Maybe<CreateTweetsUploadPayload>;
  /** Creates a single `Upload`. */
  createUpload?: Maybe<CreateUploadPayload>;
  /** Creates a single `UserFeedGroupGrant`. */
  createUserFeedGroupGrant?: Maybe<CreateUserFeedGroupGrantPayload>;
  /** Creates a single `UsersAccess`. */
  createUsersAccess?: Maybe<CreateUsersAccessPayload>;
  deactivateUser?: Maybe<DeactivateUserPayload>;
  /** Deletes a single `Agency` using a unique key. */
  deleteAgency?: Maybe<DeleteAgencyPayload>;
  /** Deletes a single `Agency` using its globally unique id. */
  deleteAgencyByNodeId?: Maybe<DeleteAgencyPayload>;
  /** Deletes a single `Campaign` using a unique key. */
  deleteCampaign?: Maybe<DeleteCampaignPayload>;
  /** Deletes a single `Campaign` using its globally unique id. */
  deleteCampaignByNodeId?: Maybe<DeleteCampaignPayload>;
  /** Deletes a single `CampaignScreenContent` using a unique key. */
  deleteCampaignScreenContentById?: Maybe<DeleteCampaignScreenContentPayload>;
  /** Deletes a single `CampaignScreenContentUpload` using a unique key. */
  deleteCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndex?: Maybe<DeleteCampaignScreenContentUploadPayload>;
  /** Deletes a single `CampaignScreenContentUpload` using a unique key. */
  deleteCampaignScreenContentUploadByCampaignScreenContentIdAndUploadId?: Maybe<DeleteCampaignScreenContentUploadPayload>;
  /** Deletes a single `ClearedReport` using a unique key. */
  deleteClearedReport?: Maybe<DeleteClearedReportPayload>;
  /** Deletes a single `ClearedReport` using its globally unique id. */
  deleteClearedReportByNodeId?: Maybe<DeleteClearedReportPayload>;
  deleteDraftMessage?: Maybe<DeleteDraftMessagePayload>;
  /** Deletes a single `FeatureFlag` using a unique key. */
  deleteFeatureFlag?: Maybe<DeleteFeatureFlagPayload>;
  /** Deletes a single `FeatureFlag` using its globally unique id. */
  deleteFeatureFlagByNodeId?: Maybe<DeleteFeatureFlagPayload>;
  /** Deletes a single `Feed` using a unique key. */
  deleteFeed?: Maybe<DeleteFeedPayload>;
  /** Deletes a single `Feed` using a unique key. */
  deleteFeedByName?: Maybe<DeleteFeedPayload>;
  /** Deletes a single `Feed` using its globally unique id. */
  deleteFeedByNodeId?: Maybe<DeleteFeedPayload>;
  /** Deletes a single `Feed` using a unique key. */
  deleteFeedByOtpId?: Maybe<DeleteFeedPayload>;
  /** Deletes a single `FeedGroup` using a unique key. */
  deleteFeedGroup?: Maybe<DeleteFeedGroupPayload>;
  /** Deletes a single `FeedGroup` using its globally unique id. */
  deleteFeedGroupByNodeId?: Maybe<DeleteFeedGroupPayload>;
  /** Deletes a single `FeedMessageTextAlias` using a unique key. */
  deleteFeedMessageTextAlias?: Maybe<DeleteFeedMessageTextAliasPayload>;
  /** Deletes a single `FeedMessageTextAlias` using a unique key. */
  deleteFeedMessageTextAliasByGtfsIdAndAliasAndFeedId?: Maybe<DeleteFeedMessageTextAliasPayload>;
  /** Deletes a single `FeedMessageTextAlias` using its globally unique id. */
  deleteFeedMessageTextAliasByNodeId?: Maybe<DeleteFeedMessageTextAliasPayload>;
  /** Deletes a single `FeedSetting` using a unique key. */
  deleteFeedSetting?: Maybe<DeleteFeedSettingPayload>;
  /** Deletes a single `FeedSetting` using its globally unique id. */
  deleteFeedSettingByNodeId?: Maybe<DeleteFeedSettingPayload>;
  /** Deletes a single `LockableUpload` using a unique key. */
  deleteLockableUpload?: Maybe<DeleteLockableUploadPayload>;
  /** Deletes a single `LockableUpload` using its globally unique id. */
  deleteLockableUploadByNodeId?: Maybe<DeleteLockableUploadPayload>;
  /** Deletes a single `MessageImpact` using a unique key. */
  deleteMessageImpact?: Maybe<DeleteMessageImpactPayload>;
  /** Deletes a single `MessageImpact` using its globally unique id. */
  deleteMessageImpactByNodeId?: Maybe<DeleteMessageImpactPayload>;
  /** Deletes a single `PlannedWork` using a unique key. */
  deletePlannedWork?: Maybe<DeletePlannedWorkPayload>;
  /** Deletes a single `PlannedWork` using its globally unique id. */
  deletePlannedWorkByNodeId?: Maybe<DeletePlannedWorkPayload>;
  /** Deletes a single `Route` using a unique key. */
  deleteRoute?: Maybe<DeleteRoutePayload>;
  /** Deletes a single `Route` using its globally unique id. */
  deleteRouteByNodeId?: Maybe<DeleteRoutePayload>;
  /** Deletes a single `ScreenNote` using a unique key. */
  deleteScreenNoteByName?: Maybe<DeleteScreenNotePayload>;
  /** Deletes a single `Screens2` using a unique key. */
  deleteScreens2?: Maybe<DeleteScreens2Payload>;
  /** Deletes a single `Screens2` using its globally unique id. */
  deleteScreens2ByNodeId?: Maybe<DeleteScreens2Payload>;
  /** Deletes a single `SocialMediaFolder` using a unique key. */
  deleteSocialMediaFolder?: Maybe<DeleteSocialMediaFolderPayload>;
  /** Deletes a single `SocialMediaFolder` using its globally unique id. */
  deleteSocialMediaFolderByNodeId?: Maybe<DeleteSocialMediaFolderPayload>;
  /** Deletes a single `SocialMediaUpload` using a unique key. */
  deleteSocialMediaUpload?: Maybe<DeleteSocialMediaUploadPayload>;
  /** Deletes a single `SocialMediaUpload` using its globally unique id. */
  deleteSocialMediaUploadByNodeId?: Maybe<DeleteSocialMediaUploadPayload>;
  /** Deletes a single `Stop` using a unique key. */
  deleteStop?: Maybe<DeleteStopPayload>;
  /** Deletes a single `Stop` using its globally unique id. */
  deleteStopByNodeId?: Maybe<DeleteStopPayload>;
  /** Deletes a single `Trip` using a unique key. */
  deleteTrip?: Maybe<DeleteTripPayload>;
  /** Deletes a single `Trip` using its globally unique id. */
  deleteTripByNodeId?: Maybe<DeleteTripPayload>;
  /** Deletes a single `TripsLirr` using a unique key. */
  deleteTripsLirr?: Maybe<DeleteTripsLirrPayload>;
  /** Deletes a single `TripsLirr` using its globally unique id. */
  deleteTripsLirrByNodeId?: Maybe<DeleteTripsLirrPayload>;
  /** Deletes a single `TripsLirr` using a unique key. */
  deleteTripsLirrByTrainNum?: Maybe<DeleteTripsLirrPayload>;
  /** Deletes a single `TweetsUpload` using a unique key. */
  deleteTweetsUpload?: Maybe<DeleteTweetsUploadPayload>;
  /** Deletes a single `TweetsUpload` using its globally unique id. */
  deleteTweetsUploadByNodeId?: Maybe<DeleteTweetsUploadPayload>;
  /** Deletes a single `Upload` using a unique key. */
  deleteUpload?: Maybe<DeleteUploadPayload>;
  /** Deletes a single `Upload` using its globally unique id. */
  deleteUploadByNodeId?: Maybe<DeleteUploadPayload>;
  /** Deletes a single `UserFeedGroupGrant` using a unique key. */
  deleteUserFeedGroupGrant?: Maybe<DeleteUserFeedGroupGrantPayload>;
  /** Deletes a single `UserFeedGroupGrant` using its globally unique id. */
  deleteUserFeedGroupGrantByNodeId?: Maybe<DeleteUserFeedGroupGrantPayload>;
  /** Deletes a single `UsersAccess` using a unique key. */
  deleteUsersAccess?: Maybe<DeleteUsersAccessPayload>;
  /** Deletes a single `UsersAccess` using its globally unique id. */
  deleteUsersAccessByNodeId?: Maybe<DeleteUsersAccessPayload>;
  /**
   *   Returns an entry from `lmm_public.cleared_reports` for a specific feed_id.
   *
   *   This function covers three scenarios:
   *
   *   * if the current cleared report for a specific feed_id is already generated and
   *     the cleared alert logs count is still the same, the cleared report is returned without
   *     performing any job.
   *   * elsif the cleared alert logs count has increased, the previous generated cleared
   *     report will be updated which will perform a job to upload a new CSV. The updated
   *     cleared report will then be returned.
   *   * else it's the first time a CSV for the current week is being generated,
   *     a new cleared report (for the specific feed_id in question) will be created
   *     and a new job to upload a new CSV file will be performed. The newly generated cleared
   *     report will then be returned.
   *
   */
  generateCurrentClearedReports?: Maybe<GenerateCurrentClearedReportsPayload>;
  getActiveRealtimeMessages?: Maybe<GetActiveRealtimeMessagesPayload>;
  grantUserFeatureFlag?: Maybe<GrantUserFeatureFlagPayload>;
  publishCampaign?: Maybe<PublishCampaignPayload>;
  publishDraftMessage?: Maybe<PublishDraftMessagePayload>;
  publishPlannedWork?: Maybe<PublishPlannedWorkPayload>;
  reactivateMessage?: Maybe<ReactivateMessagePayload>;
  reactivatePlannedWork?: Maybe<ReactivatePlannedWorkPayload>;
  reclearCampaignScreens?: Maybe<ReclearCampaignScreensPayload>;
  reclearMessageScreens?: Maybe<ReclearMessageScreensPayload>;
  reclearPlannedWorkScreens?: Maybe<ReclearPlannedWorkScreensPayload>;
  removePlannedWork?: Maybe<RemovePlannedWorkPayload>;
  renewMessagesTimestamps?: Maybe<RenewMessagesTimestampsPayload>;
  retryEmailSms?: Maybe<RetryEmailSmsPayload>;
  retryTweet?: Maybe<RetryTweetPayload>;
  setPlannedWorkDirectDurations?: Maybe<SetPlannedWorkDirectDurationsPayload>;
  toggleAdminUserAccess?: Maybe<ToggleAdminUserAccessPayload>;
  /** Updates a single `Agency` using a unique key and a patch. */
  updateAgency?: Maybe<UpdateAgencyPayload>;
  /** Updates a single `Agency` using its globally unique id and a patch. */
  updateAgencyByNodeId?: Maybe<UpdateAgencyPayload>;
  updateCampaign?: Maybe<UpdateCampaignPayload>;
  /** Updates a single `CampaignScreenContent` using a unique key and a patch. */
  updateCampaignScreenContentById?: Maybe<UpdateCampaignScreenContentPayload>;
  /** Updates a single `CampaignScreenContentUpload` using a unique key and a patch. */
  updateCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndex?: Maybe<UpdateCampaignScreenContentUploadPayload>;
  /** Updates a single `CampaignScreenContentUpload` using a unique key and a patch. */
  updateCampaignScreenContentUploadByCampaignScreenContentIdAndUploadId?: Maybe<UpdateCampaignScreenContentUploadPayload>;
  /** Updates a single `ClearedReport` using a unique key and a patch. */
  updateClearedReport?: Maybe<UpdateClearedReportPayload>;
  /** Updates a single `ClearedReport` using its globally unique id and a patch. */
  updateClearedReportByNodeId?: Maybe<UpdateClearedReportPayload>;
  updateEvent?: Maybe<UpdateEventPayload>;
  /** Updates a single `FeatureFlag` using a unique key and a patch. */
  updateFeatureFlag?: Maybe<UpdateFeatureFlagPayload>;
  /** Updates a single `FeatureFlag` using its globally unique id and a patch. */
  updateFeatureFlagByNodeId?: Maybe<UpdateFeatureFlagPayload>;
  /** Updates a single `Feed` using a unique key and a patch. */
  updateFeed?: Maybe<UpdateFeedPayload>;
  /** Updates a single `Feed` using a unique key and a patch. */
  updateFeedByName?: Maybe<UpdateFeedPayload>;
  /** Updates a single `Feed` using its globally unique id and a patch. */
  updateFeedByNodeId?: Maybe<UpdateFeedPayload>;
  /** Updates a single `Feed` using a unique key and a patch. */
  updateFeedByOtpId?: Maybe<UpdateFeedPayload>;
  /** Updates a single `FeedGroup` using a unique key and a patch. */
  updateFeedGroup?: Maybe<UpdateFeedGroupPayload>;
  /** Updates a single `FeedGroup` using its globally unique id and a patch. */
  updateFeedGroupByNodeId?: Maybe<UpdateFeedGroupPayload>;
  /** Updates a single `FeedMessageTextAlias` using a unique key and a patch. */
  updateFeedMessageTextAlias?: Maybe<UpdateFeedMessageTextAliasPayload>;
  /** Updates a single `FeedMessageTextAlias` using a unique key and a patch. */
  updateFeedMessageTextAliasByGtfsIdAndAliasAndFeedId?: Maybe<UpdateFeedMessageTextAliasPayload>;
  /** Updates a single `FeedMessageTextAlias` using its globally unique id and a patch. */
  updateFeedMessageTextAliasByNodeId?: Maybe<UpdateFeedMessageTextAliasPayload>;
  /** Updates a single `FeedSetting` using a unique key and a patch. */
  updateFeedSetting?: Maybe<UpdateFeedSettingPayload>;
  /** Updates a single `FeedSetting` using its globally unique id and a patch. */
  updateFeedSettingByNodeId?: Maybe<UpdateFeedSettingPayload>;
  updateFlag?: Maybe<UpdateFlagPayload>;
  updateLockableAsset?: Maybe<UpdateLockableAssetPayload>;
  /** Updates a single `LockableUpload` using a unique key and a patch. */
  updateLockableUpload?: Maybe<UpdateLockableUploadPayload>;
  /** Updates a single `LockableUpload` using its globally unique id and a patch. */
  updateLockableUploadByNodeId?: Maybe<UpdateLockableUploadPayload>;
  /** Updates a single `Message` using a unique key and a patch. */
  updateMessage?: Maybe<UpdateMessagePayload>;
  /** Updates a single `Message` using its globally unique id and a patch. */
  updateMessageByNodeId?: Maybe<UpdateMessagePayload>;
  /** Updates a single `MessageImpact` using a unique key and a patch. */
  updateMessageImpact?: Maybe<UpdateMessageImpactPayload>;
  /** Updates a single `MessageImpact` using its globally unique id and a patch. */
  updateMessageImpactByNodeId?: Maybe<UpdateMessageImpactPayload>;
  updatePlannedWork?: Maybe<UpdatePlannedWorkPayload>;
  updatePlannedWorkNotes?: Maybe<UpdatePlannedWorkNotesPayload>;
  /** Updates a single `Route` using a unique key and a patch. */
  updateRoute?: Maybe<UpdateRoutePayload>;
  /** Updates a single `Route` using its globally unique id and a patch. */
  updateRouteByNodeId?: Maybe<UpdateRoutePayload>;
  updateScreenCustomCriteria?: Maybe<UpdateScreenCustomCriteriaPayload>;
  /**
   *     Clears the current screen lock for screen with screen_name
   *     then creates a new screen lock asset.
   *
   */
  updateScreenLock?: Maybe<UpdateScreenLockPayload>;
  /** Updates a single `ScreenNote` using a unique key and a patch. */
  updateScreenNoteByName?: Maybe<UpdateScreenNotePayload>;
  /** Update the internal notes on a screen. */
  updateScreenNotes?: Maybe<UpdateScreenNotesPayload>;
  /** Updates a single `Screens2` using a unique key and a patch. */
  updateScreens2?: Maybe<UpdateScreens2Payload>;
  /** Updates a single `Screens2` using its globally unique id and a patch. */
  updateScreens2ByNodeId?: Maybe<UpdateScreens2Payload>;
  updateScreensCustomCriteria?: Maybe<UpdateScreensCustomCriteriaPayload>;
  /** Updates a single `SocialMediaFolder` using a unique key and a patch. */
  updateSocialMediaFolder?: Maybe<UpdateSocialMediaFolderPayload>;
  /** Updates a single `SocialMediaFolder` using its globally unique id and a patch. */
  updateSocialMediaFolderByNodeId?: Maybe<UpdateSocialMediaFolderPayload>;
  /** Updates a single `SocialMediaUpload` using a unique key and a patch. */
  updateSocialMediaUpload?: Maybe<UpdateSocialMediaUploadPayload>;
  /** Updates a single `SocialMediaUpload` using its globally unique id and a patch. */
  updateSocialMediaUploadByNodeId?: Maybe<UpdateSocialMediaUploadPayload>;
  /** Update the blueprint upload on a stop. */
  updateStopBlueprint?: Maybe<UpdateStopBlueprintPayload>;
  /** Updates a single `SyntheticScreen` using a unique key and a patch. */
  updateSyntheticScreen?: Maybe<UpdateSyntheticScreenPayload>;
  /** Updates a single `SyntheticScreen` using a unique key and a patch. */
  updateSyntheticScreenByName?: Maybe<UpdateSyntheticScreenPayload>;
  /** Updates a single `SyntheticScreen` using its globally unique id and a patch. */
  updateSyntheticScreenByNodeId?: Maybe<UpdateSyntheticScreenPayload>;
  /** Update the internal notes on a synthetic screen. */
  updateSyntheticScreenNotes?: Maybe<UpdateSyntheticScreenNotesPayload>;
  /** Updates a single `Trip` using a unique key and a patch. */
  updateTrip?: Maybe<UpdateTripPayload>;
  /** Updates a single `Trip` using its globally unique id and a patch. */
  updateTripByNodeId?: Maybe<UpdateTripPayload>;
  /** Updates a single `TripsLirr` using a unique key and a patch. */
  updateTripsLirr?: Maybe<UpdateTripsLirrPayload>;
  /** Updates a single `TripsLirr` using its globally unique id and a patch. */
  updateTripsLirrByNodeId?: Maybe<UpdateTripsLirrPayload>;
  /** Updates a single `TripsLirr` using a unique key and a patch. */
  updateTripsLirrByTrainNum?: Maybe<UpdateTripsLirrPayload>;
  /** Updates a single `TweetsUpload` using a unique key and a patch. */
  updateTweetsUpload?: Maybe<UpdateTweetsUploadPayload>;
  /** Updates a single `TweetsUpload` using its globally unique id and a patch. */
  updateTweetsUploadByNodeId?: Maybe<UpdateTweetsUploadPayload>;
  /** Updates a single `Upload` using a unique key and a patch. */
  updateUpload?: Maybe<UpdateUploadPayload>;
  /** Updates a single `Upload` using its globally unique id and a patch. */
  updateUploadByNodeId?: Maybe<UpdateUploadPayload>;
  /** Updates a single `UserFeedGroupGrant` using a unique key and a patch. */
  updateUserFeedGroupGrant?: Maybe<UpdateUserFeedGroupGrantPayload>;
  /** Updates a single `UserFeedGroupGrant` using its globally unique id and a patch. */
  updateUserFeedGroupGrantByNodeId?: Maybe<UpdateUserFeedGroupGrantPayload>;
  /** Updates a single `UsersAccess` using a unique key and a patch. */
  updateUsersAccess?: Maybe<UpdateUsersAccessPayload>;
  /** Updates a single `UsersAccess` using its globally unique id and a patch. */
  updateUsersAccessByNodeId?: Maybe<UpdateUsersAccessPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddUpdateToEventArgs = {
  input: AddUpdateToEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearCampaignArgs = {
  input: ClearCampaignInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearMessageArgs = {
  input: ClearMessageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearPlannedWorkArgs = {
  input: ClearPlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearScreenLockArgs = {
  input: ClearScreenLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAgencyArgs = {
  input: CreateAgencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignScreenContentArgs = {
  input: CreateCampaignScreenContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignScreenContentUploadArgs = {
  input: CreateCampaignScreenContentUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClearedReportArgs = {
  input: CreateClearedReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFallbackContentArgs = {
  input: CreateFallbackContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFallbackScreensContentArgs = {
  input: CreateFallbackScreensContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeatureFlagArgs = {
  input: CreateFeatureFlagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedArgs = {
  input: CreateFeedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedGroupArgs = {
  input: CreateFeedGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedMessageTextAliasArgs = {
  input: CreateFeedMessageTextAliasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedSettingArgs = {
  input: CreateFeedSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLockableUploadArgs = {
  input: CreateLockableUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMessageImpactArgs = {
  input: CreateMessageImpactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlannedWorkArgs = {
  input: CreatePlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRouteArgs = {
  input: CreateRouteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreenLockArgs = {
  input: CreateScreenLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreenNoteArgs = {
  input: CreateScreenNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreens2Args = {
  input: CreateScreens2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSocialMediaFolderArgs = {
  input: CreateSocialMediaFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSocialMediaUploadArgs = {
  input: CreateSocialMediaUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStopArgs = {
  input: CreateStopInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStopTimeArgs = {
  input: CreateStopTimeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTripArgs = {
  input: CreateTripInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTripsLirrArgs = {
  input: CreateTripsLirrInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTweetsUploadArgs = {
  input: CreateTweetsUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUploadArgs = {
  input: CreateUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserFeedGroupGrantArgs = {
  input: CreateUserFeedGroupGrantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUsersAccessArgs = {
  input: CreateUsersAccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeactivateUserArgs = {
  input: DeactivateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAgencyArgs = {
  input: DeleteAgencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAgencyByNodeIdArgs = {
  input: DeleteAgencyByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignByNodeIdArgs = {
  input: DeleteCampaignByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignScreenContentByIdArgs = {
  input: DeleteCampaignScreenContentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexArgs =
  {
    input: DeleteCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdArgs =
  {
    input: DeleteCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClearedReportArgs = {
  input: DeleteClearedReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClearedReportByNodeIdArgs = {
  input: DeleteClearedReportByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDraftMessageArgs = {
  input: DeleteDraftMessageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeatureFlagArgs = {
  input: DeleteFeatureFlagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeatureFlagByNodeIdArgs = {
  input: DeleteFeatureFlagByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedArgs = {
  input: DeleteFeedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedByNameArgs = {
  input: DeleteFeedByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedByNodeIdArgs = {
  input: DeleteFeedByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedByOtpIdArgs = {
  input: DeleteFeedByOtpIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedGroupArgs = {
  input: DeleteFeedGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedGroupByNodeIdArgs = {
  input: DeleteFeedGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedMessageTextAliasArgs = {
  input: DeleteFeedMessageTextAliasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdArgs = {
  input: DeleteFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedMessageTextAliasByNodeIdArgs = {
  input: DeleteFeedMessageTextAliasByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedSettingArgs = {
  input: DeleteFeedSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedSettingByNodeIdArgs = {
  input: DeleteFeedSettingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLockableUploadArgs = {
  input: DeleteLockableUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLockableUploadByNodeIdArgs = {
  input: DeleteLockableUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageImpactArgs = {
  input: DeleteMessageImpactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageImpactByNodeIdArgs = {
  input: DeleteMessageImpactByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlannedWorkArgs = {
  input: DeletePlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlannedWorkByNodeIdArgs = {
  input: DeletePlannedWorkByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRouteArgs = {
  input: DeleteRouteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRouteByNodeIdArgs = {
  input: DeleteRouteByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreenNoteByNameArgs = {
  input: DeleteScreenNoteByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreens2Args = {
  input: DeleteScreens2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreens2ByNodeIdArgs = {
  input: DeleteScreens2ByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSocialMediaFolderArgs = {
  input: DeleteSocialMediaFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSocialMediaFolderByNodeIdArgs = {
  input: DeleteSocialMediaFolderByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSocialMediaUploadArgs = {
  input: DeleteSocialMediaUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSocialMediaUploadByNodeIdArgs = {
  input: DeleteSocialMediaUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStopArgs = {
  input: DeleteStopInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStopByNodeIdArgs = {
  input: DeleteStopByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTripArgs = {
  input: DeleteTripInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTripByNodeIdArgs = {
  input: DeleteTripByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTripsLirrArgs = {
  input: DeleteTripsLirrInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTripsLirrByNodeIdArgs = {
  input: DeleteTripsLirrByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTripsLirrByTrainNumArgs = {
  input: DeleteTripsLirrByTrainNumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTweetsUploadArgs = {
  input: DeleteTweetsUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTweetsUploadByNodeIdArgs = {
  input: DeleteTweetsUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUploadArgs = {
  input: DeleteUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUploadByNodeIdArgs = {
  input: DeleteUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserFeedGroupGrantArgs = {
  input: DeleteUserFeedGroupGrantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserFeedGroupGrantByNodeIdArgs = {
  input: DeleteUserFeedGroupGrantByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersAccessArgs = {
  input: DeleteUsersAccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersAccessByNodeIdArgs = {
  input: DeleteUsersAccessByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateCurrentClearedReportsArgs = {
  input: GenerateCurrentClearedReportsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetActiveRealtimeMessagesArgs = {
  input: GetActiveRealtimeMessagesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGrantUserFeatureFlagArgs = {
  input: GrantUserFeatureFlagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishCampaignArgs = {
  input: PublishCampaignInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishDraftMessageArgs = {
  input: PublishDraftMessageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishPlannedWorkArgs = {
  input: PublishPlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReactivateMessageArgs = {
  input: ReactivateMessageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReactivatePlannedWorkArgs = {
  input: ReactivatePlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReclearCampaignScreensArgs = {
  input: ReclearCampaignScreensInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReclearMessageScreensArgs = {
  input: ReclearMessageScreensInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReclearPlannedWorkScreensArgs = {
  input: ReclearPlannedWorkScreensInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemovePlannedWorkArgs = {
  input: RemovePlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRenewMessagesTimestampsArgs = {
  input: RenewMessagesTimestampsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetryEmailSmsArgs = {
  input: RetryEmailSmsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetryTweetArgs = {
  input: RetryTweetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetPlannedWorkDirectDurationsArgs = {
  input: SetPlannedWorkDirectDurationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationToggleAdminUserAccessArgs = {
  input: ToggleAdminUserAccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAgencyArgs = {
  input: UpdateAgencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAgencyByNodeIdArgs = {
  input: UpdateAgencyByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignScreenContentByIdArgs = {
  input: UpdateCampaignScreenContentByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexArgs =
  {
    input: UpdateCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdArgs =
  {
    input: UpdateCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClearedReportArgs = {
  input: UpdateClearedReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClearedReportByNodeIdArgs = {
  input: UpdateClearedReportByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeatureFlagArgs = {
  input: UpdateFeatureFlagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeatureFlagByNodeIdArgs = {
  input: UpdateFeatureFlagByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedArgs = {
  input: UpdateFeedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedByNameArgs = {
  input: UpdateFeedByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedByNodeIdArgs = {
  input: UpdateFeedByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedByOtpIdArgs = {
  input: UpdateFeedByOtpIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedGroupArgs = {
  input: UpdateFeedGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedGroupByNodeIdArgs = {
  input: UpdateFeedGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedMessageTextAliasArgs = {
  input: UpdateFeedMessageTextAliasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdArgs = {
  input: UpdateFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedMessageTextAliasByNodeIdArgs = {
  input: UpdateFeedMessageTextAliasByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedSettingArgs = {
  input: UpdateFeedSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedSettingByNodeIdArgs = {
  input: UpdateFeedSettingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFlagArgs = {
  input: UpdateFlagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLockableAssetArgs = {
  input: UpdateLockableAssetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLockableUploadArgs = {
  input: UpdateLockableUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLockableUploadByNodeIdArgs = {
  input: UpdateLockableUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageByNodeIdArgs = {
  input: UpdateMessageByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageImpactArgs = {
  input: UpdateMessageImpactInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageImpactByNodeIdArgs = {
  input: UpdateMessageImpactByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlannedWorkArgs = {
  input: UpdatePlannedWorkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlannedWorkNotesArgs = {
  input: UpdatePlannedWorkNotesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRouteArgs = {
  input: UpdateRouteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRouteByNodeIdArgs = {
  input: UpdateRouteByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenCustomCriteriaArgs = {
  input: UpdateScreenCustomCriteriaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenLockArgs = {
  input: UpdateScreenLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenNoteByNameArgs = {
  input: UpdateScreenNoteByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenNotesArgs = {
  input: UpdateScreenNotesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreens2Args = {
  input: UpdateScreens2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreens2ByNodeIdArgs = {
  input: UpdateScreens2ByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreensCustomCriteriaArgs = {
  input: UpdateScreensCustomCriteriaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSocialMediaFolderArgs = {
  input: UpdateSocialMediaFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSocialMediaFolderByNodeIdArgs = {
  input: UpdateSocialMediaFolderByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSocialMediaUploadArgs = {
  input: UpdateSocialMediaUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSocialMediaUploadByNodeIdArgs = {
  input: UpdateSocialMediaUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStopBlueprintArgs = {
  input: UpdateStopBlueprintInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSyntheticScreenArgs = {
  input: UpdateSyntheticScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSyntheticScreenByNameArgs = {
  input: UpdateSyntheticScreenByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSyntheticScreenByNodeIdArgs = {
  input: UpdateSyntheticScreenByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSyntheticScreenNotesArgs = {
  input: UpdateSyntheticScreenNotesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTripArgs = {
  input: UpdateTripInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTripByNodeIdArgs = {
  input: UpdateTripByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTripsLirrArgs = {
  input: UpdateTripsLirrInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTripsLirrByNodeIdArgs = {
  input: UpdateTripsLirrByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTripsLirrByTrainNumArgs = {
  input: UpdateTripsLirrByTrainNumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTweetsUploadArgs = {
  input: UpdateTweetsUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTweetsUploadByNodeIdArgs = {
  input: UpdateTweetsUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUploadArgs = {
  input: UpdateUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUploadByNodeIdArgs = {
  input: UpdateUploadByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserFeedGroupGrantArgs = {
  input: UpdateUserFeedGroupGrantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserFeedGroupGrantByNodeIdArgs = {
  input: UpdateUserFeedGroupGrantByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersAccessArgs = {
  input: UpdateUsersAccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersAccessByNodeIdArgs = {
  input: UpdateUsersAccessByNodeIdInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type OrderedStop = {
  __typename?: 'OrderedStop';
  borough?: Maybe<Borough>;
  feedId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  lat?: Maybe<Scalars['BigFloat']['output']>;
  locationType?: Maybe<LocationType>;
  lon?: Maybe<Scalars['BigFloat']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentStationGtfsId?: Maybe<Scalars['String']['output']>;
  routeGtfsId: Scalars['String']['output'];
  screensAvailable: Scalars['Boolean']['output'];
  sequence: Scalars['Int']['output'];
};

/** A filter to be used against `OrderedStop` object types. All fields are combined with a logical ‘and.’ */
export type OrderedStopFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrderedStopFilter>>;
  /** Filter by the object’s `borough` field. */
  borough?: InputMaybe<BoroughFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `locationType` field. */
  locationType?: InputMaybe<LocationTypeFilter>;
  /** Filter by the object’s `lon` field. */
  lon?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrderedStopFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrderedStopFilter>>;
  /** Filter by the object’s `parentStationGtfsId` field. */
  parentStationGtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `routeGtfsId` field. */
  routeGtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `screensAvailable` field. */
  screensAvailable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `sequence` field. */
  sequence?: InputMaybe<IntFilter>;
};

/** A connection to a list of `OrderedStop` values. */
export type OrderedStopsConnection = {
  __typename?: 'OrderedStopsConnection';
  /** A list of edges which contains the `OrderedStop` and cursor to aid in pagination. */
  edges: Array<OrderedStopsEdge>;
  /** A list of `OrderedStop` objects. */
  nodes: Array<OrderedStop>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrderedStop` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `OrderedStop` edge in the connection. */
export type OrderedStopsEdge = {
  __typename?: 'OrderedStopsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `OrderedStop` at the end of the edge. */
  node: OrderedStop;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PlannedWork = Node & {
  __typename?: 'PlannedWork';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  body: Scalars['JSON']['output'];
  bodyHtml: Scalars['String']['output'];
  canReclearScreens: Scalars['Boolean']['output'];
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  clearedBy?: Maybe<Scalars['UUID']['output']>;
  cloneId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `DashboardPlannedWork`. */
  dashboardPlannedWorksById: DashboardPlannedWorksConnection;
  durations: Array<DatetimeRange>;
  durationsEnd?: Maybe<Scalars['Datetime']['output']>;
  durationsSource: DurationsSource;
  durationsWithoutExceptions: Array<DatetimeRange>;
  end?: Maybe<Scalars['Datetime']['output']>;
  /**
   *   Currently only used to to save stops tagged in the planned work headline field.
   *   Can be extended to include other entities if needed.
   *
   */
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  fastDurations?: Maybe<Array<Maybe<DatetimeRange>>>;
  fastStatus: PlannedWorkStatus;
  feedId: Scalars['String']['output'];
  generalOrderNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  header: Scalars['JSON']['output'];
  headerHtml: Scalars['String']['output'];
  homepagePublishOffset?: Maybe<Interval>;
  id: Scalars['Int']['output'];
  internalNotes?: Maybe<Scalars['String']['output']>;
  isLiveOnHomepage: Scalars['Boolean']['output'];
  isLiveOnScreens: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PlannedWorkImpact`. */
  plannedWorkImpacts: PlannedWorkImpactsConnection;
  /** Reads and enables pagination through a set of `PlannedWorkScreenMessage`. */
  plannedWorkScreenMessages: PlannedWorkScreenMessagesConnection;
  publishedAt?: Maybe<Scalars['Datetime']['output']>;
  removedAt?: Maybe<Scalars['Datetime']['output']>;
  screenDurations?: Maybe<Array<Maybe<DatetimeRange>>>;
  screensAreClearing: Scalars['Boolean']['output'];
  selectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  servicePlanNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  stationAlternatives: Array<StationAlternative>;
  status?: Maybe<PlannedWorkStatus>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  updaterId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

export type PlannedWorkDashboardPlannedWorksByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DashboardPlannedWorkCondition>;
  filter?: InputMaybe<DashboardPlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardPlannedWorksOrderBy>>;
};

export type PlannedWorkPlannedWorkImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkImpactCondition>;
  filter?: InputMaybe<PlannedWorkImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkImpactsOrderBy>>;
};

export type PlannedWorkPlannedWorkScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkScreenMessageCondition>;
  filter?: InputMaybe<PlannedWorkScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkScreenMessagesOrderBy>>;
};

export type PlannedWorkScreenDurationsArgs = {
  screenPublishOffset?: InputMaybe<IntervalInput>;
};

/**
 * A condition to be used against `PlannedWork` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PlannedWorkCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `clearedBy` field. */
  clearedBy?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `cloneId` field. */
  cloneId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `durationsSource` field. */
  durationsSource?: InputMaybe<DurationsSourceInput>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `fastStatus` field. */
  fastStatus?: InputMaybe<PlannedWorkStatus>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `generalOrderNumbers` field. */
  generalOrderNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `header` field. */
  header?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `homepagePublishOffset` field. */
  homepagePublishOffset?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `internalNotes` field. */
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `removedAt` field. */
  removedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `servicePlanNumbers` field. */
  servicePlanNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `stationAlternatives` field. */
  stationAlternatives?: InputMaybe<Array<InputMaybe<StationAlternativeInput>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updaterId` field. */
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `PlannedWork` object types. All fields are combined with a logical ‘and.’ */
export type PlannedWorkFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlannedWorkFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `canReclearScreens` field. */
  canReclearScreens?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `clearedBy` field. */
  clearedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `cloneId` field. */
  cloneId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `durations` field. */
  durations?: InputMaybe<DatetimeRangeListFilter>;
  /** Filter by the object’s `durationsEnd` field. */
  durationsEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `durationsWithoutExceptions` field. */
  durationsWithoutExceptions?: InputMaybe<DatetimeRangeListFilter>;
  /** Filter by the object’s `end` field. */
  end?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fastDurations` field. */
  fastDurations?: InputMaybe<DatetimeRangeListFilter>;
  /** Filter by the object’s `fastStatus` field. */
  fastStatus?: InputMaybe<PlannedWorkStatusFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `generalOrderNumbers` field. */
  generalOrderNumbers?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `header` field. */
  header?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `headerHtml` field. */
  headerHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `homepagePublishOffset` field. */
  homepagePublishOffset?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `internalNotes` field. */
  internalNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isLiveOnHomepage` field. */
  isLiveOnHomepage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isLiveOnScreens` field. */
  isLiveOnScreens?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlannedWorkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlannedWorkFilter>>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `removedAt` field. */
  removedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `screensAreClearing` field. */
  screensAreClearing?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `servicePlanNumbers` field. */
  servicePlanNumbers?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<PlannedWorkStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updaterId` field. */
  updaterId?: InputMaybe<UuidFilter>;
};

export type PlannedWorkImpact = Node & {
  __typename?: 'PlannedWorkImpact';
  entitySelectors: Array<Maybe<GtfsEntitySelector>>;
  id: Scalars['Int']['output'];
  isAgencyWide: Scalars['Boolean']['output'];
  messageType: MessageType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PlannedWork` that is related to this `PlannedWorkImpact`. */
  plannedWork?: Maybe<PlannedWork>;
  plannedWorkId: Scalars['Int']['output'];
};

/**
 * A condition to be used against `PlannedWorkImpact` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PlannedWorkImpactCondition = {
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isAgencyWide` field. */
  isAgencyWide?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `messageType` field. */
  messageType?: InputMaybe<MessageType>;
  /** Checks for equality with the object’s `plannedWorkId` field. */
  plannedWorkId?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `PlannedWorkImpact` object types. All fields are combined with a logical ‘and.’ */
export type PlannedWorkImpactFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlannedWorkImpactFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isAgencyWide` field. */
  isAgencyWide?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageType` field. */
  messageType?: InputMaybe<MessageTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlannedWorkImpactFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlannedWorkImpactFilter>>;
  /** Filter by the object’s `plannedWorkId` field. */
  plannedWorkId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `PlannedWorkImpact` values. */
export type PlannedWorkImpactsConnection = {
  __typename?: 'PlannedWorkImpactsConnection';
  /** A list of edges which contains the `PlannedWorkImpact` and cursor to aid in pagination. */
  edges: Array<PlannedWorkImpactsEdge>;
  /** A list of `PlannedWorkImpact` objects. */
  nodes: Array<PlannedWorkImpact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlannedWorkImpact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PlannedWorkImpact` edge in the connection. */
export type PlannedWorkImpactsEdge = {
  __typename?: 'PlannedWorkImpactsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PlannedWorkImpact` at the end of the edge. */
  node: PlannedWorkImpact;
};

/** Methods to use when ordering `PlannedWorkImpact`. */
export enum PlannedWorkImpactsOrderBy {
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_AGENCY_WIDE_ASC = 'IS_AGENCY_WIDE_ASC',
  IS_AGENCY_WIDE_DESC = 'IS_AGENCY_WIDE_DESC',
  MESSAGE_TYPE_ASC = 'MESSAGE_TYPE_ASC',
  MESSAGE_TYPE_DESC = 'MESSAGE_TYPE_DESC',
  NATURAL = 'NATURAL',
  PLANNED_WORK_ID_ASC = 'PLANNED_WORK_ID_ASC',
  PLANNED_WORK_ID_DESC = 'PLANNED_WORK_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** An input for mutations affecting `PlannedWorkInputRecord` */
export type PlannedWorkInputRecordInput = {
  body: Scalars['JSON']['input'];
  cloneId?: InputMaybe<Scalars['Int']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  durationsSource?: InputMaybe<DurationsSourceInput>;
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  feedId: Scalars['String']['input'];
  generalOrderNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header: Scalars['JSON']['input'];
  homepagePublishOffset?: InputMaybe<IntervalInput>;
  impacts: Array<InputMaybe<CreatePlannedWorkImpactInputRecordInput>>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  screenMessages: Array<
    InputMaybe<CreatePlannedWorkScreenMessageInputRecordInput>
  >;
  servicePlanNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stationAlternatives: Array<InputMaybe<StationAlternativeInput>>;
};

export type PlannedWorkMessageDurationSetting = {
  __typename?: 'PlannedWorkMessageDurationSetting';
  days?: Maybe<Array<Maybe<Days>>>;
  daysOfWeek?: Maybe<MessageDaysOfWeek>;
  durationForTheWeekMessage?: Maybe<Scalars['String']['output']>;
  humanReadableDurationsOverrideMessage?: Maybe<Scalars['String']['output']>;
  timeOfDay?: Maybe<MessageTimeOfDay>;
};

/** An input for mutations affecting `PlannedWorkMessageDurationSetting` */
export type PlannedWorkMessageDurationSettingInput = {
  days?: InputMaybe<Array<InputMaybe<Days>>>;
  daysOfWeek?: InputMaybe<MessageDaysOfWeek>;
  durationForTheWeekMessage?: InputMaybe<Scalars['String']['input']>;
  humanReadableDurationsOverrideMessage?: InputMaybe<
    Scalars['String']['input']
  >;
  timeOfDay?: InputMaybe<MessageTimeOfDay>;
};

/** An input for mutations affecting `PlannedWorkPatchRecord` */
export type PlannedWorkPatchRecordInput = {
  body?: InputMaybe<Scalars['JSON']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  durationsSource?: InputMaybe<DurationsSourceInput>;
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  generalOrderNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  header?: InputMaybe<Scalars['JSON']['input']>;
  homepagePublishOffset?: InputMaybe<IntervalInput>;
  impacts?: InputMaybe<
    Array<InputMaybe<CreatePlannedWorkImpactInputRecordInput>>
  >;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  screenMessages?: InputMaybe<
    Array<InputMaybe<CreatePlannedWorkScreenMessageInputRecordInput>>
  >;
  servicePlanNumbers?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stationAlternatives?: InputMaybe<Array<InputMaybe<StationAlternativeInput>>>;
};

export type PlannedWorkScreenMessage = Node & {
  __typename?: 'PlannedWorkScreenMessage';
  additionalInfo?: Maybe<Scalars['JSON']['output']>;
  beforeActivePriority: SchedulePriority;
  beforeActiveWeight: Scalars['Int']['output'];
  body?: Maybe<Scalars['JSON']['output']>;
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  createdAt: Scalars['Datetime']['output'];
  durationsSettings?: Maybe<PlannedWorkMessageDurationSetting>;
  id: Scalars['Int']['output'];
  isActiveDuringPeriod: Scalars['Boolean']['output'];
  isAda: Scalars['Boolean']['output'];
  isClearing: Scalars['Boolean']['output'];
  isDup?: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PlannedWork` that is related to this `PlannedWorkScreenMessage`. */
  plannedWork?: Maybe<PlannedWork>;
  plannedWorkId: Scalars['Int']['output'];
  priority: SchedulePriority;
  screenPublishOffset?: Maybe<Interval>;
  screenTargeting: Array<Maybe<PlannedWorkScreenSelector>>;
  selectors?: Maybe<Array<Maybe<ContextualSelector>>>;
  targetTypes?: Maybe<Array<Maybe<TargetType>>>;
  title?: Maybe<Scalars['String']['output']>;
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `Upload` that is related to this `PlannedWorkScreenMessage`. */
  upload?: Maybe<Upload>;
  uploadId?: Maybe<Scalars['UUID']['output']>;
  weight: Scalars['Int']['output'];
};

/**
 * A condition to be used against `PlannedWorkScreenMessage` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type PlannedWorkScreenMessageCondition = {
  /** Checks for equality with the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `beforeActivePriority` field. */
  beforeActivePriority?: InputMaybe<SchedulePriority>;
  /** Checks for equality with the object’s `beforeActiveWeight` field. */
  beforeActiveWeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `durationsSettings` field. */
  durationsSettings?: InputMaybe<PlannedWorkMessageDurationSettingInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isActiveDuringPeriod` field. */
  isActiveDuringPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isAda` field. */
  isAda?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `plannedWorkId` field. */
  plannedWorkId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriority>;
  /** Checks for equality with the object’s `screenPublishOffset` field. */
  screenPublishOffset?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `screenTargeting` field. */
  screenTargeting?: InputMaybe<
    Array<InputMaybe<PlannedWorkScreenSelectorInput>>
  >;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `PlannedWorkScreenMessage` object types. All fields are combined with a logical ‘and.’ */
export type PlannedWorkScreenMessageFilter = {
  /** Filter by the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlannedWorkScreenMessageFilter>>;
  /** Filter by the object’s `beforeActivePriority` field. */
  beforeActivePriority?: InputMaybe<SchedulePriorityFilter>;
  /** Filter by the object’s `beforeActiveWeight` field. */
  beforeActiveWeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isActiveDuringPeriod` field. */
  isActiveDuringPeriod?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isAda` field. */
  isAda?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isClearing` field. */
  isClearing?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDup` field. */
  isDup?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlannedWorkScreenMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlannedWorkScreenMessageFilter>>;
  /** Filter by the object’s `plannedWorkId` field. */
  plannedWorkId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriorityFilter>;
  /** Filter by the object’s `screenPublishOffset` field. */
  screenPublishOffset?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<TargetTypeListFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
};

/** A connection to a list of `PlannedWorkScreenMessage` values. */
export type PlannedWorkScreenMessagesConnection = {
  __typename?: 'PlannedWorkScreenMessagesConnection';
  /** A list of edges which contains the `PlannedWorkScreenMessage` and cursor to aid in pagination. */
  edges: Array<PlannedWorkScreenMessagesEdge>;
  /** A list of `PlannedWorkScreenMessage` objects. */
  nodes: Array<PlannedWorkScreenMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlannedWorkScreenMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PlannedWorkScreenMessage` edge in the connection. */
export type PlannedWorkScreenMessagesEdge = {
  __typename?: 'PlannedWorkScreenMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PlannedWorkScreenMessage` at the end of the edge. */
  node: PlannedWorkScreenMessage;
};

/** Methods to use when ordering `PlannedWorkScreenMessage`. */
export enum PlannedWorkScreenMessagesOrderBy {
  ADDITIONAL_INFO_ASC = 'ADDITIONAL_INFO_ASC',
  ADDITIONAL_INFO_DESC = 'ADDITIONAL_INFO_DESC',
  BEFORE_ACTIVE_PRIORITY_ASC = 'BEFORE_ACTIVE_PRIORITY_ASC',
  BEFORE_ACTIVE_PRIORITY_DESC = 'BEFORE_ACTIVE_PRIORITY_DESC',
  BEFORE_ACTIVE_WEIGHT_ASC = 'BEFORE_ACTIVE_WEIGHT_ASC',
  BEFORE_ACTIVE_WEIGHT_DESC = 'BEFORE_ACTIVE_WEIGHT_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DURATIONS_SETTINGS_ASC = 'DURATIONS_SETTINGS_ASC',
  DURATIONS_SETTINGS_DESC = 'DURATIONS_SETTINGS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_ACTIVE_DURING_PERIOD_ASC = 'IS_ACTIVE_DURING_PERIOD_ASC',
  IS_ACTIVE_DURING_PERIOD_DESC = 'IS_ACTIVE_DURING_PERIOD_DESC',
  IS_ADA_ASC = 'IS_ADA_ASC',
  IS_ADA_DESC = 'IS_ADA_DESC',
  NATURAL = 'NATURAL',
  PLANNED_WORK_ID_ASC = 'PLANNED_WORK_ID_ASC',
  PLANNED_WORK_ID_DESC = 'PLANNED_WORK_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  SCREEN_PUBLISH_OFFSET_ASC = 'SCREEN_PUBLISH_OFFSET_ASC',
  SCREEN_PUBLISH_OFFSET_DESC = 'SCREEN_PUBLISH_OFFSET_DESC',
  SCREEN_TARGETING_ASC = 'SCREEN_TARGETING_ASC',
  SCREEN_TARGETING_DESC = 'SCREEN_TARGETING_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export type PlannedWorkScreenSelector = {
  __typename?: 'PlannedWorkScreenSelector';
  selectors?: Maybe<Array<Maybe<ContextualSelector>>>;
  tags?: Maybe<Scalars['JSON']['output']>;
  targetTypes?: Maybe<Array<Maybe<TargetType>>>;
};

/** An input for mutations affecting `PlannedWorkScreenSelector` */
export type PlannedWorkScreenSelectorInput = {
  selectors?: InputMaybe<Array<InputMaybe<ContextualSelectorInput>>>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
};

export enum PlannedWorkStatus {
  CLEARED = 'CLEARED',
  CLEARING = 'CLEARING',
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  LOADING = 'LOADING',
  PUBLISHED = 'PUBLISHED',
  REMOVED = 'REMOVED',
  UNKNOWN = 'UNKNOWN',
}

/** A filter to be used against PlannedWorkStatus fields. All fields are combined with a logical ‘and.’ */
export type PlannedWorkStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PlannedWorkStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PlannedWorkStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PlannedWorkStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PlannedWorkStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PlannedWorkStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PlannedWorkStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PlannedWorkStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PlannedWorkStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PlannedWorkStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PlannedWorkStatus>>;
};

/** A connection to a list of `PlannedWork` values. */
export type PlannedWorksConnection = {
  __typename?: 'PlannedWorksConnection';
  /** A list of edges which contains the `PlannedWork` and cursor to aid in pagination. */
  edges: Array<PlannedWorksEdge>;
  /** A list of `PlannedWork` objects. */
  nodes: Array<PlannedWork>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlannedWork` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PlannedWork` edge in the connection. */
export type PlannedWorksEdge = {
  __typename?: 'PlannedWorksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PlannedWork` at the end of the edge. */
  node: PlannedWork;
};

/** Methods to use when ordering `PlannedWork`. */
export enum PlannedWorksOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  CLEARED_BY_ASC = 'CLEARED_BY_ASC',
  CLEARED_BY_DESC = 'CLEARED_BY_DESC',
  CLONE_ID_ASC = 'CLONE_ID_ASC',
  CLONE_ID_DESC = 'CLONE_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DURATIONS_SOURCE_ASC = 'DURATIONS_SOURCE_ASC',
  DURATIONS_SOURCE_DESC = 'DURATIONS_SOURCE_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  FAST_STATUS_ASC = 'FAST_STATUS_ASC',
  FAST_STATUS_DESC = 'FAST_STATUS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GENERAL_ORDER_NUMBERS_ASC = 'GENERAL_ORDER_NUMBERS_ASC',
  GENERAL_ORDER_NUMBERS_DESC = 'GENERAL_ORDER_NUMBERS_DESC',
  HEADER_ASC = 'HEADER_ASC',
  HEADER_DESC = 'HEADER_DESC',
  HOMEPAGE_PUBLISH_OFFSET_ASC = 'HOMEPAGE_PUBLISH_OFFSET_ASC',
  HOMEPAGE_PUBLISH_OFFSET_DESC = 'HOMEPAGE_PUBLISH_OFFSET_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTERNAL_NOTES_ASC = 'INTERNAL_NOTES_ASC',
  INTERNAL_NOTES_DESC = 'INTERNAL_NOTES_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PUBLISHED_AT_ASC = 'PUBLISHED_AT_ASC',
  PUBLISHED_AT_DESC = 'PUBLISHED_AT_DESC',
  REMOVED_AT_ASC = 'REMOVED_AT_ASC',
  REMOVED_AT_DESC = 'REMOVED_AT_DESC',
  SERVICE_PLAN_NUMBERS_ASC = 'SERVICE_PLAN_NUMBERS_ASC',
  SERVICE_PLAN_NUMBERS_DESC = 'SERVICE_PLAN_NUMBERS_DESC',
  STATION_ALTERNATIVES_ASC = 'STATION_ALTERNATIVES_ASC',
  STATION_ALTERNATIVES_DESC = 'STATION_ALTERNATIVES_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPDATER_ID_ASC = 'UPDATER_ID_ASC',
  UPDATER_ID_DESC = 'UPDATER_ID_DESC',
}

/** All input for the `publishCampaign` mutation. */
export type PublishCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `publishCampaign` mutation. */
export type PublishCampaignPayload = {
  __typename?: 'PublishCampaignPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our `publishCampaign` mutation. */
export type PublishCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `publishDraftMessage` mutation. */
export type PublishDraftMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['Int']['input'];
};

/** The output of our `publishDraftMessage` mutation. */
export type PublishDraftMessagePayload = {
  __typename?: 'PublishDraftMessagePayload';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `publishDraftMessage` mutation. */
export type PublishDraftMessagePayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `publishPlannedWork` mutation. */
export type PublishPlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWorkId: Scalars['Int']['input'];
};

/** The output of our `publishPlannedWork` mutation. */
export type PublishPlannedWorkPayload = {
  __typename?: 'PublishPlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `publishPlannedWork` mutation. */
export type PublishPlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

export enum PublishStatus {
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

/** A filter to be used against PublishStatus fields. All fields are combined with a logical ‘and.’ */
export type PublishStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PublishStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PublishStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PublishStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PublishStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PublishStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PublishStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PublishStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PublishStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PublishStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PublishStatus>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `Agency`. */
  agencies?: Maybe<AgenciesConnection>;
  agency?: Maybe<Agency>;
  /** Reads a single `Agency` using its globally unique `ID`. */
  agencyByNodeId?: Maybe<Agency>;
  calculateDurationsFromDurationsSource?: Maybe<Array<Maybe<DatetimeRange>>>;
  campaign?: Maybe<Campaign>;
  /** Reads a single `Campaign` using its globally unique `ID`. */
  campaignByNodeId?: Maybe<Campaign>;
  campaignScreenContentById?: Maybe<CampaignScreenContent>;
  campaignScreenContentUploadByCampaignScreenContentIdAndSetIndex?: Maybe<CampaignScreenContentUpload>;
  campaignScreenContentUploadByCampaignScreenContentIdAndUploadId?: Maybe<CampaignScreenContentUpload>;
  /** Reads and enables pagination through a set of `CampaignScreenContentUpload`. */
  campaignScreenContentUploads?: Maybe<CampaignScreenContentUploadsConnection>;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  campaignScreenContents?: Maybe<CampaignScreenContentsConnection>;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns?: Maybe<CampaignsConnection>;
  clearedReport?: Maybe<ClearedReport>;
  /** Reads a single `ClearedReport` using its globally unique `ID`. */
  clearedReportByNodeId?: Maybe<ClearedReport>;
  /** Reads and enables pagination through a set of `ClearedReport`. */
  clearedReports?: Maybe<ClearedReportsConnection>;
  contextualSelectorToEntitySelector?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  /**
   *     Fetches the current (not-yet cleared) screen lock for the asset with
   *     asset_id. To get cleared/archived screen locks use the `Query.screenLocks`
   *     connection.
   *
   */
  currentScreenLock?: Maybe<ScreenLock>;
  currentUser?: Maybe<User>;
  /** Reads and enables pagination through a set of `Message`. */
  dashboardMessages?: Maybe<MessagesConnection>;
  /** Reads and enables pagination through a set of `DashboardPlannedWork`. */
  dashboardPlannedWorks?: Maybe<DashboardPlannedWorksConnection>;
  /** Reads and enables pagination through a set of `DraftMessage`. */
  draftMessages?: Maybe<DraftMessagesConnection>;
  editorTaggedEntityIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  event?: Maybe<Event>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  /** Reads and enables pagination through a set of `Event`. */
  events?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `FallbackScreensContent`. */
  fallbackScreensContents?: Maybe<FallbackScreensContentsConnection>;
  featureFlag?: Maybe<FeatureFlag>;
  /** Reads a single `FeatureFlag` using its globally unique `ID`. */
  featureFlagByNodeId?: Maybe<FeatureFlag>;
  /** Reads and enables pagination through a set of `FeatureFlag`. */
  featureFlags?: Maybe<FeatureFlagsConnection>;
  feed?: Maybe<Feed>;
  feedByName?: Maybe<Feed>;
  /** Reads a single `Feed` using its globally unique `ID`. */
  feedByNodeId?: Maybe<Feed>;
  feedByOtpId?: Maybe<Feed>;
  feedGroup?: Maybe<FeedGroup>;
  /** Reads a single `FeedGroup` using its globally unique `ID`. */
  feedGroupByNodeId?: Maybe<FeedGroup>;
  /** Reads and enables pagination through a set of `FeedGroup`. */
  feedGroups?: Maybe<FeedGroupsConnection>;
  feedMessageTextAlias?: Maybe<FeedMessageTextAlias>;
  feedMessageTextAliasByGtfsIdAndAliasAndFeedId?: Maybe<FeedMessageTextAlias>;
  /** Reads a single `FeedMessageTextAlias` using its globally unique `ID`. */
  feedMessageTextAliasByNodeId?: Maybe<FeedMessageTextAlias>;
  /** Reads and enables pagination through a set of `FeedMessageTextAlias`. */
  feedMessageTextAliases?: Maybe<FeedMessageTextAliasesConnection>;
  feedSetting?: Maybe<FeedSetting>;
  /** Reads a single `FeedSetting` using its globally unique `ID`. */
  feedSettingByNodeId?: Maybe<FeedSetting>;
  /** Reads and enables pagination through a set of `FeedSetting`. */
  feedSettings?: Maybe<FeedSettingsConnection>;
  /** Reads and enables pagination through a set of `Feed`. */
  feeds?: Maybe<FeedsConnection>;
  /** Reads and enables pagination through a set of `LiveContent`. */
  filteredLiveContent?: Maybe<LiveContentsConnection>;
  /** Reads and enables pagination through a set of `Stop`. */
  filteredStops?: Maybe<StopsConnection>;
  /** Reads and enables pagination through a set of `GeneralOrderNumber`. */
  generalOrderNumbers?: Maybe<GeneralOrderNumbersConnection>;
  /**
   *   A function that transforms train targeting into "yard" values used by Outfront in order to filter screens for displaying on the front-end or used as screens filter when targeting TransitAPI.
   *
   */
  getYardValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `GroupedStop`. */
  groupedStops?: Maybe<GroupedStopsConnection>;
  /** Reads and enables pagination through a set of `Job`. */
  jobs?: Maybe<JobsConnection>;
  /** Reads and enables pagination through a set of `LiveContent`. */
  liveContents?: Maybe<LiveContentsConnection>;
  lockableUpload?: Maybe<LockableUpload>;
  /** Reads a single `LockableUpload` using its globally unique `ID`. */
  lockableUploadByNodeId?: Maybe<LockableUpload>;
  /** Reads and enables pagination through a set of `ScreenLock`. */
  lockableUploadLockedScreens?: Maybe<ScreenLocksConnection>;
  /** Reads and enables pagination through a set of `Campaign`. */
  lockableUploadSingleScreenCampaigns?: Maybe<CampaignsConnection>;
  /** Reads and enables pagination through a set of `LockableUpload`. */
  lockableUploads?: Maybe<LockableUploadsConnection>;
  /**
   *   Returns a set of `lmm_public.mention` types that match the given query and list of feeds.
   *
   *   Mentions are a concatenated list of routes, trips, and stops, in that order.
   *   Trips are only returned for MNR and LIRR, and stops are not returned for buses.
   *
   *   The `preferring_route_ids` argument will influence results by mention type:
   *
   *   * stops will be ordered such that stops occurring on those routes are higher,
   *   * trips TBD,
   *   * routes are unaffected.
   *
   *   Following the types and route intersection ordering, ties are broken by alphabetical name
   *   ascending.
   *
   *   Query string matching differs slightly by mention type:
   *
   *   * routes match against short and, if that' null, long names, anywhere in the name
   *   * trips match against short name from start of name
   *   * stops match against name from start of name if query is one character,
   *     any anywhere in name if query is more than one character.
   *
   */
  mentionSuggestions?: Maybe<MentionsConnection>;
  /** Reads and enables pagination through a set of `MatchedMentionAlias`. */
  mentionsForAliases?: Maybe<MatchedMentionAliasesConnection>;
  message?: Maybe<Message>;
  /** Reads a single `Message` using its globally unique `ID`. */
  messageByNodeId?: Maybe<Message>;
  messageImpact?: Maybe<MessageImpact>;
  /** Reads a single `MessageImpact` using its globally unique `ID`. */
  messageImpactByNodeId?: Maybe<MessageImpact>;
  /** Reads and enables pagination through a set of `MessageImpact`. */
  messageImpacts?: Maybe<MessageImpactsConnection>;
  /** Reads and enables pagination through a set of `Message`. */
  messages?: Maybe<MessagesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  plannedWork?: Maybe<PlannedWork>;
  /** Reads a single `PlannedWork` using its globally unique `ID`. */
  plannedWorkByNodeId?: Maybe<PlannedWork>;
  plannedWorkImpact?: Maybe<PlannedWorkImpact>;
  /** Reads a single `PlannedWorkImpact` using its globally unique `ID`. */
  plannedWorkImpactByNodeId?: Maybe<PlannedWorkImpact>;
  /** Reads and enables pagination through a set of `PlannedWorkImpact`. */
  plannedWorkImpacts?: Maybe<PlannedWorkImpactsConnection>;
  plannedWorkScreenMessage?: Maybe<PlannedWorkScreenMessage>;
  /** Reads a single `PlannedWorkScreenMessage` using its globally unique `ID`. */
  plannedWorkScreenMessageByNodeId?: Maybe<PlannedWorkScreenMessage>;
  /** Reads and enables pagination through a set of `PlannedWorkScreenMessage`. */
  plannedWorkScreenMessages?: Maybe<PlannedWorkScreenMessagesConnection>;
  /** Reads and enables pagination through a set of `PlannedWork`. */
  plannedWorks?: Maybe<PlannedWorksConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  route?: Maybe<Route>;
  /** Reads a single `Route` using its globally unique `ID`. */
  routeByNodeId?: Maybe<Route>;
  /** Reads and enables pagination through a set of `Route`. */
  routes?: Maybe<RoutesConnection>;
  /** Reads and enables pagination through a set of `ScreenCountsForEntitySelectorsType`. */
  screenCountsForEntitySelectors?: Maybe<ScreenCountsForEntitySelectorsTypesConnection>;
  screenDetails?: Maybe<ScreensNormalized>;
  /** Reads and enables pagination through a set of `ScreenLayoutCount`. */
  screenLayoutCounts?: Maybe<ScreenLayoutCountsConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutFallbackCount`. */
  screenLayoutFallbackCounts?: Maybe<ScreenLayoutFallbackCountsConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. */
  screenLayoutTypeCounts?: Maybe<ScreenLayoutTypesConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. */
  screenLayoutTypeCountsV2?: Maybe<ScreenLayoutTypesConnection>;
  screenLock?: Maybe<ScreenLock>;
  /** Reads a single `ScreenLock` using its globally unique `ID`. */
  screenLockByNodeId?: Maybe<ScreenLock>;
  /** Reads and enables pagination through a set of `ScreenLock`. */
  screenLocks?: Maybe<ScreenLocksConnection>;
  screenMessage?: Maybe<ScreenMessage>;
  /** Reads a single `ScreenMessage` using its globally unique `ID`. */
  screenMessageByNodeId?: Maybe<ScreenMessage>;
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  screenMessages?: Maybe<ScreenMessagesConnection>;
  screenNoteByName?: Maybe<ScreenNote>;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotes?: Maybe<ScreenNotesConnection>;
  screens2?: Maybe<Screens2>;
  /** Reads a single `Screens2` using its globally unique `ID`. */
  screens2ByNodeId?: Maybe<Screens2>;
  /** Reads and enables pagination through a set of `Screens2`. */
  screens2s?: Maybe<Screens2sConnection>;
  /** Reads and enables pagination through a set of `ScreensNormalized`. */
  screensNormalizeds?: Maybe<ScreensNormalizedsConnection>;
  /** Tuned search of Campaigns. Some data may be out-of-sync. Use top-level campaigns when finding a single campaign or guarantees around matches are needed. */
  searchCampaigns?: Maybe<SearchCampaignsResult>;
  searchClearedMessages?: Maybe<SearchClearedMessagesResult>;
  searchLockableUploads?: Maybe<SearchLockableUploadsResult>;
  searchPlannedWorks?: Maybe<SearchPlannedWorksResult>;
  searchSocialMediaUploads?: Maybe<SearchSocialMediaUploadsResult>;
  /** Reads and enables pagination through a set of `ServicePlanNumber`. */
  servicePlanNumbers?: Maybe<ServicePlanNumbersConnection>;
  socialMediaFolder?: Maybe<SocialMediaFolder>;
  /** Reads a single `SocialMediaFolder` using its globally unique `ID`. */
  socialMediaFolderByNodeId?: Maybe<SocialMediaFolder>;
  /** Reads and enables pagination through a set of `SocialMediaFolder`. */
  socialMediaFolders?: Maybe<SocialMediaFoldersConnection>;
  socialMediaUpload?: Maybe<SocialMediaUpload>;
  /** Reads a single `SocialMediaUpload` using its globally unique `ID`. */
  socialMediaUploadByNodeId?: Maybe<SocialMediaUpload>;
  /** Reads and enables pagination through a set of `SocialMediaUpload`. */
  socialMediaUploads?: Maybe<SocialMediaUploadsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  sortedUsers?: Maybe<UsersConnection>;
  stop?: Maybe<Stop>;
  /** Reads a single `Stop` using its globally unique `ID`. */
  stopByNodeId?: Maybe<Stop>;
  /** Reads and enables pagination through a set of `GroupedStop`. */
  stopOptionsForSelector?: Maybe<GroupedStopsConnection>;
  /** Reads and enables pagination through a set of `StopSequence`. */
  stopSequences?: Maybe<StopSequencesConnection>;
  stopSyntheticScreensCount?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `StopTime`. */
  stopTimes?: Maybe<StopTimesConnection>;
  /** Reads and enables pagination through a set of `Stop`. */
  stops?: Maybe<StopsConnection>;
  syntheticScreen?: Maybe<SyntheticScreen>;
  syntheticScreenByName?: Maybe<SyntheticScreen>;
  /** Reads a single `SyntheticScreen` using its globally unique `ID`. */
  syntheticScreenByNodeId?: Maybe<SyntheticScreen>;
  /** Reads and enables pagination through a set of `SyntheticScreen`. */
  syntheticScreens?: Maybe<SyntheticScreensConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. */
  syntheticScreensLayoutTypeCounts?: Maybe<ScreenLayoutTypesConnection>;
  targetTypeToTransitCriteria?: Maybe<Scalars['JSON']['output']>;
  targetedStaticOnlyScreens?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `TransitSchedule`. */
  transitSchedules?: Maybe<TransitSchedulesConnection>;
  trip?: Maybe<Trip>;
  /** Reads a single `Trip` using its globally unique `ID`. */
  tripByNodeId?: Maybe<Trip>;
  /** Reads and enables pagination through a set of `Trip`. */
  trips?: Maybe<TripsConnection>;
  tripsLirr?: Maybe<TripsLirr>;
  /** Reads a single `TripsLirr` using its globally unique `ID`. */
  tripsLirrByNodeId?: Maybe<TripsLirr>;
  tripsLirrByTrainNum?: Maybe<TripsLirr>;
  /** Reads and enables pagination through a set of `TripsLirr`. */
  tripsLirrs?: Maybe<TripsLirrsConnection>;
  tweet?: Maybe<Tweet>;
  /** Reads a single `Tweet` using its globally unique `ID`. */
  tweetByNodeId?: Maybe<Tweet>;
  /** Reads and enables pagination through a set of `Tweet`. */
  tweets?: Maybe<TweetsConnection>;
  tweetsUpload?: Maybe<TweetsUpload>;
  /** Reads a single `TweetsUpload` using its globally unique `ID`. */
  tweetsUploadByNodeId?: Maybe<TweetsUpload>;
  /** Reads and enables pagination through a set of `TweetsUpload`. */
  tweetsUploads?: Maybe<TweetsUploadsConnection>;
  upload?: Maybe<Upload>;
  /** Reads a single `Upload` using its globally unique `ID`. */
  uploadByNodeId?: Maybe<Upload>;
  /** Reads and enables pagination through a set of `Upload`. */
  uploads?: Maybe<UploadsConnection>;
  user?: Maybe<User>;
  userByEmail?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  userFeedGroupGrant?: Maybe<UserFeedGroupGrant>;
  /** Reads a single `UserFeedGroupGrant` using its globally unique `ID`. */
  userFeedGroupGrantByNodeId?: Maybe<UserFeedGroupGrant>;
  /** Reads and enables pagination through a set of `UserFeedGroupGrant`. */
  userFeedGroupGrants?: Maybe<UserFeedGroupGrantsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  usersAccess?: Maybe<UsersAccess>;
  /** Reads a single `UsersAccess` using its globally unique `ID`. */
  usersAccessByNodeId?: Maybe<UsersAccess>;
  /** Reads and enables pagination through a set of `UsersAccess`. */
  usersAccesses?: Maybe<UsersAccessesConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAgenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AgencyCondition>;
  filter?: InputMaybe<AgencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAgencyArgs = {
  gtfsId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAgencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCalculateDurationsFromDurationsSourceArgs = {
  _durationsSource?: InputMaybe<DurationsSourceInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignScreenContentByIdArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexArgs =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    setIndex: Scalars['Int']['input'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdArgs =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    uploadId: Scalars['UUID']['input'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryCampaignScreenContentUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentUploadCondition>;
  filter?: InputMaybe<CampaignScreenContentUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignScreenContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentCondition>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryClearedReportArgs = {
  id: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryClearedReportByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryClearedReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClearedReportCondition>;
  filter?: InputMaybe<ClearedReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryContextualSelectorToEntitySelectorArgs = {
  selectors: Array<InputMaybe<ContextualSelectorInput>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrentScreenLockArgs = {
  assetId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDashboardMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<DashboardMessagesInputRecordInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDashboardPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DashboardPlannedWorkCondition>;
  filter?: InputMaybe<DashboardPlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardPlannedWorksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDraftMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DraftMessageCondition>;
  filter?: InputMaybe<DraftMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DraftMessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEditorTaggedEntityIdsArgs = {
  _editorData?: InputMaybe<Scalars['JSON']['input']>;
  _mentionType?: InputMaybe<MentionType>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFallbackScreensContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FallbackScreensContentCondition>;
  filter?: InputMaybe<FallbackScreensContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FallbackScreensContentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagArgs = {
  name: FeatureFlagName;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeatureFlagCondition>;
  filter?: InputMaybe<FeatureFlagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedArgs = {
  id: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedByNameArgs = {
  name: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedByOtpIdArgs = {
  otpId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedGroupArgs = {
  name: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedGroupCondition>;
  filter?: InputMaybe<FeedGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedMessageTextAliasArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdArgs = {
  alias: Scalars['String']['input'];
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedMessageTextAliasByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedMessageTextAliasesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedMessageTextAliasCondition>;
  filter?: InputMaybe<FeedMessageTextAliasFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedMessageTextAliasesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingArgs = {
  feedId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedSettingCondition>;
  filter?: InputMaybe<FeedSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedCondition>;
  filter?: InputMaybe<FeedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFilteredLiveContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LiveContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiveContentsOrderBy>>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchStr?: InputMaybe<Scalars['String']['input']>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFilteredStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  borough?: InputMaybe<Borough>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasScreensWithPartnerSlots?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
  query?: InputMaybe<Scalars['String']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stopId?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGeneralOrderNumbersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeneralOrderNumberCondition>;
  filter?: InputMaybe<GeneralOrderNumberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneralOrderNumbersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetYardValuesArgs = {
  isTargetingAllLirrTrains?: InputMaybe<Scalars['Boolean']['input']>;
  lines?: InputMaybe<Array<InputMaybe<MnrRollingStockLine>>>;
  shops?: InputMaybe<Array<InputMaybe<Shop>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupedStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GroupedStopCondition>;
  filter?: InputMaybe<GroupedStopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GroupedStopsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<JobCondition>;
  filter?: InputMaybe<JobFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLiveContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LiveContentCondition>;
  filter?: InputMaybe<LiveContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiveContentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockableUploadArgs = {
  uploadId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockableUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockableUploadLockedScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<ScreenLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lockableUploadId: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockableUploadSingleScreenCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lockableUploadId: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockableUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockableUploadCondition>;
  filter?: InputMaybe<LockableUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMentionSuggestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter?: InputMaybe<MentionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  preferringRouteIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMentionsForAliasesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  aliases: Array<InputMaybe<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<MatchedMentionAliasFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageImpactArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageImpactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageImpactCondition>;
  filter?: InputMaybe<MessageImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkImpactArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkImpactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkImpactCondition>;
  filter?: InputMaybe<PlannedWorkImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkImpactsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkScreenMessageArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkScreenMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorkScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkScreenMessageCondition>;
  filter?: InputMaybe<PlannedWorkScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkScreenMessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkCondition>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRouteArgs = {
  gtfsId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRouteByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryRoutesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RouteCondition>;
  filter?: InputMaybe<RouteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenCountsForEntitySelectorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  ess: Array<InputMaybe<GtfsEntitySelectorInput>>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<ScreenCountsForEntitySelectorsTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenDetailsArgs = {
  screenName?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLayoutCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  excludedTargetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isTargetingAllLirrTrains?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lines?: InputMaybe<Array<InputMaybe<MnrRollingStockLine>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shops?: InputMaybe<Array<InputMaybe<Shop>>>;
  specificTargetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  stopIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLayoutFallbackCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutFallbackCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLayoutTypeCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLayoutTypeCountsV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLockArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLockByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenLocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenLockCondition>;
  filter?: InputMaybe<ScreenLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenLocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenMessageArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenMessageCondition>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenMessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenNoteByNameArgs = {
  name: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenNoteCondition>;
  filter?: InputMaybe<ScreenNoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreens2Args = {
  id: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreens2ByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreens2sArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<Screens2Condition>;
  filter?: InputMaybe<Screens2Filter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreensNormalizedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreensNormalizedCondition>;
  filter?: InputMaybe<ScreensNormalizedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreensNormalizedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchCampaignsArgs = {
  input?: InputMaybe<SearchCampaignsInputRecordInput>;
  orderBy?: InputMaybe<SearchCampaignsOrderBy>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchClearedMessagesArgs = {
  input?: InputMaybe<SearchClearedMessagesInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchLockableUploadsArgs = {
  input?: InputMaybe<SearchLockableUploadsInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchPlannedWorksArgs = {
  input?: InputMaybe<SearchPlannedWorksInputRecordInput>;
  orderBy?: InputMaybe<SearchPlannedWorksOrderBy>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchSocialMediaUploadsArgs = {
  input?: InputMaybe<SearchSocialMediaUploadsInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryServicePlanNumbersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ServicePlanNumberCondition>;
  filter?: InputMaybe<ServicePlanNumberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ServicePlanNumbersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaFolderArgs = {
  id: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaFolderByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaFoldersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaFolderCondition>;
  filter?: InputMaybe<SocialMediaFolderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaUploadArgs = {
  uploadId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySocialMediaUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaUploadCondition>;
  filter?: InputMaybe<SocialMediaUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySortedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  sortAsc?: InputMaybe<Scalars['Boolean']['input']>;
  sortByFeed?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStopArgs = {
  gtfsId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStopByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStopOptionsForSelectorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  filter?: InputMaybe<GroupedStopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStopSequencesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopSequenceCondition>;
  filter?: InputMaybe<StopSequenceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopSequencesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStopSyntheticScreensCountArgs = {
  gtfsId?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStopTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopTimeCondition>;
  filter?: InputMaybe<StopTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopCondition>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySyntheticScreenArgs = {
  id: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySyntheticScreenByNameArgs = {
  name: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySyntheticScreenByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySyntheticScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SyntheticScreenCondition>;
  filter?: InputMaybe<SyntheticScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SyntheticScreensOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySyntheticScreensLayoutTypeCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTargetTypeToTransitCriteriaArgs = {
  targetType: TargetType;
};

/** The root query type which gives access points into the data universe. */
export type QueryTargetedStaticOnlyScreensArgs = {
  selectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTransitSchedulesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransitScheduleCondition>;
  filter?: InputMaybe<TransitScheduleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransitSchedulesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTripArgs = {
  gtfsId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTripByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTripsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripCondition>;
  filter?: InputMaybe<TripFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTripsLirrArgs = {
  gtfsTripId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTripsLirrByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTripsLirrByTrainNumArgs = {
  trainNum: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTripsLirrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripsLirrCondition>;
  filter?: InputMaybe<TripsLirrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetArgs = {
  id: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetCondition>;
  filter?: InputMaybe<TweetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetsUploadArgs = {
  tweetId: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetsUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTweetsUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetsUploadCondition>;
  filter?: InputMaybe<TweetsUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUploadArgs = {
  id: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UploadCondition>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UploadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserFeedGroupGrantArgs = {
  feedGroupName: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserFeedGroupGrantByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserFeedGroupGrantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserFeedGroupGrantCondition>;
  filter?: InputMaybe<UserFeedGroupGrantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersAccessArgs = {
  userId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersAccessByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UsersAccessCondition>;
  filter?: InputMaybe<UsersAccessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

/** All input for the `reactivateMessage` mutation. */
export type ReactivateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['Int']['input'];
};

/** The output of our `reactivateMessage` mutation. */
export type ReactivateMessagePayload = {
  __typename?: 'ReactivateMessagePayload';
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
};

/** The output of our `reactivateMessage` mutation. */
export type ReactivateMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `reactivatePlannedWork` mutation. */
export type ReactivatePlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWorkId: Scalars['Int']['input'];
};

/** The output of our `reactivatePlannedWork` mutation. */
export type ReactivatePlannedWorkPayload = {
  __typename?: 'ReactivatePlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `reactivatePlannedWork` mutation. */
export type ReactivatePlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `reclearCampaignScreens` mutation. */
export type ReclearCampaignScreensInput = {
  campaignId: Scalars['Int']['input'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `reclearCampaignScreens` mutation. */
export type ReclearCampaignScreensPayload = {
  __typename?: 'ReclearCampaignScreensPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our `reclearCampaignScreens` mutation. */
export type ReclearCampaignScreensPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `reclearMessageScreens` mutation. */
export type ReclearMessageScreensInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['Int']['input'];
};

/** The output of our `reclearMessageScreens` mutation. */
export type ReclearMessageScreensPayload = {
  __typename?: 'ReclearMessageScreensPayload';
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
};

/** The output of our `reclearMessageScreens` mutation. */
export type ReclearMessageScreensPayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `reclearPlannedWorkScreens` mutation. */
export type ReclearPlannedWorkScreensInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWorkId: Scalars['Int']['input'];
};

/** The output of our `reclearPlannedWorkScreens` mutation. */
export type ReclearPlannedWorkScreensPayload = {
  __typename?: 'ReclearPlannedWorkScreensPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `reclearPlannedWorkScreens` mutation. */
export type ReclearPlannedWorkScreensPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `removePlannedWork` mutation. */
export type RemovePlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  plannedWorkId: Scalars['Int']['input'];
};

/** The output of our `removePlannedWork` mutation. */
export type RemovePlannedWorkPayload = {
  __typename?: 'RemovePlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `removePlannedWork` mutation. */
export type RemovePlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `renewMessagesTimestamps` mutation. */
export type RenewMessagesTimestampsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

/** The output of our `renewMessagesTimestamps` mutation. */
export type RenewMessagesTimestampsPayload = {
  __typename?: 'RenewMessagesTimestampsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<Message>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `retryEmailSms` mutation. */
export type RetryEmailSmsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `retryEmailSms` mutation. */
export type RetryEmailSmsPayload = {
  __typename?: 'RetryEmailSmsPayload';
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
};

/** The output of our `retryEmailSms` mutation. */
export type RetryEmailSmsPayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `retryTweet` mutation. */
export type RetryTweetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tweetId?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `retryTweet` mutation. */
export type RetryTweetPayload = {
  __typename?: 'RetryTweetPayload';
  /** Reads a single `User` that is related to this `Tweet`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Message` that is related to this `Tweet`. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  tweet?: Maybe<Tweet>;
  /** An edge for our `Tweet`. May be used by Relay 1. */
  tweetEdge?: Maybe<TweetsEdge>;
};

/** The output of our `retryTweet` mutation. */
export type RetryTweetPayloadTweetEdgeArgs = {
  orderBy?: InputMaybe<Array<TweetsOrderBy>>;
};

export type Route = Node & {
  __typename?: 'Route';
  /** Reads a single `Agency` that is related to this `Route`. */
  agency?: Maybe<Agency>;
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Route`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  gtfsAgencyId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `OrderedStop`. */
  orderedStopsByDirection: OrderedStopsConnection;
  shortName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Stop`. */
  stops: StopsConnection;
  textColor?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Trip`. */
  trips: TripsConnection;
  /** Reads and enables pagination through a set of `TripsLirr`. */
  tripsLirrsByGtfsRouteId: TripsLirrsConnection;
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type RouteOrderedStopsByDirectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  direction?: InputMaybe<Direction>;
  filter?: InputMaybe<OrderedStopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteTripsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripCondition>;
  filter?: InputMaybe<TripFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

export type RouteTripsLirrsByGtfsRouteIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripsLirrCondition>;
  filter?: InputMaybe<TripsLirrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/** A condition to be used against `Route` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RouteCondition = {
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsAgencyId` field. */
  gtfsAgencyId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `longName` field. */
  longName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `shortName` field. */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `textColor` field. */
  textColor?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Route` object types. All fields are combined with a logical ‘and.’ */
export type RouteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RouteFilter>>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsAgencyId` field. */
  gtfsAgencyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `longName` field. */
  longName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RouteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RouteFilter>>;
  /** Filter by the object’s `shortName` field. */
  shortName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `textColor` field. */
  textColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Route` */
export type RouteInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
  gtfsAgencyId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Represents an update to a `Route`. Fields that are set will be updated. */
export type RoutePatch = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  gtfsAgencyId?: InputMaybe<Scalars['String']['input']>;
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Route` values. */
export type RoutesConnection = {
  __typename?: 'RoutesConnection';
  /** A list of edges which contains the `Route` and cursor to aid in pagination. */
  edges: Array<RoutesEdge>;
  /** A list of `Route` objects. */
  nodes: Array<Route>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Route` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Route` edge in the connection. */
export type RoutesEdge = {
  __typename?: 'RoutesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Route` at the end of the edge. */
  node: Route;
};

/** Methods to use when ordering `Route`. */
export enum RoutesOrderBy {
  COLOR_ASC = 'COLOR_ASC',
  COLOR_DESC = 'COLOR_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_AGENCY_ID_ASC = 'GTFS_AGENCY_ID_ASC',
  GTFS_AGENCY_ID_DESC = 'GTFS_AGENCY_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  LONG_NAME_ASC = 'LONG_NAME_ASC',
  LONG_NAME_DESC = 'LONG_NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  TEXT_COLOR_ASC = 'TEXT_COLOR_ASC',
  TEXT_COLOR_DESC = 'TEXT_COLOR_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  URL_ASC = 'URL_ASC',
  URL_DESC = 'URL_DESC',
}

export enum SchedulePriority {
  EMERGENCY = 'EMERGENCY',
  FALLBACK = 'FALLBACK',
  NORMAL = 'NORMAL',
  TAKEOVER = 'TAKEOVER',
}

/** A filter to be used against SchedulePriority fields. All fields are combined with a logical ‘and.’ */
export type SchedulePriorityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SchedulePriority>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SchedulePriority>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SchedulePriority>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SchedulePriority>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SchedulePriority>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SchedulePriority>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SchedulePriority>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SchedulePriority>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SchedulePriority>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SchedulePriority>>;
};

export enum ScheduleRelationship {
  ADDED = 'ADDED',
  CANCELED = 'CANCELED',
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
}

export type ScreenCountsForEntitySelectorsType = {
  __typename?: 'ScreenCountsForEntitySelectorsType';
  amount: Scalars['Int']['output'];
  targetType: TargetType;
};

/** A filter to be used against `ScreenCountsForEntitySelectorsType` object types. All fields are combined with a logical ‘and.’ */
export type ScreenCountsForEntitySelectorsTypeFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenCountsForEntitySelectorsTypeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenCountsForEntitySelectorsTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenCountsForEntitySelectorsTypeFilter>>;
  /** Filter by the object’s `targetType` field. */
  targetType?: InputMaybe<TargetTypeFilter>;
};

/** A connection to a list of `ScreenCountsForEntitySelectorsType` values. */
export type ScreenCountsForEntitySelectorsTypesConnection = {
  __typename?: 'ScreenCountsForEntitySelectorsTypesConnection';
  /** A list of edges which contains the `ScreenCountsForEntitySelectorsType` and cursor to aid in pagination. */
  edges: Array<ScreenCountsForEntitySelectorsTypesEdge>;
  /** A list of `ScreenCountsForEntitySelectorsType` objects. */
  nodes: Array<ScreenCountsForEntitySelectorsType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenCountsForEntitySelectorsType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenCountsForEntitySelectorsType` edge in the connection. */
export type ScreenCountsForEntitySelectorsTypesEdge = {
  __typename?: 'ScreenCountsForEntitySelectorsTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenCountsForEntitySelectorsType` at the end of the edge. */
  node: ScreenCountsForEntitySelectorsType;
};

export enum ScreenDeployTypes {
  OUTFRONT = 'OUTFRONT',
  SYNTHETIC = 'SYNTHETIC',
}

/** A filter to be used against ScreenDeployTypes fields. All fields are combined with a logical ‘and.’ */
export type ScreenDeployTypesFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ScreenDeployTypes>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ScreenDeployTypes>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ScreenDeployTypes>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ScreenDeployTypes>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ScreenDeployTypes>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ScreenDeployTypes>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ScreenDeployTypes>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ScreenDeployTypes>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ScreenDeployTypes>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ScreenDeployTypes>>;
};

export type ScreenLayoutCount = {
  __typename?: 'ScreenLayoutCount';
  lockedCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<TargetType>;
};

/** A filter to be used against `ScreenLayoutCount` object types. All fields are combined with a logical ‘and.’ */
export type ScreenLayoutCountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenLayoutCountFilter>>;
  /** Filter by the object’s `lockedCount` field. */
  lockedCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenLayoutCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenLayoutCountFilter>>;
  /** Filter by the object’s `totalCount` field. */
  totalCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<TargetTypeFilter>;
};

/** A connection to a list of `ScreenLayoutCount` values. */
export type ScreenLayoutCountsConnection = {
  __typename?: 'ScreenLayoutCountsConnection';
  /** A list of edges which contains the `ScreenLayoutCount` and cursor to aid in pagination. */
  edges: Array<ScreenLayoutCountsEdge>;
  /** A list of `ScreenLayoutCount` objects. */
  nodes: Array<ScreenLayoutCount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenLayoutCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenLayoutCount` edge in the connection. */
export type ScreenLayoutCountsEdge = {
  __typename?: 'ScreenLayoutCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenLayoutCount` at the end of the edge. */
  node: ScreenLayoutCount;
};

export type ScreenLayoutFallbackCount = {
  __typename?: 'ScreenLayoutFallbackCount';
  direction?: Maybe<Direction>;
  lockedCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<TargetType>;
  upload?: Maybe<Scalars['JSON']['output']>;
};

/** A filter to be used against `ScreenLayoutFallbackCount` object types. All fields are combined with a logical ‘and.’ */
export type ScreenLayoutFallbackCountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenLayoutFallbackCountFilter>>;
  /** Filter by the object’s `direction` field. */
  direction?: InputMaybe<DirectionFilter>;
  /** Filter by the object’s `lockedCount` field. */
  lockedCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenLayoutFallbackCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenLayoutFallbackCountFilter>>;
  /** Filter by the object’s `totalCount` field. */
  totalCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<TargetTypeFilter>;
  /** Filter by the object’s `upload` field. */
  upload?: InputMaybe<JsonFilter>;
};

/** A connection to a list of `ScreenLayoutFallbackCount` values. */
export type ScreenLayoutFallbackCountsConnection = {
  __typename?: 'ScreenLayoutFallbackCountsConnection';
  /** A list of edges which contains the `ScreenLayoutFallbackCount` and cursor to aid in pagination. */
  edges: Array<ScreenLayoutFallbackCountsEdge>;
  /** A list of `ScreenLayoutFallbackCount` objects. */
  nodes: Array<ScreenLayoutFallbackCount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenLayoutFallbackCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenLayoutFallbackCount` edge in the connection. */
export type ScreenLayoutFallbackCountsEdge = {
  __typename?: 'ScreenLayoutFallbackCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenLayoutFallbackCount` at the end of the edge. */
  node: ScreenLayoutFallbackCount;
};

export type ScreenLayoutType = {
  __typename?: 'ScreenLayoutType';
  lockedCount?: Maybe<Scalars['Int']['output']>;
  orientation?: Maybe<ScreenOrientation>;
  setCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ScreenType>;
};

/** A filter to be used against `ScreenLayoutType` object types. All fields are combined with a logical ‘and.’ */
export type ScreenLayoutTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenLayoutTypeFilter>>;
  /** Filter by the object’s `lockedCount` field. */
  lockedCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenLayoutTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenLayoutTypeFilter>>;
  /** Filter by the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientationFilter>;
  /** Filter by the object’s `setCount` field. */
  setCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalCount` field. */
  totalCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<ScreenTypeFilter>;
};

/** A connection to a list of `ScreenLayoutType` values. */
export type ScreenLayoutTypesConnection = {
  __typename?: 'ScreenLayoutTypesConnection';
  /** A list of edges which contains the `ScreenLayoutType` and cursor to aid in pagination. */
  edges: Array<ScreenLayoutTypesEdge>;
  /** A list of `ScreenLayoutType` objects. */
  nodes: Array<ScreenLayoutType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenLayoutType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenLayoutType` edge in the connection. */
export type ScreenLayoutTypesEdge = {
  __typename?: 'ScreenLayoutTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenLayoutType` at the end of the edge. */
  node: ScreenLayoutType;
};

/**
 *     A screen lock schedules the referenced content and only the referenced
 *     content to the screen indefinitely. Creating a row in this table triggers
 *     the publish process. When `cleared_at` is set, the content will be removed
 *     from the screen and the record is considered archived.
 *
 */
export type ScreenLock = Node & {
  __typename?: 'ScreenLock';
  /**
   *     Setting to a value triggers job flow to remove content from screen and return
   *     to normal content scheduling.
   *
   */
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /**
   *     Timestamp of when content was first sent to Outfront to be shown on screen.
   *     If this is NULL the content has not yet made it to Outfront.
   *
   */
  publishedAt?: Maybe<Scalars['Datetime']['output']>;
  screen: ScreensNormalized;
  screenName: Scalars['String']['output'];
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `Upload` that is related to this `ScreenLock`. */
  upload?: Maybe<Upload>;
  /**
   *     Upload referencing content to be locked to the screen.
   *
   */
  uploadId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `ScreenLock` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScreenLockCondition = {
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `screenName` field. */
  screenName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `ScreenLock` object types. All fields are combined with a logical ‘and.’ */
export type ScreenLockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenLockFilter>>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenLockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenLockFilter>>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `screenName` field. */
  screenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ScreenLockInputRecord` */
export type ScreenLockInputRecordInput = {
  screenName?: InputMaybe<Scalars['String']['input']>;
  uploadId: Scalars['UUID']['input'];
};

export type ScreenLockTarget = {
  __typename?: 'ScreenLockTarget';
  screen2?: Maybe<Screens2>;
  syntheticScreen?: Maybe<SyntheticScreen>;
};

/** A connection to a list of `ScreenLock` values. */
export type ScreenLocksConnection = {
  __typename?: 'ScreenLocksConnection';
  /** A list of edges which contains the `ScreenLock` and cursor to aid in pagination. */
  edges: Array<ScreenLocksEdge>;
  /** A list of `ScreenLock` objects. */
  nodes: Array<ScreenLock>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenLock` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenLock` edge in the connection. */
export type ScreenLocksEdge = {
  __typename?: 'ScreenLocksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenLock` at the end of the edge. */
  node: ScreenLock;
};

/** Methods to use when ordering `ScreenLock`. */
export enum ScreenLocksOrderBy {
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PUBLISHED_AT_ASC = 'PUBLISHED_AT_ASC',
  PUBLISHED_AT_DESC = 'PUBLISHED_AT_DESC',
  SCREEN_NAME_ASC = 'SCREEN_NAME_ASC',
  SCREEN_NAME_DESC = 'SCREEN_NAME_DESC',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

/** Message notifications sent to Outfront screens. */
export type ScreenMessage = Node & {
  __typename?: 'ScreenMessage';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  additionalInfoHtml?: Maybe<Scalars['String']['output']>;
  /** Reads a single `User` that is related to this `ScreenMessage`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  body: Scalars['String']['output'];
  bodyHtml: Scalars['String']['output'];
  clearedAt?: Maybe<Scalars['Datetime']['output']>;
  endAt?: Maybe<Scalars['Datetime']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  id: Scalars['Int']['output'];
  isClearing: Scalars['Boolean']['output'];
  isDup?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Message` that is related to this `ScreenMessage`. */
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  priority: SchedulePriority;
  publishAt?: Maybe<Scalars['Datetime']['output']>;
  targetTypes?: Maybe<Array<Maybe<TargetType>>>;
  targeting: Array<ScreenSelector>;
  title?: Maybe<Scalars['String']['output']>;
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads a single `Upload` that is related to this `ScreenMessage`. */
  upload?: Maybe<Upload>;
  uploadId?: Maybe<Scalars['UUID']['output']>;
  weight: Scalars['Int']['output'];
};

/**
 * A condition to be used against `ScreenMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScreenMessageCondition = {
  /** Checks for equality with the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `additionalInfoHtml` field. */
  additionalInfoHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriority>;
  /** Checks for equality with the object’s `publishAt` field. */
  publishAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `targeting` field. */
  targeting?: InputMaybe<Array<InputMaybe<ScreenSelectorInput>>>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `ScreenMessage` object types. All fields are combined with a logical ‘and.’ */
export type ScreenMessageFilter = {
  /** Filter by the object’s `additionalInfo` field. */
  additionalInfo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `additionalInfoHtml` field. */
  additionalInfoHtml?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenMessageFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bodyHtml` field. */
  bodyHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isClearing` field. */
  isClearing?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDup` field. */
  isDup?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenMessageFilter>>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriorityFilter>;
  /** Filter by the object’s `publishAt` field. */
  publishAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetTypes` field. */
  targetTypes?: InputMaybe<TargetTypeListFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ScreenMessagePatchRecord` */
export type ScreenMessagePatchRecordInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  additionalInfoHtml?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  bodyHtml?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['Datetime']['input']>;
  priority?: InputMaybe<SchedulePriority>;
  targeting?: InputMaybe<Array<InputMaybe<ScreenSelectorInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `ScreenMessage` values. */
export type ScreenMessagesConnection = {
  __typename?: 'ScreenMessagesConnection';
  /** A list of edges which contains the `ScreenMessage` and cursor to aid in pagination. */
  edges: Array<ScreenMessagesEdge>;
  /** A list of `ScreenMessage` objects. */
  nodes: Array<ScreenMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenMessage` edge in the connection. */
export type ScreenMessagesEdge = {
  __typename?: 'ScreenMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenMessage` at the end of the edge. */
  node: ScreenMessage;
};

/** Methods to use when ordering `ScreenMessage`. */
export enum ScreenMessagesOrderBy {
  ADDITIONAL_INFO_ASC = 'ADDITIONAL_INFO_ASC',
  ADDITIONAL_INFO_DESC = 'ADDITIONAL_INFO_DESC',
  ADDITIONAL_INFO_HTML_ASC = 'ADDITIONAL_INFO_HTML_ASC',
  ADDITIONAL_INFO_HTML_DESC = 'ADDITIONAL_INFO_HTML_DESC',
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  BODY_HTML_ASC = 'BODY_HTML_ASC',
  BODY_HTML_DESC = 'BODY_HTML_DESC',
  CLEARED_AT_ASC = 'CLEARED_AT_ASC',
  CLEARED_AT_DESC = 'CLEARED_AT_DESC',
  END_AT_ASC = 'END_AT_ASC',
  END_AT_DESC = 'END_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  MESSAGE_ID_ASC = 'MESSAGE_ID_ASC',
  MESSAGE_ID_DESC = 'MESSAGE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  PUBLISH_AT_ASC = 'PUBLISH_AT_ASC',
  PUBLISH_AT_DESC = 'PUBLISH_AT_DESC',
  TARGETING_ASC = 'TARGETING_ASC',
  TARGETING_DESC = 'TARGETING_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export type ScreenNote = {
  __typename?: 'ScreenNote';
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `ScreenNote` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScreenNoteCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `ScreenNote` object types. All fields are combined with a logical ‘and.’ */
export type ScreenNoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenNoteFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenNoteFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenNoteFilter>>;
};

/** An input for mutations affecting `ScreenNote` */
export type ScreenNoteInput = {
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** Represents an update to a `ScreenNote`. Fields that are set will be updated. */
export type ScreenNotePatch = {
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `ScreenNote` values. */
export type ScreenNotesConnection = {
  __typename?: 'ScreenNotesConnection';
  /** A list of edges which contains the `ScreenNote` and cursor to aid in pagination. */
  edges: Array<ScreenNotesEdge>;
  /** A list of `ScreenNote` objects. */
  nodes: Array<ScreenNote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenNote` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreenNote` edge in the connection. */
export type ScreenNotesEdge = {
  __typename?: 'ScreenNotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreenNote` at the end of the edge. */
  node: ScreenNote;
};

/** Methods to use when ordering `ScreenNote`. */
export enum ScreenNotesOrderBy {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  NOTES_ASC = 'NOTES_ASC',
  NOTES_DESC = 'NOTES_DESC',
}

export enum ScreenOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

/** A filter to be used against ScreenOrientation fields. All fields are combined with a logical ‘and.’ */
export type ScreenOrientationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ScreenOrientation>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ScreenOrientation>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ScreenOrientation>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ScreenOrientation>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ScreenOrientation>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ScreenOrientation>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ScreenOrientation>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ScreenOrientation>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ScreenOrientation>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ScreenOrientation>>;
};

export type ScreenSelector = {
  __typename?: 'ScreenSelector';
  borough?: Maybe<Borough>;
  entitySelector: GtfsEntitySelector;
  layouts: Array<TargetType>;
  routeId: Scalars['String']['output'];
  tags?: Maybe<Scalars['JSON']['output']>;
};

/** An input for mutations affecting `ScreenSelector` */
export type ScreenSelectorInput = {
  borough?: InputMaybe<Borough>;
  entitySelector: GtfsEntitySelectorInput;
  layouts: Array<InputMaybe<TargetType>>;
  routeId: Scalars['String']['input'];
  tags?: InputMaybe<Scalars['JSON']['input']>;
};

export type ScreenTargeting = {
  __typename?: 'ScreenTargeting';
  tags?: Maybe<Scalars['JSON']['output']>;
  targetTypes?: Maybe<Array<Maybe<TargetType>>>;
};

/** An input for mutations affecting `ScreenTargeting` */
export type ScreenTargetingInput = {
  tags?: InputMaybe<Scalars['JSON']['input']>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
};

export enum ScreenType {
  AD = 'AD',
  ALL = 'ALL',
  CIC = 'CIC',
  COVE = 'COVE',
  DUP = 'DUP',
  FULTON = 'FULTON',
  INFO = 'INFO',
  MAP = 'MAP',
  MKT = 'MKT',
  OTHER_MARKETING = 'OTHER_MARKETING',
  PIO = 'PIO',
  SOLARI = 'SOLARI',
  SOLARI_MARKETING = 'SOLARI_MARKETING',
  SQUARE = 'SQUARE',
  STA = 'STA',
  _3SM = '_3SM',
}

/** A filter to be used against ScreenType fields. All fields are combined with a logical ‘and.’ */
export type ScreenTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ScreenType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ScreenType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ScreenType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ScreenType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ScreenType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ScreenType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ScreenType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ScreenType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ScreenType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ScreenType>>;
};

/** Screens imported from Outfront Transit API v2 */
export type Screens2 = Node & {
  __typename?: 'Screens2';
  actualName: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  alerts: ScreenMessagesConnection;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  campaignScreenContent: CampaignScreenContentsConnection;
  createdAt: Scalars['Datetime']['output'];
  criteria: Scalars['JSON']['output'];
  currentScreenLock?: Maybe<ScreenLock>;
  customCriteria?: Maybe<Scalars['JSON']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  feedId?: Maybe<Scalars['String']['output']>;
  gtfsStop?: Maybe<Stop>;
  hasPartnerRegion: Scalars['Boolean']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isLockable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orientation: ScreenOrientation;
  /** Reads and enables pagination through a set of `PlannedWork`. */
  plannedWorks: PlannedWorksConnection;
  /** Reads and enables pagination through a set of `Screens2`. */
  siblings: Screens2sConnection;
  source: Scalars['String']['output'];
  staticOnly?: Maybe<Scalars['Boolean']['output']>;
  stopEntitySelector?: Maybe<GtfsEntitySelector>;
  stopId?: Maybe<Scalars['String']['output']>;
  synchronizedAt: Scalars['Datetime']['output'];
  targetType?: Maybe<TargetType>;
  updatedAt: Scalars['Datetime']['output'];
  width: Scalars['Int']['output'];
};

/** Screens imported from Outfront Transit API v2 */
export type Screens2AlertsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Screens imported from Outfront Transit API v2 */
export type Screens2CampaignScreenContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

/** Screens imported from Outfront Transit API v2 */
export type Screens2PlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

/** Screens imported from Outfront Transit API v2 */
export type Screens2SiblingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<Screens2Filter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A condition to be used against `Screens2` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type Screens2Condition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `criteria` field. */
  criteria?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `customCriteria` field. */
  customCriteria?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `hasPartnerRegion` field. */
  hasPartnerRegion?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `synchronizedAt` field. */
  synchronizedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Screens2` object types. All fields are combined with a logical ‘and.’ */
export type Screens2Filter = {
  /** Filter by the object’s `actualName` field. */
  actualName?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Screens2Filter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `criteria` field. */
  criteria?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `customCriteria` field. */
  customCriteria?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasPartnerRegion` field. */
  hasPartnerRegion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isLockable` field. */
  isLockable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Screens2Filter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Screens2Filter>>;
  /** Filter by the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientationFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `staticOnly` field. */
  staticOnly?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `stopId` field. */
  stopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `synchronizedAt` field. */
  synchronizedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetType` field. */
  targetType?: InputMaybe<TargetTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Screens2` */
export type Screens2Input = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  criteria?: InputMaybe<Scalars['JSON']['input']>;
  customCriteria?: InputMaybe<Scalars['JSON']['input']>;
  hasPartnerRegion: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: Scalars['String']['input'];
  synchronizedAt: Scalars['Datetime']['input'];
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `Screens2`. Fields that are set will be updated. */
export type Screens2Patch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  criteria?: InputMaybe<Scalars['JSON']['input']>;
  customCriteria?: InputMaybe<Scalars['JSON']['input']>;
  hasPartnerRegion?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  synchronizedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Screens2` values. */
export type Screens2sConnection = {
  __typename?: 'Screens2sConnection';
  /** A list of edges which contains the `Screens2` and cursor to aid in pagination. */
  edges: Array<Screens2sEdge>;
  /** A list of `Screens2` objects. */
  nodes: Array<Screens2>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Screens2` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Screens2` edge in the connection. */
export type Screens2sEdge = {
  __typename?: 'Screens2sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Screens2` at the end of the edge. */
  node: Screens2;
};

/** Methods to use when ordering `Screens2`. */
export enum Screens2sOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CRITERIA_ASC = 'CRITERIA_ASC',
  CRITERIA_DESC = 'CRITERIA_DESC',
  CUSTOM_CRITERIA_ASC = 'CUSTOM_CRITERIA_ASC',
  CUSTOM_CRITERIA_DESC = 'CUSTOM_CRITERIA_DESC',
  HAS_PARTNER_REGION_ASC = 'HAS_PARTNER_REGION_ASC',
  HAS_PARTNER_REGION_DESC = 'HAS_PARTNER_REGION_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SOURCE_ASC = 'SOURCE_ASC',
  SOURCE_DESC = 'SOURCE_DESC',
  SYNCHRONIZED_AT_ASC = 'SYNCHRONIZED_AT_ASC',
  SYNCHRONIZED_AT_DESC = 'SYNCHRONIZED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type ScreensNormalized = {
  __typename?: 'ScreensNormalized';
  actualName?: Maybe<Scalars['String']['output']>;
  alertRelevantCount?: Maybe<Scalars['Int']['output']>;
  campaignRelevantCount?: Maybe<Scalars['Int']['output']>;
  deployType?: Maybe<ScreenDeployTypes>;
  entitySelectors?: Maybe<Array<GtfsEntitySelector>>;
  feedId?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orientation?: Maybe<ScreenOrientation>;
  plannedWorkRelevantCount?: Maybe<Scalars['Int']['output']>;
  setIndex?: Maybe<Scalars['Int']['output']>;
  setKey?: Maybe<Scalars['String']['output']>;
  setLength?: Maybe<Scalars['Int']['output']>;
  stopId?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<TargetType>;
  type?: Maybe<ScreenType>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ScreensNormalizedCampaignRelevantCountArgs = {
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

export type ScreensNormalizedPlannedWorkRelevantCountArgs = {
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

/**
 * A condition to be used against `ScreensNormalized` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScreensNormalizedCondition = {
  /** Checks for equality with the object’s `deployType` field. */
  deployType?: InputMaybe<ScreenDeployTypes>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientation>;
  /** Checks for equality with the object’s `setIndex` field. */
  setIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `setKey` field. */
  setKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `setLength` field. */
  setLength?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `stopId` field. */
  stopId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetType` field. */
  targetType?: InputMaybe<TargetType>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ScreenType>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `ScreensNormalized` object types. All fields are combined with a logical ‘and.’ */
export type ScreensNormalizedFilter = {
  /** Filter by the object’s `actualName` field. */
  actualName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `alertRelevantCount` field. */
  alertRelevantCount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreensNormalizedFilter>>;
  /** Filter by the object’s `deployType` field. */
  deployType?: InputMaybe<ScreenDeployTypesFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreensNormalizedFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreensNormalizedFilter>>;
  /** Filter by the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientationFilter>;
  /** Filter by the object’s `setIndex` field. */
  setIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `setKey` field. */
  setKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `setLength` field. */
  setLength?: InputMaybe<IntFilter>;
  /** Filter by the object’s `stopId` field. */
  stopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetType` field. */
  targetType?: InputMaybe<TargetTypeFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<ScreenTypeFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

/** A connection to a list of `ScreensNormalized` values. */
export type ScreensNormalizedsConnection = {
  __typename?: 'ScreensNormalizedsConnection';
  /** A list of edges which contains the `ScreensNormalized` and cursor to aid in pagination. */
  edges: Array<ScreensNormalizedsEdge>;
  /** A list of `ScreensNormalized` objects. */
  nodes: Array<ScreensNormalized>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreensNormalized` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ScreensNormalized` edge in the connection. */
export type ScreensNormalizedsEdge = {
  __typename?: 'ScreensNormalizedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ScreensNormalized` at the end of the edge. */
  node: ScreensNormalized;
};

/** Methods to use when ordering `ScreensNormalized`. */
export enum ScreensNormalizedsOrderBy {
  ALERT_RELEVANT_COUNT_ASC = 'ALERT_RELEVANT_COUNT_ASC',
  ALERT_RELEVANT_COUNT_DESC = 'ALERT_RELEVANT_COUNT_DESC',
  DEPLOY_TYPE_ASC = 'DEPLOY_TYPE_ASC',
  DEPLOY_TYPE_DESC = 'DEPLOY_TYPE_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  HEIGHT_ASC = 'HEIGHT_ASC',
  HEIGHT_DESC = 'HEIGHT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  NOTES_ASC = 'NOTES_ASC',
  NOTES_DESC = 'NOTES_DESC',
  ORIENTATION_ASC = 'ORIENTATION_ASC',
  ORIENTATION_DESC = 'ORIENTATION_DESC',
  SET_INDEX_ASC = 'SET_INDEX_ASC',
  SET_INDEX_DESC = 'SET_INDEX_DESC',
  SET_KEY_ASC = 'SET_KEY_ASC',
  SET_KEY_DESC = 'SET_KEY_DESC',
  SET_LENGTH_ASC = 'SET_LENGTH_ASC',
  SET_LENGTH_DESC = 'SET_LENGTH_DESC',
  STOP_ID_ASC = 'STOP_ID_ASC',
  STOP_ID_DESC = 'STOP_ID_DESC',
  TARGET_TYPE_ASC = 'TARGET_TYPE_ASC',
  TARGET_TYPE_DESC = 'TARGET_TYPE_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  WIDTH_ASC = 'WIDTH_ASC',
  WIDTH_DESC = 'WIDTH_DESC',
}

/** An input for mutations affecting `SearchCampaignsInputRecord` */
export type SearchCampaignsInputRecordInput = {
  /** Equality comparison for campaigns.feed_id */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Full text search of campaign title and specifically targetted station names. Uses websearch_to_tsquery so double quotes, "and", and "or" have special meaning. https://bit.ly/35WjmxJ */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Containment comparison of route ids. Uses indexed lmm_search table which can drift. Only returns campaigns targetting ALL passed route ids. */
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equality comparison of calculated campaigns status. Performance various per-status value. */
  status?: InputMaybe<Array<InputMaybe<CampaignStatus>>>;
  /** Overlap comparison of campaigns.target_types. Pass NULL instead of an empty array for faster results ignoring target_types. */
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  type?: InputMaybe<CampaignType>;
};

export enum SearchCampaignsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  END_ASC = 'END_ASC',
  END_DESC = 'END_DESC',
  START_ASC = 'START_ASC',
  START_DESC = 'START_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
}

export type SearchCampaignsResult = {
  __typename?: 'SearchCampaignsResult';
  results: Array<Campaign>;
  totalCount: Scalars['BigInt']['output'];
};

/** An input for mutations affecting `SearchClearedMessagesInputRecord` */
export type SearchClearedMessagesInputRecordInput = {
  allRoutes?: InputMaybe<Scalars['Boolean']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<MessageType>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchStr?: InputMaybe<Scalars['String']['input']>;
};

export type SearchClearedMessagesResult = {
  __typename?: 'SearchClearedMessagesResult';
  results?: Maybe<Array<SearchedClearedMessage>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An input for mutations affecting `SearchLockableUploadsInputRecord` */
export type SearchLockableUploadsInputRecordInput = {
  dimension?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  matches?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
};

export type SearchLockableUploadsResult = {
  __typename?: 'SearchLockableUploadsResult';
  results?: Maybe<Array<Maybe<LockableUpload>>>;
  totalCount?: Maybe<Scalars['BigInt']['output']>;
};

/** An input for mutations affecting `SearchPaginationInputRecord` */
export type SearchPaginationInputRecordInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An input for mutations affecting `SearchPlannedWorksInputRecord` */
export type SearchPlannedWorksInputRecordInput = {
  allRoutes?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['Datetime']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filteredStatus?: InputMaybe<Array<InputMaybe<PlannedWorkStatus>>>;
  messageType?: InputMaybe<MessageType>;
  onHomepage?: InputMaybe<Scalars['Boolean']['input']>;
  onScreens?: InputMaybe<Scalars['Boolean']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchStr?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Datetime']['input']>;
  status?: InputMaybe<PlannedWorkStatus>;
};

export enum SearchPlannedWorksOrderBy {
  END_ASC = 'END_ASC',
  END_DESC = 'END_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  LAST_MODIFIED_AT_ASC = 'LAST_MODIFIED_AT_ASC',
  LAST_MODIFIED_AT_DESC = 'LAST_MODIFIED_AT_DESC',
  MESSAGE_TYPE_ASC = 'MESSAGE_TYPE_ASC',
  MESSAGE_TYPE_DESC = 'MESSAGE_TYPE_DESC',
  START_ASC = 'START_ASC',
  START_DESC = 'START_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
}

export type SearchPlannedWorksResult = {
  __typename?: 'SearchPlannedWorksResult';
  results: Array<DashboardPlannedWork>;
  totalCount?: Maybe<Scalars['BigInt']['output']>;
};

/** An input for mutations affecting `SearchSocialMediaUploadsInputRecord` */
export type SearchSocialMediaUploadsInputRecordInput = {
  dimensionsRatio?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  matches?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
};

export type SearchSocialMediaUploadsResult = {
  __typename?: 'SearchSocialMediaUploadsResult';
  results: Array<Upload>;
  totalCount?: Maybe<Scalars['BigInt']['output']>;
};

export type SearchedClearedMessage = {
  __typename?: 'SearchedClearedMessage';
  /** Reads a single `User` that is related to this `SearchedClearedMessage`. */
  author: User;
  authorId?: Maybe<Scalars['UUID']['output']>;
  body?: Maybe<Scalars['JSON']['output']>;
  clearedBy?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `User` that is related to this `SearchedClearedMessage`. */
  clearedByUser?: Maybe<User>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  duration?: Maybe<Interval>;
  emailSmsError?: Maybe<Scalars['JSON']['output']>;
  emailSmsPostedAt?: Maybe<Scalars['Datetime']['output']>;
  emailSmsStatus: PublishStatus;
  endAt?: Maybe<Scalars['Datetime']['output']>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  eventId?: Maybe<Scalars['Int']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  impacts?: Maybe<Array<Maybe<MessageImpact>>>;
  incidentEndAt?: Maybe<Scalars['Datetime']['output']>;
  incidentStartAt?: Maybe<Scalars['Datetime']['output']>;
  messageText?: Maybe<Scalars['String']['output']>;
  neededBy?: Maybe<Scalars['Datetime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifiedAt?: Maybe<Scalars['Datetime']['output']>;
  numEmailsSmsSent?: Maybe<Scalars['Int']['output']>;
  screensStatus: PublishStatus;
  startAt?: Maybe<Scalars['Datetime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  taggedTrips?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalCount?: Maybe<Scalars['BigInt']['output']>;
  tweetsStatus: PublishStatus;
  webStatus: PublishStatus;
};

export type ServicePlanNumber = {
  __typename?: 'ServicePlanNumber';
  servicePlanNumber: Scalars['String']['output'];
};

/**
 * A condition to be used against `ServicePlanNumber` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ServicePlanNumberCondition = {
  /** Checks for equality with the object’s `servicePlanNumber` field. */
  servicePlanNumber?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `ServicePlanNumber` object types. All fields are combined with a logical ‘and.’ */
export type ServicePlanNumberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ServicePlanNumberFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ServicePlanNumberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ServicePlanNumberFilter>>;
  /** Filter by the object’s `servicePlanNumber` field. */
  servicePlanNumber?: InputMaybe<StringFilter>;
};

/** A connection to a list of `ServicePlanNumber` values. */
export type ServicePlanNumbersConnection = {
  __typename?: 'ServicePlanNumbersConnection';
  /** A list of edges which contains the `ServicePlanNumber` and cursor to aid in pagination. */
  edges: Array<ServicePlanNumbersEdge>;
  /** A list of `ServicePlanNumber` objects. */
  nodes: Array<ServicePlanNumber>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ServicePlanNumber` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ServicePlanNumber` edge in the connection. */
export type ServicePlanNumbersEdge = {
  __typename?: 'ServicePlanNumbersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ServicePlanNumber` at the end of the edge. */
  node: ServicePlanNumber;
};

/** Methods to use when ordering `ServicePlanNumber`. */
export enum ServicePlanNumbersOrderBy {
  NATURAL = 'NATURAL',
  SERVICE_PLAN_NUMBER_ASC = 'SERVICE_PLAN_NUMBER_ASC',
  SERVICE_PLAN_NUMBER_DESC = 'SERVICE_PLAN_NUMBER_DESC',
}

/** All input for the `setPlannedWorkDirectDurations` mutation. */
export type SetPlannedWorkDirectDurationsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durations: Array<InputMaybe<DatetimeRangeInput>>;
  id: Scalars['Int']['input'];
};

/** The output of our `setPlannedWorkDirectDurations` mutation. */
export type SetPlannedWorkDirectDurationsPayload = {
  __typename?: 'SetPlannedWorkDirectDurationsPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `setPlannedWorkDirectDurations` mutation. */
export type SetPlannedWorkDirectDurationsPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** Is a list of NYCT Subway shops, also known as yards, to be used for specific rolling stock screen targeting. */
export enum Shop {
  CONCOURSE = 'CONCOURSE',
  CONEY_ISLAND = 'CONEY_ISLAND',
  CORONA = 'CORONA',
  EAST_NEW_YORK = 'EAST_NEW_YORK',
  E_180_ST = 'E_180_ST',
  JAMAICA = 'JAMAICA',
  JEROME = 'JEROME',
  LIVONIA = 'LIVONIA',
  PELHAM = 'PELHAM',
  PITKIN = 'PITKIN',
  _207_ST = '_207_ST',
  _239_ST = '_239_ST',
  _240_ST = '_240_ST',
}

export type SocialMediaFolder = Node & {
  __typename?: 'SocialMediaFolder';
  createdAt: Scalars['Datetime']['output'];
  feedId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `SocialMediaUpload`. */
  socialMediaUploadsByFolderId: SocialMediaUploadsConnection;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `SocialMediaFolder`. */
  updater?: Maybe<User>;
  updaterId?: Maybe<Scalars['UUID']['output']>;
};

export type SocialMediaFolderSocialMediaUploadsByFolderIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaUploadCondition>;
  filter?: InputMaybe<SocialMediaUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/**
 * A condition to be used against `SocialMediaFolder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SocialMediaFolderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updaterId` field. */
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `SocialMediaFolder` object types. All fields are combined with a logical ‘and.’ */
export type SocialMediaFolderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SocialMediaFolderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SocialMediaFolderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SocialMediaFolderFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updaterId` field. */
  updaterId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `SocialMediaFolder` */
export type SocialMediaFolderInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  feedId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** Represents an update to a `SocialMediaFolder`. Fields that are set will be updated. */
export type SocialMediaFolderPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  updaterId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `SocialMediaFolder` values. */
export type SocialMediaFoldersConnection = {
  __typename?: 'SocialMediaFoldersConnection';
  /** A list of edges which contains the `SocialMediaFolder` and cursor to aid in pagination. */
  edges: Array<SocialMediaFoldersEdge>;
  /** A list of `SocialMediaFolder` objects. */
  nodes: Array<SocialMediaFolder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SocialMediaFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SocialMediaFolder` edge in the connection. */
export type SocialMediaFoldersEdge = {
  __typename?: 'SocialMediaFoldersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SocialMediaFolder` at the end of the edge. */
  node: SocialMediaFolder;
};

/** Methods to use when ordering `SocialMediaFolder`. */
export enum SocialMediaFoldersOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UPDATER_ID_ASC = 'UPDATER_ID_ASC',
  UPDATER_ID_DESC = 'UPDATER_ID_DESC',
}

export type SocialMediaUpload = Node & {
  __typename?: 'SocialMediaUpload';
  assetTitle?: Maybe<Scalars['String']['output']>;
  /** Reads a single `User` that is related to this `SocialMediaUpload`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  feedId: Scalars['String']['output'];
  /** Reads a single `SocialMediaFolder` that is related to this `SocialMediaUpload`. */
  folder?: Maybe<SocialMediaFolder>;
  folderId?: Maybe<Scalars['UUID']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Upload` that is related to this `SocialMediaUpload`. */
  upload?: Maybe<Upload>;
  uploadId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `SocialMediaUpload` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SocialMediaUploadCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `SocialMediaUpload` object types. All fields are combined with a logical ‘and.’ */
export type SocialMediaUploadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SocialMediaUploadFilter>>;
  /** Filter by the object’s `assetTitle` field. */
  assetTitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SocialMediaUploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SocialMediaUploadFilter>>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `SocialMediaUpload` */
export type SocialMediaUploadInput = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  feedId: Scalars['String']['input'];
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  uploadId: Scalars['UUID']['input'];
};

/** Represents an update to a `SocialMediaUpload`. Fields that are set will be updated. */
export type SocialMediaUploadPatch = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `SocialMediaUpload` values. */
export type SocialMediaUploadsConnection = {
  __typename?: 'SocialMediaUploadsConnection';
  /** A list of edges which contains the `SocialMediaUpload` and cursor to aid in pagination. */
  edges: Array<SocialMediaUploadsEdge>;
  /** A list of `SocialMediaUpload` objects. */
  nodes: Array<SocialMediaUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SocialMediaUpload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SocialMediaUpload` edge in the connection. */
export type SocialMediaUploadsEdge = {
  __typename?: 'SocialMediaUploadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SocialMediaUpload` at the end of the edge. */
  node: SocialMediaUpload;
};

/** Methods to use when ordering `SocialMediaUpload`. */
export enum SocialMediaUploadsOrderBy {
  ASSET_TITLE_ASC = 'ASSET_TITLE_ASC',
  ASSET_TITLE_DESC = 'ASSET_TITLE_DESC',
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  FOLDER_ID_ASC = 'FOLDER_ID_ASC',
  FOLDER_ID_DESC = 'FOLDER_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

export type StationAlternative = {
  __typename?: 'StationAlternative';
  contextualSelector?: Maybe<ContextualSelector>;
  notes?: Maybe<Scalars['JSON']['output']>;
};

/** An input for mutations affecting `StationAlternative` */
export type StationAlternativeInput = {
  contextualSelector?: InputMaybe<ContextualSelectorInput>;
  notes?: InputMaybe<Scalars['JSON']['input']>;
};

export type Stop = Node & {
  __typename?: 'Stop';
  /** Reads a single `Upload` that is related to this `Stop`. */
  blueprintUpload?: Maybe<Upload>;
  boroughs?: Maybe<Array<Maybe<Borough>>>;
  /** Reads and enables pagination through a set of `Stop`. */
  childStops: StopsConnection;
  /** Reads a single `Feed` that is related to this `Stop`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  lat?: Maybe<Scalars['BigFloat']['output']>;
  locationType: LocationType;
  lon?: Maybe<Scalars['BigFloat']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Stop` that is related to this `Stop`. */
  parentStation?: Maybe<Stop>;
  parentStationGtfsId?: Maybe<Scalars['String']['output']>;
  partnerSlotsCount?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Route`. */
  routes: RoutesConnection;
  /** Reads and enables pagination through a set of `ScreensNormalized`. */
  screens: ScreensNormalizedsConnection;
  sequenceInRoute?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `StopTime`. */
  stopTimes: StopTimesConnection;
  syntheticScreenCount?: Maybe<Scalars['Int']['output']>;
};

export type StopChildStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopCondition>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

export type StopRoutesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<RouteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type StopScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ScreensNormalizedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreensNormalizedsOrderBy>>;
};

export type StopSequenceInRouteArgs = {
  direction: Direction;
  routeGtfsId: Scalars['String']['input'];
};

export type StopStopTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopTimeCondition>;
  filter?: InputMaybe<StopTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

/** A condition to be used against `Stop` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type StopCondition = {
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lat` field. */
  lat?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `locationType` field. */
  locationType?: InputMaybe<LocationType>;
  /** Checks for equality with the object’s `lon` field. */
  lon?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `parentStationGtfsId` field. */
  parentStationGtfsId?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Stop` object types. All fields are combined with a logical ‘and.’ */
export type StopFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StopFilter>>;
  /** Filter by the object’s `boroughs` field. */
  boroughs?: InputMaybe<BoroughListFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `locationType` field. */
  locationType?: InputMaybe<LocationTypeFilter>;
  /** Filter by the object’s `lon` field. */
  lon?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StopFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StopFilter>>;
  /** Filter by the object’s `parentStationGtfsId` field. */
  parentStationGtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `partnerSlotsCount` field. */
  partnerSlotsCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `syntheticScreenCount` field. */
  syntheticScreenCount?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Stop` */
export type StopInput = {
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['BigFloat']['input']>;
  locationType: LocationType;
  lon?: InputMaybe<Scalars['BigFloat']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentStationGtfsId?: InputMaybe<Scalars['String']['input']>;
};

export type StopSequence = {
  __typename?: 'StopSequence';
  borough?: Maybe<Borough>;
  feedId?: Maybe<Scalars['String']['output']>;
  gtfsId?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['BigFloat']['output']>;
  locationType?: Maybe<LocationType>;
  lon?: Maybe<Scalars['BigFloat']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentStationGtfsId?: Maybe<Scalars['String']['output']>;
  routeGtfsId?: Maybe<Scalars['String']['output']>;
  sequenceNorthbound?: Maybe<Scalars['Int']['output']>;
  sequenceSouthbound?: Maybe<Scalars['Int']['output']>;
};

/**
 * A condition to be used against `StopSequence` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type StopSequenceCondition = {
  /** Checks for equality with the object’s `borough` field. */
  borough?: InputMaybe<Borough>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lat` field. */
  lat?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `locationType` field. */
  locationType?: InputMaybe<LocationType>;
  /** Checks for equality with the object’s `lon` field. */
  lon?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `parentStationGtfsId` field. */
  parentStationGtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `routeGtfsId` field. */
  routeGtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sequenceNorthbound` field. */
  sequenceNorthbound?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `sequenceSouthbound` field. */
  sequenceSouthbound?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `StopSequence` object types. All fields are combined with a logical ‘and.’ */
export type StopSequenceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StopSequenceFilter>>;
  /** Filter by the object’s `borough` field. */
  borough?: InputMaybe<BoroughFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `locationType` field. */
  locationType?: InputMaybe<LocationTypeFilter>;
  /** Filter by the object’s `lon` field. */
  lon?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StopSequenceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StopSequenceFilter>>;
  /** Filter by the object’s `parentStationGtfsId` field. */
  parentStationGtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `routeGtfsId` field. */
  routeGtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sequenceNorthbound` field. */
  sequenceNorthbound?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sequenceSouthbound` field. */
  sequenceSouthbound?: InputMaybe<IntFilter>;
};

/** A connection to a list of `StopSequence` values. */
export type StopSequencesConnection = {
  __typename?: 'StopSequencesConnection';
  /** A list of edges which contains the `StopSequence` and cursor to aid in pagination. */
  edges: Array<StopSequencesEdge>;
  /** A list of `StopSequence` objects. */
  nodes: Array<StopSequence>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StopSequence` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `StopSequence` edge in the connection. */
export type StopSequencesEdge = {
  __typename?: 'StopSequencesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `StopSequence` at the end of the edge. */
  node: StopSequence;
};

/** Methods to use when ordering `StopSequence`. */
export enum StopSequencesOrderBy {
  BOROUGH_ASC = 'BOROUGH_ASC',
  BOROUGH_DESC = 'BOROUGH_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  LAT_ASC = 'LAT_ASC',
  LAT_DESC = 'LAT_DESC',
  LOCATION_TYPE_ASC = 'LOCATION_TYPE_ASC',
  LOCATION_TYPE_DESC = 'LOCATION_TYPE_DESC',
  LON_ASC = 'LON_ASC',
  LON_DESC = 'LON_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PARENT_STATION_GTFS_ID_ASC = 'PARENT_STATION_GTFS_ID_ASC',
  PARENT_STATION_GTFS_ID_DESC = 'PARENT_STATION_GTFS_ID_DESC',
  ROUTE_GTFS_ID_ASC = 'ROUTE_GTFS_ID_ASC',
  ROUTE_GTFS_ID_DESC = 'ROUTE_GTFS_ID_DESC',
  SEQUENCE_NORTHBOUND_ASC = 'SEQUENCE_NORTHBOUND_ASC',
  SEQUENCE_NORTHBOUND_DESC = 'SEQUENCE_NORTHBOUND_DESC',
  SEQUENCE_SOUTHBOUND_ASC = 'SEQUENCE_SOUTHBOUND_ASC',
  SEQUENCE_SOUTHBOUND_DESC = 'SEQUENCE_SOUTHBOUND_DESC',
}

export type StopTime = {
  __typename?: 'StopTime';
  /** Reads a single `Feed` that is related to this `StopTime`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  gtfsStopId: Scalars['String']['output'];
  gtfsTripId: Scalars['String']['output'];
  scheduledDeparture: Interval;
  /** Reads a single `Stop` that is related to this `StopTime`. */
  stop?: Maybe<Stop>;
  /** Reads a single `Trip` that is related to this `StopTime`. */
  trip?: Maybe<Trip>;
};

/**
 * A condition to be used against `StopTime` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StopTimeCondition = {
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsStopId` field. */
  gtfsStopId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsTripId` field. */
  gtfsTripId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `scheduledDeparture` field. */
  scheduledDeparture?: InputMaybe<IntervalInput>;
};

/** A filter to be used against `StopTime` object types. All fields are combined with a logical ‘and.’ */
export type StopTimeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StopTimeFilter>>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsStopId` field. */
  gtfsStopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsTripId` field. */
  gtfsTripId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StopTimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StopTimeFilter>>;
  /** Filter by the object’s `scheduledDeparture` field. */
  scheduledDeparture?: InputMaybe<IntervalFilter>;
};

/** An input for mutations affecting `StopTime` */
export type StopTimeInput = {
  feedId: Scalars['String']['input'];
  gtfsStopId: Scalars['String']['input'];
  gtfsTripId: Scalars['String']['input'];
  scheduledDeparture: IntervalInput;
};

/** A connection to a list of `StopTime` values. */
export type StopTimesConnection = {
  __typename?: 'StopTimesConnection';
  /** A list of edges which contains the `StopTime` and cursor to aid in pagination. */
  edges: Array<StopTimesEdge>;
  /** A list of `StopTime` objects. */
  nodes: Array<StopTime>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StopTime` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `StopTime` edge in the connection. */
export type StopTimesEdge = {
  __typename?: 'StopTimesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `StopTime` at the end of the edge. */
  node: StopTime;
};

/** Methods to use when ordering `StopTime`. */
export enum StopTimesOrderBy {
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_STOP_ID_ASC = 'GTFS_STOP_ID_ASC',
  GTFS_STOP_ID_DESC = 'GTFS_STOP_ID_DESC',
  GTFS_TRIP_ID_ASC = 'GTFS_TRIP_ID_ASC',
  GTFS_TRIP_ID_DESC = 'GTFS_TRIP_ID_DESC',
  NATURAL = 'NATURAL',
  SCHEDULED_DEPARTURE_ASC = 'SCHEDULED_DEPARTURE_ASC',
  SCHEDULED_DEPARTURE_DESC = 'SCHEDULED_DEPARTURE_DESC',
}

/** A connection to a list of `Stop` values. */
export type StopsConnection = {
  __typename?: 'StopsConnection';
  /** A list of edges which contains the `Stop` and cursor to aid in pagination. */
  edges: Array<StopsEdge>;
  /** A list of `Stop` objects. */
  nodes: Array<Stop>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Stop` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Stop` edge in the connection. */
export type StopsEdge = {
  __typename?: 'StopsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Stop` at the end of the edge. */
  node: Stop;
};

/** Methods to use when ordering `Stop`. */
export enum StopsOrderBy {
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  LAT_ASC = 'LAT_ASC',
  LAT_DESC = 'LAT_DESC',
  LOCATION_TYPE_ASC = 'LOCATION_TYPE_ASC',
  LOCATION_TYPE_DESC = 'LOCATION_TYPE_DESC',
  LON_ASC = 'LON_ASC',
  LON_DESC = 'LON_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PARENT_STATION_GTFS_ID_ASC = 'PARENT_STATION_GTFS_ID_ASC',
  PARENT_STATION_GTFS_ID_DESC = 'PARENT_STATION_GTFS_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern using the SQL standard's definition of a regular expression. */
  notSimilarTo?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern using the SQL standard's definition of a regular expression. */
  similarTo?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type Subscription = {
  __typename?: 'Subscription';
  /** Reads and enables pagination through a set of `Agency`. (live) */
  agencies?: Maybe<AgenciesConnection>;
  /**  (live) */
  agency?: Maybe<Agency>;
  /** Reads a single `Agency` using its globally unique `ID`. (live) */
  agencyByNodeId?: Maybe<Agency>;
  /**  (live) */
  calculateDurationsFromDurationsSource?: Maybe<Array<Maybe<DatetimeRange>>>;
  /**  (live) */
  campaign?: Maybe<Campaign>;
  /** Reads a single `Campaign` using its globally unique `ID`. (live) */
  campaignByNodeId?: Maybe<Campaign>;
  /**  (live) */
  campaignScreenContentById?: Maybe<CampaignScreenContent>;
  /**  (live) */
  campaignScreenContentUploadByCampaignScreenContentIdAndSetIndex?: Maybe<CampaignScreenContentUpload>;
  /**  (live) */
  campaignScreenContentUploadByCampaignScreenContentIdAndUploadId?: Maybe<CampaignScreenContentUpload>;
  /** Reads and enables pagination through a set of `CampaignScreenContentUpload`. (live) */
  campaignScreenContentUploads?: Maybe<CampaignScreenContentUploadsConnection>;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. (live) */
  campaignScreenContents?: Maybe<CampaignScreenContentsConnection>;
  /** Reads and enables pagination through a set of `Campaign`. (live) */
  campaigns?: Maybe<CampaignsConnection>;
  /**  (live) */
  clearedReport?: Maybe<ClearedReport>;
  /** Reads a single `ClearedReport` using its globally unique `ID`. (live) */
  clearedReportByNodeId?: Maybe<ClearedReport>;
  /** Reads and enables pagination through a set of `ClearedReport`. (live) */
  clearedReports?: Maybe<ClearedReportsConnection>;
  /**  (live) */
  contextualSelectorToEntitySelector?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  /**
   *     Fetches the current (not-yet cleared) screen lock for the asset with
   *     asset_id. To get cleared/archived screen locks use the `Query.screenLocks`
   *     connection.
   *    (live)
   */
  currentScreenLock?: Maybe<ScreenLock>;
  /**  (live) */
  currentUser?: Maybe<User>;
  /** Reads and enables pagination through a set of `Message`. (live) */
  dashboardMessages?: Maybe<MessagesConnection>;
  /** Reads and enables pagination through a set of `DashboardPlannedWork`. (live) */
  dashboardPlannedWorks?: Maybe<DashboardPlannedWorksConnection>;
  /** Reads and enables pagination through a set of `DraftMessage`. (live) */
  draftMessages?: Maybe<DraftMessagesConnection>;
  /**  (live) */
  editorTaggedEntityIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**  (live) */
  event?: Maybe<Event>;
  /** Reads a single `Event` using its globally unique `ID`. (live) */
  eventByNodeId?: Maybe<Event>;
  /** Reads and enables pagination through a set of `Event`. (live) */
  events?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `FallbackScreensContent`. (live) */
  fallbackScreensContents?: Maybe<FallbackScreensContentsConnection>;
  /**  (live) */
  featureFlag?: Maybe<FeatureFlag>;
  /** Reads a single `FeatureFlag` using its globally unique `ID`. (live) */
  featureFlagByNodeId?: Maybe<FeatureFlag>;
  /** Reads and enables pagination through a set of `FeatureFlag`. (live) */
  featureFlags?: Maybe<FeatureFlagsConnection>;
  /**  (live) */
  feed?: Maybe<Feed>;
  /**  (live) */
  feedByName?: Maybe<Feed>;
  /** Reads a single `Feed` using its globally unique `ID`. (live) */
  feedByNodeId?: Maybe<Feed>;
  /**  (live) */
  feedByOtpId?: Maybe<Feed>;
  /**  (live) */
  feedGroup?: Maybe<FeedGroup>;
  /** Reads a single `FeedGroup` using its globally unique `ID`. (live) */
  feedGroupByNodeId?: Maybe<FeedGroup>;
  /** Reads and enables pagination through a set of `FeedGroup`. (live) */
  feedGroups?: Maybe<FeedGroupsConnection>;
  /**  (live) */
  feedMessageTextAlias?: Maybe<FeedMessageTextAlias>;
  /**  (live) */
  feedMessageTextAliasByGtfsIdAndAliasAndFeedId?: Maybe<FeedMessageTextAlias>;
  /** Reads a single `FeedMessageTextAlias` using its globally unique `ID`. (live) */
  feedMessageTextAliasByNodeId?: Maybe<FeedMessageTextAlias>;
  /** Reads and enables pagination through a set of `FeedMessageTextAlias`. (live) */
  feedMessageTextAliases?: Maybe<FeedMessageTextAliasesConnection>;
  /**  (live) */
  feedSetting?: Maybe<FeedSetting>;
  /** Reads a single `FeedSetting` using its globally unique `ID`. (live) */
  feedSettingByNodeId?: Maybe<FeedSetting>;
  /** Reads and enables pagination through a set of `FeedSetting`. (live) */
  feedSettings?: Maybe<FeedSettingsConnection>;
  /** Reads and enables pagination through a set of `Feed`. (live) */
  feeds?: Maybe<FeedsConnection>;
  /** Reads and enables pagination through a set of `LiveContent`. (live) */
  filteredLiveContent?: Maybe<LiveContentsConnection>;
  /** Reads and enables pagination through a set of `Stop`. (live) */
  filteredStops?: Maybe<StopsConnection>;
  /** Reads and enables pagination through a set of `GeneralOrderNumber`. (live) */
  generalOrderNumbers?: Maybe<GeneralOrderNumbersConnection>;
  /**
   *   A function that transforms train targeting into "yard" values used by Outfront in order to filter screens for displaying on the front-end or used as screens filter when targeting TransitAPI.
   *  (live)
   */
  getYardValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `GroupedStop`. (live) */
  groupedStops?: Maybe<GroupedStopsConnection>;
  /** Reads and enables pagination through a set of `Job`. (live) */
  jobs?: Maybe<JobsConnection>;
  /** Reads and enables pagination through a set of `LiveContent`. (live) */
  liveContents?: Maybe<LiveContentsConnection>;
  /**  (live) */
  lockableUpload?: Maybe<LockableUpload>;
  /** Reads a single `LockableUpload` using its globally unique `ID`. (live) */
  lockableUploadByNodeId?: Maybe<LockableUpload>;
  /** Reads and enables pagination through a set of `ScreenLock`. (live) */
  lockableUploadLockedScreens?: Maybe<ScreenLocksConnection>;
  /** Reads and enables pagination through a set of `Campaign`. (live) */
  lockableUploadSingleScreenCampaigns?: Maybe<CampaignsConnection>;
  /** Reads and enables pagination through a set of `LockableUpload`. (live) */
  lockableUploads?: Maybe<LockableUploadsConnection>;
  /**
   *   Returns a set of `lmm_public.mention` types that match the given query and list of feeds.
   *
   *   Mentions are a concatenated list of routes, trips, and stops, in that order.
   *   Trips are only returned for MNR and LIRR, and stops are not returned for buses.
   *
   *   The `preferring_route_ids` argument will influence results by mention type:
   *
   *   * stops will be ordered such that stops occurring on those routes are higher,
   *   * trips TBD,
   *   * routes are unaffected.
   *
   *   Following the types and route intersection ordering, ties are broken by alphabetical name
   *   ascending.
   *
   *   Query string matching differs slightly by mention type:
   *
   *   * routes match against short and, if that' null, long names, anywhere in the name
   *   * trips match against short name from start of name
   *   * stops match against name from start of name if query is one character,
   *     any anywhere in name if query is more than one character.
   *  (live)
   */
  mentionSuggestions?: Maybe<MentionsConnection>;
  /** Reads and enables pagination through a set of `MatchedMentionAlias`. (live) */
  mentionsForAliases?: Maybe<MatchedMentionAliasesConnection>;
  /**  (live) */
  message?: Maybe<Message>;
  /** Reads a single `Message` using its globally unique `ID`. (live) */
  messageByNodeId?: Maybe<Message>;
  /**  (live) */
  messageImpact?: Maybe<MessageImpact>;
  /** Reads a single `MessageImpact` using its globally unique `ID`. (live) */
  messageImpactByNodeId?: Maybe<MessageImpact>;
  /** Reads and enables pagination through a set of `MessageImpact`. (live) */
  messageImpacts?: Maybe<MessageImpactsConnection>;
  /** Reads and enables pagination through a set of `Message`. (live) */
  messages?: Maybe<MessagesConnection>;
  /** Fetches an object given its globally unique `ID`. (live) */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. (live) */
  nodeId: Scalars['ID']['output'];
  /**  (live) */
  plannedWork?: Maybe<PlannedWork>;
  /** Reads a single `PlannedWork` using its globally unique `ID`. (live) */
  plannedWorkByNodeId?: Maybe<PlannedWork>;
  /**  (live) */
  plannedWorkImpact?: Maybe<PlannedWorkImpact>;
  /** Reads a single `PlannedWorkImpact` using its globally unique `ID`. (live) */
  plannedWorkImpactByNodeId?: Maybe<PlannedWorkImpact>;
  /** Reads and enables pagination through a set of `PlannedWorkImpact`. (live) */
  plannedWorkImpacts?: Maybe<PlannedWorkImpactsConnection>;
  /**  (live) */
  plannedWorkScreenMessage?: Maybe<PlannedWorkScreenMessage>;
  /** Reads a single `PlannedWorkScreenMessage` using its globally unique `ID`. (live) */
  plannedWorkScreenMessageByNodeId?: Maybe<PlannedWorkScreenMessage>;
  /** Reads and enables pagination through a set of `PlannedWorkScreenMessage`. (live) */
  plannedWorkScreenMessages?: Maybe<PlannedWorkScreenMessagesConnection>;
  /** Reads and enables pagination through a set of `PlannedWork`. (live) */
  plannedWorks?: Maybe<PlannedWorksConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form. (live)
   */
  query: Query;
  /**  (live) */
  route?: Maybe<Route>;
  /** Reads a single `Route` using its globally unique `ID`. (live) */
  routeByNodeId?: Maybe<Route>;
  /** Reads and enables pagination through a set of `Route`. (live) */
  routes?: Maybe<RoutesConnection>;
  /** Reads and enables pagination through a set of `ScreenCountsForEntitySelectorsType`. (live) */
  screenCountsForEntitySelectors?: Maybe<ScreenCountsForEntitySelectorsTypesConnection>;
  /**  (live) */
  screenDetails?: Maybe<ScreensNormalized>;
  /** Reads and enables pagination through a set of `ScreenLayoutCount`. (live) */
  screenLayoutCounts?: Maybe<ScreenLayoutCountsConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutFallbackCount`. (live) */
  screenLayoutFallbackCounts?: Maybe<ScreenLayoutFallbackCountsConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. (live) */
  screenLayoutTypeCounts?: Maybe<ScreenLayoutTypesConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. (live) */
  screenLayoutTypeCountsV2?: Maybe<ScreenLayoutTypesConnection>;
  /**  (live) */
  screenLock?: Maybe<ScreenLock>;
  /** Reads a single `ScreenLock` using its globally unique `ID`. (live) */
  screenLockByNodeId?: Maybe<ScreenLock>;
  /** Reads and enables pagination through a set of `ScreenLock`. (live) */
  screenLocks?: Maybe<ScreenLocksConnection>;
  /**  (live) */
  screenMessage?: Maybe<ScreenMessage>;
  /** Reads a single `ScreenMessage` using its globally unique `ID`. (live) */
  screenMessageByNodeId?: Maybe<ScreenMessage>;
  /** Reads and enables pagination through a set of `ScreenMessage`. (live) */
  screenMessages?: Maybe<ScreenMessagesConnection>;
  /**  (live) */
  screenNoteByName?: Maybe<ScreenNote>;
  /** Reads and enables pagination through a set of `ScreenNote`. (live) */
  screenNotes?: Maybe<ScreenNotesConnection>;
  /**  (live) */
  screens2?: Maybe<Screens2>;
  /** Reads a single `Screens2` using its globally unique `ID`. (live) */
  screens2ByNodeId?: Maybe<Screens2>;
  /** Reads and enables pagination through a set of `Screens2`. (live) */
  screens2s?: Maybe<Screens2sConnection>;
  /** Reads and enables pagination through a set of `ScreensNormalized`. (live) */
  screensNormalizeds?: Maybe<ScreensNormalizedsConnection>;
  /** Tuned search of Campaigns. Some data may be out-of-sync. Use top-level campaigns when finding a single campaign or guarantees around matches are needed. (live) */
  searchCampaigns?: Maybe<SearchCampaignsResult>;
  /**  (live) */
  searchClearedMessages?: Maybe<SearchClearedMessagesResult>;
  /**  (live) */
  searchLockableUploads?: Maybe<SearchLockableUploadsResult>;
  /**  (live) */
  searchPlannedWorks?: Maybe<SearchPlannedWorksResult>;
  /**  (live) */
  searchSocialMediaUploads?: Maybe<SearchSocialMediaUploadsResult>;
  /** Reads and enables pagination through a set of `ServicePlanNumber`. (live) */
  servicePlanNumbers?: Maybe<ServicePlanNumbersConnection>;
  /**  (live) */
  socialMediaFolder?: Maybe<SocialMediaFolder>;
  /** Reads a single `SocialMediaFolder` using its globally unique `ID`. (live) */
  socialMediaFolderByNodeId?: Maybe<SocialMediaFolder>;
  /** Reads and enables pagination through a set of `SocialMediaFolder`. (live) */
  socialMediaFolders?: Maybe<SocialMediaFoldersConnection>;
  /**  (live) */
  socialMediaUpload?: Maybe<SocialMediaUpload>;
  /** Reads a single `SocialMediaUpload` using its globally unique `ID`. (live) */
  socialMediaUploadByNodeId?: Maybe<SocialMediaUpload>;
  /** Reads and enables pagination through a set of `SocialMediaUpload`. (live) */
  socialMediaUploads?: Maybe<SocialMediaUploadsConnection>;
  /** Reads and enables pagination through a set of `User`. (live) */
  sortedUsers?: Maybe<UsersConnection>;
  /**  (live) */
  stop?: Maybe<Stop>;
  /** Reads a single `Stop` using its globally unique `ID`. (live) */
  stopByNodeId?: Maybe<Stop>;
  /** Reads and enables pagination through a set of `GroupedStop`. (live) */
  stopOptionsForSelector?: Maybe<GroupedStopsConnection>;
  /** Reads and enables pagination through a set of `StopSequence`. (live) */
  stopSequences?: Maybe<StopSequencesConnection>;
  /**  (live) */
  stopSyntheticScreensCount?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `StopTime`. (live) */
  stopTimes?: Maybe<StopTimesConnection>;
  /** Reads and enables pagination through a set of `Stop`. (live) */
  stops?: Maybe<StopsConnection>;
  /**  (live) */
  syntheticScreen?: Maybe<SyntheticScreen>;
  /**  (live) */
  syntheticScreenByName?: Maybe<SyntheticScreen>;
  /** Reads a single `SyntheticScreen` using its globally unique `ID`. (live) */
  syntheticScreenByNodeId?: Maybe<SyntheticScreen>;
  /** Reads and enables pagination through a set of `SyntheticScreen`. (live) */
  syntheticScreens?: Maybe<SyntheticScreensConnection>;
  /** Reads and enables pagination through a set of `ScreenLayoutType`. (live) */
  syntheticScreensLayoutTypeCounts?: Maybe<ScreenLayoutTypesConnection>;
  /**  (live) */
  targetTypeToTransitCriteria?: Maybe<Scalars['JSON']['output']>;
  /**  (live) */
  targetedStaticOnlyScreens?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `TransitSchedule`. (live) */
  transitSchedules?: Maybe<TransitSchedulesConnection>;
  /**  (live) */
  trip?: Maybe<Trip>;
  /** Reads a single `Trip` using its globally unique `ID`. (live) */
  tripByNodeId?: Maybe<Trip>;
  /** Reads and enables pagination through a set of `Trip`. (live) */
  trips?: Maybe<TripsConnection>;
  /**  (live) */
  tripsLirr?: Maybe<TripsLirr>;
  /** Reads a single `TripsLirr` using its globally unique `ID`. (live) */
  tripsLirrByNodeId?: Maybe<TripsLirr>;
  /**  (live) */
  tripsLirrByTrainNum?: Maybe<TripsLirr>;
  /** Reads and enables pagination through a set of `TripsLirr`. (live) */
  tripsLirrs?: Maybe<TripsLirrsConnection>;
  /**  (live) */
  tweet?: Maybe<Tweet>;
  /** Reads a single `Tweet` using its globally unique `ID`. (live) */
  tweetByNodeId?: Maybe<Tweet>;
  /** Reads and enables pagination through a set of `Tweet`. (live) */
  tweets?: Maybe<TweetsConnection>;
  /**  (live) */
  tweetsUpload?: Maybe<TweetsUpload>;
  /** Reads a single `TweetsUpload` using its globally unique `ID`. (live) */
  tweetsUploadByNodeId?: Maybe<TweetsUpload>;
  /** Reads and enables pagination through a set of `TweetsUpload`. (live) */
  tweetsUploads?: Maybe<TweetsUploadsConnection>;
  /**  (live) */
  upload?: Maybe<Upload>;
  /** Reads a single `Upload` using its globally unique `ID`. (live) */
  uploadByNodeId?: Maybe<Upload>;
  /** Reads and enables pagination through a set of `Upload`. (live) */
  uploads?: Maybe<UploadsConnection>;
  /**  (live) */
  user?: Maybe<User>;
  /**  (live) */
  userByEmail?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. (live) */
  userByNodeId?: Maybe<User>;
  /**  (live) */
  userFeedGroupGrant?: Maybe<UserFeedGroupGrant>;
  /** Reads a single `UserFeedGroupGrant` using its globally unique `ID`. (live) */
  userFeedGroupGrantByNodeId?: Maybe<UserFeedGroupGrant>;
  /** Reads and enables pagination through a set of `UserFeedGroupGrant`. (live) */
  userFeedGroupGrants?: Maybe<UserFeedGroupGrantsConnection>;
  /** Reads and enables pagination through a set of `User`. (live) */
  users?: Maybe<UsersConnection>;
  /**  (live) */
  usersAccess?: Maybe<UsersAccess>;
  /** Reads a single `UsersAccess` using its globally unique `ID`. (live) */
  usersAccessByNodeId?: Maybe<UsersAccess>;
  /** Reads and enables pagination through a set of `UsersAccess`. (live) */
  usersAccesses?: Maybe<UsersAccessesConnection>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAgenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AgencyCondition>;
  filter?: InputMaybe<AgencyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAgencyArgs = {
  gtfsId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAgencyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCalculateDurationsFromDurationsSourceArgs = {
  _durationsSource?: InputMaybe<DurationsSourceInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignScreenContentByIdArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexArgs =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    setIndex: Scalars['Int']['input'];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdArgs =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    uploadId: Scalars['UUID']['input'];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignScreenContentUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentUploadCondition>;
  filter?: InputMaybe<CampaignScreenContentUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignScreenContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentCondition>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionClearedReportArgs = {
  id: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionClearedReportByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionClearedReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClearedReportCondition>;
  filter?: InputMaybe<ClearedReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContextualSelectorToEntitySelectorArgs = {
  selectors: Array<InputMaybe<ContextualSelectorInput>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCurrentScreenLockArgs = {
  assetId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDashboardMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<DashboardMessagesInputRecordInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDashboardPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DashboardPlannedWorkCondition>;
  filter?: InputMaybe<DashboardPlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardPlannedWorksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDraftMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DraftMessageCondition>;
  filter?: InputMaybe<DraftMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DraftMessagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEditorTaggedEntityIdsArgs = {
  _editorData?: InputMaybe<Scalars['JSON']['input']>;
  _mentionType?: InputMaybe<MentionType>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEventArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEventByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFallbackScreensContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FallbackScreensContentCondition>;
  filter?: InputMaybe<FallbackScreensContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FallbackScreensContentsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeatureFlagArgs = {
  name: FeatureFlagName;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeatureFlagByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeatureFlagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeatureFlagCondition>;
  filter?: InputMaybe<FeatureFlagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedArgs = {
  id: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedByNameArgs = {
  name: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedByOtpIdArgs = {
  otpId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedGroupArgs = {
  name: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedGroupCondition>;
  filter?: InputMaybe<FeedGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedMessageTextAliasArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdArgs = {
  alias: Scalars['String']['input'];
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedMessageTextAliasByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedMessageTextAliasesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedMessageTextAliasCondition>;
  filter?: InputMaybe<FeedMessageTextAliasFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedMessageTextAliasesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedSettingArgs = {
  feedId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedSettingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedSettingCondition>;
  filter?: InputMaybe<FeedSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedCondition>;
  filter?: InputMaybe<FeedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFilteredLiveContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LiveContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiveContentsOrderBy>>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchStr?: InputMaybe<Scalars['String']['input']>;
  targetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFilteredStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  borough?: InputMaybe<Borough>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasScreensWithPartnerSlots?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
  query?: InputMaybe<Scalars['String']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stopId?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGeneralOrderNumbersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeneralOrderNumberCondition>;
  filter?: InputMaybe<GeneralOrderNumberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeneralOrderNumbersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGetYardValuesArgs = {
  isTargetingAllLirrTrains?: InputMaybe<Scalars['Boolean']['input']>;
  lines?: InputMaybe<Array<InputMaybe<MnrRollingStockLine>>>;
  shops?: InputMaybe<Array<InputMaybe<Shop>>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGroupedStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GroupedStopCondition>;
  filter?: InputMaybe<GroupedStopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GroupedStopsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionJobsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<JobCondition>;
  filter?: InputMaybe<JobFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLiveContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LiveContentCondition>;
  filter?: InputMaybe<LiveContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiveContentsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLockableUploadArgs = {
  uploadId: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLockableUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLockableUploadLockedScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<ScreenLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lockableUploadId: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLockableUploadSingleScreenCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lockableUploadId: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLockableUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockableUploadCondition>;
  filter?: InputMaybe<LockableUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMentionSuggestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter?: InputMaybe<MentionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  preferringRouteIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  query?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMentionsForAliasesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  aliases: Array<InputMaybe<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<MatchedMentionAliasFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageImpactArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageImpactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageImpactCondition>;
  filter?: InputMaybe<MessageImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkImpactArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkImpactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkImpactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkImpactCondition>;
  filter?: InputMaybe<PlannedWorkImpactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkImpactsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkScreenMessageArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkScreenMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorkScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkScreenMessageCondition>;
  filter?: InputMaybe<PlannedWorkScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkScreenMessagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkCondition>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRouteArgs = {
  gtfsId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRouteByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoutesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RouteCondition>;
  filter?: InputMaybe<RouteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenCountsForEntitySelectorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  ess: Array<InputMaybe<GtfsEntitySelectorInput>>;
  feedId: Scalars['String']['input'];
  filter?: InputMaybe<ScreenCountsForEntitySelectorsTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenDetailsArgs = {
  screenName?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLayoutCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  excludedTargetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isTargetingAllLirrTrains?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lines?: InputMaybe<Array<InputMaybe<MnrRollingStockLine>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  routeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shops?: InputMaybe<Array<InputMaybe<Shop>>>;
  specificTargetTypes?: InputMaybe<Array<InputMaybe<TargetType>>>;
  stopIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLayoutFallbackCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutFallbackCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLayoutTypeCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLayoutTypeCountsV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLockArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLockByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenLocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenLockCondition>;
  filter?: InputMaybe<ScreenLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenLocksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenMessageArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenMessageCondition>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenMessagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenNoteByNameArgs = {
  name: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreenNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenNoteCondition>;
  filter?: InputMaybe<ScreenNoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreens2Args = {
  id: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreens2ByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreens2sArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<Screens2Condition>;
  filter?: InputMaybe<Screens2Filter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionScreensNormalizedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreensNormalizedCondition>;
  filter?: InputMaybe<ScreensNormalizedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreensNormalizedsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSearchCampaignsArgs = {
  input?: InputMaybe<SearchCampaignsInputRecordInput>;
  orderBy?: InputMaybe<SearchCampaignsOrderBy>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSearchClearedMessagesArgs = {
  input?: InputMaybe<SearchClearedMessagesInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSearchLockableUploadsArgs = {
  input?: InputMaybe<SearchLockableUploadsInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSearchPlannedWorksArgs = {
  input?: InputMaybe<SearchPlannedWorksInputRecordInput>;
  orderBy?: InputMaybe<SearchPlannedWorksOrderBy>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSearchSocialMediaUploadsArgs = {
  input?: InputMaybe<SearchSocialMediaUploadsInputRecordInput>;
  pagination?: InputMaybe<SearchPaginationInputRecordInput>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionServicePlanNumbersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ServicePlanNumberCondition>;
  filter?: InputMaybe<ServicePlanNumberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ServicePlanNumbersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaFolderArgs = {
  id: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaFolderByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaFoldersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaFolderCondition>;
  filter?: InputMaybe<SocialMediaFolderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaUploadArgs = {
  uploadId: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSocialMediaUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaUploadCondition>;
  filter?: InputMaybe<SocialMediaUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSortedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  sortAsc?: InputMaybe<Scalars['Boolean']['input']>;
  sortByFeed?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopArgs = {
  gtfsId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopOptionsForSelectorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  filter?: InputMaybe<GroupedStopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopSequencesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopSequenceCondition>;
  filter?: InputMaybe<StopSequenceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopSequencesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopSyntheticScreensCountArgs = {
  gtfsId?: InputMaybe<Scalars['String']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopTimeCondition>;
  filter?: InputMaybe<StopTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopCondition>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSyntheticScreenArgs = {
  id: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSyntheticScreenByNameArgs = {
  name: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSyntheticScreenByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSyntheticScreensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SyntheticScreenCondition>;
  filter?: InputMaybe<SyntheticScreenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SyntheticScreensOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSyntheticScreensLayoutTypeCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScreenLayoutTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTargetTypeToTransitCriteriaArgs = {
  targetType: TargetType;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTargetedStaticOnlyScreensArgs = {
  selectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTransitSchedulesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransitScheduleCondition>;
  filter?: InputMaybe<TransitScheduleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransitSchedulesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripArgs = {
  gtfsId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripCondition>;
  filter?: InputMaybe<TripFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripsLirrArgs = {
  gtfsTripId: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripsLirrByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripsLirrByTrainNumArgs = {
  trainNum: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTripsLirrsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TripsLirrCondition>;
  filter?: InputMaybe<TripsLirrFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetArgs = {
  id: Scalars['Int']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetCondition>;
  filter?: InputMaybe<TweetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetsUploadArgs = {
  tweetId: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetsUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTweetsUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetsUploadCondition>;
  filter?: InputMaybe<TweetsUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUploadArgs = {
  id: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUploadByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UploadCondition>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UploadsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserArgs = {
  id: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserByEmailArgs = {
  email: Scalars['String']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFeedGroupGrantArgs = {
  feedGroupName: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFeedGroupGrantByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserFeedGroupGrantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserFeedGroupGrantCondition>;
  filter?: InputMaybe<UserFeedGroupGrantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAccessArgs = {
  userId: Scalars['UUID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAccessByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UsersAccessCondition>;
  filter?: InputMaybe<UsersAccessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

/** One synthetic screen installed somewhere within the transit system. */
export type SyntheticScreen = Node & {
  __typename?: 'SyntheticScreen';
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  alerts: ScreenMessagesConnection;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  campaignScreenContent: CampaignScreenContentsConnection;
  currentScreenLock?: Maybe<ScreenLock>;
  entitySelectors: Array<Maybe<GtfsEntitySelector>>;
  /** Reads a single `Feed` that is related to this `SyntheticScreen`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orientation: ScreenOrientation;
  /** Reads and enables pagination through a set of `PlannedWork`. */
  plannedWorks: PlannedWorksConnection;
  stopId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Stop`. */
  stops: StopsConnection;
  targetType?: Maybe<TargetType>;
  type: ScreenType;
  width: Scalars['Int']['output'];
};

/** One synthetic screen installed somewhere within the transit system. */
export type SyntheticScreenAlertsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** One synthetic screen installed somewhere within the transit system. */
export type SyntheticScreenCampaignScreenContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

/** One synthetic screen installed somewhere within the transit system. */
export type SyntheticScreenPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeframe?: InputMaybe<DatetimeRangeInput>;
};

/** One synthetic screen installed somewhere within the transit system. */
export type SyntheticScreenStopsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<StopFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A condition to be used against `SyntheticScreen` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SyntheticScreenCondition = {
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientation>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ScreenType>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `SyntheticScreen` object types. All fields are combined with a logical ‘and.’ */
export type SyntheticScreenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SyntheticScreenFilter>>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SyntheticScreenFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SyntheticScreenFilter>>;
  /** Filter by the object’s `orientation` field. */
  orientation?: InputMaybe<ScreenOrientationFilter>;
  /** Filter by the object’s `stopId` field. */
  stopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetType` field. */
  targetType?: InputMaybe<TargetTypeFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<ScreenTypeFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

/** Represents an update to a `SyntheticScreen`. Fields that are set will be updated. */
export type SyntheticScreenPatch = {
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orientation?: InputMaybe<ScreenOrientation>;
  type?: InputMaybe<ScreenType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `SyntheticScreen` values. */
export type SyntheticScreensConnection = {
  __typename?: 'SyntheticScreensConnection';
  /** A list of edges which contains the `SyntheticScreen` and cursor to aid in pagination. */
  edges: Array<SyntheticScreensEdge>;
  /** A list of `SyntheticScreen` objects. */
  nodes: Array<SyntheticScreen>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SyntheticScreen` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SyntheticScreen` edge in the connection. */
export type SyntheticScreensEdge = {
  __typename?: 'SyntheticScreensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SyntheticScreen` at the end of the edge. */
  node: SyntheticScreen;
};

/** Methods to use when ordering `SyntheticScreen`. */
export enum SyntheticScreensOrderBy {
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  HEIGHT_ASC = 'HEIGHT_ASC',
  HEIGHT_DESC = 'HEIGHT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  ORIENTATION_ASC = 'ORIENTATION_ASC',
  ORIENTATION_DESC = 'ORIENTATION_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  WIDTH_ASC = 'WIDTH_ASC',
  WIDTH_DESC = 'WIDTH_DESC',
}

export enum TargetType {
  COVES_OF_FIVE = 'COVES_OF_FIVE',
  COVES_OF_TWO = 'COVES_OF_TWO',
  DUO_CIC_LEFT = 'DUO_CIC_LEFT',
  DUO_CIC_RIGHT = 'DUO_CIC_RIGHT',
  DUP = 'DUP',
  FULTON_PORTRAIT = 'FULTON_PORTRAIT',
  NON_AD = 'NON_AD',
  OTHER_MARKETING_LANDSCAPE = 'OTHER_MARKETING_LANDSCAPE',
  SINGLE_AD_LANDSCAPE = 'SINGLE_AD_LANDSCAPE',
  SINGLE_AD_PORTRAIT = 'SINGLE_AD_PORTRAIT',
  SINGLE_CIC = 'SINGLE_CIC',
  SINGLE_CIC_LANDSCAPE = 'SINGLE_CIC_LANDSCAPE',
  SINGLE_INFO_LANDSCAPE = 'SINGLE_INFO_LANDSCAPE',
  SINGLE_INFO_PORTRAIT = 'SINGLE_INFO_PORTRAIT',
  SINGLE_MKT_LANDSCAPE = 'SINGLE_MKT_LANDSCAPE',
  SINGLE_PIO_LANDSCAPE = 'SINGLE_PIO_LANDSCAPE',
  SINGLE_STA_LANDSCAPE = 'SINGLE_STA_LANDSCAPE',
  SOLARI_LANDSCAPE = 'SOLARI_LANDSCAPE',
  SOLARI_MARKETING_LANDSCAPE = 'SOLARI_MARKETING_LANDSCAPE',
  SOLARI_PORTRAIT = 'SOLARI_PORTRAIT',
  SPECTACULAR_AD = 'SPECTACULAR_AD',
  SQUARE = 'SQUARE',
  TRIPTYCH_AD = 'TRIPTYCH_AD',
  _3SM = '_3SM',
}

/** A filter to be used against TargetType fields. All fields are combined with a logical ‘and.’ */
export type TargetTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TargetType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TargetType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TargetType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TargetType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TargetType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TargetType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TargetType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TargetType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TargetType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TargetType>>;
};

/** A filter to be used against TargetType List fields. All fields are combined with a logical ‘and.’ */
export type TargetTypeListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<TargetType>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<TargetType>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<TargetType>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<TargetType>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<TargetType>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<TargetType>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<TargetType>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<TargetType>>>;
};

/** All input for the `toggleAdminUserAccess` mutation. */
export type ToggleAdminUserAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** The output of our `toggleAdminUserAccess` mutation. */
export type ToggleAdminUserAccessPayload = {
  __typename?: 'ToggleAdminUserAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our `toggleAdminUserAccess` mutation. */
export type ToggleAdminUserAccessPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/**
 *   Stores screens targeting related to rolling stocks.
 *
 *   `shops` is used for targeting NYCT Subway shops/yards.
 *   `lines` is used for targeting lines/line groups that have rolling stock screens, currently only used for MNR.
 *   `is_targeting_all_lirr_trains` is used for targeting all LIRR rolling stock screens.
 *
 */
export type TrainTargeting = {
  __typename?: 'TrainTargeting';
  isTargetingAllLirrTrains?: Maybe<Scalars['Boolean']['output']>;
  lines?: Maybe<Array<MnrRollingStockLine>>;
  shops?: Maybe<Array<Shop>>;
  yardValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** An input for mutations affecting `TrainTargeting` */
export type TrainTargetingInput = {
  isTargetingAllLirrTrains?: InputMaybe<Scalars['Boolean']['input']>;
  lines?: InputMaybe<Array<MnrRollingStockLine>>;
  shops?: InputMaybe<Array<Shop>>;
};

export enum TransitDestination {
  BROADSIGN = 'BROADSIGN',
  VLE = 'VLE',
}

export type TransitSchedule = {
  __typename?: 'TransitSchedule';
  active?: Maybe<Scalars['Boolean']['output']>;
  assetIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  direction?: Maybe<Direction>;
  durations?: Maybe<Array<Maybe<DatetimeRange>>>;
  entitySelectors?: Maybe<Array<Maybe<GtfsEntitySelector>>>;
  fallbackTransitAuthority?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<SchedulePriority>;
  screenCriterias?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  screenName?: Maybe<Scalars['String']['output']>;
  screenTargeting?: Maybe<Array<Maybe<ScreenTargeting>>>;
  stopIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tableName?: Maybe<Scalars['String']['output']>;
  transitScheduleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  weight?: Maybe<Scalars['Int']['output']>;
  yards?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/**
 * A condition to be used against `TransitSchedule` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransitScheduleCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `assetIds` field. */
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `durations` field. */
  durations?: InputMaybe<Array<InputMaybe<DatetimeRangeInput>>>;
  /** Checks for equality with the object’s `entitySelectors` field. */
  entitySelectors?: InputMaybe<Array<InputMaybe<GtfsEntitySelectorInput>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriority>;
  /** Checks for equality with the object’s `screenCriterias` field. */
  screenCriterias?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  /** Checks for equality with the object’s `screenName` field. */
  screenName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `screenTargeting` field. */
  screenTargeting?: InputMaybe<Array<InputMaybe<ScreenTargetingInput>>>;
  /** Checks for equality with the object’s `tableName` field. */
  tableName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `yards` field. */
  yards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A filter to be used against `TransitSchedule` object types. All fields are combined with a logical ‘and.’ */
export type TransitScheduleFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransitScheduleFilter>>;
  /** Filter by the object’s `assetIds` field. */
  assetIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `direction` field. */
  direction?: InputMaybe<DirectionFilter>;
  /** Filter by the object’s `durations` field. */
  durations?: InputMaybe<DatetimeRangeListFilter>;
  /** Filter by the object’s `fallbackTransitAuthority` field. */
  fallbackTransitAuthority?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransitScheduleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransitScheduleFilter>>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<SchedulePriorityFilter>;
  /** Filter by the object’s `screenCriterias` field. */
  screenCriterias?: InputMaybe<JsonListFilter>;
  /** Filter by the object’s `screenName` field. */
  screenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stopIds` field. */
  stopIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `tableName` field. */
  tableName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transitScheduleIds` field. */
  transitScheduleIds?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `yards` field. */
  yards?: InputMaybe<StringListFilter>;
};

/** A connection to a list of `TransitSchedule` values. */
export type TransitSchedulesConnection = {
  __typename?: 'TransitSchedulesConnection';
  /** A list of edges which contains the `TransitSchedule` and cursor to aid in pagination. */
  edges: Array<TransitSchedulesEdge>;
  /** A list of `TransitSchedule` objects. */
  nodes: Array<TransitSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransitSchedule` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TransitSchedule` edge in the connection. */
export type TransitSchedulesEdge = {
  __typename?: 'TransitSchedulesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TransitSchedule` at the end of the edge. */
  node: TransitSchedule;
};

/** Methods to use when ordering `TransitSchedule`. */
export enum TransitSchedulesOrderBy {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  ASSET_IDS_ASC = 'ASSET_IDS_ASC',
  ASSET_IDS_DESC = 'ASSET_IDS_DESC',
  DURATIONS_ASC = 'DURATIONS_ASC',
  DURATIONS_DESC = 'DURATIONS_DESC',
  ENTITY_SELECTORS_ASC = 'ENTITY_SELECTORS_ASC',
  ENTITY_SELECTORS_DESC = 'ENTITY_SELECTORS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIORITY_ASC = 'PRIORITY_ASC',
  PRIORITY_DESC = 'PRIORITY_DESC',
  SCREEN_CRITERIAS_ASC = 'SCREEN_CRITERIAS_ASC',
  SCREEN_CRITERIAS_DESC = 'SCREEN_CRITERIAS_DESC',
  SCREEN_NAME_ASC = 'SCREEN_NAME_ASC',
  SCREEN_NAME_DESC = 'SCREEN_NAME_DESC',
  SCREEN_TARGETING_ASC = 'SCREEN_TARGETING_ASC',
  SCREEN_TARGETING_DESC = 'SCREEN_TARGETING_DESC',
  TABLE_NAME_ASC = 'TABLE_NAME_ASC',
  TABLE_NAME_DESC = 'TABLE_NAME_DESC',
  TRANSIT_SCHEDULE_IDS_ASC = 'TRANSIT_SCHEDULE_IDS_ASC',
  TRANSIT_SCHEDULE_IDS_DESC = 'TRANSIT_SCHEDULE_IDS_DESC',
  WEIGHT_ASC = 'WEIGHT_ASC',
  WEIGHT_DESC = 'WEIGHT_DESC',
  YARDS_ASC = 'YARDS_ASC',
  YARDS_DESC = 'YARDS_DESC',
}

export type TransitSetting = {
  __typename?: 'TransitSetting';
  /** The date when this Outfront asset was created, this is different of when the asset actually gets processed at a later point. */
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  transitDestination?: Maybe<TransitDestination>;
  /**
   *   Used to store zipped media urls for Outfront assets, this url should be provided from the UI,
   *   as we can't load large media files in our `job-queue` and zip them there due the current ec2
   *   tier we use not having enough memory.
   *
   */
  zippedMediaS3Url?: Maybe<Scalars['String']['output']>;
};

/** An input for mutations affecting `TransitSetting` */
export type TransitSettingInput = {
  /** The date when this Outfront asset was created, this is different of when the asset actually gets processed at a later point. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  transitDestination?: InputMaybe<TransitDestination>;
  /**
   *   Used to store zipped media urls for Outfront assets, this url should be provided from the UI,
   *   as we can't load large media files in our `job-queue` and zip them there due the current ec2
   *   tier we use not having enough memory.
   *
   */
  zippedMediaS3Url?: InputMaybe<Scalars['String']['input']>;
};

export type Trip = Node & {
  __typename?: 'Trip';
  direction: Scalars['Int']['output'];
  /** Reads a single `Feed` that is related to this `Trip`. */
  feed?: Maybe<Feed>;
  feedId: Scalars['String']['output'];
  gtfsId: Scalars['String']['output'];
  gtfsRouteId: Scalars['String']['output'];
  headsign: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Route` that is related to this `Trip`. */
  route?: Maybe<Route>;
  runDate: Scalars['Date']['output'];
  shortName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `StopTime`. */
  stopTimes: StopTimesConnection;
};

export type TripStopTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StopTimeCondition>;
  filter?: InputMaybe<StopTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StopTimesOrderBy>>;
};

/** A condition to be used against `Trip` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TripCondition = {
  /** Checks for equality with the object’s `direction` field. */
  direction?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsRouteId` field. */
  gtfsRouteId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `headsign` field. */
  headsign?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `runDate` field. */
  runDate?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `shortName` field. */
  shortName?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Trip` object types. All fields are combined with a logical ‘and.’ */
export type TripFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TripFilter>>;
  /** Filter by the object’s `direction` field. */
  direction?: InputMaybe<IntFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsId` field. */
  gtfsId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsRouteId` field. */
  gtfsRouteId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `headsign` field. */
  headsign?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TripFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TripFilter>>;
  /** Filter by the object’s `runDate` field. */
  runDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `shortName` field. */
  shortName?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Trip` */
export type TripInput = {
  direction: Scalars['Int']['input'];
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
  gtfsRouteId: Scalars['String']['input'];
  headsign: Scalars['String']['input'];
  runDate: Scalars['Date']['input'];
  shortName?: InputMaybe<Scalars['String']['input']>;
};

/** Represents an update to a `Trip`. Fields that are set will be updated. */
export type TripPatch = {
  direction?: InputMaybe<Scalars['Int']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  gtfsId?: InputMaybe<Scalars['String']['input']>;
  gtfsRouteId?: InputMaybe<Scalars['String']['input']>;
  headsign?: InputMaybe<Scalars['String']['input']>;
  runDate?: InputMaybe<Scalars['Date']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Trip` values. */
export type TripsConnection = {
  __typename?: 'TripsConnection';
  /** A list of edges which contains the `Trip` and cursor to aid in pagination. */
  edges: Array<TripsEdge>;
  /** A list of `Trip` objects. */
  nodes: Array<Trip>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Trip` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Trip` edge in the connection. */
export type TripsEdge = {
  __typename?: 'TripsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Trip` at the end of the edge. */
  node: Trip;
};

export type TripsLirr = Node & {
  __typename?: 'TripsLirr';
  destinationGtfsStopId: Scalars['String']['output'];
  destinationTime: Scalars['Datetime']['output'];
  direction?: Maybe<Direction>;
  /** Reads a single `Route` that is related to this `TripsLirr`. */
  gtfsRoute?: Maybe<Route>;
  gtfsRouteId: Scalars['String']['output'];
  gtfsTripId: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  originGtfsStopId: Scalars['String']['output'];
  originTime: Scalars['Datetime']['output'];
  runDate: Scalars['Date']['output'];
  trainNum: Scalars['String']['output'];
};

/**
 * A condition to be used against `TripsLirr` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TripsLirrCondition = {
  /** Checks for equality with the object’s `destinationGtfsStopId` field. */
  destinationGtfsStopId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `destinationTime` field. */
  destinationTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `direction` field. */
  direction?: InputMaybe<Direction>;
  /** Checks for equality with the object’s `gtfsRouteId` field. */
  gtfsRouteId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `gtfsTripId` field. */
  gtfsTripId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `originGtfsStopId` field. */
  originGtfsStopId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `originTime` field. */
  originTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `runDate` field. */
  runDate?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `trainNum` field. */
  trainNum?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `TripsLirr` object types. All fields are combined with a logical ‘and.’ */
export type TripsLirrFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TripsLirrFilter>>;
  /** Filter by the object’s `destinationGtfsStopId` field. */
  destinationGtfsStopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `destinationTime` field. */
  destinationTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `direction` field. */
  direction?: InputMaybe<DirectionFilter>;
  /** Filter by the object’s `gtfsRouteId` field. */
  gtfsRouteId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gtfsTripId` field. */
  gtfsTripId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TripsLirrFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TripsLirrFilter>>;
  /** Filter by the object’s `originGtfsStopId` field. */
  originGtfsStopId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `originTime` field. */
  originTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `runDate` field. */
  runDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `trainNum` field. */
  trainNum?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TripsLirr` */
export type TripsLirrInput = {
  destinationGtfsStopId: Scalars['String']['input'];
  destinationTime: Scalars['Datetime']['input'];
  direction?: InputMaybe<Direction>;
  gtfsRouteId: Scalars['String']['input'];
  gtfsTripId: Scalars['String']['input'];
  originGtfsStopId: Scalars['String']['input'];
  originTime: Scalars['Datetime']['input'];
  runDate: Scalars['Date']['input'];
  trainNum: Scalars['String']['input'];
};

/** Represents an update to a `TripsLirr`. Fields that are set will be updated. */
export type TripsLirrPatch = {
  destinationGtfsStopId?: InputMaybe<Scalars['String']['input']>;
  destinationTime?: InputMaybe<Scalars['Datetime']['input']>;
  direction?: InputMaybe<Direction>;
  gtfsRouteId?: InputMaybe<Scalars['String']['input']>;
  gtfsTripId?: InputMaybe<Scalars['String']['input']>;
  originGtfsStopId?: InputMaybe<Scalars['String']['input']>;
  originTime?: InputMaybe<Scalars['Datetime']['input']>;
  runDate?: InputMaybe<Scalars['Date']['input']>;
  trainNum?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `TripsLirr` values. */
export type TripsLirrsConnection = {
  __typename?: 'TripsLirrsConnection';
  /** A list of edges which contains the `TripsLirr` and cursor to aid in pagination. */
  edges: Array<TripsLirrsEdge>;
  /** A list of `TripsLirr` objects. */
  nodes: Array<TripsLirr>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TripsLirr` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TripsLirr` edge in the connection. */
export type TripsLirrsEdge = {
  __typename?: 'TripsLirrsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TripsLirr` at the end of the edge. */
  node: TripsLirr;
};

/** Methods to use when ordering `TripsLirr`. */
export enum TripsLirrsOrderBy {
  DESTINATION_GTFS_STOP_ID_ASC = 'DESTINATION_GTFS_STOP_ID_ASC',
  DESTINATION_GTFS_STOP_ID_DESC = 'DESTINATION_GTFS_STOP_ID_DESC',
  DESTINATION_TIME_ASC = 'DESTINATION_TIME_ASC',
  DESTINATION_TIME_DESC = 'DESTINATION_TIME_DESC',
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  GTFS_ROUTE_ID_ASC = 'GTFS_ROUTE_ID_ASC',
  GTFS_ROUTE_ID_DESC = 'GTFS_ROUTE_ID_DESC',
  GTFS_TRIP_ID_ASC = 'GTFS_TRIP_ID_ASC',
  GTFS_TRIP_ID_DESC = 'GTFS_TRIP_ID_DESC',
  NATURAL = 'NATURAL',
  ORIGIN_GTFS_STOP_ID_ASC = 'ORIGIN_GTFS_STOP_ID_ASC',
  ORIGIN_GTFS_STOP_ID_DESC = 'ORIGIN_GTFS_STOP_ID_DESC',
  ORIGIN_TIME_ASC = 'ORIGIN_TIME_ASC',
  ORIGIN_TIME_DESC = 'ORIGIN_TIME_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RUN_DATE_ASC = 'RUN_DATE_ASC',
  RUN_DATE_DESC = 'RUN_DATE_DESC',
  TRAIN_NUM_ASC = 'TRAIN_NUM_ASC',
  TRAIN_NUM_DESC = 'TRAIN_NUM_DESC',
}

/** Methods to use when ordering `Trip`. */
export enum TripsOrderBy {
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  GTFS_ID_ASC = 'GTFS_ID_ASC',
  GTFS_ID_DESC = 'GTFS_ID_DESC',
  GTFS_ROUTE_ID_ASC = 'GTFS_ROUTE_ID_ASC',
  GTFS_ROUTE_ID_DESC = 'GTFS_ROUTE_ID_DESC',
  HEADSIGN_ASC = 'HEADSIGN_ASC',
  HEADSIGN_DESC = 'HEADSIGN_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  RUN_DATE_ASC = 'RUN_DATE_ASC',
  RUN_DATE_DESC = 'RUN_DATE_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
}

/** Individual tweets per message. Multiple tweets if threaded. */
export type Tweet = Node & {
  __typename?: 'Tweet';
  /** Reads a single `User` that is related to this `Tweet`. */
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['UUID']['output']>;
  body: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  error?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  /** Reads a single `Message` that is related to this `Tweet`. */
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['Datetime']['output']>;
  quoteId?: Maybe<Scalars['String']['output']>;
  replyId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `TweetsUpload`. */
  tweetsUploads: TweetsUploadsConnection;
  twitterId?: Maybe<Scalars['String']['output']>;
};

/** Individual tweets per message. Multiple tweets if threaded. */
export type TweetTweetsUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetsUploadCondition>;
  filter?: InputMaybe<TweetsUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** A condition to be used against `Tweet` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TweetCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `quoteId` field. */
  quoteId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `replyId` field. */
  replyId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `twitterId` field. */
  twitterId?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Tweet` object types. All fields are combined with a logical ‘and.’ */
export type TweetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TweetFilter>>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `error` field. */
  error?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TweetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TweetFilter>>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `quoteId` field. */
  quoteId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `replyId` field. */
  replyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitterId` field. */
  twitterId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TweetPatchRecord` */
export type TweetPatchRecordInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  replyId?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Tweet` values. */
export type TweetsConnection = {
  __typename?: 'TweetsConnection';
  /** A list of edges which contains the `Tweet` and cursor to aid in pagination. */
  edges: Array<TweetsEdge>;
  /** A list of `Tweet` objects. */
  nodes: Array<Tweet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tweet` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Tweet` edge in the connection. */
export type TweetsEdge = {
  __typename?: 'TweetsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Tweet` at the end of the edge. */
  node: Tweet;
};

/** Methods to use when ordering `Tweet`. */
export enum TweetsOrderBy {
  AUTHOR_ID_ASC = 'AUTHOR_ID_ASC',
  AUTHOR_ID_DESC = 'AUTHOR_ID_DESC',
  BODY_ASC = 'BODY_ASC',
  BODY_DESC = 'BODY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ERROR_ASC = 'ERROR_ASC',
  ERROR_DESC = 'ERROR_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  MESSAGE_ID_ASC = 'MESSAGE_ID_ASC',
  MESSAGE_ID_DESC = 'MESSAGE_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PUBLISHED_AT_ASC = 'PUBLISHED_AT_ASC',
  PUBLISHED_AT_DESC = 'PUBLISHED_AT_DESC',
  QUOTE_ID_ASC = 'QUOTE_ID_ASC',
  QUOTE_ID_DESC = 'QUOTE_ID_DESC',
  REPLY_ID_ASC = 'REPLY_ID_ASC',
  REPLY_ID_DESC = 'REPLY_ID_DESC',
  TWITTER_ID_ASC = 'TWITTER_ID_ASC',
  TWITTER_ID_DESC = 'TWITTER_ID_DESC',
}

export type TweetsUpload = Node & {
  __typename?: 'TweetsUpload';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Tweet` that is related to this `TweetsUpload`. */
  tweet?: Maybe<Tweet>;
  tweetId: Scalars['Int']['output'];
  /** Reads a single `Upload` that is related to this `TweetsUpload`. */
  upload?: Maybe<Upload>;
  uploadId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `TweetsUpload` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TweetsUploadCondition = {
  /** Checks for equality with the object’s `tweetId` field. */
  tweetId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `uploadId` field. */
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `TweetsUpload` object types. All fields are combined with a logical ‘and.’ */
export type TweetsUploadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TweetsUploadFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TweetsUploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TweetsUploadFilter>>;
  /** Filter by the object’s `tweetId` field. */
  tweetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `uploadId` field. */
  uploadId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `TweetsUpload` */
export type TweetsUploadInput = {
  tweetId: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/** Represents an update to a `TweetsUpload`. Fields that are set will be updated. */
export type TweetsUploadPatch = {
  tweetId?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `TweetsUpload` values. */
export type TweetsUploadsConnection = {
  __typename?: 'TweetsUploadsConnection';
  /** A list of edges which contains the `TweetsUpload` and cursor to aid in pagination. */
  edges: Array<TweetsUploadsEdge>;
  /** A list of `TweetsUpload` objects. */
  nodes: Array<TweetsUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TweetsUpload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TweetsUpload` edge in the connection. */
export type TweetsUploadsEdge = {
  __typename?: 'TweetsUploadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TweetsUpload` at the end of the edge. */
  node: TweetsUpload;
};

/** Methods to use when ordering `TweetsUpload`. */
export enum TweetsUploadsOrderBy {
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TWEET_ID_ASC = 'TWEET_ID_ASC',
  TWEET_ID_DESC = 'TWEET_ID_DESC',
  UPLOAD_ID_ASC = 'UPLOAD_ID_ASC',
  UPLOAD_ID_DESC = 'UPLOAD_ID_DESC',
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** All input for the `updateAgencyByNodeId` mutation. */
export type UpdateAgencyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Agency` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Agency` being updated. */
  patch: AgencyPatch;
};

/** All input for the `updateAgency` mutation. */
export type UpdateAgencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Agency` being updated. */
  patch: AgencyPatch;
};

/** The output of our update `Agency` mutation. */
export type UpdateAgencyPayload = {
  __typename?: 'UpdateAgencyPayload';
  /** The `Agency` that was updated by this mutation. */
  agency?: Maybe<Agency>;
  /** An edge for our `Agency`. May be used by Relay 1. */
  agencyEdge?: Maybe<AgenciesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Agency`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Agency` mutation. */
export type UpdateAgencyPayloadAgencyEdgeArgs = {
  orderBy?: InputMaybe<Array<AgenciesOrderBy>>;
};

/** All input for the `updateCampaign` mutation. */
export type UpdateCampaignInput = {
  campaignInput?: InputMaybe<CampaignInputRecordInput>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `updateCampaign` mutation. */
export type UpdateCampaignPayload = {
  __typename?: 'UpdateCampaignPayload';
  /** Reads a single `User` that is related to this `Campaign`. */
  author?: Maybe<User>;
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  updater?: Maybe<User>;
};

/** The output of our `updateCampaign` mutation. */
export type UpdateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `updateCampaignScreenContentById` mutation. */
export type UpdateCampaignScreenContentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `CampaignScreenContent` being updated. */
  patch: CampaignScreenContentPatch;
};

/** The output of our update `CampaignScreenContent` mutation. */
export type UpdateCampaignScreenContentPayload = {
  __typename?: 'UpdateCampaignScreenContentPayload';
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  author?: Maybe<User>;
  /** Reads a single `Campaign` that is related to this `CampaignScreenContent`. */
  campaign?: Maybe<Campaign>;
  /** The `CampaignScreenContent` that was updated by this mutation. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** An edge for our `CampaignScreenContent`. May be used by Relay 1. */
  campaignScreenContentEdge?: Maybe<CampaignScreenContentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignScreenContent`. */
  updater?: Maybe<User>;
};

/** The output of our update `CampaignScreenContent` mutation. */
export type UpdateCampaignScreenContentPayloadCampaignScreenContentEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

/** All input for the `updateCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndex` mutation. */
export type UpdateCampaignScreenContentUploadByCampaignScreenContentIdAndSetIndexInput =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']['input']>;
    /** An object where the defined keys will be set on the `CampaignScreenContentUpload` being updated. */
    patch: CampaignScreenContentUploadPatch;
    setIndex: Scalars['Int']['input'];
  };

/** All input for the `updateCampaignScreenContentUploadByCampaignScreenContentIdAndUploadId` mutation. */
export type UpdateCampaignScreenContentUploadByCampaignScreenContentIdAndUploadIdInput =
  {
    campaignScreenContentId: Scalars['Int']['input'];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars['String']['input']>;
    /** An object where the defined keys will be set on the `CampaignScreenContentUpload` being updated. */
    patch: CampaignScreenContentUploadPatch;
    uploadId: Scalars['UUID']['input'];
  };

/** The output of our update `CampaignScreenContentUpload` mutation. */
export type UpdateCampaignScreenContentUploadPayload = {
  __typename?: 'UpdateCampaignScreenContentUploadPayload';
  /** Reads a single `CampaignScreenContent` that is related to this `CampaignScreenContentUpload`. */
  campaignScreenContent?: Maybe<CampaignScreenContent>;
  /** The `CampaignScreenContentUpload` that was updated by this mutation. */
  campaignScreenContentUpload?: Maybe<CampaignScreenContentUpload>;
  /** An edge for our `CampaignScreenContentUpload`. May be used by Relay 1. */
  campaignScreenContentUploadEdge?: Maybe<CampaignScreenContentUploadsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `CampaignScreenContentUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our update `CampaignScreenContentUpload` mutation. */
export type UpdateCampaignScreenContentUploadPayloadCampaignScreenContentUploadEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
  };

/** All input for the `updateClearedReportByNodeId` mutation. */
export type UpdateClearedReportByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `ClearedReport` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `ClearedReport` being updated. */
  patch: ClearedReportPatch;
};

/** All input for the `updateClearedReport` mutation. */
export type UpdateClearedReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `ClearedReport` being updated. */
  patch: ClearedReportPatch;
};

/** The output of our update `ClearedReport` mutation. */
export type UpdateClearedReportPayload = {
  __typename?: 'UpdateClearedReportPayload';
  /** The `ClearedReport` that was updated by this mutation. */
  clearedReport?: Maybe<ClearedReport>;
  /** An edge for our `ClearedReport`. May be used by Relay 1. */
  clearedReportEdge?: Maybe<ClearedReportsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ClearedReport` mutation. */
export type UpdateClearedReportPayloadClearedReportEdgeArgs = {
  orderBy?: InputMaybe<Array<ClearedReportsOrderBy>>;
};

/** All input for the `updateEvent` mutation. */
export type UpdateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  incidentEndAt?: InputMaybe<Scalars['Datetime']['input']>;
  incidentStartAt?: InputMaybe<Scalars['Datetime']['input']>;
  neededBy?: InputMaybe<Scalars['Datetime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** The output of our `updateEvent` mutation. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  /** Reads a single `User` that is related to this `Event`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Event`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `updateEvent` mutation. */
export type UpdateEventPayloadEventEdgeArgs = {
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

/** All input for the `updateFeatureFlagByNodeId` mutation. */
export type UpdateFeatureFlagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeatureFlag` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `FeatureFlag` being updated. */
  patch: FeatureFlagPatch;
};

/** All input for the `updateFeatureFlag` mutation. */
export type UpdateFeatureFlagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: FeatureFlagName;
  /** An object where the defined keys will be set on the `FeatureFlag` being updated. */
  patch: FeatureFlagPatch;
};

/** The output of our update `FeatureFlag` mutation. */
export type UpdateFeatureFlagPayload = {
  __typename?: 'UpdateFeatureFlagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeatureFlag` that was updated by this mutation. */
  featureFlag?: Maybe<FeatureFlag>;
  /** An edge for our `FeatureFlag`. May be used by Relay 1. */
  featureFlagEdge?: Maybe<FeatureFlagsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FeatureFlag` mutation. */
export type UpdateFeatureFlagPayloadFeatureFlagEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** All input for the `updateFeedByName` mutation. */
export type UpdateFeedByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Feed` being updated. */
  patch: FeedPatch;
};

/** All input for the `updateFeedByNodeId` mutation. */
export type UpdateFeedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Feed` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Feed` being updated. */
  patch: FeedPatch;
};

/** All input for the `updateFeedByOtpId` mutation. */
export type UpdateFeedByOtpIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Feed` being updated. */
  patch: FeedPatch;
};

/** All input for the `updateFeedGroupByNodeId` mutation. */
export type UpdateFeedGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedGroup` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `FeedGroup` being updated. */
  patch: FeedGroupPatch;
};

/** All input for the `updateFeedGroup` mutation. */
export type UpdateFeedGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `FeedGroup` being updated. */
  patch: FeedGroupPatch;
};

/** The output of our update `FeedGroup` mutation. */
export type UpdateFeedGroupPayload = {
  __typename?: 'UpdateFeedGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeedGroup` that was updated by this mutation. */
  feedGroup?: Maybe<FeedGroup>;
  /** An edge for our `FeedGroup`. May be used by Relay 1. */
  feedGroupEdge?: Maybe<FeedGroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FeedGroup` mutation. */
export type UpdateFeedGroupPayloadFeedGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedGroupsOrderBy>>;
};

/** All input for the `updateFeed` mutation. */
export type UpdateFeedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Feed` being updated. */
  patch: FeedPatch;
};

/** All input for the `updateFeedMessageTextAliasByGtfsIdAndAliasAndFeedId` mutation. */
export type UpdateFeedMessageTextAliasByGtfsIdAndAliasAndFeedIdInput = {
  alias: Scalars['String']['input'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
  gtfsId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `FeedMessageTextAlias` being updated. */
  patch: FeedMessageTextAliasPatch;
};

/** All input for the `updateFeedMessageTextAliasByNodeId` mutation. */
export type UpdateFeedMessageTextAliasByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedMessageTextAlias` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `FeedMessageTextAlias` being updated. */
  patch: FeedMessageTextAliasPatch;
};

/** All input for the `updateFeedMessageTextAlias` mutation. */
export type UpdateFeedMessageTextAliasInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `FeedMessageTextAlias` being updated. */
  patch: FeedMessageTextAliasPatch;
};

/** The output of our update `FeedMessageTextAlias` mutation. */
export type UpdateFeedMessageTextAliasPayload = {
  __typename?: 'UpdateFeedMessageTextAliasPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `FeedMessageTextAlias` that was updated by this mutation. */
  feedMessageTextAlias?: Maybe<FeedMessageTextAlias>;
  /** An edge for our `FeedMessageTextAlias`. May be used by Relay 1. */
  feedMessageTextAliasEdge?: Maybe<FeedMessageTextAliasesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FeedMessageTextAlias` mutation. */
export type UpdateFeedMessageTextAliasPayloadFeedMessageTextAliasEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedMessageTextAliasesOrderBy>>;
};

/** The output of our update `Feed` mutation. */
export type UpdateFeedPayload = {
  __typename?: 'UpdateFeedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Feed` that was updated by this mutation. */
  feed?: Maybe<Feed>;
  /** An edge for our `Feed`. May be used by Relay 1. */
  feedEdge?: Maybe<FeedsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Feed` mutation. */
export type UpdateFeedPayloadFeedEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** All input for the `updateFeedSettingByNodeId` mutation. */
export type UpdateFeedSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `FeedSetting` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `FeedSetting` being updated. */
  patch: FeedSettingPatch;
};

/** All input for the `updateFeedSetting` mutation. */
export type UpdateFeedSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `FeedSetting` being updated. */
  patch: FeedSettingPatch;
};

/** The output of our update `FeedSetting` mutation. */
export type UpdateFeedSettingPayload = {
  __typename?: 'UpdateFeedSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `FeedSetting`. */
  feed?: Maybe<Feed>;
  /** The `FeedSetting` that was updated by this mutation. */
  feedSetting?: Maybe<FeedSetting>;
  /** An edge for our `FeedSetting`. May be used by Relay 1. */
  feedSettingEdge?: Maybe<FeedSettingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FeedSetting` mutation. */
export type UpdateFeedSettingPayloadFeedSettingEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/** All input for the `updateFlag` mutation. */
export type UpdateFlagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feeds: Array<InputMaybe<FlagFeedInput>>;
  flagName: FeatureFlagName;
};

/** The output of our `updateFlag` mutation. */
export type UpdateFlagPayload = {
  __typename?: 'UpdateFlagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  featureFlag?: Maybe<FeatureFlag>;
  /** An edge for our `FeatureFlag`. May be used by Relay 1. */
  featureFlagEdge?: Maybe<FeatureFlagsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `updateFlag` mutation. */
export type UpdateFlagPayloadFeatureFlagEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** All input for the `updateLockableAsset` mutation. */
export type UpdateLockableAssetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newUploadId?: InputMaybe<Scalars['UUID']['input']>;
  oldThumbnailId?: InputMaybe<Scalars['UUID']['input']>;
  oldUploadId?: InputMaybe<Scalars['UUID']['input']>;
  screenType?: InputMaybe<ScreenType>;
  thumbnailId?: InputMaybe<Scalars['UUID']['input']>;
};

/** The output of our `updateLockableAsset` mutation. */
export type UpdateLockableAssetPayload = {
  __typename?: 'UpdateLockableAssetPayload';
  /** Reads a single `User` that is related to this `LockableUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  lockableUpload?: Maybe<LockableUpload>;
  /** An edge for our `LockableUpload`. May be used by Relay 1. */
  lockableUploadEdge?: Maybe<LockableUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  thumbnail?: Maybe<Upload>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our `updateLockableAsset` mutation. */
export type UpdateLockableAssetPayloadLockableUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/** All input for the `updateLockableUploadByNodeId` mutation. */
export type UpdateLockableUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LockableUpload` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LockableUpload` being updated. */
  patch: LockableUploadPatch;
};

/** All input for the `updateLockableUpload` mutation. */
export type UpdateLockableUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An object where the defined keys will be set on the `LockableUpload` being updated. */
  patch: LockableUploadPatch;
  uploadId: Scalars['UUID']['input'];
};

/** The output of our update `LockableUpload` mutation. */
export type UpdateLockableUploadPayload = {
  __typename?: 'UpdateLockableUploadPayload';
  /** Reads a single `User` that is related to this `LockableUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LockableUpload` that was updated by this mutation. */
  lockableUpload?: Maybe<LockableUpload>;
  /** An edge for our `LockableUpload`. May be used by Relay 1. */
  lockableUploadEdge?: Maybe<LockableUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  thumbnail?: Maybe<Upload>;
  /** Reads a single `Upload` that is related to this `LockableUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our update `LockableUpload` mutation. */
export type UpdateLockableUploadPayloadLockableUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

/** All input for the `updateMessageByNodeId` mutation. */
export type UpdateMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Message` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
};

/** All input for the `updateMessageImpactByNodeId` mutation. */
export type UpdateMessageImpactByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `MessageImpact` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `MessageImpact` being updated. */
  patch: MessageImpactPatch;
};

/** All input for the `updateMessageImpact` mutation. */
export type UpdateMessageImpactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `MessageImpact` being updated. */
  patch: MessageImpactPatch;
};

/** The output of our update `MessageImpact` mutation. */
export type UpdateMessageImpactPayload = {
  __typename?: 'UpdateMessageImpactPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Message` that is related to this `MessageImpact`. */
  message?: Maybe<Message>;
  /** The `MessageImpact` that was updated by this mutation. */
  messageImpact?: Maybe<MessageImpact>;
  /** An edge for our `MessageImpact`. May be used by Relay 1. */
  messageImpactEdge?: Maybe<MessageImpactsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `MessageImpact` mutation. */
export type UpdateMessageImpactPayloadMessageImpactEdgeArgs = {
  orderBy?: InputMaybe<Array<MessageImpactsOrderBy>>;
};

/** All input for the `updateMessage` mutation. */
export type UpdateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
};

/** The output of our update `Message` mutation. */
export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Event` that is related to this `Message`. */
  event?: Maybe<Event>;
  /** The `Message` that was updated by this mutation. */
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  updater?: Maybe<User>;
};

/** The output of our update `Message` mutation. */
export type UpdateMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `updatePlannedWork` mutation. */
export type UpdatePlannedWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  patch: PlannedWorkPatchRecordInput;
};

/** All input for the `updatePlannedWorkNotes` mutation. */
export type UpdatePlannedWorkNotesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  patch: PlannedWorkPatchRecordInput;
};

/** The output of our `updatePlannedWorkNotes` mutation. */
export type UpdatePlannedWorkNotesPayload = {
  __typename?: 'UpdatePlannedWorkNotesPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `updatePlannedWorkNotes` mutation. */
export type UpdatePlannedWorkNotesPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** The output of our `updatePlannedWork` mutation. */
export type UpdatePlannedWorkPayload = {
  __typename?: 'UpdatePlannedWorkPayload';
  /** Reads a single `User` that is related to this `PlannedWork`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  plannedWork?: Maybe<PlannedWork>;
  /** An edge for our `PlannedWork`. May be used by Relay 1. */
  plannedWorkEdge?: Maybe<PlannedWorksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  updater?: Maybe<User>;
  /** Reads a single `User` that is related to this `PlannedWork`. */
  userByClearedBy?: Maybe<User>;
};

/** The output of our `updatePlannedWork` mutation. */
export type UpdatePlannedWorkPayloadPlannedWorkEdgeArgs = {
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

/** All input for the `updateRouteByNodeId` mutation. */
export type UpdateRouteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Route` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Route` being updated. */
  patch: RoutePatch;
};

/** All input for the `updateRoute` mutation. */
export type UpdateRouteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Route` being updated. */
  patch: RoutePatch;
};

/** The output of our update `Route` mutation. */
export type UpdateRoutePayload = {
  __typename?: 'UpdateRoutePayload';
  /** Reads a single `Agency` that is related to this `Route`. */
  agency?: Maybe<Agency>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Route`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Route` that was updated by this mutation. */
  route?: Maybe<Route>;
  /** An edge for our `Route`. May be used by Relay 1. */
  routeEdge?: Maybe<RoutesEdge>;
};

/** The output of our update `Route` mutation. */
export type UpdateRoutePayloadRouteEdgeArgs = {
  orderBy?: InputMaybe<Array<RoutesOrderBy>>;
};

/** All input for the `updateScreenCustomCriteria` mutation. */
export type UpdateScreenCustomCriteriaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  screenCriteria?: InputMaybe<UpdateScreenCustomCriteriaInputRecordInput>;
};

/** An input for mutations affecting `UpdateScreenCustomCriteriaInputRecord` */
export type UpdateScreenCustomCriteriaInputRecordInput = {
  criteria?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `updateScreenCustomCriteria` mutation. */
export type UpdateScreenCustomCriteriaPayload = {
  __typename?: 'UpdateScreenCustomCriteriaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screens2?: Maybe<Screens2>;
  /** An edge for our `Screens2`. May be used by Relay 1. */
  screens2Edge?: Maybe<Screens2sEdge>;
};

/** The output of our `updateScreenCustomCriteria` mutation. */
export type UpdateScreenCustomCriteriaPayloadScreens2EdgeArgs = {
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/** All input for the `updateScreenLock` mutation. */
export type UpdateScreenLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  screenLock: ScreenLockInputRecordInput;
};

/** The output of our `updateScreenLock` mutation. */
export type UpdateScreenLockPayload = {
  __typename?: 'UpdateScreenLockPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screenLockTarget?: Maybe<ScreenLockTarget>;
};

/** All input for the `updateScreenNoteByName` mutation. */
export type UpdateScreenNoteByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `ScreenNote` being updated. */
  patch: ScreenNotePatch;
};

/** The output of our update `ScreenNote` mutation. */
export type UpdateScreenNotePayload = {
  __typename?: 'UpdateScreenNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ScreenNote` that was updated by this mutation. */
  screenNote?: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge?: Maybe<ScreenNotesEdge>;
};

/** The output of our update `ScreenNote` mutation. */
export type UpdateScreenNotePayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** All input for the `updateScreenNotes` mutation. */
export type UpdateScreenNotesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

/** The output of our `updateScreenNotes` mutation. */
export type UpdateScreenNotesPayload = {
  __typename?: 'UpdateScreenNotesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screenNote?: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge?: Maybe<ScreenNotesEdge>;
};

/** The output of our `updateScreenNotes` mutation. */
export type UpdateScreenNotesPayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** All input for the `updateScreens2ByNodeId` mutation. */
export type UpdateScreens2ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Screens2` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Screens2` being updated. */
  patch: Screens2Patch;
};

/** All input for the `updateScreens2` mutation. */
export type UpdateScreens2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Screens2` being updated. */
  patch: Screens2Patch;
};

/** The output of our update `Screens2` mutation. */
export type UpdateScreens2Payload = {
  __typename?: 'UpdateScreens2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Screens2` that was updated by this mutation. */
  screens2?: Maybe<Screens2>;
  /** An edge for our `Screens2`. May be used by Relay 1. */
  screens2Edge?: Maybe<Screens2sEdge>;
};

/** The output of our update `Screens2` mutation. */
export type UpdateScreens2PayloadScreens2EdgeArgs = {
  orderBy?: InputMaybe<Array<Screens2sOrderBy>>;
};

/** All input for the `updateScreensCustomCriteria` mutation. */
export type UpdateScreensCustomCriteriaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  screensCriteria?: InputMaybe<
    Array<InputMaybe<UpdateScreenCustomCriteriaInputRecordInput>>
  >;
};

/** The output of our `updateScreensCustomCriteria` mutation. */
export type UpdateScreensCustomCriteriaPayload = {
  __typename?: 'UpdateScreensCustomCriteriaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  screens2S?: Maybe<Array<Maybe<Screens2>>>;
};

/** All input for the `updateSocialMediaFolderByNodeId` mutation. */
export type UpdateSocialMediaFolderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SocialMediaFolder` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `SocialMediaFolder` being updated. */
  patch: SocialMediaFolderPatch;
};

/** All input for the `updateSocialMediaFolder` mutation. */
export type UpdateSocialMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `SocialMediaFolder` being updated. */
  patch: SocialMediaFolderPatch;
};

/** The output of our update `SocialMediaFolder` mutation. */
export type UpdateSocialMediaFolderPayload = {
  __typename?: 'UpdateSocialMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaFolder` that was updated by this mutation. */
  socialMediaFolder?: Maybe<SocialMediaFolder>;
  /** An edge for our `SocialMediaFolder`. May be used by Relay 1. */
  socialMediaFolderEdge?: Maybe<SocialMediaFoldersEdge>;
  /** Reads a single `User` that is related to this `SocialMediaFolder`. */
  updater?: Maybe<User>;
};

/** The output of our update `SocialMediaFolder` mutation. */
export type UpdateSocialMediaFolderPayloadSocialMediaFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

/** All input for the `updateSocialMediaUploadByNodeId` mutation. */
export type UpdateSocialMediaUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SocialMediaUpload` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `SocialMediaUpload` being updated. */
  patch: SocialMediaUploadPatch;
};

/** All input for the `updateSocialMediaUpload` mutation. */
export type UpdateSocialMediaUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An object where the defined keys will be set on the `SocialMediaUpload` being updated. */
  patch: SocialMediaUploadPatch;
  uploadId: Scalars['UUID']['input'];
};

/** The output of our update `SocialMediaUpload` mutation. */
export type UpdateSocialMediaUploadPayload = {
  __typename?: 'UpdateSocialMediaUploadPayload';
  /** Reads a single `User` that is related to this `SocialMediaUpload`. */
  author?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `SocialMediaFolder` that is related to this `SocialMediaUpload`. */
  folder?: Maybe<SocialMediaFolder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SocialMediaUpload` that was updated by this mutation. */
  socialMediaUpload?: Maybe<SocialMediaUpload>;
  /** An edge for our `SocialMediaUpload`. May be used by Relay 1. */
  socialMediaUploadEdge?: Maybe<SocialMediaUploadsEdge>;
  /** Reads a single `Upload` that is related to this `SocialMediaUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our update `SocialMediaUpload` mutation. */
export type UpdateSocialMediaUploadPayloadSocialMediaUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

/** All input for the `updateStopBlueprint` mutation. */
export type UpdateStopBlueprintInput = {
  blueprintUploadId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `updateStopBlueprint` mutation. */
export type UpdateStopBlueprintPayload = {
  __typename?: 'UpdateStopBlueprintPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Stop`. */
  feed?: Maybe<Feed>;
  /** Reads a single `Stop` that is related to this `Stop`. */
  parentStation?: Maybe<Stop>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  stop?: Maybe<Stop>;
  /** An edge for our `Stop`. May be used by Relay 1. */
  stopEdge?: Maybe<StopsEdge>;
};

/** The output of our `updateStopBlueprint` mutation. */
export type UpdateStopBlueprintPayloadStopEdgeArgs = {
  orderBy?: InputMaybe<Array<StopsOrderBy>>;
};

/** All input for the `updateSyntheticScreenByName` mutation. */
export type UpdateSyntheticScreenByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `SyntheticScreen` being updated. */
  patch: SyntheticScreenPatch;
};

/** All input for the `updateSyntheticScreenByNodeId` mutation. */
export type UpdateSyntheticScreenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SyntheticScreen` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `SyntheticScreen` being updated. */
  patch: SyntheticScreenPatch;
};

/** All input for the `updateSyntheticScreen` mutation. */
export type UpdateSyntheticScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `SyntheticScreen` being updated. */
  patch: SyntheticScreenPatch;
};

/** All input for the `updateSyntheticScreenNotes` mutation. */
export type UpdateSyntheticScreenNotesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  notes: Scalars['String']['input'];
};

/** The output of our `updateSyntheticScreenNotes` mutation. */
export type UpdateSyntheticScreenNotesPayload = {
  __typename?: 'UpdateSyntheticScreenNotesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `SyntheticScreen`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  syntheticScreen?: Maybe<SyntheticScreen>;
  /** An edge for our `SyntheticScreen`. May be used by Relay 1. */
  syntheticScreenEdge?: Maybe<SyntheticScreensEdge>;
};

/** The output of our `updateSyntheticScreenNotes` mutation. */
export type UpdateSyntheticScreenNotesPayloadSyntheticScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<SyntheticScreensOrderBy>>;
};

/** The output of our update `SyntheticScreen` mutation. */
export type UpdateSyntheticScreenPayload = {
  __typename?: 'UpdateSyntheticScreenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `SyntheticScreen`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SyntheticScreen` that was updated by this mutation. */
  syntheticScreen?: Maybe<SyntheticScreen>;
  /** An edge for our `SyntheticScreen`. May be used by Relay 1. */
  syntheticScreenEdge?: Maybe<SyntheticScreensEdge>;
};

/** The output of our update `SyntheticScreen` mutation. */
export type UpdateSyntheticScreenPayloadSyntheticScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<SyntheticScreensOrderBy>>;
};

/** All input for the `updateTripByNodeId` mutation. */
export type UpdateTripByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Trip` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Trip` being updated. */
  patch: TripPatch;
};

/** All input for the `updateTrip` mutation. */
export type UpdateTripInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `Trip` being updated. */
  patch: TripPatch;
};

/** The output of our update `Trip` mutation. */
export type UpdateTripPayload = {
  __typename?: 'UpdateTripPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Feed` that is related to this `Trip`. */
  feed?: Maybe<Feed>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Route` that is related to this `Trip`. */
  route?: Maybe<Route>;
  /** The `Trip` that was updated by this mutation. */
  trip?: Maybe<Trip>;
  /** An edge for our `Trip`. May be used by Relay 1. */
  tripEdge?: Maybe<TripsEdge>;
};

/** The output of our update `Trip` mutation. */
export type UpdateTripPayloadTripEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsOrderBy>>;
};

/** All input for the `updateTripsLirrByNodeId` mutation. */
export type UpdateTripsLirrByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `TripsLirr` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `TripsLirr` being updated. */
  patch: TripsLirrPatch;
};

/** All input for the `updateTripsLirrByTrainNum` mutation. */
export type UpdateTripsLirrByTrainNumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An object where the defined keys will be set on the `TripsLirr` being updated. */
  patch: TripsLirrPatch;
  trainNum: Scalars['String']['input'];
};

/** All input for the `updateTripsLirr` mutation. */
export type UpdateTripsLirrInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gtfsTripId: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `TripsLirr` being updated. */
  patch: TripsLirrPatch;
};

/** The output of our update `TripsLirr` mutation. */
export type UpdateTripsLirrPayload = {
  __typename?: 'UpdateTripsLirrPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Route` that is related to this `TripsLirr`. */
  gtfsRoute?: Maybe<Route>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TripsLirr` that was updated by this mutation. */
  tripsLirr?: Maybe<TripsLirr>;
  /** An edge for our `TripsLirr`. May be used by Relay 1. */
  tripsLirrEdge?: Maybe<TripsLirrsEdge>;
};

/** The output of our update `TripsLirr` mutation. */
export type UpdateTripsLirrPayloadTripsLirrEdgeArgs = {
  orderBy?: InputMaybe<Array<TripsLirrsOrderBy>>;
};

/** All input for the `updateTweetsUploadByNodeId` mutation. */
export type UpdateTweetsUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `TweetsUpload` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `TweetsUpload` being updated. */
  patch: TweetsUploadPatch;
};

/** All input for the `updateTweetsUpload` mutation. */
export type UpdateTweetsUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An object where the defined keys will be set on the `TweetsUpload` being updated. */
  patch: TweetsUploadPatch;
  tweetId: Scalars['Int']['input'];
  uploadId: Scalars['UUID']['input'];
};

/** The output of our update `TweetsUpload` mutation. */
export type UpdateTweetsUploadPayload = {
  __typename?: 'UpdateTweetsUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tweet` that is related to this `TweetsUpload`. */
  tweet?: Maybe<Tweet>;
  /** The `TweetsUpload` that was updated by this mutation. */
  tweetsUpload?: Maybe<TweetsUpload>;
  /** An edge for our `TweetsUpload`. May be used by Relay 1. */
  tweetsUploadEdge?: Maybe<TweetsUploadsEdge>;
  /** Reads a single `Upload` that is related to this `TweetsUpload`. */
  upload?: Maybe<Upload>;
};

/** The output of our update `TweetsUpload` mutation. */
export type UpdateTweetsUploadPayloadTweetsUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** All input for the `updateUploadByNodeId` mutation. */
export type UpdateUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Upload` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Upload` being updated. */
  patch: UploadPatch;
};

/** All input for the `updateUpload` mutation. */
export type UpdateUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `Upload` being updated. */
  patch: UploadPatch;
};

/** The output of our update `Upload` mutation. */
export type UpdateUploadPayload = {
  __typename?: 'UpdateUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Upload` that was updated by this mutation. */
  upload?: Maybe<Upload>;
  /** An edge for our `Upload`. May be used by Relay 1. */
  uploadEdge?: Maybe<UploadsEdge>;
};

/** The output of our update `Upload` mutation. */
export type UpdateUploadPayloadUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<UploadsOrderBy>>;
};

/** All input for the `updateUserFeedGroupGrantByNodeId` mutation. */
export type UpdateUserFeedGroupGrantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `UserFeedGroupGrant` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `UserFeedGroupGrant` being updated. */
  patch: UserFeedGroupGrantPatch;
};

/** All input for the `updateUserFeedGroupGrant` mutation. */
export type UpdateUserFeedGroupGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedGroupName: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `UserFeedGroupGrant` being updated. */
  patch: UserFeedGroupGrantPatch;
  userId: Scalars['UUID']['input'];
};

/** The output of our update `UserFeedGroupGrant` mutation. */
export type UpdateUserFeedGroupGrantPayload = {
  __typename?: 'UpdateUserFeedGroupGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `FeedGroup` that is related to this `UserFeedGroupGrant`. */
  feedGroupByFeedGroupName?: Maybe<FeedGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserFeedGroupGrant`. */
  user?: Maybe<User>;
  /** The `UserFeedGroupGrant` that was updated by this mutation. */
  userFeedGroupGrant?: Maybe<UserFeedGroupGrant>;
  /** An edge for our `UserFeedGroupGrant`. May be used by Relay 1. */
  userFeedGroupGrantEdge?: Maybe<UserFeedGroupGrantsEdge>;
};

/** The output of our update `UserFeedGroupGrant` mutation. */
export type UpdateUserFeedGroupGrantPayloadUserFeedGroupGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

/** All input for the `updateUsersAccessByNodeId` mutation. */
export type UpdateUsersAccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `UsersAccess` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `UsersAccess` being updated. */
  patch: UsersAccessPatch;
};

/** All input for the `updateUsersAccess` mutation. */
export type UpdateUsersAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An object where the defined keys will be set on the `UsersAccess` being updated. */
  patch: UsersAccessPatch;
  userId: Scalars['UUID']['input'];
};

/** The output of our update `UsersAccess` mutation. */
export type UpdateUsersAccessPayload = {
  __typename?: 'UpdateUsersAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersAccess`. */
  user?: Maybe<User>;
  /** The `UsersAccess` that was updated by this mutation. */
  usersAccess?: Maybe<UsersAccess>;
  /** An edge for our `UsersAccess`. May be used by Relay 1. */
  usersAccessEdge?: Maybe<UsersAccessesEdge>;
};

/** The output of our update `UsersAccess` mutation. */
export type UpdateUsersAccessPayloadUsersAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

export type Upload = Node & {
  __typename?: 'Upload';
  /** Reads and enables pagination through a set of `CampaignScreenContentUpload`. */
  campaignScreenContentUploads: CampaignScreenContentUploadsConnection;
  createdAt: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dimension?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `FallbackScreensContent`. */
  fallbackScreensContents: FallbackScreensContentsConnection;
  feedId?: Maybe<Scalars['String']['output']>;
  hasMotion: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  isLandscape?: Maybe<Scalars['Boolean']['output']>;
  isLockable?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `LockableUpload` that is related to this `Upload`. */
  lockableUploadByUploadId?: Maybe<LockableUpload>;
  /**
   * Reads and enables pagination through a set of `LockableUpload`.
   * @deprecated Please use lockableUploadByUploadId instead
   */
  lockableUploads: LockableUploadsConnection;
  /** Reads and enables pagination through a set of `LockableUpload`. */
  lockableUploadsByThumbnailId: LockableUploadsConnection;
  mimeType: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  originalFilename?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PlannedWorkScreenMessage`. */
  plannedWorkScreenMessages: PlannedWorkScreenMessagesConnection;
  s3Url: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `ScreenLock`. */
  screenLocks: ScreenLocksConnection;
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  screenMessages: ScreenMessagesConnection;
  searchTitle?: Maybe<Scalars['String']['output']>;
  signedS3Url?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  /** Reads a single `SocialMediaUpload` that is related to this `Upload`. */
  socialMediaUploadByUploadId?: Maybe<SocialMediaUpload>;
  /**
   * Reads and enables pagination through a set of `SocialMediaUpload`.
   * @deprecated Please use socialMediaUploadByUploadId instead
   */
  socialMediaUploads: SocialMediaUploadsConnection;
  title?: Maybe<Scalars['String']['output']>;
  transitId?: Maybe<Scalars['String']['output']>;
  transitSettings?: Maybe<TransitSetting>;
  /** Reads and enables pagination through a set of `TweetsUpload`. */
  tweetsUploads: TweetsUploadsConnection;
  updatedAt: Scalars['Datetime']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadCampaignScreenContentUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentUploadCondition>;
  filter?: InputMaybe<CampaignScreenContentUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentUploadsOrderBy>>;
};

export type UploadFallbackScreensContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FallbackScreensContentCondition>;
  filter?: InputMaybe<FallbackScreensContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FallbackScreensContentsOrderBy>>;
};

export type UploadLockableUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockableUploadCondition>;
  filter?: InputMaybe<LockableUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

export type UploadLockableUploadsByThumbnailIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockableUploadCondition>;
  filter?: InputMaybe<LockableUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

export type UploadPlannedWorkScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkScreenMessageCondition>;
  filter?: InputMaybe<PlannedWorkScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorkScreenMessagesOrderBy>>;
};

export type UploadScreenLocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenLockCondition>;
  filter?: InputMaybe<ScreenLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenLocksOrderBy>>;
};

export type UploadScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenMessageCondition>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenMessagesOrderBy>>;
};

export type UploadSocialMediaUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaUploadCondition>;
  filter?: InputMaybe<SocialMediaUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

export type UploadTweetsUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetsUploadCondition>;
  filter?: InputMaybe<TweetsUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsUploadsOrderBy>>;
};

/** A condition to be used against `Upload` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UploadCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `mimeType` field. */
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `originalFilename` field. */
  originalFilename?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `s3Url` field. */
  s3Url?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitId` field. */
  transitId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transitSettings` field. */
  transitSettings?: InputMaybe<TransitSettingInput>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `Upload` object types. All fields are combined with a logical ‘and.’ */
export type UploadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UploadFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dimension` field. */
  dimension?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasMotion` field. */
  hasMotion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isLandscape` field. */
  isLandscape?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isLockable` field. */
  isLockable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `mimeType` field. */
  mimeType?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UploadFilter>>;
  /** Filter by the object’s `originalFilename` field. */
  originalFilename?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3Url` field. */
  s3Url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `searchTitle` field. */
  searchTitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `signedS3Url` field. */
  signedS3Url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transitId` field. */
  transitId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Upload` */
export type UploadInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  mimeType: Scalars['String']['input'];
  originalFilename?: InputMaybe<Scalars['String']['input']>;
  s3Url: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  transitId?: InputMaybe<Scalars['String']['input']>;
  transitSettings?: InputMaybe<TransitSettingInput>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents an update to a `Upload`. Fields that are set will be updated. */
export type UploadPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feedId?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  originalFilename?: InputMaybe<Scalars['String']['input']>;
  s3Url?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  transitId?: InputMaybe<Scalars['String']['input']>;
  transitSettings?: InputMaybe<TransitSettingInput>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `Upload` values. */
export type UploadsConnection = {
  __typename?: 'UploadsConnection';
  /** A list of edges which contains the `Upload` and cursor to aid in pagination. */
  edges: Array<UploadsEdge>;
  /** A list of `Upload` objects. */
  nodes: Array<Upload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Upload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Upload` edge in the connection. */
export type UploadsEdge = {
  __typename?: 'UploadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Upload` at the end of the edge. */
  node: Upload;
};

/** Methods to use when ordering `Upload`. */
export enum UploadsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  FEED_ID_ASC = 'FEED_ID_ASC',
  FEED_ID_DESC = 'FEED_ID_DESC',
  HEIGHT_ASC = 'HEIGHT_ASC',
  HEIGHT_DESC = 'HEIGHT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  MIME_TYPE_ASC = 'MIME_TYPE_ASC',
  MIME_TYPE_DESC = 'MIME_TYPE_DESC',
  NATURAL = 'NATURAL',
  ORIGINAL_FILENAME_ASC = 'ORIGINAL_FILENAME_ASC',
  ORIGINAL_FILENAME_DESC = 'ORIGINAL_FILENAME_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  S3_URL_ASC = 'S3_URL_ASC',
  S3_URL_DESC = 'S3_URL_DESC',
  SIZE_ASC = 'SIZE_ASC',
  SIZE_DESC = 'SIZE_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TRANSIT_ID_ASC = 'TRANSIT_ID_ASC',
  TRANSIT_ID_DESC = 'TRANSIT_ID_DESC',
  TRANSIT_SETTINGS_ASC = 'TRANSIT_SETTINGS_ASC',
  TRANSIT_SETTINGS_DESC = 'TRANSIT_SETTINGS_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  WIDTH_ASC = 'WIDTH_ASC',
  WIDTH_DESC = 'WIDTH_DESC',
}

export type User = Node & {
  __typename?: 'User';
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  authoredCampaignScreenContents: CampaignScreenContentsConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  authoredCampaigns: CampaignsConnection;
  /** Reads and enables pagination through a set of `DraftMessage`. */
  authoredDraftMessages: DraftMessagesConnection;
  /** Reads and enables pagination through a set of `Event`. */
  authoredEvents: EventsConnection;
  /** Reads and enables pagination through a set of `LockableUpload`. */
  authoredLockableUploads: LockableUploadsConnection;
  /** Reads and enables pagination through a set of `Message`. */
  authoredMessages: MessagesConnection;
  /** Reads and enables pagination through a set of `PlannedWork`. */
  authoredPlannedWorks: PlannedWorksConnection;
  /** Reads and enables pagination through a set of `ScreenMessage`. */
  authoredScreenMessages: ScreenMessagesConnection;
  /** Reads and enables pagination through a set of `SocialMediaUpload`. */
  authoredSocialMediaUploads: SocialMediaUploadsConnection;
  /** Reads and enables pagination through a set of `Tweet`. */
  authoredTweets: TweetsConnection;
  /** Reads and enables pagination through a set of `CampaignScreenContent`. */
  campaignScreenContentsByUpdaterId: CampaignScreenContentsConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaignsByUpdaterId: CampaignsConnection;
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `Event`. */
  eventsByClearedBy: EventsConnection;
  /**
   * Returns a json tree shaped as follows:
   *
   * {
   *   FEATURE_FLAG_NAME: { // capitalized to match postgraphile
   *     feed_id: true | false // true if feature enabled for this user in this feed
   *   }
   * }
   *
   */
  featureFlags?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['UUID']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastSeenAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `Message`. */
  messagesByUpdaterId: MessagesConnection;
  name?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PlannedWork`. */
  plannedWorksByClearedBy: PlannedWorksConnection;
  /** Reads and enables pagination through a set of `PlannedWork`. */
  plannedWorksByUpdaterId: PlannedWorksConnection;
  /** Reads and enables pagination through a set of `SocialMediaFolder`. */
  socialMediaFoldersByUpdaterId: SocialMediaFoldersConnection;
  /** Reads and enables pagination through a set of `UserFeedGroupGrant`. */
  userFeedGroupGrants: UserFeedGroupGrantsConnection;
  /** Reads a single `UsersAccess` that is related to this `User`. */
  usersAccessByUserId?: Maybe<UsersAccess>;
  /**
   * Reads and enables pagination through a set of `UsersAccess`.
   * @deprecated Please use usersAccessByUserId instead
   */
  usersAccesses: UsersAccessesConnection;
};

export type UserAuthoredCampaignScreenContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentCondition>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

export type UserAuthoredCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

export type UserAuthoredDraftMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DraftMessageCondition>;
  filter?: InputMaybe<DraftMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DraftMessagesOrderBy>>;
};

export type UserAuthoredEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

export type UserAuthoredLockableUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockableUploadCondition>;
  filter?: InputMaybe<LockableUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockableUploadsOrderBy>>;
};

export type UserAuthoredMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type UserAuthoredPlannedWorksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkCondition>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

export type UserAuthoredScreenMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ScreenMessageCondition>;
  filter?: InputMaybe<ScreenMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScreenMessagesOrderBy>>;
};

export type UserAuthoredSocialMediaUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaUploadCondition>;
  filter?: InputMaybe<SocialMediaUploadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaUploadsOrderBy>>;
};

export type UserAuthoredTweetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TweetCondition>;
  filter?: InputMaybe<TweetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TweetsOrderBy>>;
};

export type UserCampaignScreenContentsByUpdaterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignScreenContentCondition>;
  filter?: InputMaybe<CampaignScreenContentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignScreenContentsOrderBy>>;
};

export type UserCampaignsByUpdaterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

export type UserEventsByClearedByArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EventCondition>;
  filter?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
};

export type UserMessagesByUpdaterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type UserPlannedWorksByClearedByArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkCondition>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

export type UserPlannedWorksByUpdaterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PlannedWorkCondition>;
  filter?: InputMaybe<PlannedWorkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlannedWorksOrderBy>>;
};

export type UserSocialMediaFoldersByUpdaterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialMediaFolderCondition>;
  filter?: InputMaybe<SocialMediaFolderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialMediaFoldersOrderBy>>;
};

export type UserUserFeedGroupGrantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserFeedGroupGrantCondition>;
  filter?: InputMaybe<UserFeedGroupGrantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserFeedGroupGrantsOrderBy>>;
};

export type UserUsersAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UsersAccessCondition>;
  filter?: InputMaybe<UsersAccessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersAccessesOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lastSeenAt` field. */
  lastSeenAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserFeedGroupGrant = Node & {
  __typename?: 'UserFeedGroupGrant';
  /** Reads a single `FeedGroup` that is related to this `UserFeedGroupGrant`. */
  feedGroupByFeedGroupName?: Maybe<FeedGroup>;
  feedGroupName: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `User` that is related to this `UserFeedGroupGrant`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `UserFeedGroupGrant` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserFeedGroupGrantCondition = {
  /** Checks for equality with the object’s `feedGroupName` field. */
  feedGroupName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `UserFeedGroupGrant` object types. All fields are combined with a logical ‘and.’ */
export type UserFeedGroupGrantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFeedGroupGrantFilter>>;
  /** Filter by the object’s `feedGroupName` field. */
  feedGroupName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFeedGroupGrantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFeedGroupGrantFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `UserFeedGroupGrant` */
export type UserFeedGroupGrantInput = {
  feedGroupName: Scalars['String']['input'];
  userId: Scalars['UUID']['input'];
};

/** Represents an update to a `UserFeedGroupGrant`. Fields that are set will be updated. */
export type UserFeedGroupGrantPatch = {
  feedGroupName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `UserFeedGroupGrant` values. */
export type UserFeedGroupGrantsConnection = {
  __typename?: 'UserFeedGroupGrantsConnection';
  /** A list of edges which contains the `UserFeedGroupGrant` and cursor to aid in pagination. */
  edges: Array<UserFeedGroupGrantsEdge>;
  /** A list of `UserFeedGroupGrant` objects. */
  nodes: Array<UserFeedGroupGrant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserFeedGroupGrant` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserFeedGroupGrant` edge in the connection. */
export type UserFeedGroupGrantsEdge = {
  __typename?: 'UserFeedGroupGrantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserFeedGroupGrant` at the end of the edge. */
  node: UserFeedGroupGrant;
};

/** Methods to use when ordering `UserFeedGroupGrant`. */
export enum UserFeedGroupGrantsOrderBy {
  FEED_GROUP_NAME_ASC = 'FEED_GROUP_NAME_ASC',
  FEED_GROUP_NAME_DESC = 'FEED_GROUP_NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  USER_ID_ASC = 'USER_ID_ASC',
  USER_ID_DESC = 'USER_ID_DESC',
}

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `featureFlags` field. */
  featureFlags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isAdmin` field. */
  isAdmin?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastSeenAt` field. */
  lastSeenAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
};

export type UsersAccess = Node & {
  __typename?: 'UsersAccess';
  isActive: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `User` that is related to this `UsersAccess`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `UsersAccess` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UsersAccessCondition = {
  /** Checks for equality with the object’s `isAdmin` field. */
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `UsersAccess` object types. All fields are combined with a logical ‘and.’ */
export type UsersAccessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsersAccessFilter>>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isAdmin` field. */
  isAdmin?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsersAccessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsersAccessFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `UsersAccess` */
export type UsersAccessInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['UUID']['input'];
};

/** Represents an update to a `UsersAccess`. Fields that are set will be updated. */
export type UsersAccessPatch = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `UsersAccess` values. */
export type UsersAccessesConnection = {
  __typename?: 'UsersAccessesConnection';
  /** A list of edges which contains the `UsersAccess` and cursor to aid in pagination. */
  edges: Array<UsersAccessesEdge>;
  /** A list of `UsersAccess` objects. */
  nodes: Array<UsersAccess>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersAccess` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UsersAccess` edge in the connection. */
export type UsersAccessesEdge = {
  __typename?: 'UsersAccessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UsersAccess` at the end of the edge. */
  node: UsersAccess;
};

/** Methods to use when ordering `UsersAccess`. */
export enum UsersAccessesOrderBy {
  IS_ADMIN_ASC = 'IS_ADMIN_ASC',
  IS_ADMIN_DESC = 'IS_ADMIN_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  USER_ID_ASC = 'USER_ID_ASC',
  USER_ID_DESC = 'USER_ID_DESC',
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_ADMIN_ASC = 'IS_ADMIN_ASC',
  IS_ADMIN_DESC = 'IS_ADMIN_DESC',
  LAST_SEEN_AT_ASC = 'LAST_SEEN_AT_ASC',
  LAST_SEEN_AT_DESC = 'LAST_SEEN_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
}
