import React, { Suspense } from 'react';

import { ScreenContentAttributes } from '@mta-live-media-manager/shared';
import Message from './Message';
import NoContent from '../NoContent';
import { FeedId } from '../../../../../types/feed';

interface IScreenContent {
  feedId?: FeedId;
  attributes?: ScreenContentAttributes;
  isLandscape?: boolean;
  isDup?: boolean;
}

const ScreenContent: React.FC<IScreenContent> = ({
  feedId,
  attributes,
  isLandscape = false,
  isDup = false,
}) => {
  if (typeof feedId === 'undefined' || !attributes) {
    return <NoContent isLandscape={isLandscape} />;
  }

  return (
    <Suspense fallback={<div />}>
      <Message
        {...attributes}
        feedId={feedId}
        isLandscape={isLandscape}
        isDup={isDup}
      />
    </Suspense>
  );
};

export default ScreenContent;
