import React from 'react';
import styled from 'styled-components';

import pxToViewport from '../../../utils/px-to-viewport-units';

const Title = styled.h1`
  line-height: ${pxToViewport(72)}rem;
  letter-spacing: -${pxToViewport(1)}rem;
  font-size: ${pxToViewport(56)}rem;
  padding-bottom: ${pxToViewport(64)}rem;
  padding-top: ${pxToViewport(24)}rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PageHeader = styled.header`
  background: #000;
  color: #fff;
  padding: ${pxToViewport(0)}rem ${pxToViewport(24)}rem;
`;

const PortraitHeader: React.FC<{
  title?: string;
}> = ({ title }) => {
  return (
    <PageHeader>
      <Title>{title}</Title>
    </PageHeader>
  );
};

export default PortraitHeader;
