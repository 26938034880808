import React from 'react';
import { captureException } from '../utils/sentry';
import NoContent from './NoContent';

type TProps = {
  children: React.ReactNode;
};
type TState = { hasError: boolean };

export default class ErrorBoundary extends React.Component<TProps, TState> {
  static getDerivedStateFromError(): TState {
    return { hasError: true };
  }

  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error): void {
    captureException(error);
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <NoContent /> : <>{children}</>;
  }
}
