"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.humanReadableDurations = exports.DurationsType = void 0;
const date_fns_1 = require("date-fns");
const date_fns_tz_1 = require("date-fns-tz");
const lodash_1 = require("lodash");
const date_holidays_1 = require("date-holidays");
const construct_events_1 = require("./construct-events");
const event_helpers_1 = require("./event-helpers");
const TIME_ZONE = 'America/New_York';
const TIME_FORMAT = 'h:mm a';
const DATE_FORMAT = 'd';
const MONTH_FORMAT = 'MMM';
const WEEK_DAY_FORMAT = 'ccc';
const hasHolidays = (duration) => {
    const holidaysCheck = new date_holidays_1.default();
    holidaysCheck.init('US', { types: ['public'] });
    const holidays = [];
    const startHoliday = holidaysCheck.isHoliday(new Date(duration.start.value));
    const endHoliday = holidaysCheck.isHoliday(new Date(duration?.end?.value));
    if (startHoliday && !holidays.includes(startHoliday.name)) {
        holidays.push(startHoliday.name);
    }
    if (endHoliday && !holidays.includes(endHoliday.name)) {
        holidays.push(endHoliday.name);
    }
    return holidays;
};
const dateToString = (date) => {
    return date instanceof Date ? date.toISOString() : date;
};
var DurationsType;
(function (DurationsType) {
    DurationsType["CADENCE"] = "CADENCE";
    DurationsType["DIRECT"] = "DIRECT";
})(DurationsType = exports.DurationsType || (exports.DurationsType = {}));
const humanReadableDurations = (dates, options, exceptions) => {
    if (options?.overrideText) {
        return options.overrideText;
    }
    if (!dates.length) {
        return '';
    }
    const type = options?.type ?? DurationsType.DIRECT;
    const isOngoing = options?.isOngoing ?? false;
    const ongoingText = options?.ongoingText || 'Ongoing';
    const handleOngoingAsSeperateEvent = type === DurationsType.DIRECT && isOngoing;
    let eventsNotSameDates = false;
    let ongoingEvent;
    const mappedDates = dates
        .map(d => ({
        start: { value: dateToString(d.start.value) },
        ...(d?.end?.value ? { end: { value: dateToString(d.end.value) } } : {}),
    }))
        .sort((a, b) => a.start.value.localeCompare(b.start.value))
        .map(date => ({
        start: (0, date_fns_1.parseISO)(date.start.value),
        end: date?.end?.value ? (0, date_fns_1.parseISO)(date.end.value) : null,
        timeException: '',
        startWeekday: (0, event_helpers_1.formatDate)(date.start.value, WEEK_DAY_FORMAT),
        endWeekday: date?.end?.value
            ? (0, event_helpers_1.formatDate)(date.end.value, WEEK_DAY_FORMAT)
            : null,
        startDate: (0, event_helpers_1.formatDate)(date.start.value, DATE_FORMAT),
        endDate: date?.end?.value
            ? (0, event_helpers_1.formatDate)(date.end.value, DATE_FORMAT)
            : null,
        startMonth: (0, event_helpers_1.formatDate)(date.start.value, MONTH_FORMAT),
        endMonth: date?.end?.value
            ? (0, event_helpers_1.formatDate)(date.end.value, MONTH_FORMAT)
            : null,
        startTime: `${(0, event_helpers_1.formatDate)(date.start.value, TIME_FORMAT)}`,
        endTime: date?.end?.value
            ? `${(0, event_helpers_1.formatDate)(date.end.value, TIME_FORMAT)}`
            : null,
        sameDay: !!date?.end?.value &&
            (0, date_fns_1.isSameDay)((0, date_fns_tz_1.utcToZonedTime)(date.start.value, TIME_ZONE), (0, date_fns_tz_1.utcToZonedTime)(date.end.value, TIME_ZONE)),
        sameDayDurations: [],
        skipListingDurations: false,
        holidays: [], //hasHolidays(date),
    }));
    const mappedExceptions = (type === DurationsType.CADENCE
        ? exceptions ?? []
        : [])
        .filter(d => d.start && d.end && d.start.value !== d.end.value)
        .sort((a, b) => a.start.value.localeCompare(b.start.value))
        .map(date => {
        const startDate = new Date(date.start.value);
        let endDate = new Date(date.end?.value);
        // Subtracts 1 day from the end date if whole day(s) are selected
        // example: 05/04/2022 00:00 - 05/05/2022 00:00 shows 'May 4' instead of 'May 4, 5'
        if (!endDate.getHours() &&
            !endDate.getMinutes() &&
            !startDate.getHours() &&
            !startDate.getMinutes()) {
            endDate = (0, date_fns_1.subDays)(endDate, 1);
        }
        return {
            start: (0, date_fns_1.parseISO)(startDate.toISOString()),
            startTime: `${(0, event_helpers_1.formatDate)(startDate, TIME_FORMAT)}`,
            startDate: (0, event_helpers_1.formatDate)(startDate, DATE_FORMAT),
            startMonth: (0, event_helpers_1.formatDate)(startDate, MONTH_FORMAT),
            end: (0, date_fns_1.parseISO)(endDate.toISOString()),
            endTime: `${(0, event_helpers_1.formatDate)(endDate, TIME_FORMAT)}`,
            endDate: (0, event_helpers_1.formatDate)(endDate, DATE_FORMAT),
            endMonth: (0, event_helpers_1.formatDate)(endDate, MONTH_FORMAT),
        };
    });
    if (mappedDates.length === 1) {
        return (0, construct_events_1.generateSingleDuration)(mappedDates[0], isOngoing, ongoingText, mappedExceptions);
    }
    if (handleOngoingAsSeperateEvent) {
        ongoingEvent = mappedDates.pop();
    }
    // Adds handling for when cadence range ends before the day of week the cadence ends on
    if (!isOngoing && type === DurationsType.CADENCE && options?.cadenceEnd) {
        const lastDateRange = mappedDates[mappedDates.length - 1];
        const actualCadenceEnd = options.cadenceEnd.inclusive
            ? new Date(`${options?.cadenceEnd.value}T00:00:00`)
            : (0, date_fns_1.subDays)(new Date(`${options.cadenceEnd.value}T00:00:00`), 1);
        // This can moved to a more exception type format if needed
        if (lastDateRange.end && lastDateRange.end > actualCadenceEnd) {
            lastDateRange.endMonth = (0, event_helpers_1.formatDate)(actualCadenceEnd, 'MMM');
            lastDateRange.endWeekday = (0, event_helpers_1.formatDate)(actualCadenceEnd, 'ccc');
            lastDateRange.endDate = (0, event_helpers_1.formatDate)(actualCadenceEnd, 'd');
            lastDateRange.endTime = lastDateRange.sameDay ? (0, event_helpers_1.formatDate)(lastDateRange.end, TIME_FORMAT) : 'Midnight';
        }
    }
    const sameDayDates = Object.values((0, lodash_1.groupBy)(mappedDates.filter(d => d.end && d.sameDay), d => `${d.startMonth} ${d.startDate}`));
    if (sameDayDates.length) {
        sameDayDates.forEach(dates => {
            if (dates.length > 1 &&
                dates.every(date => !mappedDates.find(d => `${d.startTime} ${d.endTime}` ===
                    `${date.startTime} ${date.endTime}` &&
                    `${d.startMonth} ${d.startDate}` !==
                        `${date.endMonth} ${date.endDate}`))) {
                for (let i = 1; i < dates.length; i++) {
                    dates[0].sameDayDurations.push(`${dates[i].startTime} to ${dates[i].endTime}`);
                    dates[i].skipListingDurations = true;
                }
            }
        });
    }
    // groups durations by start/end hours and minutes, and the numbers of days they span
    const groupByDuration = (0, lodash_1.groupBy)(mappedDates, date => `${date.start.getHours()}:${date.start.getMinutes()} - ${date.end?.getHours()}:${date.end?.getMinutes()} - ${date.end ? (0, date_fns_1.differenceInCalendarDays)(date.end, date.start) : '0'}`);
    let events = Object.values(groupByDuration);
    for (let i = 0; i < events.length - 1; i += 1) {
        for (let j = i + 1; j < events.length; j += 1) {
            // check for event durations with same start or end time and mark as time exceptions
            if (type === DurationsType.DIRECT) {
                const sameStartTimeAndSpan = events[j].filter(item1 => {
                    const item1DaySpan = item1.end
                        ? (0, date_fns_1.differenceInCalendarDays)(item1.end, item1.start)
                        : 0;
                    return events[i].some(item2 => {
                        const item2DaySpan = item2.end
                            ? (0, date_fns_1.differenceInCalendarDays)(item2.end, item2.start)
                            : 0;
                        return (item2.startTime === item1.startTime &&
                            item2DaySpan === item1DaySpan);
                    });
                });
                const sameEndTimeAndSpan = events[j].filter(item1 => {
                    const item1DaySpan = item1.end
                        ? (0, date_fns_1.differenceInCalendarDays)(item1.end, item1.start)
                        : 0;
                    return events[i].some(item2 => {
                        const item2DaySpan = item2.end
                            ? (0, date_fns_1.differenceInCalendarDays)(item2.end, item2.start)
                            : 0;
                        return (item2.endTime === item1.endTime && item2DaySpan === item1DaySpan);
                    });
                });
                events[j] = events[j].filter(d => !sameStartTimeAndSpan.includes(d) &&
                    !sameEndTimeAndSpan.includes(d));
                sameStartTimeAndSpan.forEach(d => {
                    d.timeException = `ends at ${d.endTime} on ${d.endMonth} ${d.endDate}`;
                    events[i].push(d);
                });
                sameEndTimeAndSpan.forEach(d => {
                    d.timeException = `starts at ${d.startTime} on ${d.startMonth} ${d.startDate}`;
                    events[i].push(d);
                });
            }
            // check the events are on the same dates
            const checkSameDates = events[j].filter(item1 => events[i].some(item2 => item2.start.getUTCDate() === item1.start.getUTCDate() &&
                ((!item1.end && !item2.end) ||
                    item2.end?.getUTCDate() === item1.end?.getUTCDate())));
            if (checkSameDates.length !== events[i].length) {
                eventsNotSameDates = true;
            }
            events[i].sort((a, b) => a.start.getTime() - b.start.getTime());
        }
        events = events.filter(x => x.length !== 0);
    }
    if (events.length === 1 || eventsNotSameDates) {
        if (handleOngoingAsSeperateEvent && ongoingEvent) {
            events.push([ongoingEvent]);
        }
        return (0, construct_events_1.constructEvents)(events, isOngoing, ongoingText, type, mappedExceptions);
    }
    return (0, construct_events_1.constructMultiEvents)(events, mappedExceptions);
};
exports.humanReadableDurations = humanReadableDurations;
exports.default = exports.humanReadableDurations;
