import pxToViewport from './px-to-viewport-units';

const getHeadlineFontSize = (characterCount: number): number => {
  if (characterCount >= 23) {
    return pxToViewport(48);
  }

  if (characterCount >= 16) {
    return pxToViewport(52);
  }

  return pxToViewport(80);
};

export default getHeadlineFontSize;
