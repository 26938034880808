import React, { Suspense } from 'react';

import { PlannedWorkTemplateProps } from '../../types';
import Portrait from './Portrait';
import Landscape from './Landscape';
import Dup from '../Alert/Dup';

const ScreenContent: React.FC<PlannedWorkTemplateProps> = ({
  isLandscape,
  feedId,
  messageType,
  isAtNight,
  headline,
  humanReadableDurations,
  affectsADA = false,
  isDup = false,
  title,
  description,
  additionalInfo,
  affectedRoutes = [],
}) => {
  if (isDup) {
    return <Dup content={description} />;
  }

  return (
    <Suspense fallback={<div />}>
      {isLandscape ? (
        <Landscape
          messageType={messageType}
          isAtNight={isAtNight}
          headline={headline}
          humanReadableDurations={humanReadableDurations}
          affectedRoutes={affectedRoutes}
          description={description}
          additionalInfo={additionalInfo}
          title={title}
        />
      ) : (
        <Portrait
          feedId={feedId}
          messageType={messageType}
          isAtNight={isAtNight}
          headline={headline}
          humanReadableDurations={humanReadableDurations}
          title={title}
          affectsADA={affectsADA}
          description={description}
          additionalInfo={additionalInfo}
          affectedRoutes={affectedRoutes}
        />
      )}
    </Suspense>
  );
};

export default ScreenContent;
