import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import dayjs from '../../../utils/dayjs';
import pxToViewport from '../../../utils/px-to-viewport-units';

interface LastUpdatedProps {
  isUpdate?: boolean;
  publishedAt?: string;
}

const Container = styled.div`
  font-size: ${pxToViewport(40)}rem;
  letter-spacing: ${pxToViewport(-0.4)}rem;
`;

const FromNowText = styled.span`
  font-weight: bold;
`;

const LastUpdated: React.FC<LastUpdatedProps> = ({ publishedAt, isUpdate }) => {
  const publishedAtDate = dayjs<string>(publishedAt);
  const [fromNowString, setFromNowString] = useState<string>(
    dayjs().to(publishedAtDate),
  );

  useEffect(() => {
    const pid = setInterval(() => {
      const newFromNow = dayjs().to(publishedAtDate);
      setFromNowString(newFromNow);
    }, 1000);

    return () => clearInterval(pid);
  }, []);

  if (!publishedAtDate || !fromNowString) {
    return null;
  }

  const twelveHourTimeString = publishedAtDate.format('h:mm A'); // e.g. 6:46 PM
  const actionText = isUpdate ? 'Updated' : 'Posted';

  return (
    <Container>
      {actionText} <FromNowText>{fromNowString}</FromNowText> at{' '}
      {twelveHourTimeString}
    </Container>
  );
};

export default LastUpdated;
