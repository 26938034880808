import React from 'react';

const MTALogo: React.FC<{ fill: string; size: number }> = ({ fill, size }) => (
  <svg
    style={{
      display: 'flex',
      flexBasis: `${size}rem`,
    }}
    width={`${size}rem`}
    height={`${size}rem`}
    viewBox="0 0 80 80"
  >
    <title>MTA Logo</title>
    <g stroke="none" fill="none" fillRule="evenodd">
      <path
        d="M54.1646091,33.3834586 L49.6584362,33.0827068 L49.6584362,55.9398496 L43.3497942,57.1428571 L43.3497942,32.481203 L37.9423868,31.8796992 L37.9423868,22.8571429 L54.1646091,25.5639098 L54.1646091,33.3834586 Z M66.4814815,53.2330827 L62.5761317,53.8345865 L62.2757202,50.5263158 L58.6707819,51.1278195 L58.0699588,54.4360902 L53.2633745,55.3383459 L58.0699588,26.4661654 L62.5761317,27.3684211 L66.4814815,53.2330827 Z M61.3744856,44.8120301 L60.473251,36.0902256 L60.1728395,36.0902256 L59.2716049,45.112782 L61.3744856,44.8120301 Z M40.0452675,80 C61.9753086,80 80,62.2556391 80,40 C80,17.7443609 62.2757202,0 40.0452675,0 C26.2263374,0 14.2098765,6.91729323 7,17.7443609 L18.1152263,19.5488722 L21.1193416,42.7067669 C21.1193416,42.7067669 21.4197531,42.7067669 21.4197531,42.7067669 L24.7242798,20.7518797 L35.8395062,22.556391 L35.8395062,58.3458647 L28.9300412,59.5488722 L28.9300412,44.2105263 C28.9300412,44.2105263 29.2304527,37.593985 28.9300412,36.3909774 C28.9300412,36.3909774 28.6296296,36.3909774 28.6296296,36.3909774 L24.4238683,60.1503759 L17.5144033,61.3533835 L12.4074074,36.0902256 L12.1069959,36.0902256 C12.1069959,36.9924812 12.4074074,45.112782 12.4074074,45.112782 L12.4074074,62.8571429 L7.90123457,63.7593985 C15.1111111,73.3834586 26.8271605,80 40.0452675,80 Z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

MTALogo.defaultProps = {
  fill: '#000000',
  size: 7.4,
};

export default MTALogo;
