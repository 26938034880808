"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortedRoutes = exports.ORDERED_ROUTES = void 0;
/*
  This helper lets us sort routes according to arbitrary orders as dictated by the MTA.
  If you want to sort routes not in this list in a way Postgres supports, like alphabetically,
    please do so on the server-side.
*/
exports.ORDERED_ROUTES = [
    'MTASBWY:1',
    'MTASBWY:2',
    'MTASBWY:3',
    'MTASBWY:4',
    'MTASBWY:5',
    'MTASBWY:6',
    'MTASBWY:6X',
    'MTASBWY:7',
    'MTASBWY:7X',
    'MTASBWY:A',
    'MTASBWY:C',
    'MTASBWY:E',
    'MTASBWY:B',
    'MTASBWY:D',
    'MTASBWY:F',
    'MTASBWY:FX',
    'MTASBWY:M',
    'MTASBWY:G',
    'MTASBWY:J',
    'MTASBWY:Z',
    'MTASBWY:L',
    'MTASBWY:N',
    'MTASBWY:Q',
    'MTASBWY:R',
    'MTASBWY:W',
    'MTASBWY:FS',
    'MTASBWY:GS',
    'MTASBWY:H',
    'MTASBWY:SI',
    'MNR:2',
    'MNR:1',
    'MNR:3',
    'MNR:5',
    'MNR:4',
    'MNR:6',
];
const getRouteIndex = (routeGtfsId) => {
    const index = exports.ORDERED_ROUTES.indexOf(routeGtfsId);
    return index === -1 ? exports.ORDERED_ROUTES.length : index;
};
function sortedRoutes(routes) {
    // `sort` mutates the array, so better to copy the array first.
    return routes.slice().sort((a, b) => getRouteIndex(a.gtfsId) - getRouteIndex(b.gtfsId));
}
exports.sortedRoutes = sortedRoutes;
