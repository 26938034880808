import React from 'react';
import styled from 'styled-components';
import { FeedId } from '@mta-live-media-manager/shared';

import pxToViewport from '../../../utils/px-to-viewport-units';
import MoonIcon from '../../Icons/Moon';

import { PlannedWorkAffectedRoute } from '../../../types';
import PortraitRouteBulletList from './PortraitRouteBulletList';
import PortraitRouteList from './PortraitRouteList';
import getHeadlineFontSize from '../../../utils/get-pw-headline-font-size';

const Container = styled.div`
  min-height: ${pxToViewport(320)}rem;
  background-color: #dddddd;
  display: flex;
`;

const TimeMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  flex: 0 0 ${pxToViewport(650)}rem;
  flex-basis: ${pxToViewport(650)}rem;
  padding: ${pxToViewport(32)}rem 0 ${pxToViewport(32)}rem
    ${pxToViewport(24)}rem;
`;

const HumanReadableDurations = styled.div`
  display: flex;
  font-size: ${pxToViewport(48)}rem;
  line-height: ${pxToViewport(56)}rem;
  letter-spacing: ${pxToViewport(-1.92)}rem;
  margin-bottom: ${pxToViewport(32)}rem;
  white-space: pre-line;
`;

const Headline = styled.div`
  display: flex;
  align-items: center;
  line-height: ${pxToViewport(80)}rem;
  letter-spacing: ${pxToViewport(-3.2)}rem;
`;

const HeadlineText = styled.div<{
  isAtNight: boolean;
  characterCount: number;
}>`
  ${props => props.isAtNight && `margin-left: ${pxToViewport(16)}rem;`}
  ${props => `font-size: ${getHeadlineFontSize(props.characterCount)}rem;`}
`;

const PortraitSubeader: React.FC<{
  isAtNight: boolean;
  headline: string;
  humanReadableDurations: string;
  affectsADA: boolean;
  affectedRoutes: PlannedWorkAffectedRoute[];
  feedId: FeedId;
}> = ({
  isAtNight,
  headline,
  humanReadableDurations,
  affectsADA,
  affectedRoutes,
  feedId,
}) => {
  return (
    <Container>
      <TimeMessages>
        <Headline>
          {isAtNight && <MoonIcon size={pxToViewport(72)} />}
          <HeadlineText
            isAtNight={isAtNight}
            characterCount={headline.length ?? 0}
          >
            {headline}
          </HeadlineText>
        </Headline>
        <HumanReadableDurations>
          {humanReadableDurations}
        </HumanReadableDurations>
      </TimeMessages>
      {feedId === FeedId.NYCTSubway ? (
        <PortraitRouteBulletList
          affectedRoutes={affectedRoutes}
          affectsADA={affectsADA}
        />
      ) : (
        <PortraitRouteList affectedRoutes={affectedRoutes} />
      )}
    </Container>
  );
};

export default PortraitSubeader;
