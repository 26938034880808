import React from 'react';
import styled from 'styled-components';
import { getFlattenedGtfsKey } from '@mta-live-media-manager/shared';
import pxToViewport from '../../../utils/px-to-viewport-units';
import ADAIcon from '../../Icons/Accessibility';
import { PlannedWorkAffectedRoute } from '../../../types';

const BulletsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ADAContainer = styled.div`
  display: flex;
  padding-top: ${pxToViewport(24)}rem;
`;

// Returns a bullet's sizes in rem
const getBulletSize = (numberOfIcons: number): number => {
  if (numberOfIcons === 1) {
    return pxToViewport(250);
  }

  if (numberOfIcons === 2) {
    return pxToViewport(160);
  }

  if (numberOfIcons > 2) {
    return pxToViewport(104);
  }

  return 0;
};

const AffectedRoutesContainer = styled.div<{
  iconNumber: number;
}>`
  display: flex;
  flex-wrap: wrap;
  padding: ${pxToViewport(24)}rem;
  flex-basis: ${props =>
    props.iconNumber > 2 ? `${pxToViewport(270)}rem` : 'auto'};
`;

const AffectedRoute = styled.span<{
  addMargin: boolean;
  iconSize: number;
}>`
  display: flex;
  width: ${props => props.iconSize}rem !important;
  height: ${props => props.iconSize}rem !important;
  ${props => props.addMargin && `margin-right: ${pxToViewport(12)}rem;`}
`;

const PortraitRouteBulletList: React.FC<{
  affectsADA: boolean;
  affectedRoutes: PlannedWorkAffectedRoute[];
}> = ({ affectsADA, affectedRoutes }) => {
  const iconNumber =
    (affectedRoutes.length >= 4 ? 4 : affectedRoutes.length) +
    (affectsADA ? 1 : 0);

  const iconSize = getBulletSize(iconNumber);

  return (
    <BulletsContainer>
      {affectsADA && (
        <ADAContainer>
          <ADAIcon size={iconSize} />
        </ADAContainer>
      )}
      <AffectedRoutesContainer iconNumber={iconNumber}>
        {affectedRoutes.slice(0, 4).map((r, index) => (
          <AffectedRoute
            key={r.gtfsRouteId}
            addMargin={index % 2 === 0 && affectedRoutes.length > 1}
            data-bullet-route-id={getFlattenedGtfsKey(r.gtfsRouteId)}
            iconSize={iconSize}
          />
        ))}
      </AffectedRoutesContainer>
    </BulletsContainer>
  );
};

export default PortraitRouteBulletList;
