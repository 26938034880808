import * as Sentry from '@sentry/browser';

const { ENVIRONMENT, GIT_RELEASE } = process.env;

const sentryEnabled = process.env.DEBUG === 'true';
if (sentryEnabled) {
  Sentry.init({
    dsn: 'https://2a7e191341c84bed9aed17dc7fe92113@sentry.io/1724687',
    environment: ENVIRONMENT,
    release: GIT_RELEASE,
  });
}

export function captureException(exception: any) {
  if (sentryEnabled) {
    Sentry.captureException(exception);
  }
}

export function captureMessage(message: string, level?: Sentry.Severity) {
  if (sentryEnabled) {
    Sentry.captureMessage(message, level);
  }
}

export default Sentry;
