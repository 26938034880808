import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const Template: React.FC = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Template;
