import React from 'react';
import styled from 'styled-components';

import pxToViewport from '../../../utils/px-to-viewport-units';

const PortraitHeader: React.FC<{
  title?: string;
}> = ({ title, children }) => {
  const Title = styled.h1`
    line-height: ${pxToViewport(140)}rem;
    letter-spacing: -${pxToViewport(1)}rem;
    font-size: ${pxToViewport(60)}rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const PageHeader = styled.header`
    background: #000;
    color: #fff;
    padding: ${pxToViewport(0)}rem ${pxToViewport(75)}rem;
  `;

  return (
    <>
      {title ? (
        <PageHeader>
          <Title>{title}</Title>
          {children}
        </PageHeader>
      ) : (
        children
      )}
    </>
  );
};

export default PortraitHeader;
