import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import pxToViewport from '../../../utils/px-to-viewport-units';
import LastUpdated from './LastUpdated';
import { EMPTY_CONTENT, FORMATTED_REGEX } from '../../../constants';

type AlertType = {
  content: string;
  orientation: 'portrait' | 'landscape';
  additionalContent?: string;
  additionalContentBgColor?: string;
  contentBgColor?: string;
  isUpdate?: boolean;
  publishedAt?: string;
  isDup?: boolean;
};

const Spacer = styled.div`
  margin-top: ${pxToViewport(25)}rem;
`;

const Alert: React.FC<AlertType> = ({
  content,
  additionalContent,
  isUpdate,
  publishedAt,
  orientation,
  contentBgColor = '#fff',
  additionalContentBgColor = '#f6f6f6',
  isDup = false,
}) => {
  const isLandscape = orientation === 'landscape';

  const AlertContainer = styled.div`
    width: 100%;
    height: ${isDup ? `calc(100% - ${pxToViewport(20)}rem)` : '100%'};
    position: relative;
    flex: 1 1 auto;
    padding: ${isDup
      ? '3.6rem 2rem'
      : `${pxToViewport(isLandscape ? 45 : 85)}rem ${pxToViewport(75)}rem`};
    background-color: ${isDup ? '#000' : contentBgColor};
    font-size: ${isDup
      ? pxToViewport(68)
      : pxToViewport(isLandscape ? 100 : 115)}rem;
    word-break: break-word;
    color: ${isDup ? '#fff' : '#000'};

    p {
      font-size: inherit;
      line-height: ${isDup
        ? pxToViewport(70)
        : pxToViewport(isLandscape ? 115 : 140)}rem !important;
      margin: 0;
      min-height: 1px;
      letter-spacing: ${pxToViewport(isDup ? 0 : -2.4)}rem;
    }

    p:empty {
      margin-top: ${pxToViewport(50)}rem;
    }

    p:first-of-type {
      margin-top: 0;
    }

    ${isDup
      ? `[data-bullet-route-id^='mtasbwy-'] {
            width: ${pxToViewport(70)}rem;
          }

          [data-bullet-route-id^='li'],
          [data-bullet-route-id^='mnr'],
          [data-bullet-route-id^='njt'] {
            display: inline;
            font-weight: bold;

            /* Override bullet style attributes in HTML */
            color: white !important;
            background-color: inherit !important;
          }`
      : ''}
  `;

  const AdditionalContentContainer = styled.div`
    background-color: ${additionalContentBgColor};
    margin-top: ${pxToViewport(isLandscape ? 40 : 60)}rem;
    padding: ${pxToViewport(30)}rem ${pxToViewport(25)}rem;
    font-size: ${isLandscape ? pxToViewport(46) : pxToViewport(38)}rem;
    letter-spacing: ${isLandscape
      ? pxToViewport(-1.5)
      : pxToViewport(-0.85)}rem;

    h2,
    h3 {
      margin: ${pxToViewport(8)}rem 0;
    }

    ul {
      margin: ${pxToViewport(8)}rem 0;
      padding: 0;
    }

    li {
      list-style: inside;

      > * {
        margin: 0;
        margin-left: -${pxToViewport(20)}rem;
        display: inline-block;

        /* targeting Firefox */
        @-moz-document url-prefix() {
          margin-left: 0;
        }
      }
      > p {
        display: initial;
      }
    }

    p {
      line-height: ${pxToViewport(isLandscape ? 60 : 50)}rem !important;
    }

    p:empty {
      margin-top: ${pxToViewport(19)}rem;
    }

    [data-bullet-route-id^='mtasbwy-'] {
      width: ${pxToViewport(43)}rem;
    }

    [data-bullet-display^='ada'],
    [data-bullet-display^='shuttle_bus'],
    [data-bullet-display^='airplane'] {
      width: ${pxToViewport(43)}rem;
    }
  `;

  const StripedDiv = styled.div`
    background: repeating-linear-gradient(
      -45deg,
      #f8ca03,
      #f8ca03 10px,
      #000000 10px,
      #000000 20px
    );
    height: ${pxToViewport(20)}rem;
    margin-top: -${pxToViewport(20)}rem;
  `;

  const formattedContent = content ? content.replace(FORMATTED_REGEX, ' ') : '';
  const formattedAdditionalContent = additionalContent
    ? additionalContent.replace(FORMATTED_REGEX, ' ')
    : '';

  const isContentNotEmpty =
    !!formattedContent && formattedContent !== EMPTY_CONTENT;
  const isAdditionalContentNotEmpty =
    !!formattedAdditionalContent &&
    formattedAdditionalContent !== EMPTY_CONTENT;

  return (
    <>
      {isDup && <StripedDiv />}
      <AlertContainer>
        {ReactHtmlParser(formattedContent)}
        {publishedAt && isContentNotEmpty && (
          <>
            <Spacer />
            <LastUpdated publishedAt={publishedAt} isUpdate={isUpdate} />
          </>
        )}
        {isAdditionalContentNotEmpty && (
          <AdditionalContentContainer>
            {ReactHtmlParser(formattedAdditionalContent)}
          </AdditionalContentContainer>
        )}
      </AlertContainer>
    </>
  );
};

export default Alert;
