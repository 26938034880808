import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ADAIcon from '../../Icons/Accessibility';
import pxToViewport from '../../../utils/px-to-viewport-units';
import { EMPTY_CONTENT, FORMATTED_REGEX } from '../../../constants';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  flex: 1;
`;

const Title = styled.div`
  display: flex;
  font-size: ${pxToViewport(40)}rem;
  font-weight: bold;
  letter-spaning: ${pxToViewport(-1)}rem;
  line-height: ${pxToViewport(48)}rem;
  text-transform: uppercase;
  margin: ${pxToViewport(24)}rem;
  margin-bottom: 0;
  padding-bottom: ${pxToViewport(16)}rem;
  height: fit-content;
  border-bottom: 1px solid black;
`;

const DescriptionContainer = styled.div`
  p {
    font-size: ${pxToViewport(112)}rem;
    line-height: ${pxToViewport(120)}rem !important;
    margin: 0;
    min-height: 1px;
    letter-spacing: ${pxToViewport(-2.4)}rem;
  }

  p:empty {
    margin-top: ${pxToViewport(50)}rem;
  }

  p:first-of-type {
    margin-top: 0;
  }

  margin: ${pxToViewport(41)}rem ${pxToViewport(48)}rem ${pxToViewport(24)}rem
    ${pxToViewport(48)}rem;
`;

const AdditionalInfoContainer = styled.div`
  background-color: #f6f6f6;
  font-size: ${pxToViewport(48)}rem;
  letter-spacing: ${pxToViewport(-2.16)}rem;
  margin: 0 ${pxToViewport(24)}rem 0 ${pxToViewport(24)}rem;
  padding: ${pxToViewport(24)}rem;

  h2,
  h3 {
    margin: ${pxToViewport(8)}rem 0;
  }

  ul {
    margin: ${pxToViewport(8)}rem 0;
    padding: 0;
  }

  li {
    list-style: inside;

    > * {
      margin: 0;
      margin-left: -${pxToViewport(20)}rem;
      display: inline-block;

      /* targeting Firefox */
      @-moz-document url-prefix() {
        margin-left: 0;
      }
    }
    > p {
      display: initial;
    }
  }

  p {
    line-height: ${pxToViewport(56)}rem !important;
    margin: 0;
  }

  p:empty {
    margin-top: ${pxToViewport(19)}rem;
  }

  [data-bullet-route-id^='mtasbwy-'] {
    width: ${pxToViewport(43)}rem;
  }

  [data-bullet-display^='ada'],
  [data-bullet-display^='shuttle_bus'],
  [data-bullet-display^='airplane'] {
    width: ${pxToViewport(43)}rem;
  }
`;

const AccessibilityMessageContainer = styled.div`
  display: flex;
  margin: ${pxToViewport(24)}rem;
  font-size: ${pxToViewport(44)}rem;
  line-height: ${pxToViewport(52)}rem;
  letter-spacing: ${pxToViewport(-1.26)}rem;
`;

const AccessibilityMessageText = styled.span`
  margin-left: ${pxToViewport(24)}rem;
`;

const Message: React.FC<{
  title?: string;
  affectsADA?: boolean;
  description: string;
  additionalInfo?: string;
}> = ({ title, affectsADA = false, description, additionalInfo }) => {
  const formattedDescription = description
    ? description.replace(FORMATTED_REGEX, ' ')
    : '';

  const formattedAdditionalInfo = additionalInfo
    ? additionalInfo.replace(FORMATTED_REGEX, ' ')
    : '';
  const isAdditionalInfoNotEmpty =
    !!formattedAdditionalInfo && formattedAdditionalInfo !== EMPTY_CONTENT;

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <DescriptionContainer>
        {ReactHtmlParser(formattedDescription)}
      </DescriptionContainer>
      {isAdditionalInfoNotEmpty && (
        <AdditionalInfoContainer>
          {ReactHtmlParser(formattedAdditionalInfo)}
        </AdditionalInfoContainer>
      )}
      {affectsADA && (
        <AccessibilityMessageContainer>
          <ADAIcon size={pxToViewport(104)} />
          <AccessibilityMessageText>
            This service change affects one or more ADA accessible stations.
          </AccessibilityMessageText>
        </AccessibilityMessageContainer>
      )}
    </Container>
  );
};

export default Message;
