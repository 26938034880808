import React, { useEffect, useState } from 'react';

import {
  ScreenContentAttributes as AlertScreenContentProps,
  FeedId,
} from '@mta-live-media-manager/shared';
import AlertScreenContent from './Alert/ScreenContent';
import PlannedWorkScreenContent from './PlannedWork/ScreenContent';

import {
  PlannedWorkTemplateProps,
  ScreenMetadata,
  ScreenType,
  ScreenOrientation,
  IAlertScreenPreview,
} from '../types';

enum ScreenPreviewType {
  ALERT = 'ALERT',
  PLANNED_WORK = 'PLANNED_WORK',
}

const defaultMetadata: ScreenMetadata = {
  assetId: '',
  deviceId: '',
  height: 1920,
  feedId: FeedId.NYCTSubway,
  width: 1080,
  name: '',
  orientation: ScreenOrientation.PORTRAIT,
  type: ScreenType.CIC,
  stop: {
    gtfsId: '',
    name: 'Stop Name',
  },
};

const ScreenRenderer: React.FC = () => {
  const [metadata, setMetadata] = useState<ScreenMetadata>(defaultMetadata);
  const [screenProps, setScreenProps] = useState<
    AlertScreenContentProps | PlannedWorkTemplateProps
  >();
  const [screenPreviewType, setScreenPreviewType] = useState<
    ScreenPreviewType
  >();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { default: Penpal } = await import('penpal');
      Penpal.connectToParent({
        // Methods child is exposing to parent
        methods: {
          renderAlert: ({
            feedId,
            content,
            additionalContent,
            messageType,
            title,
            affectedRoutes,
            publishAt,
            allRoutesAffected = false,
            isUpdate = false,
            isLandscape = false,
            isDup = false,
          }: IAlertScreenPreview): void => {
            setMetadata({
              ...defaultMetadata,
              feedId,
              orientation:
                isLandscape || isDup
                  ? ScreenOrientation.LANDSCAPE
                  : ScreenOrientation.PORTRAIT,
              type: isDup ? ScreenType.DUP : ScreenType.CIC,
              stop: {
                name: 'Stop Name',
              },
            });

            setScreenProps({
              messageType,
              title,
              content,
              additionalContent,
              affectedRoutes,
              allRoutesAffected,
              publishAt,
              isUpdate,
              isLandscape,
            });

            setScreenPreviewType(ScreenPreviewType.ALERT);
          },
          renderPlannedWork: ({
            feedId,
            isLandscape,
            messageType,
            description,
            headline,
            humanReadableDurations,
            isAtNight,
            additionalInfo,
            affectedRoutes,
            affectsADA,
            title,
            isDup,
          }: PlannedWorkTemplateProps): void => {
            setScreenProps({
              feedId,
              isLandscape,
              messageType,
              description,
              headline,
              humanReadableDurations,
              isAtNight,
              additionalInfo,
              affectedRoutes,
              affectsADA,
              title,
              isDup,
            } as PlannedWorkTemplateProps);

            setScreenPreviewType(ScreenPreviewType.PLANNED_WORK);
          },
        },
      });
    };

    fetchData();
  }, []);

  const isLandscape =
    metadata.orientation?.toUpperCase() === ScreenOrientation.LANDSCAPE;
  const isDup =
    metadata.type === ScreenType.DUP &&
    metadata.orientation?.toUpperCase() === ScreenOrientation.LANDSCAPE;

  return (
    <>
      {screenPreviewType === ScreenPreviewType.ALERT && (
        <AlertScreenContent
          feedId={metadata.feedId}
          attributes={screenProps as AlertScreenContentProps}
          isLandscape={isLandscape}
          isDup={isDup}
        />
      )}
      {screenPreviewType === ScreenPreviewType.PLANNED_WORK && (
        <PlannedWorkScreenContent
          {...(screenProps as PlannedWorkTemplateProps)}
        />
      )}
    </>
  );
};

export default ScreenRenderer;
