import React from 'react';
import styled from 'styled-components';

import {
  MESSAGE_TYPE_DESCRIPTIONS,
  databaseToGQLEnum,
  MessageType,
} from '@mta-live-media-manager/shared';
import pxToViewport from '../../utils/px-to-viewport-units';
import LandscapeHeader from './Body/LandscapeHeader';
import LandscapeRouteList from './Body/LandscapeRouteList';

import Footer from './Body/Footer';
import LandscapeSubheader from './Body/LandscapeSubheader';
import { PlannedWorkAffectedRoute } from '../../types';
import Message from './Body/Message';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const LeftBar = styled.div`
  background-color: black;
  display: flex;
  flex: 0 0 ${pxToViewport(768)}rem;
  flex-direction: column;
  color: #ffffff;
  font-weight: bold;
  justify-content: space-between;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface LandscapeProps {
  messageType?: MessageType;
  isAtNight: boolean;
  headline: string;
  humanReadableDurations: string;
  affectedRoutes: PlannedWorkAffectedRoute[];
  title?: string;
  description: string;
  additionalInfo?: string;
}

const Landscape: React.FC<LandscapeProps> = ({
  messageType,
  isAtNight,
  headline,
  humanReadableDurations,
  affectedRoutes,
  description,
  additionalInfo,
  title,
}) => {
  const status = (() => {
    if (messageType) {
      return MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)];
    }

    return 'Planned Work';
  })();

  return (
    <Container>
      <LeftBar>
        <DetailsContainer>
          <LandscapeHeader status={status} />
          <LandscapeSubheader
            isAtNight={isAtNight}
            headline={headline}
            humanReadableDurations={humanReadableDurations}
          />
          <LandscapeRouteList affectedRoutes={affectedRoutes} />
        </DetailsContainer>
        <Footer logoSize={pxToViewport(40)} />
      </LeftBar>
      <Message
        title={title}
        description={description}
        additionalInfo={additionalInfo}
      />
    </Container>
  );
};

export default Landscape;
