import React from 'react';

const Moon: React.FC<{
  size: number;
  isInverted?: boolean;
}> = ({ size, isInverted = false }) => {
  return (
    <svg
      style={{
        display: 'flex',
        flexBasis: `${size}rem`,
      }}
      width={`${size}rem`}
      height={`${size}rem`}
      viewBox="0 0 72 72"
    >
      <rect
        fill={isInverted ? '#FFFFFF' : '#000000'}
        width="72"
        height="72"
        rx="12"
      />
      <path
        fill={isInverted ? '#231F20' : '#FFFFFF'}
        d="M27.1 8.67c-13 26 9.54 52.05 34.7 42.51C30.57 87.61-16.28 33.83 27.1 8.67Z"
      />
    </svg>
  );
};

export default Moon;
