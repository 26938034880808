import React from 'react';
import styled from 'styled-components';

import {
  MESSAGE_TYPE_DESCRIPTIONS,
  databaseToGQLEnum,
  MessageType,
  FeedId,
} from '@mta-live-media-manager/shared';
import Footer from './Body/Footer';
import PortraitHeader from './Body/PortraitHeader';

import pxToViewport from '../../utils/px-to-viewport-units';
import PortraitSubheader from './Body/PortraitSubheader';
import Message from './Body/Message';
import { PlannedWorkAffectedRoute } from '../../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

interface PortraitProps {
  messageType?: MessageType;
  isAtNight: boolean;
  headline: string;
  humanReadableDurations: string;
  affectsADA: boolean;
  affectedRoutes: PlannedWorkAffectedRoute[];
  feedId: FeedId;
  description: string;
  additionalInfo?: string;
  title?: string;
}

const Portrait: React.FC<PortraitProps> = ({
  messageType,
  isAtNight,
  headline,
  humanReadableDurations,
  title,
  affectsADA,
  description,
  additionalInfo,
  affectedRoutes,
  feedId,
}) => {
  const status = (() => {
    if (messageType) {
      return MESSAGE_TYPE_DESCRIPTIONS[databaseToGQLEnum(messageType)];
    }

    return 'Planned Work';
  })();

  return (
    <Container>
      <PortraitHeader title={status ?? 'Planned Work'} />
      <PortraitSubheader
        isAtNight={isAtNight}
        headline={headline}
        humanReadableDurations={humanReadableDurations}
        affectsADA={affectsADA && feedId === FeedId.NYCTSubway}
        affectedRoutes={affectedRoutes}
        feedId={feedId}
      />
      <Message
        title={title}
        affectsADA={affectsADA}
        description={description}
        additionalInfo={additionalInfo}
      />
      <Footer logoSize={pxToViewport(32)} />
    </Container>
  );
};

export default Portrait;
