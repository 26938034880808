// Should polyfill for Chromium version
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './utils/sentry';

document.onreadystatechange = (): void => {
  if (document.readyState === 'interactive') {
    ReactDOM.render(<App />, document.getElementById('template'));
  }
};
