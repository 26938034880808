import React from 'react';
import styled from 'styled-components';
import pxToViewport from '../../../utils/px-to-viewport-units';
import MTALogo from '../../Icons/MTALogo';

const PageFooter = styled.footer`
  background: #000;
  color: #fff;
  display: flex;
  padding: ${pxToViewport(24)}rem ${pxToViewport(24)}rem;
  justify-content: space-between;
  font-size: ${pxToViewport(32)}rem;
  line-height: ${pxToViewport(32)}rem;
  font-weight: bold;
  align-items: center;

  svg {
    display: block;
    vertical-align: baseline;
  }
`;

const Footer = ({ logoSize }: { logoSize: number }): JSX.Element => (
  <PageFooter>
    Visit mta.info for more information
    <MTALogo fill="#FFFFFF" size={logoSize} />
  </PageFooter>
);

export default Footer;
