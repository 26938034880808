import React from 'react';
import styled from 'styled-components';

import MoonIcon from '../../Icons/Moon';
import pxToViewport from '../../../utils/px-to-viewport-units';
import getHeadlineFontSize from '../../../utils/get-pw-headline-font-size';

const Headline = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pxToViewport(80)}rem;
  line-height: ${pxToViewport(80)}rem;
  letter-spacing: ${pxToViewport(-3.2)}rem;
  margin-left: ${pxToViewport(24)}rem;
`;

const HumanReadableDurations = styled.div`
  display: flex;
  font-size: ${pxToViewport(48)}rem;
  line-height: ${pxToViewport(56)}rem;
  letter-spacing: ${pxToViewport(-1.92)}rem;
  white-space: pre-line;
  margin: ${pxToViewport(20)}rem ${pxToViewport(24)}rem ${pxToViewport(32)}rem
    ${pxToViewport(24)}rem;
`;

const HeadlineText = styled.div<{
  isAtNight: boolean;
  characterCount: number;
}>`
  ${props => props.isAtNight && `margin-left: ${pxToViewport(16)}rem;`}
  ${props => `font-size: ${getHeadlineFontSize(props.characterCount)}rem;`}
`;

const LandscapeSubheader: React.FC<{
  isAtNight: boolean;
  headline: string;
  humanReadableDurations: string;
}> = ({ isAtNight, headline, humanReadableDurations }) => {
  return (
    <>
      <Headline>
        {isAtNight && <MoonIcon size={pxToViewport(72)} isInverted />}
        <HeadlineText
          isAtNight={isAtNight}
          characterCount={headline?.length ?? 0}
        >
          {headline}
        </HeadlineText>
      </Headline>
      <HumanReadableDurations>{humanReadableDurations}</HumanReadableDurations>
    </>
  );
};

export default LandscapeSubheader;
